import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useModel } from "../../contexts/modelContext";
import BaseField from "./baseField";
import { IPropsInputField, IPropsOptions } from "./fieldPropertiesInterface";
import modelHelper from "./modelHelper";
import "./typeaheadField.scss";

type IPropsTypeaheadField = IPropsInputField & IPropsOptions & { onChange?: (value: any) => void };

function TypeaheadField({
  options,
  model,
  attr,
  value,
  label,
  onChange,
  required,
  autoFocus
}: IPropsTypeaheadField) {
  const modelContext = useModel();
  const modelState = model || modelContext;
  const [ state, setState ] = modelState;
  const { getValue } = modelHelper({ model: modelState, attr, value, onChange });

  const onValueChange = (option: Array<Option>) => {
    if (state && attr) {
      const modelWithChange = {...state};
      modelWithChange[attr] = option;
      setState(modelWithChange);
      if (onChange) {
        onChange(option);
      } 
    }
  }

  return (
    <BaseField attr={attr} label={label} required={required} value={getValue()} >
      <Typeahead
          multiple
          id="state"
          inputProps={{ autoComplete: "off" }}
          onChange={onValueChange}
          selected={getValue() as Option[]}
          options={options}
          autoFocus={autoFocus}
          className="typeahead"
          placeholder=" "
      />
    </BaseField>
  );
}

export default TypeaheadField;