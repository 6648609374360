import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";

interface IProps {
    children: React.ReactElement;
    message: string,
}

const Tooltip = ({ children, message }: IProps): React.ReactElement => {
    const buildTooltip = () => {
        return (
            <OverlayTrigger
                placement={'top'}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <BootstrapTooltip>
                        {message}
                    </BootstrapTooltip>
                }
            >
                {children}
            </OverlayTrigger>
        );
    }

    return (
        <>
            { message ? buildTooltip() : children }
        </>
    );
  }
  
  export default Tooltip;
  