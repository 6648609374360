
import React, { Fragment, useCallback, useState } from "react";
import { SettlementService } from "../../services/SettlementService";
import {ISettlementOnboardingRequest} from "../../models/ISettlementData";
import { useTranslation } from 'react-i18next';
import Button from '../controls/buttons/button';
import Page from '../containers/page';
import ErrorsView from "../ErrorsView";
import Card from "../containers/card";
import Form from "../containers/form";
import NumericField from "../controls/fields/numericField";
import { AxiosResponse } from "axios";
import { Navigate } from "react-router-dom";
import { WebCache } from "../../services/WebCache";

interface IFilter{
    rutCuit: string,
    merchantNumber: string
}

const SettlementConf: React.FC = () =>{
    const { t } = useTranslation();
    const T_PREFIX: string = 'pages.settlementsConf.';    
    const [errors, setErrors] = useState<string[]>([]);
    const [filters, setFilters] = useState<IFilter>({
        rutCuit:"211319220018",
        merchantNumber:"18151754"
    });   
    const setConfiguration = useCallback(() => {
        const request: ISettlementOnboardingRequest = {
            FiscalDocument: filters.rutCuit,
            MerchantNumber: filters.merchantNumber
        };
        /*
        SettlementService.addOrDisabledOnboardingSettlement(request)
                         .then((response: AxiosResponse) => {
                            if(response.status === 200){
                               <Navigate to={"/dashboard"}/>
                            } else{
                                setErrors(["Error"]);
                            } 
                         })
                         .catch((error) => {
                            setErrors(error.response.data);
                         }).finally(() => WebCache.GetSettlementEnabled());
                            */
                           
    }, []);   

    return (<Page>                    
            <Card><Fragment>
                <h2>{t(`${T_PREFIX}title`)}</h2>                   
                <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={errors} />
                        </div>
                    </div>
                <Form model={[ filters, setFilters.bind(this) ]}>
                <div className="row">
                    <div className="col-lg-6">                 
                    <NumericField attr="merchantNumber" 
                                   label={`${T_PREFIX}merchantNumber`}></NumericField>
                    </div>                         
                    <div className="col-lg-6">                        
                            <NumericField attr="rutCuit"                                          
                                   label={`${T_PREFIX}rutCuit`}></NumericField> 
                    </div> 
                     </div> 
                    </Form>
               
                <div className="row">
                    <div className="col-lg-12" >                    
                        <Button className='mt-2 ps-8 pe-8' label='accept' onClick={setConfiguration}/>
                     </div>
                </div>   
                </Fragment>
            </Card>  
        </Page> ) ;
}

export default SettlementConf;
