import { axiosApiInstance } from "./HttpService";
import { IManagementUser } from '../models/IUser';
import { IGetUserWebModelRequest } from "../requests/IGetUserWebModelRequest";
import { WebCache } from "./WebCache";
import axios from "axios";
import { IAuthenticateRequest, IOtpRequest } from "../requests/ILoginRequest";

export class UserService {

    public static login(request: IAuthenticateRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Users/authenticate`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static requestOtp(request: IOtpRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Users/requestOtp`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static logout() {
        WebCache.clearCache();
        axiosApiInstance.post(`${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/fireLogoutTasks`,{})
        window.location.href = '/#/login'

    }



    public static getUserProfile() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/userProfile`;
        return axiosApiInstance.get(dataURL);
    }

    public static getAll(userRequest: IGetUserWebModelRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/getUser`;
        return axiosApiInstance.post(dataURL, {
            skip: userRequest.Skip,
            take: userRequest.Take,
            rol: userRequest.Profile,
            email: userRequest.Email
        });
    }

    static saveUser(user: IManagementUser) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/createUser`;
        return axiosApiInstance.post(dataURL, user);
    }

    static updateUser(user: IManagementUser) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/updateUser`;
        return axiosApiInstance.post(dataURL, user);
    }

    static deleteUser(id: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/deleteUser`;
        return axiosApiInstance.post(dataURL, { id: id });
    }

    static getWebAuthnRegistrationOptions() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/getWebAuthnRegistrationOptions`;
        return axiosApiInstance.post(dataURL, {});
    }

    static webAuthnRegistration(request: any) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/webAuthnRegistration`;
        return axiosApiInstance.post(dataURL, request);
    }


    static assertionOptions() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Users/assertionOptions`;
        return axiosApiInstance.post(dataURL, { Username: localStorage.getItem('lastUserName') });
    }

    static makeAssertion(authenticatorAssertionRawResponse: any) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Users/makeAssertion`;
        return axiosApiInstance.post(dataURL, { Username: localStorage.getItem('lastUserName'), AuthenticatorAssertionRawResponse: authenticatorAssertionRawResponse });
    }



    public static resetDevices(useremail: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/resetDevices`;
        return axiosApiInstance.post(dataURL, {
            email: useremail
        });
    }

    public static sendEmailToRecoverPass(useremail: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/sendEmailForgotPassword`;
        return axiosApiInstance.post(dataURL, {
            email: useremail
        });
    }


    public static FinishResetDevices(token: string) {
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/finishResetDevices`;
        return axiosApiInstance.post(dataURL, {
            requestId:token
        }, config);
    }

    public static RecoverPass(password: string, token: string | undefined) {
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/recoverPassword`;
        return axiosApiInstance.post(dataURL, {
            password: password,
            requestId: token
        }, config);
    }


    static loginWithToken(merchantToken: string) {
        var promise = new Promise( (resolve, reject) => {
                let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Account/validateApiToken`;
                WebCache.clearCache();
                localStorage.setItem("user",JSON.stringify({token:merchantToken}));
                axiosApiInstance.post(dataURL, {}).then(function(res){
                        WebCache.clearCache();
                        let user=res.data.data;
                        user.token=merchantToken;
                        localStorage.setItem("user",JSON.stringify(user));
                        resolve("Promise resolved successfully");
                    })

                })
        return promise;
    }

    public static GetExcel(id: string) {
        return axiosApiInstance.post(`${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/getExcel`, { id: id },{
            responseType: 'blob' // don't forget this,
            
        })
    }

    public static getCsvOnline(body: string,fileName:string) {
        return axiosApiInstance.post(`${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/getCsvOnline`, { body: body,fileName:fileName },{
            responseType: 'blob' // don't forget this,

        })
    }

    public static GetExcelPdf(id: string, fileName: string) {
        return axiosApiInstance.post(`${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/getExcel`, { 
            id: id,
            fileName: fileName },{
            responseType: 'blob' // don't forget this,
            
        })
    }

    public static getAllTasks(take:number) {
        return axiosApiInstance.post(`${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/getAllTasks`,{take:take})
    }

    public static lostDeviceNotify(user: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Users/lostDeviceNotify/${user}`;
        return axiosApiInstance.post(dataURL, user);
    }

}
