import React, {RefObject, useEffect, useRef, useState} from 'react';
//import "../assets/plugins/select2/select2.full.min.js"
import SalesChart from "./charts/SalesChart";
import IssuerChart from "./charts/IssuersChart";
import CardTypeChart from "./charts/CardTypeChart";
import OperationsChart from "./charts/OperationsChart";
import {IBarChartData, IPeriodIndicator} from "../models/IBarChartData";
import { DashboardService } from "../services/DashboardService";
import { UtilService } from "../services/UtilService";
import {IPieChartData, IRejectionIndicator} from "../models/IPieChartData";
import {useTranslation} from "react-i18next";
import PeriodIndicator from "./charts/PeriodIndicator";
import TransactionGrid from "./transactions/TransactionGrid";
import {TransactionService} from "../services/TransactionService";
import {AxiosResponse} from "axios";
import {allTransactionStatesFilter} from "../models/TransactionReportConstants";
import {ITransaction} from "../requests/ITransactionWebModelRequest";
import {Line} from "react-chartjs-2";
import ReverseIndicator from "./charts/ReverseIndicator";
import RejectionIndicator from "./charts/RejectionIndicator";
import {DateObject} from "react-multi-date-picker";
import AmountIndicator from "./charts/AmountIndicator";
import CountIndicator from "./charts/CountIndicator";
import CouponPromIndicator from "./charts/CouponPromIndicator";
import AuthorizationRateIndicator from "./charts/ApprovalRateIndicator";
import ApprovalRateIndicator from "./charts/ApprovalRateIndicator";
import AmountByIssuerChart from "./charts/AmountByIssuerChart";
import CountByIssuerChart from "./charts/CountByIssuerChart";
import RejectionByIssuerChart from "./charts/RejectionByIssuerChart";
import PlanChart from "./charts/RankingChart";
import ChartByIssuer from "./charts/ChartByIssuer";
import RankingChart from "./charts/RankingChart";
import i18next from "i18next";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
import TransactionList from "./transactions/TransactionList";
import { CurrencyHelper } from '../helpers/CurrencyHelper';
import Card from './containers/card';
import NetAmountIndicator from './charts/NetAmountIndicator';
import ReturnIndicator from './charts/ReturnIndicator';
import TaxIndicator from './charts/TaxIndicator';
import PercentageChart from './charts/PercentageChart';
import Page from '../components/containers/page';
import { UserService } from '../services/UserService';
import { WebCache } from '../services/WebCache';

const T_PREFIX: string = 'pages.Dashboard.';

interface IState {
    dateRange: string,
    currency: ICurrency,
    allTotal: IBarChartData,
    longPeriodIndicator: IPeriodIndicator,
    shortPeriodIndicator: IPeriodIndicator,
    conciliationShortPeriodIndicator: IPeriodIndicator,
    conciliationNextPeriodIndicator: IPeriodIndicator,
    approvalRateIndicator: IPeriodIndicator,
    rejectionIndicators: IRejectionIndicator,
    reverseIndicators: IPieChartData,
    issuerTotals: IPieChartData,
    acquirerTotals: IPieChartData,
    cardTypeTotals: IPieChartData,
    totalByChannel: IPieChartData,
    totalByWallet: IPieChartData,
    planTotals: IPieChartData,
    totalByBrand: IPieChartData,
    transactionTypeTotals: IBarChartData,
    errorMessage: string,
    initDahboard: boolean,
    reload: boolean,
    transactions: ITransaction[],
    columns: Array<string>
    errors: string[],
    columnsToShow: string[ ],
    activePage: number,
    mainPage: number,
    pages: number[],
    userCurrencies: ICurrencyOption[]
}

interface IProps { }

interface ICurrency {
    id: string;
    name: string;
}

interface ICurrencyOption {
    id: string;
    name: string;
}

let Dashboard: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");
    const [transactions, setTransactions] = useState<ITransaction[]>([]); 
    const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(false); 
    const [columns, setColumns] = React.useState<string[]>([]);

    
    let [state, setState] = useState<IState>({
        dateRange: 'today',
        currency: WebCache.getCurrencies()[0],
        userCurrencies: [],
        allTotal: {
            rows: [{
                label: "",
                color: "",
                values: [],
                count:[]
            }],
            labels: []
        },
        transactionTypeTotals: {
            rows: [{
                label: "",
                color: "",
                values: [],
                count:[]
            }],
            labels: []
        },
        issuerTotals: {
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        acquirerTotals: {
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        cardTypeTotals: {
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        totalByChannel: {
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        totalByWallet: {
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        planTotals:{
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        totalByBrand:{
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        longPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        shortPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        conciliationNextPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        approvalRateIndicator:DashboardService.createIPeriodIndicator([]),
        conciliationShortPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        errorMessage: "",
        initDahboard: true,
        reload: false,
        rejectionIndicators: {
            labels: [],
            colors: [],
            values: [],
            authValues: [],
            authCounts: [],
            totalCount:0,
            counts: []
        },
        reverseIndicators: {
            labels: [],
            colors: [],
            values: [],
            counts: []
        },
        transactions: [],
        errors: [],
        columns: [
        ],
        columnsToShow:["TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"],
        activePage: 1,
        mainPage: 0,
        pages: [1],
    });

    
    const pageSize=10;     
    useEffect(() => {
    if (!transactionsLoaded) {
        TransactionService.getAll({ From: UtilService.getYYYY_MM_DDMinusDaysFormat(365), To: UtilService.getYYYY_MM_DDMinusDaysFormat(0), Skip: 0, Take: pageSize })
            .then((response: AxiosResponse<ITransaction[]>) => {
                if (!response || !response.data || response.data.length === 0) {
                    setTransactions([]);
                    setColumns([]);
                    setTransactionsLoaded(true); 
                    return;
                }                
                // Obtener las columnas del primer elemento de la respuesta
                const newColumns = Object.keys(response.data[0]);
                // Actualizar el estado con los datos y las columnas
                setTransactions(response.data); 
                setColumns(newColumns);
                setTransactionsLoaded(true); 
            })
            .catch(error => {
                setTransactionsLoaded(true); 
                setTransactions([]);
                setColumns([]);
                console.error("Error:", error);
            });
    }
}, []);

    let refreshData = (): void => {
        state.reload = true;
        let startDate = UtilService.getYYYY_MM_DDFormat(new Date());
        let endDate = UtilService.getYYYY_MM_DDFormat(new Date());
        let accumulationCriteria = "Day";
        if (state.dateRange === "yesterday") {
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(1);
            endDate=startDate;
        } else if (state.dateRange === "7days") {
            accumulationCriteria = "Week"
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(6);
        } else if (state.dateRange === "30days") {
            accumulationCriteria = "Month"
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(30);
        } else if (state.dateRange === "1year") {
            accumulationCriteria = "Year"
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(365);
        }    

        DashboardService.getDashboard(startDate, endDate, state.currency.id, accumulationCriteria).then((response) => {
            let data = response.data;
            state.allTotal = data.allTotal;
            state.issuerTotals = data.totalByIssuer;
            state.totalByWallet = data.totalByWallet;
            state.acquirerTotals = data.totalByAcquirer;
            if(state.acquirerTotals!=null&&state.acquirerTotals.labels!=null)
                for(var i in state.acquirerTotals.labels){
                    state.acquirerTotals.labels[i]=i18next.t(state.acquirerTotals.labels[i]) as never;
                }

            state.cardTypeTotals = filterPieChartData(data.totalByCardType);
            state.totalByChannel = filterPieChartData(data.totalByChannel);


            state.totalByBrand = data.totalByBrand;
            if(WebCache.getCurrentUser()?.countryId==2) { //las cuotas no vienen de b24 por lo que si es arg se saca de la liqudiacion
                state.planTotals = data.totalByPlanType;
                if (state.planTotals != null && state.planTotals.labels != null)
                    for (var i in state.planTotals.labels) {
                        if (state.planTotals.labels[i] == "0")
                            state.planTotals.labels[i] = i18next.t("cash") as never;
                        else
                            state.planTotals.labels[i] = state.planTotals.labels[i] + " " + i18next.t("quotas") as never;
                    }
            }
            state.transactionTypeTotals = data.totalByTransactionType;
            state.longPeriodIndicator=data.longPeriodIndicator;
            state.shortPeriodIndicator=data.shortPeriodIndicator;
            state.approvalRateIndicator=data.approvalRateIndicator;

            state.rejectionIndicators=filterRejectionIndicators(data.rejectionIndicators);
            state.reverseIndicators=data.reverseIndicators;

            DashboardService.getSettlementDashboard(startDate, endDate, state.currency.id, accumulationCriteria).then((response) => {
                state.conciliationShortPeriodIndicator=response.data.shortPeriodIndicator;
                state.conciliationNextPeriodIndicator=response.data.nextPeriodIndicator;

                if(WebCache.getCurrentUser()?.countryId!=2) {
                    state.planTotals = response.data.totalByPlanType;
                    if (state.planTotals != null && state.planTotals.labels != null)
                        for (var i in state.planTotals.labels) {
                            if (state.planTotals.labels[i] == "0")
                                state.planTotals.labels[i] = i18next.t("cash") as never;
                            else
                                state.planTotals.labels[i] = state.planTotals.labels[i] + " " + i18next.t("quotas") as never;
                        }
                }

                setState({
                    ...state,
                    allTotal: data.allTotal,
                    reload: false
                })
            }).catch((error) => {
                setState({
                    ...state,
                    errorMessage: error.message,
                    reload: true
                })
            });
        }).catch((error) => {
            setState({
                ...state,
                errorMessage: error.message,
                reload: true
            })
        });
    }

    useEffect(() => {
        setState(prevState => ({...prevState, initDahboard: false}));
        if (!state.initDahboard) {
            refreshData();
        }
    }, [state.initDahboard, state.currency]); 
    
    const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedCurrencyId = event.target.value;
        setSelectedCurrency(selectedCurrencyId);
    
        const selectedCurrencyOption = WebCache.getCurrencies().find(currency => currency.id === selectedCurrencyId);
    
        if (selectedCurrencyOption) {
            setState(prevState => ({
                ...prevState,
                currency: {
                    id: selectedCurrencyOption.id,
                    name: selectedCurrencyOption.name
                }
            }));
        }
    };

    let handleTimeRangeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        state.dateRange = event.target.value;
        refreshData();
    }

    const getCurrencySymbol = (): string => {
        return CurrencyHelper.getCurrencySymbol(state.currency.id);
    }

    const netAmountIndicatorTitle = (): string => {
        return (state.dateRange === "today") ? t("amountToChargeToday") : t("chargedAmount");
    }

    /*
        Function that is responsible for filtering the data from the pie's dashboard graphics that come with "?" in their labels.
    */
    function filterPieChartData(data: IPieChartData): IPieChartData {
        const filteredData: IPieChartData = {
            labels:[],
            colors:[],
            values: [],
            counts: [],
        }
        for (let i = 0; i < data.labels.length; i++) {
            const label = data.labels[i];
            if (label !== "?") {
                // Data with label different from "?" is aded to the returned filtered data.
                filteredData.labels.push(label as never);
                filteredData.colors.push(data.colors[i] as never);
                filteredData.values.push(data.values[i] as never);
                filteredData.counts.push(data.counts[i]);
            }
        }
        return filteredData;
    }
    
    /*
        Function that is responsible for filtering the data from the pie's dashboard graphics that come with "?" in their labels.
    */
    function filterRejectionIndicators(data: IRejectionIndicator): IRejectionIndicator {
        const filteredData: IRejectionIndicator = {
            labels: [],
            colors: [],
            values: [],
            counts: [],
            authValues: [],
            authCounts: [],
            totalCount: data.totalCount,
        };
        
        for (let i = 0; i < data.labels.length; i++) {
            const label = data.labels[i];
            if (label !== "?") {
                // Append non-"?" labels to filtered data
                filteredData.labels.push(label as never);
                filteredData.colors.push(data.colors[i] as never);
                filteredData.values.push(data.values[i] as never);
                filteredData.counts.push(data.counts[i]);
                filteredData.authValues.push(data.authValues[i]);
                filteredData.authCounts.push(data.authCounts[i]);
            } else {
                // Subtract counts for "?" labels from totalCount to consider the reduced amount from the substracted data with "?" labels.
                filteredData.totalCount -= data.counts[i];
            }
        }
        return filteredData;
    }

    const componentRef:RefObject<any> = useRef();

    const currentUser = WebCache.getCurrentUser()?.countryId;
    let currencySelect;

    if (currentUser !== undefined && currentUser === 2) {
        currencySelect = (<select
            name="country"
            className="form-control form-select form-select-lg select2 currencyStyle"
            value={selectedCurrency}
            onChange={handleCurrencyChange}
        >
            { WebCache.getCurrencies().map((currency) => (
                <option key={currency.id} value={currency.id}>
                    {currency.name}
                </option>
            ))}
        </select>);
    } else {
        currencySelect = ``;
    }

    return (
            <Page>
                <div ref={componentRef}>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <h2>{t(`${T_PREFIX}dashboard`)}</h2>
                        </div>
                        <div className="form-group menuDashboard">
                            {currencySelect}
                            <div className="divFilter"></div>
                            <select className="form-control form-select form-select-lg select2 itemDashboard" value={state.dateRange} onChange={handleTimeRangeChange}>
                                <option value="today">{t('today')}</option>
                                <option value="yesterday">{t('yesterday')}</option>
                                <option value="7days">7 {t('days')}</option>
                                <option value="30days">30 {t('days')}</option>
                                <option value="1year">1 {t('year')}</option>
                            </select>
                            <div className='divFilter'></div>
                            <div className='divExport'>
                                <ReactToPrint
                                    trigger={() => <button name="country" className="form-control form-select form-select-lg select2  bg-primary text-white"  >
                                        {t(`${T_PREFIX}Export`)}
                                    </button>}
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row" id="dashboard-panel">
                        <NetAmountIndicator t={t} dateRange={state.dateRange} title={netAmountIndicatorTitle()} infoTooltip={t(`${T_PREFIX}chargedAmountInfoTooltip`)} chartData={ state.conciliationShortPeriodIndicator} currencySymbol={getCurrencySymbol()}></NetAmountIndicator>
                        <NetAmountIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}amountToCharge`)} infoTooltip={t(`${T_PREFIX}amountToChargeInfoTooltip`)} chartData={ state.conciliationNextPeriodIndicator} currencySymbol={getCurrencySymbol()}></NetAmountIndicator>
                        <ReturnIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}contracChargeAmount`)} infoTooltip={t(`${T_PREFIX}contracChargeAmountInfoTooltip`)} chartData={ state.conciliationShortPeriodIndicator} currencySymbol={getCurrencySymbol()}></ReturnIndicator>
                        <TaxIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}taxAmount`)} infoTooltip={t(`${T_PREFIX}taxAmountInfoTooltip`)} chartData={ state.conciliationShortPeriodIndicator} currencySymbol={getCurrencySymbol()}></TaxIndicator>

                        <CountIndicator  t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}salesTotalTitle`)} infoTooltip={t(`${T_PREFIX}salesTotalInfoTooltip`)} chartData={ state.shortPeriodIndicator} currencySymbol={getCurrencySymbol()}></CountIndicator>
                        <AmountIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}totalAmountForReport`)} infoTooltip={t(`${T_PREFIX}totalAmountForReportInfoTooltip`)} chartData={ state.shortPeriodIndicator} currencySymbol={getCurrencySymbol()}></AmountIndicator>
                        <CouponPromIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}couponPromIndicator`)} infoTooltip={t(`${T_PREFIX}couponPromIndicatorInfoTooltip`)} chartData={ state.shortPeriodIndicator} currencySymbol={getCurrencySymbol()}></CouponPromIndicator>
                        <ApprovalRateIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}approvalRateIndicator`)} infoTooltip={t(`${T_PREFIX}approvalRateIndicatorInfoTooltip`)} chartData={state.approvalRateIndicator} currencySymbol={getCurrencySymbol()}></ApprovalRateIndicator>
                        {
                            UtilService.isSmallScreen() ||
                            <>
                                <div className="col-lg-6">
                                    <Card title={t(`${T_PREFIX}salesQuantity`)}>
                                        <CountByIssuerChart currencySymbol={getCurrencySymbol()} dateRange={state.dateRange} shortPeriodChartData={state.shortPeriodIndicator} chartData={state.issuerTotals} infoTooltip={t(`${T_PREFIX}countByIssuerInfoTooltip`)} approvalRateIndicator={state.approvalRateIndicator} ></CountByIssuerChart>
                                    </Card>
                                </div>
                                <div className="col-lg-6">
                                    <Card title={t(`${T_PREFIX}grossAmountCamelCase`)} >
                                        <AmountByIssuerChart currencySymbol={getCurrencySymbol()} dateRange={state.dateRange} shortPeriodChartData={state.shortPeriodIndicator} chartData={state.issuerTotals} infoTooltip={t(`${T_PREFIX}amountByIssuerInfoTooltip`)}></AmountByIssuerChart>
                                    </Card>
                                </div>
                            </>
                        }

                        { UtilService.isSmallScreen() || <SalesChart name={t(`${T_PREFIX}salesEvolution`)} chartData={state.allTotal} /> }

                        {/*<PeriodIndicator t={t} dateRange={state.dateRange} chartData={ state.shortPeriodIndicator} currencySymbol={getCurrencySymbol()}></PeriodIndicator>
                        <PeriodIndicator t={t} dateRange={state.dateRange} chartData={state.longPeriodIndicator} currencySymbol={getCurrencySymbol()}></PeriodIndicator>
                        <RejectionIndicator t={t} dateRange={state.dateRange} chartData={state.rejectionIndicators}></RejectionIndicator>
                        <ReverseIndicator t={t} dateRange={state.dateRange} chartData={state.reverseIndicators}></ReverseIndicator>*/}
                        {WebCache.getCurrentUser()?.countryId==2?<RejectionByIssuerChart currencySymbol={getCurrencySymbol()} name={"rejectionByIssuer"} chartData={state.rejectionIndicators} />:
                            <RankingChart chartData={state.totalByWallet} currencySymbol={getCurrencySymbol()} bgColor="#FFC626" title={t(`${T_PREFIX}rankingWallet`)} name="rankingWallet"/>}

                        {/*<IssuerChart chartData={state.issuerTotals} />*/}
                        <CardTypeChart name={"totalByChannel"} chartData={state.totalByChannel} currencySymbol={getCurrencySymbol()}/>
                        <CardTypeChart name={"paymentModes"} chartData={state.cardTypeTotals} currencySymbol={getCurrencySymbol()}/>
                        <RankingChart chartData={state.acquirerTotals} currencySymbol={getCurrencySymbol()} bgColor="#823078" title={t(`${T_PREFIX}acquirerRanking`)} name="acquirerRanking"/>
                        <RankingChart chartData={state.planTotals} currencySymbol={getCurrencySymbol()} bgColor="#9B9B9B" title={t(`${T_PREFIX}rankingPurchases`)} name="rankingPurchases"/>
                        <RankingChart chartData={state.totalByBrand} currencySymbol={getCurrencySymbol()} bgColor="#FFC626" title={t(`${T_PREFIX}rankingBrands`)} name="rankingBrands"/>
                        <div className="col-md-12">
                            <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{t(`${T_PREFIX}lastTransactions`)}</h3>
                                    </div>
                                    <div className="card-body">
                                    <TransactionList
                                        transactions={transactions} 
                                        columns={columns}
                                        columnsToShow={["TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"]}
                                        mainPage={state.mainPage}
                                        pages={state.pages}
                                        activePage={state.activePage}
                                        customButtonAction={undefined}
                                        showCupon={false}
                                    ></TransactionList>
                                    </div>
                            </div>
                        </div>                      
                    </div>
                </div>
            </Page>
    )
}
export default Dashboard;

 export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        // @ts-ignore
        <div ref={ref}>My cool content here!</div>
    );
});
