import React from 'react';
import { ModelProvider } from '../contexts/modelContext';


interface IProps {
  children: React.ReactElement,
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void,
  model?: any
}

const Form = ({ children, onSubmit, model }: IProps) => {

  const executeValidation = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <ModelProvider model={model} >
      <form onSubmit={executeValidation} noValidate>
        { children }
      </form>
    </ModelProvider>
  );
};

export default Form;