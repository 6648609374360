import { AxiosResponse } from 'axios';
import React from 'react';
import { TransactionService } from '../../services/TransactionService';
import { withTranslation } from 'react-i18next';
import "./TransactionReport.css"
import ticketImg from '../../../node_modules/itd-common/assets/images/transactions/Receipt Slip.svg';
import { ITransaction } from "../../requests/ITransactionWebModelRequest";
import { UtilService } from "../../services/UtilService";
import { IIssuerTotalDto } from '../../models/IIssuerTotalDto';
import { Modal } from 'react-bootstrap';
import Accordion from "react-bootstrap/Accordion";
import AccordionBody from "react-bootstrap/AccordionBody";
import { WebCache } from '../../services/WebCache';
import { ReactComponent as NoResultsFoundSVG } from "../../../node_modules/itd-common/assets/images/svgs/no_results_found.svg";


interface IProps {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    transactions: ITransaction[],
    columnsToShow: string[],
    customButtonAction: undefined|{action:((transaction:ITransaction) => void),buttonText:string},
    showCupon:boolean
}

interface IState {
    show: boolean,
    issuersByTotal: IIssuerTotalDto[],
    tranId: string
}

interface TransactionRow {
    style: string;
    value: string;
    icon: string;
    image: string;
    columnName: string;

}
const pageSize = 13;
class TransactionList extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = { show: false, issuersByTotal: [], tranId: "" };
    }



    getTicket = (tranId: string, transactionType: string) => {
        TransactionService.showTicket(tranId, transactionType);
    }

    customButtonAction=(transaction:ITransaction)=> {
        if(this.props.customButtonAction!=undefined)
            this.props.customButtonAction.action(transaction);
    }

    getTotalsByIssuerAndCurrency = (tranId: string) => {
        TransactionService.getTotalsByIssuerAndCurrency(tranId).then((response: AxiosResponse<IIssuerTotalDto[]>) => {
            this.setState({ show: true, issuersByTotal: response.data, tranId: tranId });
        }).catch(error => console.log('error:', error));
    }

    private processAction(columnName:string, event: any, transaction: ITransaction) {
        event.preventDefault();
        if(columnName=="totalAmountForReport")
            this.getTotalsByIssuerAndCurrency(transaction.id);
    }

    private chunkRows(array:{ style: string, value: string, icon: string, image: string,columnName:string }[]):{ style: string, value: string, icon: string, image: string,columnName:string }[][] {
        var arrays=[];
        let chunkSize = 9;
        for (let i = 0; i < array.length; i += chunkSize) {
            arrays.push(array.slice(i, i + chunkSize)) ;
            chunkSize=10;//dejo un lugar en la primera file para el ticket
        }
        return arrays;
    }
    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <>
                <Modal show={this.state.show} size="lg">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header bg-primary">
                            <h5 className="modal-title text-white"> {'Transacción Nro. ' + this.state.tranId}  </h5>
                            <button aria-label="Close" className="btn-close" onClick={() => this.setState({ ...this.state, show: false })}><span className='text-white' aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12 mb-3">
                                <div className="table-responsive">
                                    <table className="table text-nowrap text-md-nowrap mb-0">
                                        <thead>
                                            <tr >
                                                <th style={{ color: "black" }}>{t("issuer")}</th>
                                                <th style={{ color: "black" }}>{t("amount")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.issuersByTotal.map((val: IIssuerTotalDto) => {
                                                    return (
                                                        <tr >
                                                            <td>{val.issuerName}</td>
                                                            <td>{Intl.NumberFormat('es-UY', {
                                                                style: 'currency',
                                                                currency: val.currency=="$"?"UYU":"USD"
                                                            }).format(Number(val.totalAmount))}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.setState({ ...this.state, show: false })}
                                className="btn btn-light"
                                style={{
                                    backgroundColor: '#f60'
                                    ,
                                    color: 'white',
                                }}
                                data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </Modal>
                <div className="table-responsive">
                            {this.props.transactions.length > 0 ?
                                this.props.transactions.slice(this.props.pages.indexOf(this.props.activePage) * pageSize, (this.props.pages.indexOf(this.props.activePage) * pageSize) + pageSize).map((transaction, Tindex) => {
                                    const rows = [];
                                    const rowsHeader = [];
                                    let rowStyle = "";
                                    let currencySymbol = " "
                                    let i = this.props.columns[0].toUpperCase() == "TICKET" ? 1 : 0;
                                    for (let prop in transaction) {
                                        let value = (transaction as any)[prop];
                                        const val = { style: "", value: "", icon: "", image: "",columnName:this.props.columns[i] };
                                        if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean')
                                            if (value == '') {
                                                if (this.props.columns[i]?.toUpperCase() === "TOTALAMOUNTFORREPORT" && transaction.transactionType == "L") {
                                                    val.value = " ";
                                                    val.icon = "fa-solid fa-magnifying-glass text-primary";
                                                    
                                                }else if (this.props.columns[i]?.toUpperCase().indexOf("AMOUNTFORREPORT") >= 0) {
                                                    val.value = " ";
                                                } else
                                                    val.value = "-";
                                            } else {
                                                switch (this.props.columns[i]?.toUpperCase()) {
                                                    case "STATE":
                                                        rowStyle = this.props.columns[i] + "-" + value;
                                                        val.value = t(this.props.columns[i] + "-" + value);
                                                        break;
                                                    case "CLIENTDOCUMENT":
                                                        if(WebCache.getCurrentUser()?.countryId == 2) {
                                                            val.columnName = "RUT";
                                                            val.value= t(value)
                                                        } else {
                                                            val.columnName = "CUIT";
                                                            val.value= t(value)
                                                        } 
                                                        break;
                                                    case "DEPARTMENT":
                                                        if(WebCache.getCurrentUser()?.countryId == 2) {
                                                            val.columnName = "Departamento";
                                                            val.value= t(value)
                                                        } else {
                                                            val.columnName = "Provincia";
                                                            val.value= t(value)
                                                        } 
                                                        break;
                                                    case "TAXREFUND":
                                                        if ((value + "").indexOf("0#") === 0) {
                                                            val.value = t("taxRefund-0");
                                                        } else {
                                                            const fields = ("" + value).split("#");
                                                            val.value = t(fields.length > 1 && fields[1].trim().length > 0 ? this.props.columns[i] + "-" + fields[1].trim() : this.props.columns[i] + "-" + fields[0].trim());
                                                            if (val.value.indexOf("taxRefund-") === 0) {
                                                                val.value = "-";
                                                            }
                                                        }
                                                        break;
                                                    case "ISCLOSED":
                                                        val.value = "";
                                                        if (value) {
                                                            val.style = "success-icon"
                                                            val.icon = "fa fa-check-circle-o";
                                                        }
                                                        break;
                                                    case "TRANSACTIONTYPE":
                                                        val.value = t(this.props.columns[i] + "-" + value);
                                                        break;
                                                    case "ISSUER":
                                                        UtilService.getIssuerGridValue(value, i, val, t, this.props.columns);
                                                        break;
                                                    case "INPUTMODE":
                                                        val.value = t(this.props.columns[i] + "-" + value);
                                                        break;
                                                    case "ACCOUNTTYPE":
                                                        val.value = t(this.props.columns[i] + "-" + value);
                                                        break;
                                                    case "TERMINAL":
                                                        if (value.toString().includes("B24_")) {
                                                            val.value = t(value).substring(4);
                                                        } else {
                                                            val.value = t(value)
                                                        }
                                                        break;
                                                    case "AUTHDATETIME":
                                                        val.value = UtilService.getDDMMyyHHmmssFormatFromString(value.toString());
                                                        break;
                                                    case "ACQUIRER":
                                                        if (this.props.columns[i] === "0000") {
                                                            val.value = "-";
                                                        } else {
                                                            val.value = t(this.props.columns[i] + "-" + value).toUpperCase();
                                                            val.image = UtilService.getImageTag(val.value);
                                                            if (val.value.indexOf("EDENRED") >= 0) {
                                                                val.value = " " + val.value.replace("EDENRED", "").trim().toLowerCase();
                                                            } else if (val.value.indexOf("CABAL") >= 0) {
                                                                val.value = " " + val.value.replace("CABAL", "").trim().toLowerCase();
                                                            } else if (val.value.indexOf("CREDITEL") >= 0) {
                                                                val.value = " " + val.value.replace("CREDITEL", "").trim().toLowerCase();
                                                            } else if (val.value.indexOf("PASS CARD") >= 0) {
                                                                val.value = " " + val.value.replace("PASS CARD", "").trim().toLowerCase();
                                                            } else {
                                                                val.value = "";
                                                            }
                                                        }
                                                        break;
                                                    case "RESPONSECODE":
                                                        val.value = t(this.props.columns[i] + "-" + value);
                                                        if (value === "00" || value === "08" || value === "11" || value === "85" || value === "LV" || value === "OF") {
                                                            val.style = "response-code-success";
                                                        } else if (value === "01" || value === "02" || value === "10" || value === "76" || value === "95" || value === "LL") {
                                                            val.style = "response-code-warning";
                                                        } else {
                                                            val.style = "response-code-error";
                                                        }
                                                        break;
                                                    case "CURRENCY":
                                                        val.value = t(this.props.columns[i] + "-" + value);
                                                        break;    
                                                    case "TOTALAMOUNTFORREPORT":
                                                        if (transaction.transactionType === "L") {
                                                            val.icon = "fa-solid fa-magnifying-glass text-primary";
                                                        }
                                                        // @ts-ignore
                                                        val.value = Intl.NumberFormat('es-UY', {
                                                            style: 'currency',
                                                            currency: transaction.currency=="840"?"USD":"UYU"
                                                        }).format(Number(transaction.totalAmountForReport))
                                                        break;
                                                    case "TAXAMOUNTFORREPORT":
                                                        // @ts-ignore
                                                        val.value = Intl.NumberFormat('es-UY', {
                                                            style: 'currency',
                                                            currency: transaction.currency=="840"?"USD":"UYU"
                                                        }).format(Number(transaction.taxAmountForReport))          
                                                        break;
                                                    case "TAXABLEAMOUNTFORREPORT":
                                                        // @ts-ignore
                                                        val.value = Intl.NumberFormat('es-UY', {
                                                            style: 'currency',
                                                            currency: transaction.currency=="840"?"USD":"UYU"
                                                        }).format(Number(transaction.taxableAmountForReport))
                                                        break;
                                                    case "BILLAMOUNTFORREPORT":
                                                        // @ts-ignore
                                                        val.value = Intl.NumberFormat('es-UY', {
                                                            style: 'currency',
                                                            currency: transaction.currency=="840"?"USD":"UYU"
                                                        }).format(Number(transaction.billAmountForReport))
                                                        break;
                                                    case "AMOUNTTOCUSTOMER":
                                                        // @ts-ignore
                                                        val.value = Intl.NumberFormat('es-UY', {
                                                            style: 'currency',
                                                            currency: transaction.currency=="840"?"USD":"UYU"
                                                        }).format(Number(transaction.amountToCustomer))
                                                        break;
                                                    default:
                                                        val.value = value + "";
                                                        break;
                                                }
                                            }
                                        else
                                            val.value = "-";
                                        i++;
                                        if (this.props.columnsToShow.length == 0 || this.props.columnsToShow.filter(ex => ex == prop.toUpperCase()).length > 0){
                                            rowsHeader.push(val);
                                        }else
                                            rows.push(val);
                                    }
                                    return (
                                        <Accordion>
                                            <Accordion.Item eventKey="0" className='tableAcordionItem'>
                                                <Accordion.Header>
                                                    <table id="datetime-table" className="table table-borderless text-center">
                                                        <tbody>
                                                        <tr key={"tr-head"} className="text-gray">
                                                                                                                {this.props.showCupon && 

                                                                <td style={{width:"280px"}} key={`li-ticket`}>{t("coupon")}</td>
                                                            }
                                                            {
                                                                this.props.columns.length > 0 ?
                                                                    this.props.columns.map(coll => {
                                                                        return (
                                                                            (this.props.columnsToShow.length==0||
                                                                                this.props.columnsToShow.filter(ex=>ex==coll.toUpperCase()).length>0)?
                                                                                <td style={{width:"280px"}} key={`li-${coll}`}>{t(coll)}</td>:""
                                                                        )
                                                                    }) :
                                                                    <></>
                                                            }
                                                            {
                                                                this.props.customButtonAction!=undefined?<th  key={`li-CUSTOMACTION`}>{t("ACTION")}</th> : ""
                                                            }
                                                        </tr>
                                                        <tr key={Tindex} className={`text-strong ${rowStyle}` }>
                                                            {this.props.showCupon && 
                                                                <td>
                                                                    {
                                                                        ((transaction as any)['acquirer'] != "0700" &&(transaction.transactionType == "C" ||
                                                                            transaction.transactionType == "A" ||
                                                                            transaction.transactionType == "L" ||
                                                                            transaction.transactionType == "D") && (
                                                                            transaction.responseCode == "00" ||
                                                                            transaction.responseCode == "08" ||
                                                                            transaction.responseCode == "11" ||
                                                                            transaction.responseCode == "85" ||
                                                                            transaction.responseCode == "OF"
                                                                        )) ? 
                                                                            <a href={window.location.href} onClick={() => this.getTicket(transaction.id, transaction.transactionType)}>
                                                                                <img src={ticketImg} alt='Ver Ticket' height='20' />
                                                                            </a> 
                                                                        : 
                                                                            ""
                                                                    }
                                                                </td>
                                                            }
                                                            {rowsHeader.map((val, index) => {
                                                                return <td key={index.toString() + Tindex.toString()} className={`${val.style}`}>
                                                                    <img src={val.image}></img>
                                                                    <span>{val.value}</span>
                                                                    <a href={window.location.href} onClick={(event: any) => this.processAction(val.columnName, event, transaction)}><i className={`${val.icon}`}></i></a>
                                                                </td>
                                                            })}
                                                            {  this.props.customButtonAction!=undefined?  <td>
                                                                <button type="button"
                                                                        className="btn btn-primary mb-3 btn-block" onClick={() =>this.customButtonAction(transaction)}>
                                                                    {this.props.customButtonAction?.buttonText}
                                                                </button>
                                                            </td> : ""}
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </Accordion.Header>
                                            <AccordionBody>
                                                        {this.chunkRows(rows).map((chunk,index)=>{
                                                            return  <table id="datetime-table" className="table table-borderless text-center">
                                                                <tbody>
                                                                    <tr key={"tr-head"} className="text-gray">
                                                                        {
                                                                            chunk.map(coll => {
                                                                                return (
                                                                                    <td style={{width:"280px"}} key={`li-${coll.columnName}`}>{t(coll.columnName)}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr key={Tindex} className={`text-strong ${rowStyle}` }>
                                                                        {
                                                                            chunk.map((val, index) => {
                                                                                return <td key={index.toString() + Tindex.toString()} className={`${val.style}`}>
                                                                                <span>{val.value}</span>
                                                                                <a href={window.location.href} onClick={(event: any) => this.processAction(val.columnName, event, transaction)}><i className={`${val.icon}`}></i></a>
                                                                                </td>
                                                                            })
                                                                        }
                                                                        {  this.props.customButtonAction!=undefined?  <td>
                                                                            <button type="button"
                                                                                    className="btn btn-primary mb-3 btn-block" onClick={() =>this.customButtonAction(transaction)}>
                                                                                {this.props.customButtonAction?.buttonText}
                                                                            </button>
                                                                        </td> : ""}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        })}
                                            </AccordionBody>
                                            </Accordion.Item>
                                        </Accordion>
                                    )
                                })
                           :
                           <div className="d-flex justify-content-center mb-5">
                                <NoResultsFoundSVG></NoResultsFoundSVG>
                            </div>
                           
                           }
                </div>
            </>
        )
    };
}
export default withTranslation()(TransactionList);
