import React, { ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { TFunction, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserService } from "../services/UserService";
import { UtilService } from "../services/UtilService";
import { WebCache } from "../services/WebCache";
import LabelMessage from './LabelMessage';
import "./Login.scss";
import Form from './containers/form';
import SubmitButton from './controls/buttons/submitButton';
import EmailField from './controls/fields/emailField';
import PasswordField from './controls/fields/passwordField';
import { ReactComponent as LogoSVG } from "../../node_modules/itd-common/assets/images/brand/logo.svg";
import Button from './controls/buttons/button';
import TextField from './controls/fields/textField';
import Card from './containers/card';
import Footer from './layout/Footer';
import { IAuthenticateRequest, IOtpRequest } from '../requests/ILoginRequest';
import SelectField from './controls/fields/selectField';
import { TOTPService } from '../services/TOTPService';
import { OptionMapperHelper } from '../helpers/OptionMapperHelper';
import { QRCodeSVG } from 'qrcode.react';
import { ActivateDeviceRequest, DeviceRequest, UnpairOtpDevicesRequest } from '../requests/ITOTPRequest';
import { IBaseDevice, ICreateDevice } from '../models/ITOTPDevice';
import { ObjectHelper } from '../helpers/ObjectHelper';
import { StringHelper } from '../helpers/StringHelper';
import { Option } from '../models/Option';
import { toast, ToastContainer } from 'react-toastify';
import logoQR from "../../node_modules/itd-common/assets/images/qr/logoQR.svg";
import { Modal } from "react-bootstrap";
import src from 'react-select/dist/declarations/src';

enum PageMode {
    LOGIN,
    PHONE_NUMBER_REQUEST,
    FINGERPRINT_REGISTRATION,
    OTP_CODE_REQUEST,
    OTP_CODE_REQUEST_LOST_DEVICE,
    OTP_CREATE_DEVICE,
    OTP_ACTIVATE_DEVICE,
    OTP_CHOICE_DEVICE
}

type RenderObject = {
    title: string,
    renderFunction: () => ReactElement,
    initialize?: {
        state: [any, Dispatch<SetStateAction<any>>],
        function: <T>(state: [T, Dispatch<SetStateAction<T>>]) => void 
    } 
};

type LoginFormType = { username: string, password: string };
type CodeFormType = { code: string };
type PhoneFormType = { number: string };
type ChoiceDeviceFormType = { device: string };
type NameType = { name: string };
type RegistrationUriType = { registrationUri: string };
type ActivateDeviceFormType = CodeFormType & RegistrationUriType;
type DeviceType = NameType & { authId?: string, token?: string }

interface IProps {
    t: TFunction
}

let loginLoaded = false;
const T_PREFIX: string = 'pages.login.';

const Login: React.FC<IProps> = ({ t }: IProps) => {
    const [pageMode, setPageMode] = useState<PageMode>(PageMode.LOGIN);

    const [loginForm, setLoginForm] = useState<LoginFormType>({ username: '', password: '' });
    const [phoneForm, setPhoneForm] = useState<PhoneFormType>({ number: '' });
    const [otpCodeForm, setOtpCodeForm] = useState<CodeFormType>({ code: '' });
    const [otpCreateDeviceForm, setOtpCreateDeviceForm] = useState<NameType>({ name: '' });
    const [otpActivateDeviceForm, setOtpActivateDeviceForm] = useState<ActivateDeviceFormType>({ code: '', registrationUri: '' });
    const [otpChoiceDeviceForm, setOtpChoiceDeviceForm] = useState<ChoiceDeviceFormType>({ device: '' });
    const [showStepsQr, setShowStepsQr] = useState<boolean>(false);


    const [otpChoiceDeviceOptions, setOtpChoiceDeviceOptions] = useState<Option[]>([]);
    
    const [device, setDevice] = useState<DeviceType>({ name: '', authId: '', token: '' });

    const [serverError, setServerError] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
    const [isOTPCodeValid, setIsOTPCodeValid] = useState<boolean>(true);

    const [fingerPrintEnabled] = useState<boolean>(localStorage.getItem('credential') !== null);
    const [initLogin, setInitLogin] = useState<boolean>(false);

    const openStepsQr = () => {
        setShowStepsQr(true);
    };

    const closeStepsQr = () => {
        setShowStepsQr(false);
    };

    useEffect(() => {
        setInitLogin(false);
        if (!initLogin) {
            if(window.location.href.indexOf("merchantToken=") > 0){
                let merchantToken = window.location.href.split("merchantToken=")[1];
                if(merchantToken && merchantToken.length > 0) {
                    UserService.loginWithToken(merchantToken).then(() => {
                        WebCache.initCache().then(() => {
                            if (UtilService.mobileCheck()) {
                                setPageMode(PageMode.FINGERPRINT_REGISTRATION);
                            }
                            else {
                                navigateToDashboard();
                            }
                        }).catch(() => setIsValid(false));
                    }).catch(() => setIsValid(false))
                }
            }
        }
    }, [initLogin]);

    const registerFingerPrint = (): void => {
        UserService.getWebAuthnRegistrationOptions().then(function (res) {
            const credentialCreationOptions = res.data;
            credentialCreationOptions.challenge = StringHelper.base64DecodeURL(credentialCreationOptions.challenge);
            credentialCreationOptions.user.id = StringHelper.base64DecodeURL(credentialCreationOptions.user.id);
            credentialCreationOptions.user.name = loginForm.username;
            credentialCreationOptions.user.displayName = 'Visor transacciones ITD';
            if (credentialCreationOptions.excludeCredentials != null) {
                for (var i in credentialCreationOptions.excludeCredentials) {
                    credentialCreationOptions.excludeCredentials[i].id = StringHelper.base64DecodeURL(credentialCreationOptions.excludeCredentials[i].id);
                }
            }
            navigator.credentials.create({
                publicKey: credentialCreationOptions
            }).then(function (credential: any) {

                const credentialId = StringHelper.base64Encode(credential.rawId);

                localStorage.setItem('credential', JSON.stringify({ credentialId }));
                localStorage.setItem('lastUserName', loginForm.username);

                //Registro la huella
                const data = {
                    AttestationResponse: {
                        rawId: credentialId,
                        id: credential.id,
                        type: credential.type,
                        response: {
                            attestationObject: StringHelper.base64Encode(credential.response.attestationObject),
                            clientDataJSON: StringHelper.base64Encode(credential.response.clientDataJSON)
                        }
                    },
                    AdditionalData: StringHelper.base64Encode(credential.response.clientDataJSON)
                };
                UserService.webAuthnRegistration(data);
                navigateToDashboard();
            });
        }, function (error: any) {
            //alert(JSON.stringify(error));
        });
    }

    const navigateToDashboard = (): void => {
        window.location.href = '/#/dashboard'
    }

    const handleCancel = () => {
        const renderObject: RenderObject = getRenderObjectByPageMode(pageMode);

        if (renderObject.initialize) {
            renderObject.initialize.function(renderObject.initialize.state);
        }

        setPageMode(PageMode.LOGIN);
    }

    const loginWithFingerPrint = (): void => {
        UserService.assertionOptions().then(function (res: any) {
            let credentialRequestOptions = res.data;
            let credentialId = JSON.parse(localStorage.getItem('credential') + "");
            credentialRequestOptions.challenge = StringHelper.base64DecodeURL(credentialRequestOptions.challenge);
            credentialRequestOptions.allowCredentials = [
                {
                    id: StringHelper.base64ToBuffer(credentialId.credentialId),
                    type: 'public-key',
                    transports: ['internal']
                }
            ];
            navigator.credentials.get({
                publicKey: credentialRequestOptions
            }).then(function (credential: any) {
                const data = {
                    rawId: StringHelper.base64Encode(credential.rawId),
                    id: credential.id,
                    type: credential.type,
                    userHandle: StringHelper.base64Encode(credential.response.userHandle),
                    response: {
                        authenticatorData: StringHelper.base64Encode(credential.response.authenticatorData),
                        signature: StringHelper.base64Encode(credential.response.signature),

                        clientDataJSON: StringHelper.base64Encode(credential.response.clientDataJSON),
                    }
                };
                UserService.makeAssertion(data).then(function (res) {
                    if (!res.data.success) {
                        localStorage.clear();
                    }
                    WebCache.initCache().then(() => {
                        navigateToDashboard();
                    });
                });
            }, function (error: any) {
                console.error(error);
            });
        })
    }

    const requestOtp = (): void => {
        const requestOtp: IOtpRequest = {
            username: loginForm.username,
            password: loginForm.password,
            deviceName: device.name,
            phoneNumber: phoneForm.number,
            typeAuthentication: "TOTP"
        };

        setIsValid(true);
        UserService.requestOtp(requestOtp)
            .then((response) => response.data.data)
            .then((data) => {
               if(!data.success){
                   setServerError(data.message);
                   setIsValid(false);
                   return;
               }
                const devices: Array<string> = data.totP_Devices;
                
                if (!devices) {
                    if (data.totpAuthId && data.totpToken) {
                        setDevice({ name: data.deviceName, authId: data.totpAuthId, token: data.totpToken });
                        setPageMode(PageMode.OTP_CODE_REQUEST);
                    }
                    else {
                        setPageMode(PageMode.OTP_CREATE_DEVICE);
                    }
                    return;
                }

                if (devices && devices.length > 1) {
                    const deviceOptions: Array<HTMLOptionElement> = new OptionMapperHelper(t).buildSelectOptionsFromEnum(devices) as Array<HTMLOptionElement>;
                    setOtpChoiceDeviceOptions(deviceOptions);
                    setOtpChoiceDeviceForm({ device: deviceOptions[0].value });
                    setPageMode(PageMode.OTP_CHOICE_DEVICE);
                    return;
                }

                if (!data.phoneNumberValidated) {
                    setPageMode(PageMode.PHONE_NUMBER_REQUEST);
                    return;
                }
                
                setIsOTPCodeValid(true);
                setPageMode(PageMode.OTP_CODE_REQUEST);
            })
            .catch((error) => {
                console.error(error);
                setIsValid(false);
            });
    };

    const validatePhoneNumberAndRequestOtp = (): void => {
        let phoneNumberValid: boolean = false;
        if (phoneForm.number) {
            const validatePhoneNumber = (phoneNumber: string): boolean => {
                return /^((\+|00)?\d{1,3}\s?)?(\(\d{1,4}\)|\d{1,4})?[-\s]?\d{3,4}[-\s]?\d{3,4}$/.test(phoneNumber);
            }
            phoneNumberValid = validatePhoneNumber(phoneForm.number);
            setIsPhoneNumberValid(phoneNumberValid);
        }

        if (phoneNumberValid) {
            setPageMode(PageMode.LOGIN);
            requestOtp();
        }
        else {
            setIsPhoneNumberValid(false);
        }
    };

    const login = (): void => {
        const authenticateRequest: IAuthenticateRequest = {
            username: loginForm.username,
            password: loginForm.password,
            otp: otpCodeForm.code,
            deviceName: device.name,
            totpToken: device.token,
            totpAuthId: device.authId
        };

        setIsOTPCodeValid(true);
        UserService.login(authenticateRequest).then((response) => {
            WebCache.initCache().then(() => {
                // To enable the fingerprint functionality, uncomment this if code

                // if (UtilService.mobileCheck()) {
                //     setPageMode(PageMode.FINGERPRINT_REGISTRATION);
                // }
                // else {
                //     navigateToDashboard();
                // }

                // To enable the fingerprint functionality, remove this navigateToDashboard() call.
                navigateToDashboard();
            }).catch(() => setIsValid(false));
        }).catch((error) => {
            console.error(error);
            setIsOTPCodeValid(false);
        });
    }

    const createDevice = (): void => {
        const request: DeviceRequest = { user: loginForm.username, deviceName: otpCreateDeviceForm.name };
        TOTPService.createDevice(request)
            .then((response) => response.data)
            .then((device: ICreateDevice) => {
                setOtpActivateDeviceForm({ registrationUri: device.registrationUri || '', code: '' });
                setDevice({ name: device.deviceName || '', authId: device.authId, token: device.token });
                setPageMode(PageMode.OTP_ACTIVATE_DEVICE);
            }).catch((error) => {
                console.error(error);
                setIsValid(false);
            });
    };

    const activateDevice = (): void => {
        const request: ActivateDeviceRequest = {
            user: loginForm.username,
            authId: device.authId,
            token: device.token,
            otpCode: otpActivateDeviceForm.code
        };

        TOTPService.activateDevice(request)
            .then((response) => response.data)
            .then((device: IBaseDevice) => {
                setPageMode(PageMode.OTP_CODE_REQUEST);
            }).catch((error) => {
                console.error(error);
                setIsValid(false);
            });
    };



    const lostDeviceUnpairAll = (): void => {
        const request: UnpairOtpDevicesRequest = {
            username: loginForm.username,
            code: otpCodeForm.code
        };

        setIsOTPCodeValid(true);
        TOTPService.unpairOtpDevices(request)
            .then((response) => response.data)
            .then(() => {
                toast.success(t(`${T_PREFIX}otpCodeRequestLostDevice.successMessage`).toString());
                setPageMode(PageMode.LOGIN);
            }).catch((error) => {
                console.error(error);
                setIsOTPCodeValid(false);
            }); 
    }
   
    if (!loginLoaded) {
        loginLoaded = true;
        if (localStorage.getItem('credential') !== null) {
            loginWithFingerPrint()
        }
    }

    // #################### Render Methods ####################

    const renderDownloadMerchantApp = () => {
        const isIOS = /iPhone/.test(navigator.userAgent);
        const isAndroid = /Android/.test(navigator.userAgent);
    
    if (isAndroid || isIOS) {
            return (
                <>
                 <div className='d-flex flex-column align-items-center justify-content-center text-center qrContainer'>
                     <div className='mb-?'>{isAndroid ? t(`${T_PREFIX}downloadMerchantApp.messageAndroid`): t(`${T_PREFIX}downloadMerchantApp.messageIOS`)}</div>
                     <div><a href={window.location.href} onClick={openStepsQr} className='stepsDownload linkTo'> {t(`${T_PREFIX}downloadMerchantApp.stepsDownload`)}</a></div>
                </div>
                <Modal show={showStepsQr} className='modal-primary'>
                        <div className="modal-content modal-content-demo">
                            <div className="modal-header bg-primary align-items-center">
                                <h4 className="modal-title text-white">{t(`${T_PREFIX}downloadMerchantApp.title`)}</h4>
                                <Button aria-label="Close" className="btn-close p-0" onClick={() => closeStepsQr()} noBlock>
                                    <span className='text-white' aria-hidden="true">&times;</span>
                                </Button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className='textModal'>
                                        <ol className='textDeviceApp'>
                                            <li className='stepsApp'>{t(`${T_PREFIX}downloadMerchantApp.openBrowser`)}</li>
                                            <li className='stepsApp'>{isAndroid ? t(`${T_PREFIX}downloadMerchantApp.moreBrowser`):t(`${T_PREFIX}downloadMerchantApp.shareBrowser`)}</li>
                                            <li className='stepsApp'>{isAndroid ? t(`${T_PREFIX}downloadMerchantApp.toolsApp`):t(`${T_PREFIX}downloadMerchantApp.addDisplay`)}</li>
                                            {isAndroid ? <li className='stepsApp'> {t(`${T_PREFIX}downloadMerchantApp.createShortcut`)} </li> : ""}
                                            <li className='stepsApp'>{isAndroid ? t(`${T_PREFIX}downloadMerchantApp.appName`):t(`${T_PREFIX}downloadMerchantApp.changeNameApp`)}</li>
                                            <li className='stepsApp'>{isAndroid ? t(`${T_PREFIX}downloadMerchantApp.createApp`): t(`${T_PREFIX}downloadMerchantApp.createApp`)}</li>
                                            <li className='stepsApp'>{t(`${T_PREFIX}downloadMerchantApp.readyDownload`)}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button onClick={() => closeStepsQr()} noBlock>
                                    <span className='text-white' aria-hidden="true">{t(`close`)}</span>
                                </Button>
                            </div>
                        </div>
                    </Modal></>
            );
        } else {
            return (
                <div className='d-flex align-items-center justify-content-center h-100 w-100 qrDownload mb-9'>
                    <img src={logoQR} alt="" className='imgQR'/>
                    <span className='textApp'>{t(`${T_PREFIX}downloadMerchantApp.qrApp`)}</span>
                </div>                
            );
        }    
    };

    const renderLoginForm = (): ReactElement => {
        return (
            <Form model={[loginForm, setLoginForm]} onSubmit={requestOtp}>
                <div className="panel panel-primary">
                    <div className="panel-body tabs-menu-body p-0">
                        <div className="tab-content">
                            <div className="tab-pane active" id="tab5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <EmailField attr='username' label={`${T_PREFIX}username`} required autoFocus></EmailField>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <PasswordField attr='password' label={`${T_PREFIX}password`} required></PasswordField>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="finger-button" onClick={() => loginWithFingerPrint()} hidden={!fingerPrintEnabled}></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <LabelMessage valid={isValid} message={t(`${T_PREFIX}${(serverError==""||serverError==null)?"wrongUserOrPass":serverError}`)}></LabelMessage>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-end">
                                            <p className="mb-1">
                                                <Link to={'/forgot-password'} className="link">
                                                    <span>{t(`${T_PREFIX}passwordRecovery`)}</span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-end">
                                            <p className="mb-0">
                                                <Link to={'/lost-phone'} className="link">
                                                    <span>{t(`${T_PREFIX}lostMyPhone`)}</span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <SubmitButton className="mb-4">
                                            <div className='submit-button-container'>
                                                <span className='ms-3'>{t(`${T_PREFIX}submitButton`)}</span>
                                                <i className='fe fe-arrow-right submit-button-container__icon me-3'></i>
                                            </div>
                                        </SubmitButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }

    const renderFingerprintRegistration = (): ReactElement => {
        return (
            <>
                <Card>
                    <div className='text-center'>
                        <div className="finger-button" onClick={() => registerFingerPrint()} >
                            {/*<img src={fingerprint}  alt="logo"/>*/}
                        </div>
                        <h4 className="h4 mb-0">{t(`${T_PREFIX}fingerPrint.fingerauthentication`)}</h4>
                        <p className="card-text">{t(`${T_PREFIX}fingerPrint.fingerprintRegistrationQuestion`)}</p>
                    </div>
                </Card>
                <div className="text-center">
                    <Button onClick={handleCancel} label={t(`${T_PREFIX}otpCodeRequest.cancel`)} color='white' className='me-3' noBlock ></Button>
                </div>
            </>
        );
    }

    const renderPhoneNumberRequest = (): ReactElement => {
        return (
            <Form model={[phoneForm, setPhoneForm]} onSubmit={validatePhoneNumberAndRequestOtp}>
                <>
                    <p>{t(`${T_PREFIX}phoneNumberRequest.message`)}</p>
                    <TextField attr='number' label={`${T_PREFIX}phoneNumberRequest.phoneNumber`} required autoFocus ></TextField>
                    <LabelMessage valid={isPhoneNumberValid} message={t(`${T_PREFIX}phoneNumberRequest.wrongPhoneNumber`)} ></LabelMessage>
                    <div className="text-center">
                        <Button onClick={handleCancel} label={t(`${T_PREFIX}phoneNumberRequest.cancel`)} color='white' className='me-3' noBlock ></Button>
                        <SubmitButton label={t(`${T_PREFIX}phoneNumberRequest.accept`)} noBlock ></SubmitButton>
                    </div>
                </>
            </Form>
        );
    }
    
    const renderOtpCodeRequest = (onSubmit: (event: React.FormEvent<HTMLFormElement>) => void, tPrefix: string): ReactElement => {
        return (
            <Form model={[otpCodeForm, setOtpCodeForm]} onSubmit={onSubmit}>
                <>
                    <p>{t(`${tPrefix}message`)}</p>
                    <TextField attr='code' label={`${tPrefix}code`} required autoFocus ></TextField>
                    <LabelMessage valid={isOTPCodeValid} message={t(`${tPrefix}wrongCode`)} ></LabelMessage>
                    <div className="text-center">
                        <Button onClick={handleCancel} label={t(`${tPrefix}cancel`)} color='white' className='me-3' noBlock ></Button>
                        <SubmitButton label={t(`${tPrefix}accept`)} noBlock ></SubmitButton>
                    </div>

                </>
            </Form>
        );
    }
    
    const renderOtpCodeRequestAuthenticator = (): ReactElement => {
        return renderOtpCodeRequest(login, `${T_PREFIX}otpCodeRequest.`);
    }
    
    const renderOtpCodeRequestLostDevice = (): ReactElement => {
        return renderOtpCodeRequest(lostDeviceUnpairAll, `${T_PREFIX}otpCodeRequestLostDevice.`);
    }

    const renderOtpCreateDevice = (): ReactElement => {
        return (
            <Form model={[otpCreateDeviceForm, setOtpCreateDeviceForm]} onSubmit={createDevice}>
                <>
                    <TextField attr='name' label={`${T_PREFIX}otpCreateDevice.name`} required autoFocus ></TextField>
                    <div className="text-center">
                        <Button onClick={handleCancel} label={t(`${T_PREFIX}otpCreateDevice.cancel`)} color='white' className='me-3' noBlock ></Button>
                        <SubmitButton label={t(`${T_PREFIX}otpCreateDevice.accept`)} noBlock ></SubmitButton>
                    </div>
                </>
            </Form>
        );
    }

    const renderOtpActivateDevice = (): ReactElement => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="wrap-input100 validate-input input-group">
                            <QRCodeSVG value={otpActivateDeviceForm.registrationUri} style={{width:"100%",height:"200px"}}/>
                        </div>
                        {/* <div className="text-center pt-3">
                            <p className="text-dark mb-0">{otpActivateDeviceForm.registrationUri}</p>
                        </div> */}
                    </div>
                </div>
                <Form model={[otpActivateDeviceForm, setOtpActivateDeviceForm]} onSubmit={activateDevice}>
                    <>
                        <div className="row">
                            <div className="col-12">
                                <TextField attr='code' label={`${T_PREFIX}otpActivateDevice.code`} required autoFocus ></TextField>
                                <LabelMessage valid={isOTPCodeValid} message={t(`${T_PREFIX}otpActivateDevice.wrongCode`)} ></LabelMessage>
                            </div>
                        </div>
                        <div className="text-center">
                            <Button onClick={handleCancel} label={t(`${T_PREFIX}otpActivateDevice.cancel`)} color='white' className='me-3' noBlock ></Button>
                            <SubmitButton label={t(`${T_PREFIX}otpActivateDevice.accept`)} noBlock ></SubmitButton>
                        </div>
                    </>
                </Form>
            </>
        );
    }

    const renderOtpChoiceDevice = (): ReactElement => {
        return (
            <Form model={[otpChoiceDeviceForm, setOtpChoiceDeviceForm]} onSubmit={requestOtp}>
                <>
                    <SelectField
                        attr='device'
                        options={otpChoiceDeviceOptions}
                        label={`${T_PREFIX}otpChoiceDevice.devices`}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            setDevice({ name: event.target.value });
                        }}
                        required
                        autoFocus
                    ></SelectField>
                    <div className="text-center">
                        <Button onClick={handleCancel} label={t(`${T_PREFIX}otpChoiceDevice.cancel`)} color='white' className='me-3' noBlock ></Button>
                        <SubmitButton label={t(`${T_PREFIX}otpChoiceDevice.accept`)} noBlock ></SubmitButton>
                    </div>
                </>
            </Form>
        );
    }

    const buildRenderObject = (
        title: string,
        renderFunction: () => ReactElement,
        initialize?: { state: [any, Dispatch<SetStateAction<any>>], function: <T>(state: [T, Dispatch<SetStateAction<T>>]) => void }
    ): RenderObject => {
        return { title, renderFunction, initialize };
    }

    const buildTitle = (sufix: string, options?: any): string => {
        return t(`${T_PREFIX}${sufix}`, options);
    }

    function initState<T>(stateParam: [T, Dispatch<SetStateAction<T>>]): void {
        let [state, setState] = stateParam;

        let needsToBeInitialized: boolean = false;
        for (const key in state) {
            needsToBeInitialized = Boolean(state[key]);
            if (needsToBeInitialized) {
                break;
            }
        }
        
        if (needsToBeInitialized) {
            setState(ObjectHelper.cleanObject(state));
        }
    }

    function initStateAndDevice<T>(stateParam: [T, Dispatch<SetStateAction<T>>]): void {
        initState(stateParam);
        setDevice({ name: '' });
    }
    
    const renderObjectsMap: Map<PageMode, RenderObject> = new Map([
        [
            PageMode.LOGIN,
            buildRenderObject(
                buildTitle('title'),
                renderLoginForm,
                { state: [loginForm, setLoginForm], function: initState }
            )
        ],
        [
            PageMode.PHONE_NUMBER_REQUEST,
            buildRenderObject(
                buildTitle('phoneNumberRequest.title'),
                renderPhoneNumberRequest,
                { state: [phoneForm, setPhoneForm], function: initState }
            )
        ],
        [
            PageMode.FINGERPRINT_REGISTRATION,
            buildRenderObject('', renderFingerprintRegistration)
        ],
        [
            PageMode.OTP_CODE_REQUEST,
            buildRenderObject(
                buildTitle('otpCodeRequest.title'),
                renderOtpCodeRequestAuthenticator,
                { state: [otpCodeForm, setOtpCodeForm], function: initStateAndDevice }
            )
        ],
        [
            PageMode.OTP_CODE_REQUEST_LOST_DEVICE,
            buildRenderObject(
                buildTitle('otpCodeRequestLostDevice.title'),
                renderOtpCodeRequestLostDevice,
                { state: [otpCodeForm, setOtpCodeForm], function: initStateAndDevice }
            )
        ],
        [
            PageMode.OTP_CREATE_DEVICE,
            buildRenderObject(
                buildTitle('otpCreateDevice.title'),
                renderOtpCreateDevice,
                { state: [otpCreateDeviceForm, setOtpCreateDeviceForm], function: initStateAndDevice }
            )
        ],
        [
            PageMode.OTP_ACTIVATE_DEVICE,
            buildRenderObject(
                buildTitle('otpActivateDevice.title', { deviceName: device.name }),
                renderOtpActivateDevice,
                { state: [otpActivateDeviceForm, setOtpActivateDeviceForm], function: initStateAndDevice }
            )
        ],
        [
            PageMode.OTP_CHOICE_DEVICE,
            buildRenderObject(
                buildTitle('otpChoiceDevice.title'),
                renderOtpChoiceDevice,
                { state: [otpChoiceDeviceForm, setOtpChoiceDeviceForm], function: initStateAndDevice }
            )
        ]
    ]);

    const getRenderObjectByPageMode = (pageMode: PageMode): RenderObject => {
        return renderObjectsMap.get(pageMode) || { title: "", renderFunction: () => <></> } as RenderObject;
    }
    
    const renderTitle = () => {
        return getRenderObjectByPageMode(pageMode).title;
    }
    
    const renderByPageMode = () => {
        return (
            <>
                { getRenderObjectByPageMode(pageMode).renderFunction() }
            </>
        )
    }

    return (
        <React.Fragment>
            <ToastContainer autoClose={3000} ></ToastContainer>
            <div className="login">
                <div className="page">
                    <div className="container-login100">
                        <div className="wrap-login100 p-6">
                            <div className='brand-logo-container'>
                                <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                            </div>
                            <span className="login100-form-title mb-5">
                                { renderTitle() }
                            </span>
                            <>
                                { renderByPageMode() }
                            </>
                        </div>
                    </div>
                    <p className='registerMerchant'> {t(`${T_PREFIX}notYetAtFiserv`)} <Link to={'/onboarding'} className='linkTo'>{t(`${T_PREFIX}register`)}</Link> </p>
                    {renderDownloadMerchantApp()}
                </div>
                <Footer isValid={isValid} />
            </div>
        </React.Fragment>
    )
}
export default withTranslation() (Login);
