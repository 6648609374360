import Button from './button';
import { IButtonEitherLabelOrChildrenProps} from './buttonPropertiesInterface';

export default function SubmitButton(props: IButtonEitherLabelOrChildrenProps) {
  
  const buildLabelProp = () => {
    if ('label' in props) {
      return props.label;
    }

    return ('children' in props) ? '' : 'Submit';
  }
  
  const buildChildrenProp = () => {
    if ('children' in props) {
      return props.children;
    }

    return undefined;
  }


  return <Button type="submit" {...props} label={buildLabelProp()} children={buildChildrenProp()} className={props.className} ></Button>;
}
