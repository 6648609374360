import { AxiosResponse } from "axios";
import { ISearchInvoicesResponse } from "../models/IFiscalDocument";
import { ISearchInvoiceDocumentRequest, ISearchInvoicesRequest } from "../requests/IFiscalDocumentInvoicesRequest";
import { axiosApiInstance } from "./HttpService";
import { DownloadFileHelper } from "../helpers/DownloadFileHelper";

export class FiscalDocumentService {

    public static searchInvoices(request: ISearchInvoicesRequest): Promise<AxiosResponse<ISearchInvoicesResponse>> {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/FiscalDocument/searchInvoices`;
        return axiosApiInstance.post(dataURL, request);
    }
        
    public static async searchInvoiceDocument(request: ISearchInvoiceDocumentRequest, fileName: string): Promise<AxiosResponse<any>> {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/FiscalDocument/searchInvoiceDocument`;
        return DownloadFileHelper.downloadFromPromise(axiosApiInstance.post(dataURL, request, {responseType: 'arraybuffer'}), fileName, {type: 'application/pdf'})
    }
    
}
