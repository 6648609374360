import { ReactElement } from "react";
import { withTranslation } from "react-i18next";
import { IChildrenProps, IClassNameProps, IIdProps, ILabelProps, ITranslationPros } from "../controlsPropertiesInterface";
import "./baseField.scss";
import { ValueType } from "./fieldPropertiesInterface";

interface IPropsBaseField extends IIdProps, ILabelProps, ITranslationPros, IChildrenProps, IClassNameProps {
    prefix?: ReactElement;
    sufix?: ReactElement;
    attr?: string;
    value?: ValueType;
    disabled?: boolean;
    required?: boolean;
}

function BaseField({
    id,
    prefix,
    children,
    sufix,
    label,
    attr,
    value,
    required,
    className,
    t
}: IPropsBaseField) {
    const hideRequiredAsterisk = () => {
        return !required;
    }

    function isObjectEmpty(value: ValueType): boolean {
        return Boolean(            
            (
                value &&
                Object.keys(value).length === 0 && 
                (value.constructor === Object || value.constructor === Array)
            )
            ||
            (
                ['string', 'number', 'undefined'].indexOf(typeof value) > -1 && !value
            )
        );
    };
    
    return (
        <div className={`input-container ${className}`} >
            <div className="input-container__prefix" >{prefix}</div>
            {children}
            <div className="input-container__sufix" >{sufix}</div>
            <div className='label-container' >
                <label className={`label-container__label ${(!isObjectEmpty(value)) ? 'label-on-top' : ''}`} htmlFor={`input-${id || attr || label}`} >
                    {t(label)}
                    <span className={`label-container__required ${ hideRequiredAsterisk() ? 'hide' : '' }`} >*</span>
                </label>
            </div>
        </div>
    );
}

export default withTranslation() (BaseField);
