import React from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import {WebCache} from "../services/WebCache";
import {IBranche, ILocation, IPayerTaxId} from "../models/ITerminal";
import SelectField from './controls/fields/selectField';
import { OptionMapperHelper } from '../helpers/OptionMapperHelper';

interface IProps {
    changeFilter: (taxPayerFilter:string,branchFilter: string,departmentFilter:string, locationFilter:string,departmentName: string,locationName: string,branchName: string,clientDocument:string) => void,
    t: TFunction,
    filter: {
        taxPayerFilter: string,
        branchFilter: string,
        departmentFilter: string,
        locationFilter:string
    }
}

interface IState {
    departmentFilter: string
    locationFilter: string
    currentLocations: ILocation[],
    branchFilter: string
    currentBranches: IBranche[],
    currentPayerIds: IPayerTaxId[],
    departmentName: string,
    locationName: string,
    branchName: string,
    taxPayerId: string
    taxPayerName: string,
    taxPayerFilter: string
}

class TerminalLocation extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            departmentFilter:"",
            locationFilter:"",
            currentLocations:WebCache.getAllLocations(),
            branchFilter:"",
            currentBranches:WebCache.getAllBranches(),
            currentPayerIds:WebCache.getAllPayerTaxIds(),
            departmentName:"",
            locationName:"",
            branchName:"",
            taxPayerId:"",
            taxPayerName:"",
            taxPayerFilter:""
        }
    }

    componentDidUpdate(): void {
        if(
            this.props.filter.branchFilter === " " && this.state.branchFilter != " " &&
            this.props.filter.locationFilter === " " && this.state.locationFilter != " " &&
            this.props.filter.departmentFilter === " " && this.state.departmentFilter != " " &&
            this.props.filter.taxPayerFilter === " " && this.state.taxPayerFilter != " " 
        ){
            this.setState({
                ...this.state,
                taxPayerFilter: " ",
                locationFilter: " ",
                departmentFilter: " ",
                branchFilter: " "
            })
        }
    }

    handleChange(event:any) {
        if(event.target.id.includes("taxPayerFilter")) {
            let taxPayerIdName="";
            if(event.target.name!="0000")
                taxPayerIdName=WebCache.getAllPayerTaxIds().filter(dep=>dep.id==event.target.value)[0].name;
            this.setState({...this.state, taxPayerFilter: event.target.value,taxPayerName:taxPayerIdName});
            this.props.changeFilter(event.target.value, this.state.branchFilter, this.state.departmentFilter,this.state.locationFilter,this.state.departmentName,this.state.locationName,this.state.branchName,taxPayerIdName)
        }else if(event.target.id.includes("branchFilter")) {
            let branchId=event.target.value;
            let taxPayerIds:IPayerTaxId[];
            let branchName="";
            taxPayerIds=WebCache.getAllPayerTaxIds();
            if(event.target.id != "input-0000"){
                taxPayerIds=taxPayerIds.filter(payerId=>payerId.branchId=="0000"||payerId.branchId==branchId)
                branchName=WebCache.getAllBranches().filter(dep=>dep.id==event.target.value)[0].name;
            }
            this.setState({...this.state, branchFilter: event.target.value,branchName:branchName,currentPayerIds:taxPayerIds,taxPayerName:"",taxPayerId:"0000"});
            this.props.changeFilter("0000",event.target.value, this.state.departmentFilter,this.state.locationFilter,this.state.departmentName,this.state.locationName,branchName,"")
        }else if(event.target.id.includes("locationFilter")) {
            let locationId=event.target.value;
            let branches:IBranche[];
            let taxPayerIds:IPayerTaxId[];
            let locationName="";
            branches=WebCache.getAllBranches();
            taxPayerIds=WebCache.getAllPayerTaxIds();
            if(locationId!="0000"){
                taxPayerIds=taxPayerIds.filter(payerId=>payerId.locationId=="0000"||payerId.locationId==locationId)
                branches=branches.filter(branch=>branch.locationId=="0000"||branch.locationId==locationId)
                locationName=WebCache.getAllLocations().filter(dep=>dep.id==locationId)[0].name;
            }
            this.setState({...this.state, locationFilter: locationId,
                branchFilter:"0000",currentBranches:branches,branchName:"",
                taxPayerId:"0000",currentPayerIds:taxPayerIds,taxPayerName:"",
                locationName:locationName});
            this.props.changeFilter("0000","0000", this.state.departmentFilter,locationId,
                this.state.departmentName,locationName,"","")
        }else if(event.target.id.includes("departmentFilter")) {
            let departmentId=event.target.value;
            let locations:ILocation[];
            let branches:IBranche[];
            let taxPayerIds:IPayerTaxId[];
            locations=WebCache.getAllLocations();
            branches=WebCache.getAllBranches();
            taxPayerIds=WebCache.getAllPayerTaxIds();
            let departmentName="";
            if(departmentId!="0000"){
                taxPayerIds=taxPayerIds.filter(payerId=>payerId.departmentId=="0000"||payerId.departmentId==departmentId)
                locations=locations.filter(location=>location.departmentId=="0000"||location.departmentId==departmentId)
                branches=branches.filter(branch=>branch.departmentId=="0000"||branch.departmentId==departmentId)
                departmentName=WebCache.getAllDepartments().filter(dep=>dep.id==departmentId)[0].name;
            }
            this.setState({...this.state,
                departmentFilter: departmentId,
                locationFilter:"0000",currentLocations:locations,locationName:"",
                branchFilter:"0000",currentBranches:branches, branchName:"",
                taxPayerId:"0000",currentPayerIds:taxPayerIds,taxPayerName:"",
                departmentName:departmentName});
            this.props.changeFilter("0000","0000", departmentId,"0000",departmentName,"","","")
        }
    }

    buildSelectOptionsFromIdName(options: Array<{id: string, name: string}>) {
        return new OptionMapperHelper(this.props.t).buildSelectOptionsFromAnArrayWithDifferentInterface(options, 'id', 'name');
    }
    
    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let merchantFilterLabel;

        if (currentUser !== undefined && currentUser === 2) {
            merchantFilterLabel = t('merchantDocument');
        } else {
            merchantFilterLabel = t('merchantDocumentArg');
        }
        return (
            <React.Fragment>
                <div className="col-md-3">
                    <SelectField model={[ this.state, this.setState.bind(this) ]} attr='departmentFilter' label={WebCache.getCurrentUser()?.countryId==2?'department':'departmentArg'} options={this.buildSelectOptionsFromIdName(WebCache.getAllDepartments())} onChange={(e)=> this.handleChange(e)}></SelectField>
                </div>
                <div className="col-md-3">
                    <SelectField model={[ this.state, this.setState.bind(this) ]} attr='locationFilter' label='location' options={this.buildSelectOptionsFromIdName(this.state.currentLocations)} onChange={(e)=> this.handleChange(e)}></SelectField>
                </div>
                <div className="col-md-3">
                    <SelectField model={[ this.state, this.setState.bind(this) ]} attr='branchFilter' label='branch' options={this.buildSelectOptionsFromIdName(this.state.currentBranches)} onChange={(e)=> this.handleChange(e)}></SelectField>
                </div>
                <div className="col-md-3">
                    <SelectField model={[ this.state, this.setState.bind(this) ]} attr='taxPayerFilter' label={merchantFilterLabel} options={this.buildSelectOptionsFromIdName(this.state.currentPayerIds)} onChange={(e)=> this.handleChange(e)}></SelectField>
                </div>
            </React.Fragment>
        )
    };
}
export default withTranslation() (TerminalLocation);
