import { ReactElement } from "react";

interface Action {
    function?: (rowObject: any) => void
};

export interface ActionIcon extends Action {
    icon: string | ReactElement| ((rowObject: any) => void)
};

export interface ActionText extends Action {
    text: string
};

export interface ColumnDefinition {
    key: string,
    type?: 'text' | 'number' | 'percentage' | 'currency' | 'currency-type' | 'date' | 'action' | 'boolean' | 'status' | 'checkbox',
    label?: string,
    header?: boolean,
    action?: ActionIcon | ActionText,
    mapValue?: (rowObject: any) => string | ReactElement,
    fixedWidth?: number,
    percentageWidth?: number,
    hidden?: boolean
};

export interface CurrencyColumnDefinition extends ColumnDefinition {
    currency: number
}

export interface ReferenceKeyColumnDefinition extends ColumnDefinition {
    columnDefinitionKey: string
}

export interface CheckColumnDefinition extends ColumnDefinition {
    selectedOptions: number[]
}

function columnDefinition(props: ColumnDefinition) {
    return props;
}

export function textColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'text'});
}

export function numericColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'number'});
}

export function booleanColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'boolean'});
}

export function statusColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'status'});
}

export function percentageColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'percentage'});
}

export function currencyColumnDefinition(props: CurrencyColumnDefinition | ReferenceKeyColumnDefinition) {
    return columnDefinition({...props, type: 'currency'});
}

export function currencyTypeColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'currency-type'});
}

export function dateColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'date'});
}

export function actionColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'action'});
}

export function checkColumnDefinition(props: ColumnDefinition) {
    return columnDefinition({...props, type: 'checkbox'});
}

function isTypeColumnDefinition(props: ColumnDefinition, type: string): boolean {
    return props.type === type;
}

export function isBooleanColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'boolean');
}

export function isStatusColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'status');
}

export function isTextColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'text');
}

export function isNumericColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'number');
}

export function isPercentageColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'percentage');
}

export function isCurrencyColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'currency');
}

export function isCurrencyTypeColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'currency-type');
}

export function isDateColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'date');
}

export function isActionColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'action');
}

export function isCheckColumnDefinition(props: ColumnDefinition): boolean {
    return isTypeColumnDefinition(props, 'checkbox');
}