import React, {RefObject, useRef} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {  Card, Popover } from "react-bootstrap";
import Swal from 'sweetalert2';
import Page from '../containers/page';
import FormFilter from '../containers/form-filter';
import TextField from '../controls/fields/textField';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { WebCache } from '../../services/WebCache';
import { TransactionService } from '../../services/TransactionService';
import NavigationTabLinksForCharges from './NavigationTabLinksForCharges';
import SelectField from '../controls/fields/selectField';
import './GenerateQR.scss'
import {QRCodeSVG} from "qrcode.react";
import {Option} from "../../models/Option";
import {AxiosResponse} from "axios";
import imageQrFrame from "../../../node_modules/itd-common/assets/images/qr/imageQR.jpg";
import { ReactComponent as PrintSVG } from "../../../node_modules/itd-common/assets/icons/print.svg";
import { ReactComponent as FourItemListSVG } from "../../../node_modules/itd-common/assets/icons/four-item-list.svg";
import { ReactComponent as FourStepListSVG } from "../../../node_modules/itd-common/assets/icons/four_step-qr.svg";
import ReactToPrint from "react-to-print";

const T_PREFIX: string = 'pages.generateQR.';
interface IProps extends WithTranslation {
}

interface IFilters {
    terminalFilter: string,
    taxPayer:string
}

interface IState {
    filters: IFilters,
    allFiscalDocuments: Array<Option>, 
    allTerminals: Array<Option>,
    qrResponse:{qr:string,terminal:string}[],
    qrGenRef:RefObject<any>
}
let doQuery = false;
class GenerateQR extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.state = {
            allFiscalDocuments: WebCache.getAllMerchantDocuments(false),
            allTerminals:[],
            qrResponse:[],
            filters:{
                terminalFilter: '',
                taxPayer: WebCache.getAllMerchantDocuments(false)[0]?.key+"",
            },
            qrGenRef: React.createRef() 
        }
        doQuery = true;
    }

    componentDidMount() {
        if (doQuery) {
            doQuery = false;
            //this.getQRCode()
        }
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }
    
    handleChange(event: any) {
        if (event.target.name === "terminalFilter") {
            const newFilters = { ...this.state.filters, terminalFilter: event.target.value };
            this.setFilters(newFilters);
        }
    }

    clearFilter = () => {
        this.setFilters({
            ...this.state.filters,
            terminalFilter: '',
            taxPayer:''
        } );
    } 

    getQRCode = async () => {
        try {
            TransactionService.generateQR(this.state.filters.taxPayer).then(this.updateQrImage)
        } catch (error) {
            Swal.fire("Error al generar el código QR. Por favor, intenta nuevamente.");
        }
    }
    
    updateQrImage = (res:AxiosResponse<{terminals:{qr:string,terminal:string}[],success:boolean,errorDesc:string}>) =>{
            if(!res.data.success){
                Swal.fire("No se encuentran QR para este CUIT.");
                this.setState({ filters:{...this.state.filters,terminalFilter:''},allTerminals:[],qrResponse:[] });
            }else{
                let allTerminals=[{ value: "", label: "", key: "",disabled: false}]
                allTerminals=allTerminals.concat(res.data.terminals.map(x=>{return { value: x.terminal, label: x.terminal, key: x.terminal,disabled: false}}))
                this.setState({ filters:{...this.state.filters,terminalFilter:''},
                    allTerminals:allTerminals,qrResponse:res.data.terminals });
            }
    }
    
    render() {
        const { t } = this.props;
        return (
            <Page>
                <div className="row">
                    <h2>{t(`${T_PREFIX}title`)}</h2>
                    <div className="col-lg-12">
                        <div>
                            <NavigationTabLinksForCharges></NavigationTabLinksForCharges>
                        </div>    
                        <div  className='formGenerateQr'>
                            <FormFilter model={[this.state.filters, this.setFilters.bind(this)]} onSubmit={() => this.getQRCode()}  clear={this.clearFilter} >
                                <div className='row m-2'>
                                    <div className="col-md-4">
                                        <SelectField
                                            attr='taxPayer'
                                            label={`merchantDocumentArg`}
                                            options={this.state.allFiscalDocuments}
                                        ></SelectField>
                                    </div>
                                    {this.state.allTerminals.length>0 &&(<div className='col-md-4'>
                                        <SelectField
                                            attr='terminalFilter'
                                            label={`terminal`}
                                            options={this.state.allTerminals}
                                        ></SelectField>
                                    </div>)}
                                </div>
                            </FormFilter>
                        </div>                       
                        <Card>
                            <Card.Body>
                                {this.state.filters.terminalFilter.length > 0 ? (
                                    <div className="row instructionsQR">
                                        <div className="col-md-6">
                                            <h3>{t(`${T_PREFIX}payByQR.title`)}</h3>
                                                <div className='row'>
                                                    <div className='col-md-1 p-0 d-flex justify-content-center align-items-center'>
                                                        <FourItemListSVG></FourItemListSVG>
                                                    </div>
                                                    <div className='col-md-11 p-0'>
                                                        <ol>
                                                            <li className='textQrsteps'>{t(`${T_PREFIX}payByQR.stepOne`)}</li>
                                                            <li className='textQrsteps'>{t(`${T_PREFIX}payByQR.stepTwo`)}</li>
                                                            <li className='textQrsteps'>{t(`${T_PREFIX}payByQR.stepThree`)}</li>
                                                            <li className='textQrsteps'>{t(`${T_PREFIX}payByQR.stepFour`)}</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-6 mt-5">
                                            <QRCodeSVG  value={this.state.qrResponse.filter(x=>x.terminal==this.state.filters.terminalFilter)[0]?.qr} style={{width:"100%",height:"200px"}}/>
                                            <ReactToPrint
                                                bodyClass='d-flex justify-content-center'
                                                trigger={() => 
                                                    <div className='w-100 pt-4 d-flex justify-content-center'>
                                                        <a type='button'>                                                    
                                                            <span className='fs-5 fw-bold'>{t(`common.action.print`)}</span> <PrintSVG className='ms-2 mb-1'></PrintSVG>
                                                        </a>
                                                    </div>
                                                }
                                                content={() => this.state.qrGenRef.current}
                                            />
                                        </div>
                                        <div hidden={true}>
                                            <div ref={this.state.qrGenRef} style={{position:"absolute",height:"2360px",width:"1671px"}} >
                                                <img src={imageQrFrame} alt={""} style={{position:"absolute",height:"2360px",width:"1671px"}} />
                                                <div style={{position:"absolute",top:"575px",left:"507px"}}>
                                                    <QRCodeSVG id={"qrImage"}
                                                            height={550}
                                                            width={550}
                                                            value={"this.state.qrResponse.filter(x=>x.terminal==this.state.filters.terminalFilter)[0].qr"} />
                                                </div>
                                                <div style={{position:"absolute",top:"1232px",left:"700px",fontSize:"16px"}}>Cuit {this.state.filters.taxPayer} - Terminal {this.state.filters.terminalFilter}</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row instructionsQR">
                                        <div className="col-lg-12">
                                            <h3>{t(`${T_PREFIX}stepsQR.stepsToCreateQR`)}</h3>
                                            <span className="bold-text">{t(`${T_PREFIX}stepsQR.instructionsQR`)}</span>
                                            <div className='row'>
                                                <div className='col-md-1 p-0 d-flex justify-content-center align-items-center'>
                                                    <FourStepListSVG className='four-step-list'></FourStepListSVG>
                                                </div>
                                                <div className='col-md-11 p-0'>
                                                    <ol className='mt-2'>
                                                        <li className='textQrsteps'>{t(`${T_PREFIX}stepsQR.stepOne`)}</li>
                                                        <li className='textQrsteps pb-2'> {t(`${T_PREFIX}stepsQR.stepTwo`)}</li>
                                                        <li className='textQrsteps'>{t(`${T_PREFIX}stepsQR.stepThree`)}</li>
                                                        <li className='textQrsteps'>{t(`${T_PREFIX}stepsQR.stepFour`)}</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className='inlineText'></div>
                                            <p>{t(`${T_PREFIX}stepsQR.unknowTerminalNumber`)}</p>
                                            <p className='stepText'>{t(`${T_PREFIX}stepsQR.stepByStep`)}</p>
                                        </div>
                                    </div> 
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Page>
        );
    }
    
}    

export default withTranslation()(GenerateQR);
