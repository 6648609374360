import React from 'react';
import { withTranslation } from "react-i18next";
import { ReactComponent as LeftIconSVG } from "../../node_modules/itd-common/assets/icons/left-arrow-paginator.svg";
import { ReactComponent as RigthIconSVG } from "../../node_modules/itd-common/assets/icons/rigth-arrow-paginator.svg";


interface IProps {    
    changePage: (page: number) => void,
    modifyMainPage: (mainPage: number, newPages: Array<number>) => void,        
    dataL: number,
    mainPage: number,
    pageSize:number,
    pageQuantity:number,
    activePageProp?: number
}

interface IState {
    activePage: number,
    pages: Array<number>,
    maxPagesInPagination: number   
}

/**
 * A Pagination component
 * @param {number} changeSate saasa
 * @param {number} dataL number of elements in the data.
                     If it is a multiple of 10 + 1, it will show the "Next" button.
                     Example: if you submit 51, it will display 5 pages and a "Next" button
 */
class Pagination extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state={           
            activePage: 1,
            pages: Array.from(Array(Math.ceil(Math.min(this.props.dataL / this.props.pageSize,this.props.pageQuantity))).keys(), (_, i) => (i + 1) + (this.props.pageQuantity * this.props.mainPage)),
            maxPagesInPagination: this.props.pageQuantity
        }
    }
    componentWillReceiveProps(nextProps:IProps) {
        this.setState({
            pages: Array.from(Array(Math.ceil(Math.min(nextProps.dataL / this.props.pageSize,this.props.pageQuantity))).keys(), (_, i) => (i + 1) + (this.props.pageQuantity * this.props.mainPage)),
        });
        if(this.props.activePageProp){
            this.setState({activePage: this.props.activePageProp});
        }
    }



    movePage(sign:number){
        let newPages = this.state.pages.map((val: number) => val + (this.state.pages.length*sign))
        this.setState({
            pages: newPages.slice(0, Math.floor(this.props.dataL / this.props.pageSize)),
            activePage: newPages[0]            
        }, () => {
            this.props.changePage(this.state.pages[0]);
            this.props.modifyMainPage(sign, this.state.pages)
        })

    }

    handleChange(event:any) { 

    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        const { pages } = this.state;

        if (this.props.dataL <= 0 || !pages.length) {
            return null;
        }
        return (
                <div className='bg-white d-flex align-items-center p-3'>
                    {/* <div className='float-left w-80 d-flex align-items-center'>
                        <p className='m-0 ps-2'>
                            Mostrando {this.props.dataL} {this.props.dataL !== 1 ? "Items" : "Item"}
                        </p>
                    </div>
                    <div className='w-40 d-flex justify-content-center'>
                        <p className='m-0 ps-2'>
                            {this.props.dataL} {this.props.dataL !== 1 ? t("results") : t("result")}
                        </p>
                    </div> */}
                    <ul className="pagination w-100 justify-content-end pe-4" >
                        {
                            //#region PREV
                        }

                        {this.state.pages.includes(1) || this.state.pages.length <= 0 ? <>
                            <li className="page-item page-prev disable" >
                                <a className="page-link border-0"
                                    tabIndex={-1}><LeftIconSVG></LeftIconSVG></a>
                            </li>
                        </> :
                            <li className="page-item page-prev" onClick={() => this.movePage(-1)}>
                                <a className="page-link border-0"
                                    tabIndex={-1}><LeftIconSVG></LeftIconSVG></a>
                            </li>
                        }
                        {
                            //#endregion
                        }

                        {
                            //#region PAGES
                        }
                        {
                            this.state.pages.length > 0 &&
                            this.state.pages.map(page => {
                                return ( 
                                    <li key={`li-${page}`}                                         
                                        onClick={() => { 
                                            /*console.log('Pagination.tsx.click');
                                            console.log('Active page before click');
                                            console.log(this.state.activePage);                                           
                                            console.log('Page');
                                            console.log(page);*/
                                            this.setState({activePage: page }, () => {                                                                                                                                                              
                                            this.props.changePage(page); 
                                            /*console.log('Active page after click');
                                            console.log(this.state.activePage);  */                                 
                                        }); }}
                                        
                                        className={`page-item ${page==this.props.activePageProp?'text-dark':'text-light'}`} >
                                        <a key={`link-${page}`} className="page-link border-0">{page}</a>
                                    </li>
                                )
                            })
                        }
                        {
                            //#endregion
                        }

                        {
                            //#region NEXT
                        }
                        {this.props.dataL == ((this.props.pageSize*this.props.pageQuantity)+1) ?
                            <li onClick={() => this.movePage(1)} className="page-item page-next">
                                <a className="page-link border-0" ><RigthIconSVG></RigthIconSVG></a>
                            </li>
                            :
                            <li className="page-item page-next disable">
                                <a className="page-link border-0" ><RigthIconSVG></RigthIconSVG></a>
                            </li>

                        }
                        {
                            //#endregion
                        }
                    </ul>
                </div>
        )
    }
}

export default withTranslation()(Pagination);