import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IChargeBack, IDailySettlements, IMonthlySettlements, ISettlementHTMLSelectProduct } from "../models/ISettlementData";
import {
    IChargeBacksRequest,
    IDailySettlementsRequest,
    IDownloadDailyESettlement,
    IDownloadMonthlyESettlement,
    IDownloadProofOfWithholdings,
    IMonthlySettlementsRequest
} from "../requests/ISettlementsRequest";
import { axiosApiInstance } from "./HttpService";

export class FiscalSettlementService {

    public static async getDailySettlements(request: IDailySettlementsRequest): Promise<IDailySettlements> {        
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/SettlementListDaily`;
        return await axiosApiInstance.post<IDailySettlements>(dataURL, request)
                                    .then((response: AxiosResponse<IDailySettlements>) => { 
            return response.data });
    }



    public static async downloadProofOfWithholdings(request: IDownloadProofOfWithholdings){
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/downloadProofOfWithholdings`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }
    
    public static async getDowloadDailyESettlement(request: IDownloadDailyESettlement){        
        //let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/DownloadDailyESettlement`;
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/downloadDailyESettlement`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
          };

        return await axiosApiInstance.post(dataURL, request, options);
    }  

    public static async getMonthlySettlements(request: IMonthlySettlementsRequest): Promise<IMonthlySettlements> {          
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/MonthlyESettlementFileList`;
        return await axiosApiInstance.post<IMonthlySettlements>(dataURL, request).then((response: AxiosResponse<IMonthlySettlements>) => { 
            return response.data });
    }    

    public static async getDowloadMonthlyESettlement(request: IDownloadMonthlyESettlement){        
        //let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/DownloadMonthlyESettlement`;
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/apiTrxSender/FileExporter/downloadMonthlyESettlement`;
        let options: AxiosRequestConfig = {                         
            responseType: 'blob' // don't forget this
          };

        return await axiosApiInstance.post(dataURL, request, options);
    }    

    public static async getAllProducts(): Promise<HTMLOptionElement[]> {          
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/GetAllProducts`;
        return await axiosApiInstance.post<HTMLOptionElement>(dataURL).then((response: AxiosResponse<HTMLOptionElement[] | any>) => { 
            return response.data });
    } 

    public static async getChargeBacks(request: IChargeBacksRequest): Promise<AxiosResponse<IChargeBack[]>> {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/getChargeBacks`;
        return await axiosApiInstance.post<IChargeBack[]>(dataURL, request);
    }
}
