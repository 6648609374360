import { AxiosResponse } from 'axios';
import React, {RefObject} from 'react';
import { withTranslation } from 'react-i18next';
import TerminalLocation from "../TerminalLocation";
import { ITerminalWebModelRequest } from "../../requests/ITerminalWebModelRequest";
import { TerminalsService } from "../../services/TerminalsService";
import { WebCache } from '../../services/WebCache';
import {IShortcutCaptureResult} from "../../models/IShortcut";
import i18next from "i18next";
import {Card, Modal} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import {IBranche, ITerminal} from "../../models/ITerminal";
import "./TerminalReport.scss"
import TextField from '../controls/fields/textField';
import Page from '../containers/page'
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { ColumnDefinition, actionColumnDefinition, booleanColumnDefinition, numericColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import { IPageRequest } from '../../models/IPageRequest';
import CustomReCAPTCHA from '../CustomCaptcha';
import {TransactionService} from "../../services/TransactionService";

const pageSize = 13;
const pageQuantity = 3

interface IProps {
    allowUpdateTerminal:boolean
}

interface IState {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    terminals: ITerminal[],
    errors: string[],
    terminalFilter: string,
    departmentFilter: string
    locationFilter: string
    branchFilter: string
    columnsToShow: string[],
    allBranches:IBranche[]
    departmentName: string
    locationName: string
    branchName: string
    tableMode: TableModeEnum,
    pageRequest: IPageRequest,
    taxPayerName:string,
    taxPayerFilter:string
    terminalToEdit:ITerminal|null
    terminalConfirmationToEdit:ITerminal|null
    terminalLocationToEdit:ITerminal|null
    terminalLocationConfirmationToEdit:ITerminal|null
    selectedBranch:string,
    terminalToDelete:ITerminal|null,
    accordionRef:RefObject<any>,
    isCaptchaVerified:boolean
}

interface TerminalRow {
    style: string;
    value: string;
    icon: string;
}

let doQuery = false;
const pageTerminalSize = 13;
const pageTerminalQuantity = 3;
const defaultTerminalPages = [1, 2, 3];
const T_PREFIX: string = 'pages.terminalReport.';
class TerminalsReport extends React.Component<IProps, IState>{
    private columnDefinitions: Array<ColumnDefinition> = [];

    componentDidMount() {
        this.getTerminals();
    }

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        let shortcut ="";
        if(window.location.href.indexOf("shortcuts=")>0){
            shortcut = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("shortcuts="))).get("shortcuts")+""
        }
        let terminalFilter = "";
        let departmentFilter = "";
        let locationFilter = "";
        let branchFilter = "";

        if(shortcut != null && shortcut.length > 0){
            doQuery = true;
            let shortcutDto : IShortcutCaptureResult[] = JSON.parse(shortcut);
            
            for(var i = 0; i < shortcutDto.length; i++){
                switch (shortcutDto[i].type){                    
                    case "TERMINAL_NUMBER":
                        //console.log(shortcutDto[i].data);
                        
                        terminalFilter = shortcutDto[i].data + "";                        
                        break;
                    case "DEPARTMENT":      
                        //console.log(shortcutDto[i].data.name);                  
                        departmentFilter = shortcutDto[i].data.id;
                        break;
                    case "LOCATION":      
                        //console.log(shortcutDto[i].data.name);                  
                        locationFilter = shortcutDto[i].data.id;
                        break;
                    case "BRANCH":      
                        //console.log(shortcutDto[i].data.name);                  
                        branchFilter = shortcutDto[i].data.id;
                        break;
                }
            }
        }

        this.state = {
            activePage: 1,
            mainPage: 0,
            pages: defaultTerminalPages,
            columns: [
            ],
            tableMode: TableModeEnum.LIST,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            terminals: [],
            errors: [],
            terminalFilter: terminalFilter,
            departmentFilter: departmentFilter,
            locationFilter: locationFilter,
            branchFilter: branchFilter,
            departmentName: "",
            locationName: "",
            branchName: "",
            taxPayerName:"",
            taxPayerFilter:"",
            terminalToEdit:null,
            terminalConfirmationToEdit:null,
            terminalLocationToEdit:null,
            terminalLocationConfirmationToEdit:null,
            selectedBranch:"",
            allBranches:[],
            columnsToShow: ["TERMINALNUMBER","CLIENTDOCUMENT","ISPINPAD", "BRANCHNAME", "DEPARTMENTNAME", "LOCATIONNAME","SERIALNUMBER"],
            terminalToDelete:null,
            accordionRef:React.createRef(),
            isCaptchaVerified:false
        }

        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument=`${T_PREFIX}clientARG`;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = `${T_PREFIX}clientURU`;
        }       


        if(WebCache.getCurrentUser()?.countryId==2){
            this.columnDefinitions = [
                textColumnDefinition({ key: 'terminalNumber', label: `${T_PREFIX}columnTable.terminal`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'clientDocument', label: clientDocument, header: true, percentageWidth: 10 }),
                booleanColumnDefinition({ key: 'isPinpad', label: `${T_PREFIX}columnTable.pinpad`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'branchName', label: `${T_PREFIX}columnTable.address`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'departmentName', label: `${T_PREFIX}columnTable.department`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'locationName', label: `${T_PREFIX}columnTable.location`, header: true, percentageWidth: 10 }),
                numericColumnDefinition({ key: 'serialNumber', label: `${T_PREFIX}columnTable.serialNumber`, header: true, percentageWidth: 20 }),
                actionColumnDefinition({key: 'delete',header: true,action: { function: this.processDeleteAction.bind(this), icon:(terminal:ITerminal)=>{
                    if(terminal.deleted === true){
                        return <div className='containerTerminalRestaured'> <i className="fa fa-arrow-circle-o-up text-success "> <p className="textSuccess"> Restaurar terminal</p></i> </div>
                    } else {
                        return <div className='containerTerminalRestaured'> <i className="fa fa-trash-o"> <p className="textSuccess"> Eliminar terminal</p></i> </div>
                    }
                }} , percentageWidth: 20 })
            ]
        }else{
            this.columnDefinitions = [
                textColumnDefinition({ key: 'terminalNumber', label: `${T_PREFIX}columnTable.terminal`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'clientDocument', label: clientDocument, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'branchName', label: `${T_PREFIX}columnTable.address`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'departmentName', label: `${T_PREFIX}columnTable.departmentArg`, header: true, percentageWidth: 10 }),
                textColumnDefinition({ key: 'locationName', label: `${T_PREFIX}columnTable.locationArg`, header: true, percentageWidth: 10 }),
                actionColumnDefinition({key: 'delete',header: true,action: { function: this.processDeleteAction.bind(this), icon:(terminal:ITerminal)=>{
                    if(terminal.deleted === true){
                        return <div className='containerTerminalRestaured'> <i className="fa fa-arrow-circle-o-up text-success "> <p className="textSuccess"> Restaurar terminal</p></i> </div>
                    } else {
                        return <div className='containerTerminalRestaured'> <i className="fa fa-trash-o"> <p className="textSuccess"> Eliminar terminal</p></i> </div>
                    }
                }} , percentageWidth: 20 })            
            ]
        }
    }   
    
    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getTerminalRequest = (all: boolean) => {
        const tranWebModel: ITerminalWebModelRequest = {
            TerminalId: (this.state.terminalFilter == null || this.state.terminalFilter == "") ? "" : this.state.terminalFilter,
            Department: (this.state.departmentFilter == null || this.state.departmentFilter == "0000") ? "" : this.state.departmentFilter,
            PayerTaxId: (this.state.taxPayerFilter==null||this.state.taxPayerFilter=="0000")?"":this.state.taxPayerFilter,
            Location: (this.state.locationFilter == null || this.state.locationFilter == "0000") ? "" : this.state.locationFilter,
            Branch: (this.state.branchFilter == null || this.state.branchFilter == "0000") ? "" : this.state.branchFilter,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
        return tranWebModel;
    }

    getTerminals = () => {
        TerminalsService.getTerminals(this.getTerminalRequest(false)).then((response: AxiosResponse<ITerminal[]>) => {
            if (response == null || response.data == null || response.data.length == 0) {
                this.setState({
                    terminals: []
                })
                return;
            }

            /*
            let newColumns = [];
            if(this.props.allowUpdateTerminal)
                newColumns.push(i18next.t("edit")+"")
            for (let prop in response.data[0]) {
                newColumns.push(prop)
            }*/
            let tmp=WebCache.getAllBranches();
            let allBranches=[];
            for(var i=1;i<tmp.length;i++){
                allBranches.push(tmp[i]);
            }
            response.data.forEach(t=>t.terminalNumber=t.terminalNumber.replace("B24_",""))
            this.setState({
               // columns: newColumns,
                terminals: response.data,
                allBranches:allBranches,
                terminalToDelete:null
            });

        }).catch(error => {
            this.setState({
                errors: error.response.data
            });
            this.setState({
                terminals: []
            })
            console.log('error:', error);
        }
        );
    }

    getTerminalsToExcel = () => {
        if (this.state.isCaptchaVerified) {

            TerminalsService.getAllToExcel(this.getTerminalRequest(true))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({ errors: error.response.data });
                    console.log('error:', error);
                });
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }

    showEditTerminalModal = (terminal:ITerminal) => {
        terminal.originalPinpadValue=terminal.isPinpad;
        this.setState({ ...this.state,terminalToEdit: terminal })
    }

    updateEditInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name.toUpperCase() == "isPinpad".toUpperCase()) {
            if(this.state.terminalToEdit!=null)
                this.setState({
                    ...this.state,
                    terminalToEdit:{
                        ...this.state.terminalToEdit,isPinpad:!this.state.terminalToEdit?.isPinpad
                    }
                });
        }
    }

    deleteTerminal=()=>{
        TerminalsService.deleteOrRestoreTerminal({terminalId:this.state.terminalToDelete?.terminalNumber,deleted:!this.state.terminalToDelete?.deleted}).then(this.getTerminals)
    }
    updateTerminalLocation = () => {
        TerminalsService.changeBranchToTerminal({
            terminalId: this.state.terminalLocationConfirmationToEdit?.terminalNumber,
            branchId: this.state.selectedBranch,
        }).then((res:any)=>{
            WebCache.initCache().then(()=>{this.afterUpdateTerminalEvent(res)});
        })

    }

    processDeleteAction = (terminal: ITerminal) => {
        this.setState({ terminalToDelete: terminal });
    };

    updateTerminal = () => {
        //http://localhost:5033/api/Terminal/UpdateTerminalConfiguration
        TerminalsService.UpdateTerminalConfiguration({
            terminal: this.state.terminalConfirmationToEdit?.terminalNumber,
            isPinpad: this.state.terminalConfirmationToEdit?.isPinpad
        }).then(this.afterUpdateTerminalEvent)
    }

    afterUpdateTerminalEvent = (res:any) => {
        if(res.data+""=="true")
            toast.success(i18next.t("terminalUpdatedSuccess")+"");
        else
            toast.error(i18next.t("erroronTerminalUpdate")+"");
        this.setState({...this.state,terminalConfirmationToEdit:null,terminalLocationConfirmationToEdit:null},this.getTerminals);
    }

    handleChange(event: any) {
        if (event.target.name == "terminalFilter")
            this.setState({ ...this.state, terminalFilter: event.target.value });
        else if(event.target.name=="selectedBranch") {
            this.setState({...this.state,selectedBranch:event.target.value})
        }
    }

    getFromBuscar = () => {
        this.setState({
            ...this.state, activePage: 1, pages: defaultTerminalPages,
            mainPage: 0
        }, this.getTerminals);
    }

    clearFilters = () => {
        this.setState({
            ...this.state,
            terminalFilter: "", 
            taxPayerFilter: " ", 
            branchFilter: " ", 
            departmentFilter: " ", 
            locationFilter: " ", 
            branchName: ""
        })
    }

    processAction=(event: any, terminal: ITerminal)  => {
        let branch=this.state.allBranches.filter(val => terminal.branchName == val.name);
        if(branch.length==0)
            branch=this.state.allBranches
        this.setState({...this.state,terminalLocationToEdit:terminal,selectedBranch:branch[0].id});
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page><>
                <ToastContainer
                    autoClose={3000}
                ></ToastContainer>
                <Modal show={this.state.terminalLocationToEdit != null} size="xl">
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({...this.state, terminalLocationToEdit: null })} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <div className="row">
                                <h4 className="h4 mb-0 mt-3">{t('Terminal')+" "+this.state.terminalLocationToEdit?.terminalNumber }</h4>
                            </div>
                            <div className="row">
                                <select name="selectedBranch" className="form-control form-select select2" value={this.state.selectedBranch}  onChange={(event)=>this.handleChange(event)}>
                                    {
                                        this.state.allBranches!=null&&
                                        this.state.allBranches.length > 0 &&
                                        this.state.allBranches.map(branch => {
                                            return (
                                                <option key={branch.id} value={branch.id}>{branch.fullDesc}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="card-footer text-center border-0 pt-0">
                            <div className="row">
                                <div className="text-center">
                                    <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({ ...this.state,terminalLocationToEdit: null })}>{t('cancel')}</a>
                                    <a href={window.location.href} className="btn btn-primary" onClick={() => this.setState({ ...this.state,terminalLocationToEdit: null,terminalLocationConfirmationToEdit:this.state.terminalLocationToEdit })}>{t('confirm')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.terminalLocationConfirmationToEdit != null}>
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({...this.state, terminalConfirmationToEdit: null })} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <span className="">
                                <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60"
                                    viewBox="0 0 24 24"><path fill="#f07f8f"
                                    d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z" /><circle
                                    cx="12" cy="17" r="1" fill="#e62a45" /><path fill="#e62a45"
                                    d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z" />
                                </svg>
                            </span>
                            <h4 className="h4 mb-0 mt-3">{t('warning')}</h4>
                            <p className="card-text">{t('confirmEditTerminalLocation')}</p>
                        </div>
                        <div className="card-footer text-center border-0 pt-0">
                            <div className="row">
                                <div className="text-center">
                                    <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({ ...this.state,terminalLocationConfirmationToEdit: null })}>{t('cancel')}</a>
                                    <a href={window.location.href} className="btn btn-danger" onClick={() => this.updateTerminalLocation()}>{t('confirm')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.terminalConfirmationToEdit != null}>
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({...this.state, terminalConfirmationToEdit: null })} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <span className="">
                                <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60"
                                    viewBox="0 0 24 24"><path fill="#f07f8f"
                                    d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z" /><circle
                                    cx="12" cy="17" r="1" fill="#e62a45" /><path fill="#e62a45"
                                    d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z" />
                                </svg>
                            </span>
                            <h4 className="h4 mb-0 mt-3">{t('warning')}</h4>
                            <p className="card-text">{t('confirmEditTerminal')}</p>
                        </div>
                        <div className="card-footer text-center border-0 pt-0">
                            <div className="row">
                                <div className="text-center">
                                    <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({ ...this.state,terminalConfirmationToEdit: null })}>{t('cancel')}</a>
                                    <a href={window.location.href} className="btn btn-danger" onClick={() => this.updateTerminal()}>{t('confirm')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.terminalToEdit!=null}>
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header bg-primary">
                            <h5 className="modal-title text-white">{t("editTerminal")}  </h5>
                            <button aria-label="Close" className="btn-close" onClick={() => this.setState({...this.state,terminalToEdit:null})}><span className='text-white' aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12 mb-3">
                                <label className="col-form-label ">{t("Terminal")+":"}</label>
                                <input autoComplete='off' autoFocus type="text" className="form-control" disabled={true} value={this.state.terminalToEdit?.terminalNumber} id="inputTerminal" />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="col-form-label ">{t("clientDocument")+":"}</label>
                                <input autoComplete='off' autoFocus type="text" className="form-control" disabled={true} value={this.state.terminalToEdit?.clientDocument} id="inputClientDocument" />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label className="col-form-label ">{t("branchName")+":"}</label>
                                <input autoComplete='off' autoFocus type="text" className="form-control" disabled={true} value={this.state.terminalToEdit?.branchName} id="inputBranchName" />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label className="col-form-label ">{t("locationName")+":"}</label>
                                <input autoComplete='off' autoFocus type="text" className="form-control" disabled={true} value={this.state.terminalToEdit?.locationName} id="inputLocationName" />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label className="col-form-label ">{t("departmentName")+":"}</label>
                                <input autoComplete='off' autoFocus type="text" className="form-control" disabled={true} value={this.state.terminalToEdit?.departmentName} id="inputDepartmentName" />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label className="custom-control custom-checkbox-md">
                                    <input type="checkbox" className="custom-control-input"  name="isPinpad"
                                        value="isPinpad" checked={this.state.terminalToEdit?.isPinpad} onChange={this.updateEditInput}></input>
                                        <span className="custom-control-label">{t("isPinpad")}</span>
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.setState({...this.state,terminalToEdit:null,terminalConfirmationToEdit:this.state.terminalToEdit})} className="btn btn-primary" disabled={this.state.terminalToEdit?.isPinpad==this.state.terminalToEdit?.originalPinpadValue}>{t("accept")}</button>
                            <button onClick={() => this.setState({...this.state,terminalToEdit:null})} className="btn btn-light" data-bs-dismiss="modal">{t("close")}</button>
                        </div>
                    </div>
                </Modal>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h3 className="title">{t(this.props.allowUpdateTerminal ? "terminalsConfiguration" : "terminalReport")}</h3>                          
                                        <div className="row filters">
                                            <div className="col-md-3"> 
                                                <TextField model={[ this.state, this.setState.bind(this) ]} attr='terminalFilter' label='terminal' ></TextField>
                                            </div>
                                            <TerminalLocation 
                                                changeFilter={(taxPayerFilter, branchFilter,
                                                    departmentFilter, locationFilter, departmentName,
                                                    locationName, branchName, clientDocument) => this.setState({
                                                        ...this.state, branchFilter: branchFilter, departmentFilter: departmentFilter, locationFilter: locationFilter,
                                                        departmentName: departmentName, locationName: locationName, branchName: branchName, taxPayerName: clientDocument, taxPayerFilter: taxPayerFilter
                                                    })} filter={{
                                                        taxPayerFilter: this.state.taxPayerFilter,
                                                        branchFilter: this.state.branchFilter,
                                                        departmentFilter: this.state.departmentFilter,
                                                        locationFilter: this.state.locationFilter
                                                    }}                                                      
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-8"></div>
                                            <div className="col-md-2 pb-5 d-flex align-items-center justify-content-end">
                                                <a type='button' className='clear-filter-button' onClick={() => this.clearFilters()}>
                                                    <>
                                                        <span className='fa fa-trash-o'>{t('clearFilters')}</span>
                                                    </>
                                                </a>
                                            </div>
                                            <div className="col-md-2 pb-5">
                                                <button type="button"
                                                    className="btn btn-primary btn-block" onClick={() => this.getFromBuscar()}>{t("query")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="terminal-accordion-table w-100">
                                        <CustomTable
                                            rowObjects={this.state.terminals}
                                            columnDefinitions={this.columnDefinitions}
                                            config={{
                                                mode: this.state.tableMode,
                                                paginator: {
                                                    mainPage: this.state.mainPage,
                                                    modifyMainPage: (value: number) => {
                                                        this.setState({
                                                            mainPage: this.state.mainPage + value
                                                        }, () => this.getTerminals())
                                                    },
                                                    pageRequest: this.state.pageRequest
                                                }
                                                // customButtonAction: this.state.trxToMerge !== "" ? { action: this.mergeTrx, buttonText: t("mergeTransaction") } : undefined
                                            }}
                                        ></CustomTable>
                                    </div>
                                    <div className='row mb-5'>
                                        <div className='col-md-6'>
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomReCAPTCHA onVerify={this.handleCaptchaVerification} />
                                        </div>
                                        <div className="col-md-3">
                                            <br />
                                            <button type="button" disabled={this.state.terminals.length == 0}
                                                className="btn btn-primary mb-3 btn-block" onClick={this.getTerminalsToExcel}>Excel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Modal show={this.state.terminalToDelete != null}>
                                    <div className="card border p-0 pb-3">
                                        <div className="card-header border-0 pt-3">
                                            <div className="card-options">
                                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({ terminalToDelete: null })} ><i className="fe fe-x"></i></a>
                                            </div>
                                        </div>
                                        <div className="card-body text-center">
                                            <span className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" viewBox="0 0 24 24">
                                                    <path fill="#f07f8f"
                                                        d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z" /><circle
                                                        cx="12" cy="17" r="1" fill="#e62a45" 
                                                    /><path fill="#e62a45"
                                                    d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z" />
                                                </svg>
                                            </span>
                                            <h4 className="h4 mb-0 mt-3">{t('warning')}</h4>
                                            <p className="card-text">{this.state.terminalToDelete?.deleted?t('confirmRestoreTerminal'):t('confirmDeleteTerminal')}</p>
                                        </div>
                                        <div className="card-footer text-center border-0 pt-0">
                                            <div className="row">
                                                <div className="text-center">
                                                    <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({ terminalToDelete: null })}>{t('cancel')}</a>
                                                    <a href={window.location.href} className={"btn "+(this.state.terminalToDelete?.deleted?"btn-success":"btn-danger")} onClick={() => this.deleteTerminal()}>{this.state.terminalToDelete?.deleted?t('restore'):t('delete')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            </Page>
        )
    };


}
export default withTranslation()(TerminalsReport);

