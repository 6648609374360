import { IPropsInputField } from "./fieldPropertiesInterface";
import InputField from "./inputField";
import {ReactComponent as SearchIconSVG } from "../../../../node_modules/itd-common/assets/icons/search-icon.svg";

function TextField({
  model,
  attr,
  value,
  label,
  onChange,
 isTextArea,
  disabled,
  required,
  autoFocus,
  sufix
}: IPropsInputField) {
  return (
    <InputField
      type='text'
      attr={attr}
      value={value}
      label={label}
      model={model}
      onChange={onChange}
      isTextArea={isTextArea}
      disabled={disabled}
      required={required}
      autoFocus={autoFocus}
      sufix={sufix}
    ></InputField>
  );
}

export default TextField;