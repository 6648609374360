import { AxiosResponse } from 'axios';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import {
    DateRangeType,
    IPresentationRequest, IPresentationTotal,
} from "../../models/ISettlementData";
import { SettlementService } from "../../services/SettlementService";
import ErrorsView from '../ErrorsView';
import FormFilter from '../containers/form-filter';
import DateField from '../controls/fields/dateField';
import "./SettlementReport.css";
import SettlementTotal from "./SettlementTotal";
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { ColumnDefinition, dateColumnDefinition, numericColumnDefinition, textColumnDefinition, currencyTypeColumnDefinition, currencyColumnDefinition} from '../table/ColumnDefinition';
import { IPageRequest } from '../../models/IPageRequest';
import Page from '../containers/page'
import NavigationTabLinksForReports from './NavigationTabLinksForReports';
import Card from '../containers/card';
import { UtilService } from '../../services/UtilService';
import { DateHelper } from '../../helpers/DateHelper';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { WebCache } from '../../services/WebCache';
import Button from '../controls/buttons/button';
import { toast } from "react-toastify";
import CustomReCAPTCHA from '../CustomCaptcha';
import { t } from 'i18next';
import {Option} from "../../models/Option";


interface IProps {
    t:any
}

interface IFilters {
    dateRangeType: DateRangeType,
    fiscalDocument: string,
    merchantDocument: string
    dateRange: DateObject[]
}

interface IState {
    filters: IFilters,
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    currencyOptions: Array<HTMLOptionElement>,
    mainPage: number,
    pageRequest: IPageRequest,
    presentations: IPresentationTotal[],
    errors: string[],
    rowDetail: { title: string, total: string, data: Array<{ prop: string, value: string }> } | null,
    tableMode: TableModeEnum,
    isCaptchaVerified:boolean
}
let doQuery = false;

let lastFiscalDocument:string=""
const T_PREFIX: string = 'pages.presentationReport.';
class PresentationReport extends React.Component<IProps, IState>{
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private translateFnc: any;
    private columnDefinitions: Array<ColumnDefinition>;

    updateDimensions = () => {
        /*
        const { innerWidth: width, innerHeight: height } = window;
        if(UtilService.isSmallScreen()&&this.state.columnsToShow.length==0){
            this.setState({...this.state,columnsToShow:[
                    "TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"
                ]});
        }else if(width>992&&this.state.columnsToShow.length>0){
            this.setState({...this.state,columnsToShow:[]});
        }
        */
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);

        if(doQuery) {
            doQuery = false;
            this.getSettlements();
        }

        this.translateFnc = this.props.t;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    constructor(props: IProps) {
        super(props);

        let dateRangeType: DateRangeType = "PAYMENT_DATE";
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();

        this.state = {
            filters: {
                dateRangeType,
                fiscalDocument:WebCache.getAllMerchantDocuments(true)[0].label+"",
                merchantDocument:"" ,
                dateRange: DateHelper.buildDateRange()
            },
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false)[0].label,true),
            fiscalDocuments:WebCache.getAllMerchantDocuments(true),
            currencyOptions,
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            presentations: [],
            errors: [],
            rowDetail: null,
            tableMode: TableModeEnum.LIST,
            isCaptchaVerified: false

        }

        const T_PREFIX_COLUMN = `${T_PREFIX}table.column.`;
        this.columnDefinitions = [
            dateColumnDefinition({ key: 'authDate', label: `${T_PREFIX_COLUMN}authDate`, header: true }),
            textColumnDefinition({ key: 'terminal', label: `${T_PREFIX_COLUMN}terminal`, header: true }),
            textColumnDefinition({ key: 'authorizationCode', label: `${T_PREFIX_COLUMN}authorizationCode`, header: true }),
            textColumnDefinition({ key: 'productCode', label: `${T_PREFIX_COLUMN}productCode`, mapValue: (presentation: any) => {
                const productCode: string = presentation.productCode;
                return (productCode) ? <img src={UtilService.getImageTag(productCode.toUpperCase())} alt={`${productCode}`} ></img> : <></>
            }, header: true }),
            textColumnDefinition({ key: 'cardNumber', label: `${T_PREFIX_COLUMN}cardNumber`, header: true }),
            textColumnDefinition({ key: 'ticket', label: `${T_PREFIX_COLUMN}ticket`, header: true }),
            currencyColumnDefinition({ key: 'grossAmount', label: `${T_PREFIX_COLUMN}grossAmount`, header: true, columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'planQuotaType', label: `${T_PREFIX_COLUMN}planQuotaType` }),
            textColumnDefinition({ key: 'planQuotaRate', label: `${T_PREFIX_COLUMN}planQuotaRate` }),
            numericColumnDefinition({ key: 'currentQuota', label: `${T_PREFIX_COLUMN}currentQuota` }),
            textColumnDefinition({ key: 'pos', label: `${T_PREFIX_COLUMN}pos` }),
            dateColumnDefinition({ key: 'aggregationPresentationDate', label: `${T_PREFIX_COLUMN}aggregationPresentationDate` }),
            dateColumnDefinition({ key: 'vtoDate', label: `${T_PREFIX_COLUMN}vtoDate` }),
            currencyTypeColumnDefinition({ key: 'currency', label: `${T_PREFIX_COLUMN}currency` }),
        ];
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    updateDateRange = (e: DateObject[] | any) => {
        this.setState({ ...this.state, filters: { ...this.state.filters, dateRange: e } });
    }

    getPresentationTotalsRequest = (all: boolean) => {      
        const tranWebModel: IPresentationRequest = {
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            DateRangeType: this.state.filters.dateRangeType,
            MerchantDocument: this.state.filters.fiscalDocument=="all"?"":this.state.filters.fiscalDocument,
            MerchantNumber: this.state.filters.merchantDocument=="all"?"":this.state.filters.merchantDocument,
            Currency: "",
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take)
        };
        return tranWebModel;
    }

    getSettlementsFromMainPage = () => {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.getSettlements();
        });
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.fiscalDocument){
            lastFiscalDocument=this.state.filters.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }
    }

    getSettlements = () => {
        SettlementService.getPresentationTotals(this.getPresentationTotalsRequest(false)).then((response: AxiosResponse<IPresentationTotal[]>) => {
            if (response === null ||
                response.data === null ||
                response.data.length === 0
            ) {
                this.setState({...this.state,
                    presentations: [],
                    errors: []
                })
                return;
            }

            this.setState({...this.state,
                presentations: response.data,
                errors: []
            });
        }).catch(error => {
            this.setState({...this.state,
                errors: error.response.data,
                presentations: []
            })
            console.log('error:', error);
        });
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getTransactionsToExcel = () => {
        if (this.state.isCaptchaVerified) {
        SettlementService.getAllPresentationsToExcel(this.getPresentationTotalsRequest(true)).then((response: AxiosResponse<Blob>) => {
        }).catch(error => {
            this.setState({ errors: error.response.data });
            console.log('error:', error);
        });
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }


    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = t("merchantDocument") ;
        } else {
            clientDocument = t("merchantDocumentArg");
        }

        return (
            <Page>
                <>
                    <SettlementTotal rowDetail={this.state.rowDetail} link={null}></SettlementTotal>
                    <div className='row'>
                        <h2>{t('fileReports')}</h2>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Card actions={
                                <>
                                    <div className="col-lg-12">
                                        <NavigationTabLinksForReports></NavigationTabLinksForReports>
                                    </div>
                                </>
                            }>
                                <>
                                    <FormFilter 
                                        onSubmit={this.getSettlementsFromMainPage} 
                                        model={[ this.state.filters, this.setFilters.bind(this) ]} 
                                        header={
                                            <div className="d-flex align-items-center justify-content-end pt-2" >
                                                <RadioButtonGroup
                                                    model={[ this.state, this.setState.bind(this) ]} 
                                                    attr="tableMode"
                                                    fieldDefinitions={[
                                                        { key: TableModeEnum.LIST, value: TableModeEnum.LIST, icon: 'fe fe-list' },
                                                        { key: TableModeEnum.GRID, value: TableModeEnum.GRID, icon: 'fe fe-grid' }
                                                    ]}
                                                ></RadioButtonGroup>
                                            </div>
                                        }>
                                        <>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <RadioButtonGroup
                                                        attr="dateRangeType" 
                                                        className='radioStyle'
                                                        fieldDefinitions={[
                                                            { key: 'vtoDate', value: 'PAYMENT_DATE', label: 'paymentDate' },
                                                            { key: 'aggregationPresentationDate', value: 'PRESENTATION_DATE', label: 'presentationDate' },
                                                            { key: 'authDate', value: 'OPERATION_DATE', label: 'operationDate' }
                                                        ]}                                                  
                                                    ></RadioButtonGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <DateField attr="dateRange" label="dateRange" range></DateField>
                                                </div>
                                                <div className="col-md-4">
                                                    <SelectField attr='fiscalDocument' label={clientDocument}
                                                    options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}></SelectField>
                                                </div>
                                                <div className="col-md-4">
                                                    <SelectField attr='merchantDocument' label={`merchantNumber`}
                                                    options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                                </div>
                                                {WebCache.getCurrentUser()?.countryId == 2 ?
                                                <div className="col-md-4">
                                                    <SelectField attr="currency" label={`${T_PREFIX}currency`}
                                                    options={this.state.currencyOptions}></SelectField>
                                                </div>
                                                :""}
                                            </div>
                                        </>
                                    </FormFilter>
                                    <CustomTable
                                        rowObjects={this.state.presentations}
                                        columnDefinitions={this.columnDefinitions}
                                        config={{
                                            mode: this.state.tableMode,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getSettlements())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                    <div className='row'>
                                        <div className='col-md-6'></div>
                                        <div className="col-md-3">
                                            <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                        </div>
                                        <div className="col-md-3">
                                            <Button disabled={this.state.presentations.length === 0} label='excel' onClick={() => { toast.info("El excel se descargará cuando esté listo"); this.getTransactionsToExcel() }}></Button>
                                        </div>
                                    </div>
                                </>
                            </Card>
                        </div>
                    </div>
                </>
            </Page>
        )
    };

}
export default withTranslation() (PresentationReport);
