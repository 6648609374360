import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {AxiosResponse} from "axios";
import merchantImage from "../../../assets/images/bodeguita.png";
import {Modal} from "react-bootstrap";
import "./LoyaltyPromotions.css"
import {ClientService} from "../../../services/loyalty/ClientService";
import {ILoyaltyPromotion} from "../../../models/loyalty/IClient";
import Page from '../../containers/page'

interface IState {
    errorMessage: string,
    initDahboard: boolean,
    reload: boolean,
    errors: string[],
    promotions:ILoyaltyPromotion[],
    generalPointValue:number,
    selectedPromotion:ILoyaltyPromotion|null,
    titlePromotionForm:string,
    imagePromotionForm:string,
    descriptionPromotionForm:string,
    pointsValuePromotionForm:number,
    externalIdPromotionForm:string
}
interface IProps { }




let LoyaltyPromotions: React.FC<IProps> = () => {

    const { t } = useTranslation();

    let [state, setState] = useState<IState>({
        errorMessage: "",
        initDahboard: true,
        reload: false,
        errors: [],
        promotions:[],
        generalPointValue:100,
        selectedPromotion:null,
        titlePromotionForm:"",
        imagePromotionForm:"",
        descriptionPromotionForm:"",
        pointsValuePromotionForm:0,
        externalIdPromotionForm:""
    });




    let date =new Date();
    date.setFullYear(date.getFullYear()-1);
    const pageSize=13;





    useEffect(() => {
        setState({...state,initDahboard:false});
        if(!state.initDahboard){
            ClientService.getAllPromotions().then(initPromotionLIst)
        }

    }, [state.initDahboard]);

    useEffect(() => {
      validationForm();
    }, [state.descriptionPromotionForm,state.titlePromotionForm,state.pointsValuePromotionForm,state.imagePromotionForm]);

    let initPromotionLIst = (result: AxiosResponse<ILoyaltyPromotion[]>) => {
        setState({...state,promotions:result.data})
    }
    let updateInput = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        setState({...state,[event.target.name]: event.target.value });
    };

    let updatePromotion = () => {
        setState({...state,selectedPromotion:null});
    };


    let validationForm = () => {

    }

    let uploadImage = () => {

    }
    let createPromotion = () => {
        var promo:ILoyaltyPromotion={
            title:"",
            image:"",
            description:"",
            pointsValue:0,
            externalIdPromotionForm:"",
            id:""
        }
        setState({...state,selectedPromotion: promo,
            titlePromotionForm:promo.title,
            imagePromotionForm:promo.image,
            descriptionPromotionForm:promo.description,
            pointsValuePromotionForm:promo.pointsValue
        });
    }

    let showPromotionToEdit = (promo:ILoyaltyPromotion) => {
        setState({...state,selectedPromotion:promo,
            titlePromotionForm:promo.title,
            imagePromotionForm:promo.image,
            descriptionPromotionForm:promo.description,
            pointsValuePromotionForm:promo.pointsValue
        });
    }


    return (
            <Page>
            <>
                <Modal show={state.selectedPromotion!=null}>
                    <div className="card-header border-0 pt-3">
                        <div className="card-options">
                            <a href="javascript:void(0)" className="card-options-remove" onClick={() => setState({...state, selectedPromotion: null })} ><i className="fe fe-x"></i></a>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={state.imagePromotionForm} key={state.imagePromotionForm} className="merchant-image-card" alt="img"></img>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-12  text-center">
                                <button  type="button" className="btn btn-primary"
                                         onClick={() =>uploadImage()} ><i className="fe fe-upload-cloud me-2"></i>{t("uploadImage")}
                                </button>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                                <div className="col-md-3">
                                    <label className="col-form-label">{t("title")}:</label>
                                </div>
                                <div className="col-md-9">
                                    <input autoComplete='off' type='text' value={state.titlePromotionForm}
                                           name={"titlePromotionForm"}
                                           onChange={updateInput} className="form-control" id="titlePromotionForm" />
                                </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("description")}:</label>
                            </div>
                            <div className="col-md-9">
                                <textarea autoComplete='off'  value={state.descriptionPromotionForm}
                                       name={"descriptionPromotionForm"}
                                       onChange={updateInput} className="form-control" id="descriptionPromotionForm" />
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("valueInPoints")}</label>
                            </div>
                            <div className="col-md-9">
                                <input autoComplete='off' type='text' value={state.pointsValuePromotionForm}
                                       name={"pointsValuePromotionForm"}
                                       onChange={updateInput} className="form-control" id="pointsValuePromotionForm" />
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("promotionExternalId")}</label>
                            </div>
                            <div className="col-md-9">
                                <input autoComplete='off' type='text' value={state.externalIdPromotionForm}
                                       name={"externalIdPromotionForm"}
                                       onChange={updateInput} className="form-control" id="externalIdPromotionForm" />
                            </div>
                        </div>
                        <div className="row">
                            <p className="text-primary">
                                {t("editPromotionNote")}
                            </p>
                        </div>
                    </div>
                    <div className="card-footer text-center border-0 pt-0">
                        <div className="row">
                            <div className="text-center">
                                <a href={window.location.href} className="btn btn-white me-2" onClick={() => setState({ ...state,selectedPromotion: null })}>{t('cancel')}</a>
                                <a href={window.location.href} className="btn btn-primary" onClick={() => updatePromotion()}>{t('confirm')}</a>
                            </div>
                        </div>
                    </div>
                </Modal>


                <div className="page-header">

                </div>

                <div className="card">
                    <div className="card-body">


                    <div className="row">
                        <div className="col-md-10"></div>
                        <div className="col-md-2">
                            <button type="button"
                                    onClick={createPromotion}
                                    className="btn btn-primary mb-3 btn-block" >{t('create')}

                            </button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="card-header">
                                        <h5 className="card-title">{t("pontsCashValue")}</h5>
                                        <div style={{position:"absolute",right:"20px"}}>
                                            <button type="button" className="btn btn-icon  btn-primary"><i
                                                className="fe fe-edit-2"></i></button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <label className="col-form-label">{t("every")}</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input autoComplete='off' type='text' value={state.generalPointValue} disabled={true} className="form-control" id="inputName" />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label">{t("pontsCashValueDesc")}</label>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        state.promotions.map(promo => {
                            return (
                                <div className="col-md-12  col-xl-6">
                                    <div className="card">
                                        <div className="row g-0">
                                            <div className="col-md-4">
                                                <img src={promo.image} className="card-img-left h-100" alt="img"></img>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-header">
                                                    <h5 className="card-title">{promo.title}</h5>
                                                    <div style={{position:"absolute",right:"20px"}}>
                                                        <button type="button" className="btn btn-icon  btn-primary"><i
                                                            className="fe fe-edit-2" onClick={()=>{showPromotionToEdit(promo)}}></i></button>
                                                    </div>
                                                </div>
                                                <div className="card-body">

                                                    <p className="card-text">{promo.description}</p>
                                                    <p className="card-text"><small className="text-muted">{t("valueInPoints")}</small><label className="text-primary">{promo.pointsValue}</label></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }


                </div>
            </>
        </Page>
    )
}
export default LoyaltyPromotions;
