import {IShortcut, IShortcutCapture, IShortcutCaptureResult, IShortcutResult} from "../../models/IShortcut";
import  {Date,String} from "sugar"
import  "./custom-es-date.js"
import {WebCache} from "../WebCache";


const shortcuts=[{
    tags:[],
    prefixDescription:"Transacciones",
    urlPrefix:"/#/transactions",
    captures:[
        {type:"DATE"},
        {type:"ISSUER"},
        {type:"TRANSACTION_STATUS"},
        {type:"TERMINAL_NUMBER"},
        {type:"TRANSACTION_TYPE"}
    ]
},
{
    tags:[],
    prefixDescription:"Terminales",
    urlPrefix:"/#/terminals",
    captures:[       
        {type:"TERMINAL_NUMBER"},
        {type:"DEPARTMENT"},
        {type:"LOCATION"},
        {type:"BRANCH"}   
    ]
}];

export class ShortcutsService {

    private static matchShortcut(text: string, shortcut: IShortcut) {
        if(shortcut.tags.length==0)
            return true;
        for(let i in shortcut.tags){
            if(ShortcutsService.matchString(text,shortcut.tags[i])) {
                return true;
            }
        }
        return false
    }

    static matchString(text1:string,text2:string):boolean{
        return text1.toLowerCase().replaceAll("á","a")
            .replaceAll("é","e")
            .replaceAll("í","i")
            .replaceAll("ó","o")
            .replaceAll("ú","u").indexOf(text2.toLowerCase().replaceAll("á","a")
            .replaceAll("é","e")
            .replaceAll("í","i")
            .replaceAll("ó","o")
            .replaceAll("ú","u"))>=0;
    }

    static processShortcutText(text:string):IShortcutResult[] {
        let res : IShortcutResult[]=[];
        if(text==null||text=="")
            return res;
        shortcuts.forEach(function(shortcut){
            res=res.concat(ShortcutsService.processShortCutsWords(text,shortcut));
        })

        return res;
    }

    private static processTerminalNumberCapture(capturesResult:IShortcutCaptureResult[],text:string,capture:IShortcutCapture) {
        let res:IShortcutCaptureResult={data:[],type:capture.type,description:"Terminal="};
        //console.log(text.replaceAll(" ",""))
        var match=text.replaceAll(" ","").match('(\\d\\d\\d\\d\\d\\d\\d\\d)+');
        if(match!=null){
            for(var i=0;i<match.length;i++){
                if(res.description.indexOf(match[i])<0){
                    res.description+=match[i]+",";
                    res.data.push(match[i]);
                }
            }
        }
        if(res.data.length>0){
            //TODO faltan filtros por multiples terminales
            res.data=res.data[0];
            capturesResult.push(res);
        }
    }


    private static processTransactionTypeCapture(capturesResult:IShortcutCaptureResult[],text:string,capture:IShortcutCapture) {
        if(ShortcutsService.matchString(text,"COMPRA"))
            capturesResult.push({data:"COMPRA",type:capture.type,description:"Tipo=Compra"});
        else if(ShortcutsService.matchString(text,"CIERRE"))
            capturesResult.push({data:"CIERRE",type:capture.type,description:"Tipo=Cierre"});
        else if(ShortcutsService.matchString(text,"ANULAC"))
            capturesResult.push({data:"ANULACION",type:capture.type,description:"Tipo=Anulación"});
        else if(ShortcutsService.matchString(text,"DEVOLUC"))
            capturesResult.push({data:"DEVOLUCION",type:capture.type,description:"Tipo=Devolución"});

    }

    private static processTransactionStatusCapture(capturesResult:IShortcutCaptureResult[],text:string,capture:IShortcutCapture) {
        if(ShortcutsService.matchString(text,"REVERSADA"))
            capturesResult.push({data:"REVERSADAS",type:capture.type,description:"Estado=Reversada"});
       /* else if(text.indexOf("AUTORIZADA")>=0)
            capturesResult.push({data:"AUTORIZADAS",type:capture.type,description:"Estado=Autorizada"});
        else if(text.indexOf("RECHAZADAS")>=0)
            capturesResult.push({data:"RECHAZADAS",type:capture.type,description:"Estado=Rechazadas"});*/

    }

    private static processIssuerCapture(capturesResult:IShortcutCaptureResult[],text:string,capture:IShortcutCapture) {
        let res:IShortcutCaptureResult={data: null, type:capture.type, description:""};
        WebCache.getAllIssuers().forEach(function(issuer){
                if(ShortcutsService.matchString(text,issuer.issuerName)){
                    res.data=issuer;
                    res.description="Tarjeta="+issuer.issuerName;
                }
        });
        //console.log(res)
        if(res.data!=null&&res.data.issuerName.toUpperCase()==res.data.acquirerName.toUpperCase())
            res={data:null,type:capture.type,description:"Adquirente="};

        if(res.data==null) {
            WebCache.getAllIssuers().forEach(function (issuer) {
                if (ShortcutsService.matchString(text,issuer.acquirerName)) {
                    res.type="ACQUIRER"
                    res.data=issuer;
                    res.description="Adquirente="+issuer.acquirerName;
                }
            });
        }
        if(res.data!=null)
            capturesResult.push(res);
    }

    private static processDateCapture(capturesResult:IShortcutCaptureResult[],text:string,capture:IShortcutCapture) {
        var words=text.split(" ");
        let lastDateStr="";
        for(var i in words){
            let lastDate=Date.create((lastDateStr+" "+words[i]).trim(),{locale:'es'})
            if((lastDate+"")!="Invalid Date"){
                lastDateStr=(lastDateStr+" "+words[i]).trim();
            }
        }
        if(lastDateStr.length>0){
            let date=Date.create(lastDateStr,{locale:'es'});
            if(date.getFullYear()>(Date.create().getFullYear()-2)&&date.getFullYear()<(Date.create().getFullYear()+2))
                capturesResult.push({data:
                        { year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()}
                    ,type:capture.type,description:"Fecha="+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()})
        }
    }

    private static processShortCutsWords(text:string,shortcut:IShortcut):IShortcutResult[] {
        text=text.toUpperCase();
        let capturesResult:IShortcutCaptureResult[] = [];
        if(ShortcutsService.matchShortcut(text,shortcut)){

            shortcut.captures.forEach(function(capture){
                switch (capture.type){
                    case 'DATE':
                        ShortcutsService.processDateCapture(capturesResult,text,capture)
                        break
                    case 'ISSUER':
                        ShortcutsService.processIssuerCapture(capturesResult,text,capture)
                        break
                    case 'TRANSACTION_STATUS':
                        ShortcutsService.processTransactionStatusCapture(capturesResult,text,capture)
                        break
                    case 'TRANSACTION_TYPE':
                        ShortcutsService.processTransactionTypeCapture(capturesResult,text,capture)
                        break
                    case 'TERMINAL_NUMBER':
                        ShortcutsService.processTerminalNumberCapture(capturesResult,text,capture)
                        break   
                    case 'DEPARTMENT':
                        ShortcutsService.processDepartmentCapture(capturesResult, text, capture);
                        break
                    case 'LOCATION':
                        ShortcutsService.processLocationCapture(capturesResult, text, capture);
                        break
                        case 'BRANCH':
                        ShortcutsService.processBranchCapture(capturesResult, text, capture);
                        break;
                }
            })

            let result:IShortcutResult={captions:[],description:shortcut.prefixDescription+" ",url:shortcut.urlPrefix}
            if(capturesResult.length>0){
                capturesResult.forEach(function(capt){
                    if(capt.description.length>0&&capt.description[capt.description.length-1]==',')
                        capt.description=capt.description.substring(0,capt.description.length-1)
                    result.description+=capt.description+";";
                    result.captions.push(capt);
                })
                result.url+="?shortcuts="+String.escapeURL(JSON.stringify(result.captions));
            }
            return [result];
        }
        return [];
    }

    private static processDepartmentCapture(capturesResult:IShortcutCaptureResult[], text:string, capture:IShortcutCapture) {
        let res:IShortcutCaptureResult = {data: null, type: capture.type, description: "Department="};
       
        WebCache.getAllDepartments().forEach(function(department){
                
                if(ShortcutsService.matchString(text, department.name)){
                    res.data = department;
                    res.description += department.name;
                }
        });
        if(res.data!=null)
            capturesResult.push(res);
    }

    private static processLocationCapture(capturesResult:IShortcutCaptureResult[], text:string, capture:IShortcutCapture) {
        let res:IShortcutCaptureResult = {data: null, type: capture.type, description: "Location="};
        
        WebCache.getAllLocations().forEach(function(location){
                
                if(ShortcutsService.matchString(text, location.name)){
                    res.data = location;
                    res.description += location.name;
                }
        });
        if(res.data!=null)
            capturesResult.push(res);
    }

    private static processBranchCapture(capturesResult:IShortcutCaptureResult[], text:string, capture:IShortcutCapture) {
        let res:IShortcutCaptureResult = {data: null, type: capture.type, description: "Branch="};
        
        console.log(WebCache.getAllBranches());
        console.log(text);
        
        WebCache.getAllBranches().forEach(function(branch){
                console.log(branch.name);
                if(ShortcutsService.matchString(text, branch.name)){
                    console.log('found it');
                    res.data = branch;
                    res.description += branch.name;
                }
            });
            
        console.log('can not found it');
        if(res.data!=null)
            capturesResult.push(res);
    }
}
