import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import { withTranslation } from 'react-i18next';
import { ReactComponent as NoResultsFoundSVG } from "../../../node_modules/itd-common/assets/images/svgs/no_results_found.svg";
import { ISettlement } from "../../models/ISettlementData";
import { IUserProfile } from '../../models/IUserProfile';
import { UtilService } from "../../services/UtilService";
import { WebCache } from '../../services/WebCache';
import "./Payments.scss";
import "./SettlementReport.css";


interface IProps {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    settlements: ISettlement[],
    columnsToShow: string[ ],
    processAction: (columnName: string,object:ISettlement) => void,
}

interface IState {
    currentUser : IUserProfile | null
}

interface SettlementRow {
    style: string;
    value:string;
    icon:string;
    image:string;
    columnName:string,
    object:ISettlement
}

const T_PREFIX: string = 'pages.paymentTaxGrid.';
const pageSize=13;
class PaymentTaxGrid extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentUser : WebCache.getCurrentUser()
        }
     }



    private processAction(columnName: string,settlement: ISettlement) {

    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (

                <div className="accordion-table-tax">
                        {   this.props.settlements.length > 0 ?
                                this.props.settlements.slice(this.props.pages.indexOf(this.props.activePage) * pageSize, (this.props.pages.indexOf(this.props.activePage) * pageSize) + pageSize).map((payment, Tindex) => {
                                    const rows:SettlementRow[] = [];
                                    let rowStyle = "";
                                    let currencySymbol="UYU"
                                    let i=0;
                                    for (let prop in payment) {
                                        let value = (payment as any)[prop];
                                        const val = {
                                            style: "",
                                            value: "",
                                            icon: "",
                                            image: "",
                                            columnName: this.props.columns[i],
                                            object: payment
                                        };
                                        if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean')
                                            if (value == '')
                                                val.value = "-";
                                            else {
                                                if (this.props.columns[i]?.toUpperCase() == "STATE") {
                                                    rowStyle = this.props.columns[i] + "-" + value;
                                                    val.value = t(this.props.columns[i] + "-" + value);
                                                } else if (this.props.columns[i]?.toUpperCase() == "MovementType".toUpperCase()) {
                                                    val.value = t(this.props.columns[i] + "-" + value);
                                                } else if (this.props.columns[i]?.toUpperCase() == "authorized".toUpperCase())
                                                    val.value = value.toString() == "true" ? t("approved") : t("denied");
                                                else if (this.props.columns[i]?.toUpperCase() == "authdate".toUpperCase())
                                                    val.value = UtilService.getDDMMyyFormatFromString(value.toString());
                                                else if (this.props.columns[i]?.toUpperCase() == "vtoDate".toUpperCase())
                                                    val.value = UtilService.getDDMMyyFormatFromString(value.toString());
                                                else if (this.props.columns[i]?.toUpperCase() == "OriginalPresentationDate".toUpperCase())
                                                    val.value = UtilService.getDDMMyyFormatFromString(value.toString());
                                                else if (this.props.columns[i]?.toUpperCase() == "PresentationDate".toUpperCase())
                                                    val.value = UtilService.getDDMMyyFormatFromString(value.toString());
                                                else if (this.props.columns[i]?.toUpperCase() == "AggregationPresentationDate".toUpperCase())
                                                    val.value = UtilService.getDDMMyyFormatFromString(value.toString());
                                                else if (this.props.columns[i]?.toUpperCase() == "CURRENCY") {
                                                    if (value == "840")
                                                        currencySymbol = "USD";
                                                    val.value = t(this.props.columns[i] + "-" + value);

                                                } else
                                                    val.value = value + "";
                                            }
                                        else
                                            val.value = "-";
                                        i++;
                                        if (this.props.columnsToShow.length == 0 || this.props.columnsToShow.filter(ex => ex.toUpperCase() == prop.toUpperCase()).length > 0)
                                            rows.push(val);
                                    }

                                    this.props.columnsToShow.map(coll=>{
                                        if(coll.indexOf(".")>0){
                                            let fields=coll.split(".");
                                            let val={style:"",value:"0",icon:"",image:"",columnName:coll,object:payment};
                                            // @ts-ignore
                                            if(payment[fields[0]]!=null&&payment[fields[0]][fields[1]]!=null)
                                                // @ts-ignore
                                                val.value=Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(payment[fields[0]][fields[1]]);
                                            rows.push(val);
                                        }
                                    })

                                    return (
                                        <>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <table id="datetime-table" className="table table-borderless text-center">
                                                            <tbody>
                                                                <tr key={"tr-head"} className="text-gray">
                                                                    {
                                                                        this.props.columns.length > 0 ?
                                                                            this.props.columns.map(coll => {
                                                                                return (
                                                                                    (this.props.columnsToShow.length==0||
                                                                                        this.props.columnsToShow.filter(ex=>{ 
                                                                                            return ex.toUpperCase() === coll.toUpperCase()
                                                                                        }).length > 0) ?
                                                                                            <td style={{width:"280px"}} key={`li-${coll}`}>{this.state.currentUser?.countryId === 1 ? t(T_PREFIX + coll + "ARG") : t("settlement-"+coll)}</td>
                                                                                        :
                                                                                            ''
                                                                                )
                                                                            }) 
                                                                        :
                                                                            <></>
                                                                    }
                                                                </tr>
                                                                <tr key={Tindex} className={`${rowStyle}` }>
                                                                    {rows.map((val:SettlementRow, index) => {
                                                                        return <td key={index.toString() + Tindex.toString()} className={`${val.style}`}>
                                                                            <span>{val.value}</span>
                                                                            <i className={`${val.icon}`}></i>
                                                                            <a hidden={val.image.length==0} href={window.location.href} onClick={() => this.processAction(val.columnName,payment)}>
                                                                                <img src={val.image}  height='20'></img>
                                                                            </a>
                                                                        </td>
                                                                    })}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            
                                        </>
                                    )
                                })
                            : 
                            <div className="d-flex justify-content-center mb-5">
                                <NoResultsFoundSVG></NoResultsFoundSVG>
                            </div>
                        }


                </div>

        )
    };



}
export default withTranslation() (PaymentTaxGrid);
