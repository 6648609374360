import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import {toast, ToastContainer} from "react-toastify";
import pedidosYaImage from "../../../node_modules/itd-common/assets/images/social/pedidosya.png";
import instagramImage from "../../../node_modules/itd-common/assets/images/social/instagram.png";
import facebookImage from "../../../node_modules/itd-common/assets/images/social/facebook.png";
import meliImage from "../../../node_modules/itd-common/assets/images/social/mercado-libre.png";
import qrImage from "../../../node_modules/itd-common/assets/images/qr-50.png";
import "./Loyalty.css"
import AutocompleteLocation from "./AutocompleteLocation";
import {Modal} from "react-bootstrap";
import {ClientService} from "../../services/loyalty/ClientService";
import {ILoyaltyMerchant} from "../../models/loyalty/IClient";
import { AxiosResponse} from "axios";
import FileUploader from "./FIleUploader";
import {QRCodeSVG} from "qrcode.react";
import i18n from "i18next";
import Page from '../containers/page'
interface IProps {

}

interface IState {
    nameForm:string,
    emailForm:string,
    telForm:string,
    instagramForm:string,
    facebookForm:string,
    peyaForm:string,
    meliForm:string,
    editHeader:boolean,
    showRedeemModal:boolean,
    merchant:ILoyaltyMerchant,
    showErrorMessage:boolean,
    errorMessage: string,
    showUploadModal:boolean,
    imagePathForm:string,
    fullAddressForm:string,
    positionForm:{lat:number,lng:number}
    showQR: { code:string }|undefined,
    qrInput:string

}


let initLoyaltyMerchant = false;
class Loyalty extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            nameForm: "",
            emailForm: "",
            telForm: "",
            instagramForm: "",
            facebookForm: "",
            peyaForm: "",
            meliForm: "",
            imagePathForm: "",
            editHeader: false,
            showRedeemModal: false,
            showUploadModal: false,
            merchant: {
                name: "",
                email: "",
                systemId: "",
                phoneNumber: "",
                instagramLink: "",
                peyaLink: "",
                meliLink: "",
                facebookLink: "",
                imagePath: "",
                fullAddress: "",
                totalLoyaltyBalance: 0,
                lat: 0,
                lng: 0,
            },
            showErrorMessage: false,
            errorMessage: "",
            fullAddressForm: "",
            positionForm: {lat: 0, lng: 0},
            showQR:undefined,
            qrInput:""
        }


        initLoyaltyMerchant=true;
    }

    componentDidMount() {
        if (initLoyaltyMerchant) {
            initLoyaltyMerchant = false;
            ClientService.getLoyaltyMerchant().then(this.initMerchant)
        }
    }

    initMerchant = (res:AxiosResponse<ILoyaltyMerchant>) => {
        if(res.data!=null||res.status==200){
            this.setState({...this.state,merchant:res.data,
                nameForm:res.data.name,
                emailForm:res.data.email,
                telForm:res.data.phoneNumber,
                instagramForm:res.data.instagramLink,
                facebookForm:res.data.facebookLink,
                peyaForm:res.data.peyaLink,
                meliForm:res.data.meliLink,
                imagePathForm:res.data.imagePath,
                fullAddressForm:res.data.fullAddress,
                positionForm:{lat:res.data.lat,lng:res.data.lng}
            });
        }
    }



    cancelHeaderModification = () => {
        this.setState({...this.state,editHeader:false})
    }

    onSuccessLocation = (res:{fullAddress:string, position:{lat:number,lng:number}}) => {
        this.setState({...this.state,fullAddressForm:res.fullAddress,positionForm:res.position},() => {
            let merchant=this.state.merchant;
            merchant.fullAddress=this.state.fullAddressForm;
            merchant.lat=this.state.positionForm.lat;
            merchant.lng=this.state.positionForm.lng;
            this.saveHeaderModification(merchant);
        });
    }


    onCancelLocation = () => {

    }

    saveHeaderModificationButton = () => {
        let merchant=this.state.merchant;
        merchant.name=this.state.nameForm;
        merchant.email=this.state.emailForm;
        merchant.phoneNumber=this.state.telForm;
        merchant.instagramLink=this.state.instagramForm;
        merchant.peyaLink=this.state.peyaForm;
        merchant.meliLink=this.state.meliForm;
        merchant.facebookLink=this.state.facebookForm;
        this.saveHeaderModification(merchant);
    }

    saveHeaderModification = (merchant:ILoyaltyMerchant) => {
        ClientService.updateMerchant(merchant).then(this.initMerchant);
        this.setState({...this.state,editHeader:false})
    }

    uploadData =(file: File)=>{
        ClientService.uploadFile(file).then(this.successUpload,this.errorUpload)
    }
    successUpload =(result: any)=>{

        let merchant=this.state.merchant;
        merchant.imagePath=result.data.imagePath;
        this.setState({...this.state,imagePathForm:result.data.imagePath,showUploadModal:false},() => {this.saveHeaderModification(merchant)});
        setTimeout(function(){
            window.location.reload()
        },1000);
    }
    errorUpload(event:any) {

    }

    redeemPoints =()=>{
        this.setState({...this.state,showRedeemModal:false})
        ClientService.redeemPoints(this.state.qrInput).then(this.processRedeemPoints);

    }

    processRedeemPoints =(res:any)=>{
        alert(JSON.stringify(res));
    }

    handleChange(event:any) {

    }


    processLastAuthorizationResponse =(res:any)=> {
            if (res.data == null||res.data.code == null||res.data.code.length==0) {
                toast.error(i18n.t("codeNotFound")+"");
            } else
                this.setState({...this.state, showQR: res.data, showRedeemModal: false})
    }

    validateEmail(): boolean {
        const emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(this.state.emailForm)) {
            return true
        } else {
            this.setState({ showErrorMessage: true, errorMessage: "invalidmail" })
            return false
        }
    }

    uploadImage = () => {
        this.setState({...this.state,showUploadModal:true})
    }

    private validationForm() {
        if (
            this.state.nameForm == null ||
            this.state.emailForm == null ||
            this.state.telForm == null||
            this.state.nameForm.length == 0 ||
            this.state.emailForm.length == 0 ||
            this.state.telForm.length == 0 ) {
            this.setState({ showErrorMessage: true, errorMessage: "fillRequieredFiles" })
            return false;
        }



        if (this.validateEmail()) {
            this.setState({ showErrorMessage: false, errorMessage: "" })
            return true;
        }
        return false;
    }


    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page>
            <>
            <React.Fragment>
                <Modal show={this.state.showUploadModal} size="lg">
                    <FileUploader
                        onCancel={() => this.setState({...this.state,showUploadModal:false})}
                        onSuccess={this.uploadData}
                    ></FileUploader>
                </Modal>
                <Modal show={this.state.showQR!=undefined}>
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({...this.state,showQR:undefined})} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <form className="login100-form validate-form">
                            <span className="login100-form-title">
									{t("redeem point")}
								</span>
                                <div className="text-center pt-3">
                                    <p className="text-dark mb-0">{t("presentCodeToCustomer")}</p>
                                </div>
                                <div className="wrap-input100 validate-input input-group">
                                    {/*<img src={qrImage} alt="img" ></img>*/}
                                    <QRCodeSVG value={this.state.showQR ?this.state.showQR.code:""} style={{width:"100%",height:"200px"}}/>

                                </div>
                                <div className="text-center pt-3">
                                    <p className="text-dark mb-0">{this.state.showQR ?this.state.showQR.code:""}</p>
                                </div>
                            </form>
                        </div>

                    </div>
                </Modal>
                <Modal show={this.state.showRedeemModal}>
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({...this.state,showRedeemModal:false})} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <form className="login100-form validate-form">
                                <span className="login100-form-title">
                                        {t("redeem point")}
                                    </span>
                                    <div className="text-center pt-3">
                                        <p className="text-dark mb-0">{t("readQRmessage")}</p>
                                    </div>
                                    <div className="wrap-input100 validate-input input-group">
                                    </div>
                                    <div className=" me-4 loyalty-social-icon">
                                        <img src={qrImage}></img>
                                    </div>
                                    <div style={{ width: "80% !important",display:"inline-block" }}>
                                        <input autoComplete='off' type='tel' className="form-control "
                                               onChange={(event) => this.setState({...this.state, qrInput: event.target.value })} />
                                    </div>
                                </form>


                        </div>
                        <div className="modal-footer">
                            <button  onClick={() =>this.redeemPoints()} className="btn ripple btn-primary" type="button">{t("accept")}</button>
                            <button onClick={() =>this.setState({...this.state,showRedeemModal:false})} className="btn btn-light" data-bs-dismiss="modal">{t("cancel")}</button>
                        </div>

                    </div>
                </Modal>
                    <div className="main-container container-fluid">
                        <div className="page-header">

                        </div>
                        <ToastContainer
                            autoClose={3000}
                        ></ToastContainer>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header" >
                                        <div>
                                            <h3 className="card-title">{t("redeem point")}</h3>
                                        </div>


                                    </div>
                                    <div className="card-body">
                                        <button  type="button" disabled={this.state.editHeader} className="btn btn-primary"
                                                 onClick={() =>this.setState({...this.state,showRedeemModal:true,qrInput:""})} ><i className="fe fe-award me-2"></i>{t("redeem")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header" >
                                        <div>
                                            <h3 className="card-title">{t("merchant info")}</h3>
                                        </div>
                                        <div style={{position:"absolute",right:"10px"}}>
                                            <button type="button" className="btn btn-icon  btn-primary" onClick={() =>this.setState({...this.state,editHeader:true})}><i
                                                className="fe fe-edit-2"></i></button>
                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <img src={this.state.imagePathForm} key={this.state.imagePathForm} className="merchant-image-card" alt="img"></img>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12  text-center">
                                                <button  type="button" disabled={this.state.editHeader} className="btn btn-primary"
                                                         onClick={() =>this.uploadImage()} ><i className="fe fe-upload-cloud me-2"></i>{t("uploadImage")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="col-form-label">{t("name")}:</label>
                                                    <input autoComplete='off' disabled={!this.state.editHeader} type='text' value={this.state.nameForm} onChange={(event) => this.setState({ nameForm: event.target.value }, () => { this.validationForm() })} className="form-control" id="inputName" />

                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="col-form-label">Email:</label>
                                                    <input autoComplete='off' disabled={!this.state.editHeader} value={this.state.emailForm} onChange={(event) => this.setState({ emailForm: event.target.value }, () => { this.validationForm() })} type="email" className="form-control" id="InputEmail" />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="col-form-label">{t("phone")}:</label>
                                                    <input autoComplete='off' type='tel' disabled={!this.state.editHeader} value={this.state.telForm} onChange={(event) => this.setState({ telForm: event.target.value }, () => { this.validationForm() })} className="form-control" id="telForm" />
                                                </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <div className=" me-4 loyalty-social-icon">
                                                    <img src={instagramImage}></img>
                                                </div>
                                                <div className="loyalty-social-input">
                                                    <input autoComplete='off' type='tel' disabled={!this.state.editHeader} value={this.state.instagramForm} onChange={(event) => this.setState({ instagramForm: event.target.value }, () => { this.validationForm() })} className="form-control " id="peyaForm" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className=" me-4 loyalty-social-icon">
                                                    <img src={facebookImage}></img>
                                                </div>
                                                <div className="loyalty-social-input">
                                                    <input autoComplete='off' type='tel' disabled={!this.state.editHeader} value={this.state.facebookForm} onChange={(event) => this.setState({ facebookForm: event.target.value }, () => { this.validationForm() })} className="form-control " id="peyaForm" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className=" me-4 loyalty-social-icon">
                                                    <img src={pedidosYaImage}></img>
                                                </div>
                                                <div className="loyalty-social-input">
                                                    <input autoComplete='off' type='tel' disabled={!this.state.editHeader} value={this.state.peyaForm} onChange={(event) => this.setState({ peyaForm: event.target.value }, () => { this.validationForm() })} className="form-control " id="peyaForm" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className=" me-4 loyalty-social-icon">
                                                    <img src={meliImage}></img>
                                                </div>
                                                <div className="loyalty-social-input">
                                                    <input autoComplete='off' type='tel' disabled={!this.state.editHeader} value={this.state.meliForm} onChange={(event) => this.setState({ meliForm: event.target.value }, () => { this.validationForm() })} className="form-control " id="peyaForm" />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.showErrorMessage ?
                                            <div className=' modal-body text-primary' style={{ textAlign: "left" }}><p>{t(this.state.errorMessage)}</p></div>
                                            : <></>}
                                    </div>

                                    <div className="card-footer">
                                        <button  type="button" disabled={!this.state.editHeader} className="btn btn-light"
                                                 onClick={() =>this.cancelHeaderModification()} ><i className="fe fe-save me-2"></i>{t("cancel")}
                                        </button>
                                        <button style={{marginLeft:"10px"}} type="button" disabled={!this.state.editHeader||this.state.showErrorMessage} className="btn btn-primary"
                                                onClick={() =>this.saveHeaderModificationButton()} ><i className="fe fe-save me-2"></i>{t("save")}
                                        </button>
                                    </div>
                                </div>
                                            <AutocompleteLocation
                                                onSuccess={this.onSuccessLocation}
                                                fullAddress={this.state.fullAddressForm}
                                                position={this.state.positionForm}
                                                showCancelButton = {true}
                                            ></AutocompleteLocation>

                            </div>
                        </div>
                    </div>
            </React.Fragment>
            </>
            </Page>
        )
    };
}
export default withTranslation() (Loyalty);
