import { GetITDTerminalsRequest } from "../requests/MPRequest/MPPOS/GetITDTerminalsRequest";
import { IMPQR } from "../requests/MPRequest/MPPOS/IMPQR";
import { IAssociateBranch } from "../requests/MPRequest/Register/IAssociateBranch";
import { IAssociateTerminal } from "../requests/MPRequest/Register/IAssociateTerminal";
import { IRegisterUserMPTenant } from "../requests/MPRequest/Register/IRegisterUserMPTenant";
import { axiosApiInstance } from "./HttpService";


export class MercadoPagoService {




    public static AssociateBranch(data: IAssociateBranch) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/AssociateBranch`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static getMPConfiguration() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/getMPConfiguration`;
        return axiosApiInstance.post(dataURL, {})
    }
    public static AssociateUser(data: IRegisterUserMPTenant) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/initializeMPUser`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static UnassociateBranch(data: IAssociateBranch) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/UnassociateBranch`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static GetBranchslinked() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/GetMeliBranchs`;
        return axiosApiInstance.get(dataURL)
    }

    public static GetMeliTerminals() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/GetMeliTerminals`;
        return axiosApiInstance.get(dataURL)
    }

    public static GetITDTerminals(data: GetITDTerminalsRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/GetITDTerminals`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static AssociateMPPos(data: IAssociateTerminal) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/AssociateTerminal`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static AssociateITDTerminal(data: {
        itdTerminalId: string;
        externalPosId: string;
        externalStoreId: string;
        tenantId: string
    }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/AssociateITDTerminal`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static UnassociateITDTerminal(data: {
        itdTerminalId: string;
        externalPosId: string;
        externalStoreId: string;
        tenantId: string
    }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/UnassociateITDTerminal`;
        return axiosApiInstance.post(dataURL, data)
    }

    public static UnasociateTerminal(data: IAssociateTerminal) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/UnassociateTerminal`;
        return axiosApiInstance.post(dataURL, data)
    }


    public static GetTerminalQR(data: IMPQR) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/GetTerminalQR`;
        return axiosApiInstance.post(dataURL, data)
    }


    //#region Purchase

    public static CancelPurchase(data: { TerminalId: string, STransactionId: string, BranchName: string }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/cancelPurchase`;
        return axiosApiInstance.post(dataURL, data)
    }
    public static CreatePurchase(data: { TerminalId: string, Amount: number, BranchName: string }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/sendPurchase`;
        return axiosApiInstance.post(dataURL, data)
    }
    public static PurchaseState(data: { TerminalId: string, STransactionId: string, BranchName: string }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/queryTransaction`;
        return axiosApiInstance.post(dataURL, data)
    }

    //#endregion
    static CreateVoidPurchase(data: { TerminalId: string; STransactionId: string; BranchName: string,TicketNumber:string }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/MercadoPago/SendPurchaseVoid`;
        return axiosApiInstance.post(dataURL, data)
    }
}
