import { AxiosResponse } from 'axios';
import React from 'react';
import CustomReCAPTCHA from '../CustomCaptcha';
import { DateObject } from "react-multi-date-picker"
import { TransactionService } from '../../services/TransactionService';
import { ITransaction, ITransactionWebModelRequest } from "../../requests/ITransactionWebModelRequest";
import Pagination from '../Pagination';
import { TFunction, withTranslation } from 'react-i18next';
import "./TransactionReport.css"
import {
    allAcquirersFilters,
    allOperationTypesFilters,
    allTransactionStatesFilter,
    allResponseCodes,
    allPaymentModes
} from "../../models/TransactionReportConstants"
import { Option } from "../../models/Option"
import { WebCache } from "../../services/WebCache";
import { IIssuer } from "../../models/IIssuer";
import TerminalLocation from "../TerminalLocation";
import ErrorsView from '../ErrorsView';
import { IShortcutCaptureResult } from "../../models/IShortcut";
import { UtilService } from "../../services/UtilService";
import TransactionGrid from "./TransactionGrid";
import { toast, ToastContainer } from 'react-toastify';
import {Link} from "react-router-dom";
import TransactionList from "./TransactionList";
import TextField from '../controls/fields/textField';
import NumericField from '../controls/fields/numericField';
import SelectField from '../controls/fields/selectField';
import DateField from '../controls/fields/dateField';
import Button from '../controls/buttons/button';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import Page from '../containers/page'
import FormFilter from '../containers/form-filter';
import { TableModeEnum } from '../table/CustomTable';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import { DateHelper } from '../../helpers/DateHelper';
import NavigationTabLinksForTransactions from './NavigationTabLinksForTransactions';

interface IProps {
    t: TFunction
}
interface IState {
    msg: string,
    fromDate: DateObject,
    toDate: DateObject,
    activePage: number,
    mainPage: number,
    pages: number[],
    transactions: ITransaction[],
    columns: Array<string>
    errors: string[],
    operationTypeFilter: string,
    initQuestionFilter: string,
    groupFilter: string,
    ticketFilter:string,
    batchFilter:string,
    transactionStateFilter: string,
    terminalFilter: string,
    authorizationCodeFilter: string,
    authTerminalFilter: string,
    amountFilter: string,
    amountToCustomerFilter: string,
    merchantFilter: string,
    transactionIdFilter: string
    cardNumberIdFilter:string,
    muIdFilter:string,
    paymentModeFilter:string,
    acquirerFilter: string,
    issuerFilter: string,
    currentIssuers: IIssuer[],
    departmentFilter: string
    locationFilter: string
    branchFilter: string
    departmentName: string
    locationName: string
    branchName: string
    taxPayerName: string,
    taxPayerFilter: string
    columnsToShow: string[],
    currencies: Option[],
    currencySelected: string | null,
    responseCodes: Option[];
    paymentModes: Option[];
    responseCodeSelected: string | null;
    gridFormat:"grid"|"list"
    isCaptchaVerified: boolean;
}
const pageSize = 13;
const pageQuantity = 3
const defaultPages = [1, 2, 3]
let doQuery = false;
const T_PREFIX: string = 'pages.transactions.';

class TransactionReport extends React.Component<IProps, IState> {
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    updateDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        if (UtilService.isSmallScreen() && this.state.columnsToShow.length == 0) {
            this.setState({
                ...this.state, columnsToShow: [
                    "TICKET","TERMINAL", "AUTHDATETIME", "TRANSACTIONTYPE", "STATE", "RESPONSECODE", "TOTALAMOUNTFORREPORT", "ISSUER", "ACQUIRER"
                ]
            });
        } else if (width > 992 && this.state.columnsToShow.length > 0) {
            this.setState({ ...this.state, columnsToShow: [] });
        }
    };
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        if (doQuery) {
            doQuery = false;
            this.getTransactions();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    constructor(props: IProps) {
        super(props);
        this.handleAcquirerFilterChange = this.handleAcquirerFilterChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        let shortcut = new URLSearchParams(window.location.search).get("shortcuts")
        let acquirerFilter = "";
        let issuerFilter = "";
        let transactionStateFilter = "";
        let operationTypeFilter = "";
        let terminalFilter = "";
        let [ fromDate, toDate ] = DateHelper.buildDateRange();

        if (shortcut != null && shortcut.length > 0) {
            doQuery = true;
            let shortcutDto: IShortcutCaptureResult[] = JSON.parse(shortcut);
            for (var i = 0; i < shortcutDto.length; i++) {
                switch (shortcutDto[i].type) {
                    case "ACQUIRER":
                        acquirerFilter = shortcutDto[i].data.acquirerId + "";
                        break;
                    case "ISSUER":
                        issuerFilter = shortcutDto[i].data.code + "";
                        break;
                    case "TRANSACTION_STATUS":
                        if (shortcutDto[i].data == "REVERSADA") {
                            transactionStateFilter = "10";
                        }//todo falta aprobadas, rechazadas, etc.
                        break;
                    case "TRANSACTION_TYPE":
                        if (shortcutDto[i].data == "COMPRA") {
                            operationTypeFilter = "C";
                        } else if (shortcutDto[i].data == "CIERRE") {
                            operationTypeFilter = "L";
                        } else if (shortcutDto[i].data == "ANULACION") {
                            operationTypeFilter = "A";
                        } else if (shortcutDto[i].data == "DEVOLUCION") {
                            operationTypeFilter = "D";
                        }
                        break;
                    case "TERMINAL_NUMBER":
                        terminalFilter = shortcutDto[i].data + "";
                        break;
                    case "DATE":
                        fromDate = new DateObject(shortcutDto[i].data);// [new DateObject(shortcutDto[i].data),new DateObject(shortcutDto[i].data)];
                        toDate = new DateObject(shortcutDto[i].data);
                        break;
                }
            }
        }
        this.state = {
            msg: "",
            fromDate: fromDate,
            toDate: toDate,
            activePage: 1,
            mainPage: 0,
            pages: defaultPages,
            columns: [
            ],
            transactions: [],
            errors: [],
            operationTypeFilter: operationTypeFilter,
            transactionStateFilter: transactionStateFilter,
            issuerFilter: issuerFilter,
            acquirerFilter: acquirerFilter,
            terminalFilter: terminalFilter,
            authorizationCodeFilter: "",
            authTerminalFilter: "",
            amountFilter: "",
            batchFilter:"",
            ticketFilter:"",
            amountToCustomerFilter: "",
            merchantFilter: "",
            transactionIdFilter: "",
            cardNumberIdFilter:"",
            departmentFilter: "",
            locationFilter: "",
            branchFilter: "",
            departmentName: "",
            locationName: "",
            branchName: "",
            currentIssuers: WebCache.getAllIssuers(),
            columnsToShow: [],
            initQuestionFilter:"",
            groupFilter:"",
            currencies:  UtilService.getCurrencyOptions(),
            currencySelected: "",
            responseCodes: this.buildResponseCodesSelectOptions(),
            paymentModes:this.buildPaymentModesSelectOptions(),
            responseCodeSelected: "",
            taxPayerName: "",
            taxPayerFilter: "",
            gridFormat:"list",
            isCaptchaVerified: false,
            muIdFilter:"",
            paymentModeFilter:""
        }
    }
    updatefromDate = (e: DateObject[] | any) => {
        this.setState({ ...this.state, fromDate: e });
    }
    updateToDate = (e: DateObject[] | any) => {
        this.setState({ ...this.state, toDate: e });
    }
    
    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };
    getTransactionRequest = (all: boolean) => {
        let from_year: number;
        let from_month: number;
        let from_day: number;
        let to_year: number;
        let to_month: number;
        let to_day: number;
        from_year = this.state.fromDate.year;
        from_month = this.state.fromDate.month.number;
        from_day = this.state.fromDate.day;
        to_year = this.state.toDate.year;
        to_month = this.state.toDate.month.number;
        to_day = this.state.toDate.day;
        const getTransactionStateFromFilter: () => Array<number> = (): Array<number> => {
                const entryFound = Object.entries(allTransactionStatesFilter).find(
                (entry) => {
                    return entry[0] === this.state.transactionStateFilter;
                });
            return entryFound ? entryFound[1] : [];
        };
        const tranWebModel: ITransactionWebModelRequest = {
            From: new Date(from_year, from_month - 1,
                from_day,
                this.state.fromDate.hour - 3,
                this.state.fromDate.minute).toJSON(),
            To: new Date(to_year,
                to_month - 1,
                to_day,
                this.state.toDate.hour - 3,
                this.state.toDate.minute).toJSON(),
            Terminals: (this.state.terminalFilter == null || this.state.terminalFilter == "") ? [] : [WebCache.getCurrentUser()?.countryId==1?"B24_"+this.state.terminalFilter:this.state.terminalFilter], // todo falta filtrar las terminales del usuario
            TransactionState: (this.state.transactionStateFilter == null || this.state.transactionStateFilter == "0") ? [] : getTransactionStateFromFilter(),
            TerminalGroup: (this.state.groupFilter == null || this.state.groupFilter == "") ? "" : this.state.groupFilter,
            InitQuestion: (this.state.initQuestionFilter == null || this.state.initQuestionFilter == "") ? "" : this.state.initQuestionFilter,
            OperationType: (this.state.operationTypeFilter == null || this.state.operationTypeFilter == "" || this.state.operationTypeFilter.toLowerCase() == "all") ? "" : this.state.operationTypeFilter,
            AuthTerminal: (this.state.authTerminalFilter == null || this.state.authTerminalFilter == "") ? "" : this.state.authTerminalFilter,
            Amount: (this.state.amountFilter == null || this.state.amountFilter == "") ? "" : this.state.amountFilter,
            AmountToCustomer: (this.state.amountToCustomerFilter == null || this.state.amountToCustomerFilter == "") ? "" : this.state.amountToCustomerFilter,
            MerchantCode: (this.state.merchantFilter == null || this.state.merchantFilter == "") ? "" : this.state.merchantFilter,
            AuthorizationCode: (this.state.authorizationCodeFilter == null || this.state.authorizationCodeFilter == "") ? "" : this.state.authorizationCodeFilter,
            Issuer: (this.state.issuerFilter == null || this.state.issuerFilter == "0000") ? "" : this.state.issuerFilter,
            Department: (this.state.departmentFilter == null || this.state.departmentFilter == "0000") ? "" : this.state.departmentFilter,
            Location: (this.state.locationFilter == null || this.state.locationFilter == "0000") ? "" : this.state.locationFilter,
            PayerTaxId: (this.state.taxPayerFilter == null || this.state.taxPayerFilter == "0000") ? "" : this.state.taxPayerFilter,
            Branch: (this.state.branchFilter == null || this.state.branchFilter == "0000") ? "" : this.state.branchFilter,
            Acquirer: (this.state.acquirerFilter == null || this.state.acquirerFilter == "0000") ? "" : this.state.acquirerFilter,
            TransactionId: (this.state.transactionIdFilter == null || this.state.transactionIdFilter == "") ? "" : this.state.transactionIdFilter,
            CardNumber: (this.state.cardNumberIdFilter == null || this.state.cardNumberIdFilter == "") ? "" : this.state.cardNumberIdFilter,
            MuId: (this.state.muIdFilter == null || this.state.muIdFilter == "") ? "" : this.state.muIdFilter,
            TicketNumber: (this.state.ticketFilter == null || this.state.ticketFilter == "") ? "" : this.state.ticketFilter,
            BatchNumber: (this.state.batchFilter == null || this.state.batchFilter == "") ? "" : this.state.batchFilter,
            PaymentMode: (this.state.paymentModeFilter == null || this.state.paymentModeFilter == "") ? "" : this.state.paymentModeFilter,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
            Currency: this.state.currencySelected,
            ResponseCode: this.state.responseCodeSelected
        };
        return tranWebModel;
    }
    getTransactions = () => {
        TransactionService.getAll(this.getTransactionRequest(false)).then((response: AxiosResponse<ITransaction[]>) => {
            if (response == null || response.data == null || response.data.length == 0) {
                this.setState({
                    transactions: [],
                    errors: []
                })
                return;
            }
            let newColumns = [];
            newColumns.push("ticket")
            for (let prop in response.data[0]) {
                newColumns.push(prop)
            }
            this.setState({
                columns: newColumns,
                transactions: response.data,
                errors: []
            });
        }).catch(error => {
            this.setState({
                errors: error.response.data
            });
            this.setState({
                transactions: []
            })
            console.log('error:', error);
        }
        );
    }
    getFromBuscar = () => {
        this.setState({
            ...this.state, activePage: 1, pages: defaultPages,
            mainPage: 0
        }, this.getTransactions);
    }
        clearFilters = () => {
        let [ fromDate, toDate ] = DateHelper.buildDateRange();
        this.setState({
            ...this.state,
            fromDate: fromDate, 
            toDate: toDate, 
            operationTypeFilter: "",
            transactionStateFilter: "", 
            acquirerFilter: "", 
            issuerFilter: "",
            terminalFilter: "", 
            merchantFilter: "",
            authTerminalFilter: "", 
            amountToCustomerFilter: "", 
            amountFilter: "", 
            authorizationCodeFilter: "",
            transactionIdFilter: "", 
            initQuestionFilter: "", 
            groupFilter: "",
            currencySelected: "", 
            cardNumberIdFilter: "",
            responseCodeSelected: "", 
            muIdFilter: "", 
            paymentModeFilter: "",
            batchFilter:"",
            ticketFilter:"",
            taxPayerFilter: " ",
            branchFilter: " ", 
            departmentFilter: " ", 
            locationFilter: " ",
            departmentName: "", 
            locationName: "", 
            branchName: "", 
            taxPayerName: ""
        })
    }
    getTransactionsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            TransactionService.getAllToExcel(this.getTransactionRequest(true))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({ errors: error.response.data });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };

    handleAcquirerFilterChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let acquirerId = event.target.value;
        this.setState({ ...this.state, acquirerFilter: acquirerId, currentIssuers: WebCache.getAllIssuers(), issuerFilter: "0000" });
    }
    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        alert('Your favorite flavor is: ' + this.state);
        event.preventDefault();
    }
    buildIssuerSelectOptions() {
        let options = this.optionMapper.buildSelectOptionsFromAnArrayWithDifferentInterface(this.state.currentIssuers, 'id', 'issuerName');
        const todosIndex = options.findIndex(option => option.value === '0000');

        if (todosIndex !== -1) {
            const todosOption = options.splice(todosIndex, 1)[0]; 
            options = [todosOption, ...options]; 
        }
    
        options.sort((a, b) => {
            if (a.label === 'Todos') return -1; 
            if (b.label === 'Todos') return 1; 
            return a.label.localeCompare(b.label);
        });
    
        return options;
    } 
    buildCurrencySelectOptions(): Array<Option> {
        return [
            { value: " " ,   label: "Todo"},
            { value: "858" , label: "  $ Pesos"},
            { value: "840" , label: "US$ Dolares"}
        ]
    }
    buildPaymentModesSelectOptions(): Array<Option> {
        let respondCodes: Array<Option> = [
            { value: "", label: this.props.t("all"), key: "all" } as Option
        ];
        respondCodes.push(...this.optionMapper.buildSelectOptionsFromEnum(allPaymentModes, 'channel-'));
        return respondCodes;
    }
    buildResponseCodesSelectOptions(): Array<Option> {
        let respondCodes: Array<Option> = [
            { value: "", label: this.props.t("all"), key: "all" } as Option
        ];
        respondCodes.push(...this.optionMapper.buildSelectOptionsFromEnum(allResponseCodes, 'responseCode-'));
        
        return respondCodes;
    }
    
    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let muIdFilter;

        if (currentUser !== undefined && currentUser === 2) {
            muIdFilter = '';
        } else {
            muIdFilter = (<div className="col-md-3"><TextField attr='muIdFilter' label='muId' ></TextField></div>);
        }

        return (
            <Page>
                <>
                    <h2>{t(`sales`)}</h2>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <NavigationTabLinksForTransactions></NavigationTabLinksForTransactions>
                                </div>
                                <div className="card-body">
                                    <FormFilter 
                                        onSubmit={this.getFromBuscar.bind(this)} 
                                        model={[ this.state, this.setState.bind(this) ]}
                                        clear={()=> this.clearFilters()}
                                        header={
                                            <div className="d-flex align-items-center justify-content-end" >
                                                <RadioButtonGroup 
                                                    model={[ this.state, this.setState.bind(this) ]} 
                                                    attr="gridFormat" 
                                                    fieldDefinitions={[
                                                        { key: TableModeEnum.LIST, value: TableModeEnum.LIST, icon: 'fe fe-list' },
                                                        { key: TableModeEnum.GRID, value: TableModeEnum.GRID, icon: 'fe fe-grid' }
                                                    ]}
                                                ></RadioButtonGroup>
                                            </div>
                                        }
                                    >
                                        <>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <DateField attr='fromDate' label='authDateTimeFrom' required={true}></DateField>
                                                </div>
                                                <div className="col-md-3">
                                                    <DateField attr='toDate' label='authDateTimeTo'required={true}></DateField>
                                                </div>
                                                <div className="col-md-3">
                                                    <SelectField attr='operationTypeFilter' label='transactionType'
                                                                options={this.optionMapper.buildSelectOptionsFromEnum(allOperationTypesFilters, 'transactionType-')}></SelectField>
                                                </div>
                                                <div className="col-md-3">
                                                    <SelectField attr='transactionStateFilter' label='state'
                                                                options={this.optionMapper.buildSelectOptionsFromEnum(Object.keys(allTransactionStatesFilter), 'state-')}></SelectField>
                                                </div>
                                                {WebCache.getCurrentUser()?.countryId == 2 ?
                                                    <div className="col-md-3">
                                                        <SelectField attr='acquirerFilter' label='acquirer'
                                                                    options={this.optionMapper.buildSelectOptionsFromEnum(allAcquirersFilters, 'acquirer-')}
                                                                    onChange={(event) => this.handleAcquirerFilterChange(event)}></SelectField>
                                                    </div>:<></>
                                                }
                                                <div className="col-md-3">
                                                    <SelectField attr='issuerFilter' label='issuer'
                                                                options={this.buildIssuerSelectOptions()}></SelectField>
                                                </div>
                                                <div className="col-md-3">
                                                <TextField attr='terminalFilter' label='terminal'></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='merchantFilter' label='merchant'></TextField>
                                                </div>
                                                {WebCache.getCurrentUser()?.countryId == 2?
                                                    <>
                                                <div className="col-md-3">
                                                    <TextField attr='authTerminalFilter'
                                                            label='authTerminal'></TextField>
                                                </div></>:<></>
                                                }
                                                {WebCache.getCurrentUser()?.countryId == 2 ?
                                                    <div className="col-md-3">
                                                        <NumericField attr='amountToCustomerFilter'
                                                                    label='amountToCustomer'></NumericField>
                                                    </div> : <></>
                                                }
                                                <div className="col-md-3">
                                                    <NumericField attr='amountFilter' label='amount'></NumericField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='authorizationCodeFilter'
                                                            label='authorizationCode'></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='transactionIdFilter' label='id'></TextField>
                                                </div>
                                                {WebCache.getCurrentUser()?.countryId == 2?
                                                    <>
                                                        <div className="col-md-3">
                                                            <TextField attr='initQuestionFilter'
                                                                    label='initQuestion'></TextField>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField attr='groupFilter' label='terminalGroup'></TextField>
                                                        </div>
                                                    </>:<></>
                                                }
                                                <TerminalLocation
                                                    changeFilter={(taxPayerFilter, branchFilter, departmentFilter, locationFilter, departmentName, locationName, branchName, clientDocument) =>
                                                        this.setState({
                                                            ...this.state,
                                                            taxPayerFilter: taxPayerFilter,
                                                            branchFilter: branchFilter,
                                                            departmentFilter: departmentFilter,
                                                            locationFilter: locationFilter,
                                                            departmentName: departmentName,
                                                            locationName: locationName,
                                                            branchName: branchName,
                                                            taxPayerName: clientDocument
                                                        })} filter={{
                                                            taxPayerFilter: this.state.taxPayerFilter,
                                                            branchFilter: this.state.branchFilter,
                                                            departmentFilter: this.state.departmentFilter,
                                                            locationFilter: this.state.locationFilter
                                                        }}
                                                />
                                                {WebCache.getCurrentUser()?.countryId == 2?
                                                <>
                                                <div className="col-md-3">
                                                    <SelectField attr='currencySelected' label='currency'
                                                                options={this.state.currencies}
                                                                disabled={this.state.currencies.length == 1}></SelectField>
                                                </div>
                                                </>:<></>
                                                }
                                                <div className="col-md-3">
                                                    <TextField attr='cardNumberIdFilter' label='cardNumber'></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <SelectField attr="responseCodeSelected" label="responseCode"
                                                                options={this.state.responseCodes}></SelectField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='ticketFilter' label='ticket'></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='batchFilter' label='batch'></TextField>
                                                </div>
                                                {WebCache.getCurrentUser()?.countryId != 2?
                                                    <><div className="col-md-3">
                                                        <SelectField attr="paymentModeFilter" label="paymentMode" options={this.state.paymentModes}></SelectField>
                                                    </div> {muIdFilter}</>:<></>
                                                }
                                            </div>
                                        </>
                                    </FormFilter>
                                    {this.state.gridFormat=="list"?<TransactionList
                                        transactions={this.state.transactions}
                                        columns={this.state.columns}
                                        columnsToShow={["TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"]}
                                        mainPage={this.state.mainPage}
                                        pages={this.state.pages}
                                        activePage={this.state.activePage}
                                        customButtonAction={undefined}
                                        showCupon={true}
                                    ></TransactionList>:<TransactionGrid
                                        transactions={this.state.transactions}
                                        columns={this.state.columns}
                                        columnsToShow={this.state.columnsToShow}
                                        mainPage={this.state.mainPage}
                                        pages={this.state.pages}
                                        activePage={this.state.activePage}
                                        customButtonAction={undefined}
                                    ></TransactionGrid>}
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Pagination
                                                activePageProp={this.state.activePage}
                                                pageSize={pageSize}
                                                pageQuantity={pageQuantity}
                                                modifyMainPage={(value: number, newPages: Array<number>) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value, pages: newPages
                                                    }, () => this.getTransactions())
                                                }}
                                                changePage={(page: number) => {
                                                    this.setState({ activePage: page }, () => { });
                                                }}
                                                dataL={this.state.transactions.length}
                                                mainPage={this.state.mainPage}
                                            ></Pagination>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'></div>
                                        <div className="col-md-3">
                                            <CustomReCAPTCHA onVerify={this.handleCaptchaVerification} /> 
                                        </div>
                                        <div className="col-md-3">
                                            <Button disabled={this.state.transactions.length === 0} label='excel' onClick={() => { toast.info("El excel se descargará cuando esté listo"); this.getTransactionsToExcel() }}></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Page>
        )
    };
}
export default withTranslation()(TransactionReport);
