import { Component } from 'react';
import NavigationTabLinks from '../containers/navigationTabLinks';
import {WebCache} from "../../services/WebCache";

interface IProps { }

class NavigationTabLinksForReports extends Component {

    items:any;

 constructor(props: IProps) {
        super(props);


        if(WebCache.getCurrentUser()?.countryId==2) {
            this.items=[
                { to: '/reports/settlements', icon: 'fe fe-credit-card', label: 'settlements' },
                { to: '/reports/presentations', icon: 'fe fe-calendar', label: 'presentations' },
                { to: '/reports/operationTable', icon: 'fe fe-grid', label: 'operationTable' },
                { to: '/reports/presentationDownloads', icon: 'fe fe-download', label: 'presentationDownloads' }
            ]
        }else{
            this.items=[
                { to: '/reports/settlements', icon: 'fe fe-credit-card', label: 'settlements' },
                { to: '/reports/presentations', icon: 'fe fe-calendar', label: 'presentations' },
                { to: '/reports/presentationDownloads', icon: 'fe fe-download', label: 'presentationDownloads' }
            ]
        }
    }




    render() {
        return (
            <NavigationTabLinks
                items={this.items}
            ></NavigationTabLinks>
        )
    };
}

export default NavigationTabLinksForReports;
