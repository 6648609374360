export class ObjectHelper {

    static cleanObject<T>(object: T): T {
        const cleanObject: Partial<T> = {};
        for (const key in object) {
            if (!object[key]) {
                Object.assign(cleanObject, { [key]: object[key] });
                continue;
            }
            if (typeof object[key] === 'string') {
                Object.assign(cleanObject, { [key]: '' });
            }
            if (Array.isArray(object[key])) {
                Object.assign(cleanObject, { [key]: [] });
            } 
        }
        return cleanObject as T;
    }

    static getPropertyValue(objectParam: any, property: string) {
        return property
            .split('.') // split string based on `.`
            .reduce(
                (object: any, key: string) => object && (object)[key],
                objectParam
            );
    }
}