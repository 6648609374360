import { AxiosResponse } from 'axios';
import React from 'react';
import Pagination from '../Pagination';
import { withTranslation } from 'react-i18next';
import TerminalLocation from "../TerminalLocation";
import { ITerminalWebModelRequest } from "../../requests/ITerminalWebModelRequest";
import { TerminalsService } from "../../services/TerminalsService";
import { WebCache } from '../../services/WebCache';
import { IShortcutCaptureResult } from "../../models/IShortcut";
import i18next from "i18next";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { toast, ToastContainer } from "react-toastify";
import { MercadoPagoService } from '../../services/MercadoPagoService';
import { IMeliTerminal } from '../../models/IMeliTerminal';
import { promises as fs } from "fs";
import Swal from 'sweetalert2';
import { IAssociateTerminal as IAssociateMPPos } from '../../requests/MPRequest/Register/IAssociateTerminal';
import { IGetTerminalsAndLocationsRequest } from '../../models/IGetTerminalsAndLocationsRequest';
import { IBarnchTableData } from '../../models/IBarnchTableData';
import { IMPQR } from '../../requests/MPRequest/MPPOS/IMPQR';
import { GetITDTerminalsRequest } from '../../requests/MPRequest/MPPOS/GetITDTerminalsRequest';
import ReactDOMServer from 'react-dom/server'
import { Navigate, NavLink, Route } from 'react-router-dom';


const pageSize = 13;
const pageQuantity = 3

interface IProps {
}
interface IState {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    errors: string[],
    terminalFilter: string,
    departmentFilter: string
    locationFilter: string
    branchFilter: string


    terminals: IMeliTerminal[],

    terminalsITDToEdit: { terminalNum: string, linked: boolean, branchId: string, externalPosId: string, id: string, branchName: string }[],
    externalPosIdToEdit: string
    allBranchs: IBarnchTableData[],


    departmentName: string
    locationName: string
    branchName: string

    taxPayerName: string,
    taxPayerFilter: string,

    showModalCreateMPPos: boolean,
    showModalTerminasABM: boolean,

    branchCreateMPPos: string,
    branchCreateMPPosName: string,
}

interface TerminalRow {
    name: string;
    value: string | boolean;
    icon: string;
    aux: {
        externalStoreId: string;
        externalId: string;
        name: string;
        branchLinked: boolean
    }
}
let sss = 0
let doQuery = false;





class MercadoPagoPageTerminalsABM extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        let terminalFilter = "";
        let departmentFilter = "";
        let locationFilter = "";
        let branchFilter = "";

        this.state = {
            activePage: 1,
            mainPage: 0,
            pages: [1, 2, 3, 4, 5],
            columns: [
            ],
            terminals: [],
            errors: [],
            terminalFilter: terminalFilter,
            departmentFilter: departmentFilter,
            locationFilter: locationFilter,
            branchFilter: branchFilter,
            departmentName: "",
            locationName: "",
            branchName: "",
            taxPayerName: "",
            taxPayerFilter: "",
            showModalCreateMPPos: false,
            showModalTerminasABM: false,
            allBranchs: [],
            branchCreateMPPos: "",
            branchCreateMPPosName: "",
            terminalsITDToEdit: [],
            externalPosIdToEdit: ""
        }


    }

    //#region Utils

    componentDidMount() {
        if (doQuery) {
            doQuery = false;
            this.getMeliTerminals();
        }
    }
    handleChange(event: any) {
        if (event.target.name == "terminalFilter")
            this.setState({ ...this.state, terminalFilter: event.target.value });

    }
    //#region QR
    downloadImage = async (url: string, path: string) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const buffer = Buffer.from(arrayBuffer);
        await fs.writeFile(path, buffer);
    }

    base64ToArrayBuffer = (base64: any) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(reportName: string, byte: any) {
        var blob = new Blob([byte], { type: "image/png" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    getQRCode = async (dto: IMPQR) => {
        await MercadoPagoService.GetTerminalQR(dto).then((response: AxiosResponse) => {
            var sampleArr = this.base64ToArrayBuffer(response.data.fileContents);
            this.saveByteArray("Sample Report", sampleArr);
        })
    }
    //#endregion

    //#region Tooltips

    InfoPopover = () => (
        <Popover style={{ margin: 0 }} id="popover-basic">
            <Popover.Header as="h3">Info</Popover.Header>
            <Popover.Body>
                <ul>
                    <p>
                        Esta Sucursal no está vinculada a MercadoPago. <br />
                        Primero debe vincular la sucursal y luego las terminales pertenencientes a la misma.
                    </p>
                </ul>
            </Popover.Body>
        </Popover >
    );
    InfoTooltip = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={this.InfoPopover()}>
            <button
                className="buttonP btn"
                style={{ borderWidth: 0 }}
            >
                <i style={{ color: "orange", fontSize: "20px" }} className="fe fe-alert-circle" > </i>
            </button>
        </OverlayTrigger>
    );



    DownloadPopover = () => (
        <Popover style={{ margin: 0 }} id="popover-basic">
            <Popover.Header as="h3">Info</Popover.Header>
            <Popover.Body>
                <ul>
                    <p>
                        Click para descargar codigo QR. <br />
                        Este codigo le servirá para poder hacer pagos con la terminal.
                    </p>
                </ul>
            </Popover.Body>
        </Popover >
    );
    DownloadTooltip = (MPQR: IMPQR) => (
        <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={this.DownloadPopover()}>
            <button
                onClick={() => { this.getQRCode(MPQR) }}
                className="buttonP btn"
                style={{ borderWidth: 0 }}
            >
                <i style={{ fontSize: "20px" }} className="ion  ion-ios7-cloud-download-outline large"></i>
            </button>
        </OverlayTrigger>
    );

    //#endregion

    //#region Modals

    ModalHtmlCreateMPPos = () => {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Modal show={this.state.showModalCreateMPPos}>
                <div className="modal-content modal-content-demo">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title text-white"> {t("CreateMPPOS")} </h5>
                        <button aria-label="Close" onClick={() => this.setState({ ...this.state, showModalCreateMPPos: false })} className="btn-close" ><span className='text-white' aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form autoComplete='off' style={{ textAlign: "left" }}>
                            <div className="col-md-12 mb-3">
                                <label className="col-form-label ">{t("NMercadoPago:")} <br />
                                    <sub>{t("CreateMPPosDescription")}</sub>
                                </label>
                                <br />
                                <br />

                                <select name="branchFilter"
                                    className="form-control form-select select2"
                                    onChange={(event) => this.setState({ ...this.state, branchCreateMPPos: event.target.value.split("#")[0], branchCreateMPPosName: event.target.value.split("#")[1] })}
                                >
                                    <option
                                        disabled
                                        key={"void"}
                                        value={""}
                                        selected
                                    >
                                        {"Seleccione sucursal"}
                                    </option>
                                    {
                                        this.state.allBranchs.map(branch => {
                                            return (
                                                branch.linked &&
                                                <option
                                                    key={branch.id}
                                                    value={branch.id + "#" + branch.nombre}
                                                >
                                                    {t(branch.nombre)}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary"
                            disabled={this.state.branchCreateMPPos.length <= 1}
                            onClick={() => {
                                this.associateMPPos({
                                    externalStoreId: this.state.branchCreateMPPos,
                                    externalId: "",
                                    name: this.state.branchCreateMPPosName
                                })
                                this.setState({ ...this.state, showModalCreateMPPos: false })
                            }}
                        >{t("confirm")}</button>
                        <button className="btn btn-light" onClick={() => this.setState({ ...this.state, showModalCreateMPPos: false })} data-bs-dismiss="modal">{("close")}</button>
                    </div>
                </div>
            </Modal>)
    }


    ModalHtmlTerminalsABM = () => {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Modal show={this.state.showModalTerminasABM}>
                <div

                    className="modal-content modal-content-demo">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title text-white"> {t("EditMPPos")} </h5>
                        <button aria-label="Close" onClick={() => this.setState({ ...this.state, showModalTerminasABM: false })} className="btn-close" ><span className='text-white' aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form autoComplete='off' style={{ textAlign: "left" }}>
                            <div className="col-md-12 mb-3">
                                <br />
                                <h5>{t("EditMPPosDescription")}</h5>
                                <br />
                                <sub style={{ fontSize: "12px" }}>
                                    {t("EditMPPosDescriptionAlert")}
                                    <br />
                                    {t("EditMPPosDescriptionAlert2")}
                                </sub>
                                <br />
                                <br />
                                <div className="table-responsive">
                                    <table className="table text-nowrap text-md-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ color: "black", textAlign: "center" }}>Terminal ITD</th>
                                                <th style={{ textAlign: "center" }}  ></th>
                                                <th style={{ textAlign: "center" }}  ></th>
                                                <th style={{ color: "black", textAlign: "center" }}>Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.terminalsITDToEdit.map((ITDTerm) => {
                                                return (
                                                    <tr>
                                                        <td style={{ textAlign: "center", color: ITDTerm.linked ? "#54B34A" : "black" }} ><b>{ITDTerm.terminalNum}</b></td>
                                                        <td style={{ textAlign: "center" }} />
                                                        <td style={{ textAlign: "center" }} >
                                                            {ITDTerm.externalPosId != this.state.externalPosIdToEdit && ITDTerm.linked && ITDTerm.externalPosId.length > 1 &&
                                                                <>
                                                                    <i style={{ color: "red", fontSize: "10px" }} className="fe fe-alert-triangle" > </i>
                                                                    <br />
                                                                    <sub style={{ fontSize: "10px" }}>
                                                                        {ITDTerm.externalPosId}
                                                                    </sub>
                                                                </>
                                                            }
                                                        </td>
                                                        <td style={{ textAlign: "center" }} >

                                                            <button type="button"
                                                                className="btn btn-primary mb-3 btn-block"
                                                                onClick={() => {
                                                                    if (ITDTerm.linked) this.UnassociateITDTerminal(ITDTerm); else this.associateITDTerminal(ITDTerm)
                                                                }}
                                                            >
                                                                {ITDTerm.linked ? "Desvincular" : "Vincular"}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={() => this.setState({ ...this.state, showModalTerminasABM: false })}>{t("close")}</button>
                    </div>
                </div >
            </Modal >)
    }

    //#endregion


    //#endregion

    //#region Calls

    //#region GetBranchs
    getBranchRequest = (all: boolean) => {
        const tranWebModel: IGetTerminalsAndLocationsRequest = {
            Department: "",
            Location: "",
            Branch: "",
            Skip: 0,
            Take: 10000
        };
        return tranWebModel;
    }
    getBranchs = () => {
        TerminalsService.getAllTerminalsAndLocationsFiltered(this.getBranchRequest(false)).then((response: AxiosResponse<IBarnchTableData[]>) => {
            MercadoPagoService.GetBranchslinked().then((response1: AxiosResponse<string[]>) => {

                response.data.map((b) => b.linked = (response1.data.find((mb) => mb == b.id) ? true : false))
                this.setState({ allBranchs: response.data });
                if (response == null || response.data == null) {
                    this.setState({
                        allBranchs: []
                    })
                    return;
                }
            }).catch(error => {
                this.setState({
                    errors: error.response.data
                });
                console.log('error:', error);
            }
            );
        });
    }
    //#endregion

    //#region Terminals

    getTerminalRequest = (all: boolean) => {
        const tranWebModel: ITerminalWebModelRequest = {
            TerminalId: (this.state.terminalFilter == null || this.state.terminalFilter == "") ? "" : this.state.terminalFilter,
            Department: (this.state.departmentFilter == null || this.state.departmentFilter == "0000") ? "" : this.state.departmentFilter,
            PayerTaxId: (this.state.taxPayerFilter == null || this.state.taxPayerFilter == "0000") ? "" : this.state.taxPayerFilter,
            Location: (this.state.locationFilter == null || this.state.locationFilter == "0000") ? "" : this.state.locationFilter,
            Branch: (this.state.branchFilter == null || this.state.branchFilter == "0000") ? "" : this.state.branchFilter,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
        };
        return tranWebModel;
    }

    getITDTerminals = (data: GetITDTerminalsRequest, callback = () => { }) => {

        MercadoPagoService.GetITDTerminals(data)
            .then((terminals: AxiosResponse<{ terminalNum: string, linked: boolean, branchId: string, externalPosId: string, id: string, branchName: string }[]>) => {
                debugger
                if (terminals == null || terminals.data == null || terminals.data.length == 0) {
                    this.setState({
                        terminalsITDToEdit: []
                    })
                    return;
                }
                this.setState({
                    terminalsITDToEdit: terminals.data
                }, () => callback())

            }).catch(error => {
                this.setState({ errors: error.response.data, terminals: [] });
                console.log('error:', error);
            })
    }

    getMeliTerminals = () => {
        // @ts-ignore
        const { t } = this.props;
        MercadoPagoService.GetMeliTerminals().then((terminalsLinked: AxiosResponse<IMeliTerminal[]>) => {

            if (terminalsLinked == null || terminalsLinked.data == null || terminalsLinked.data.length == 0) {
                this.setState({
                    terminals: []
                })
                return;
            }

            let newColumns: any[] = [];

            terminalsLinked.data.map((t) => {
                t.qr = (t.qr as { image: string }).image
            })

            for (let prop in terminalsLinked.data[0]) if (prop == "id") newColumns.push("identificador"); else newColumns.push(t(prop))

            newColumns.push(i18next.t("terminals") + "")
            newColumns.push(i18next.t("delete") + "")
            newColumns.push(i18next.t("visualTran") + "")

            this.setState({
                columns: newColumns,
                terminals: terminalsLinked.data
            });
        }).catch(error => {
            this.setState({ errors: error.response.data, terminals: [] });
            console.log('error:', error);
        });
    }


    UnassociateITDTerminal = (ITDTerm: {
        terminalNum: string;
        linked: boolean;
        branchId: string;
    }) => {


        MercadoPagoService.UnassociateITDTerminal({
            externalStoreId: ITDTerm.branchId,
            externalPosId: this.state.externalPosIdToEdit,
            itdTerminalId: ITDTerm.terminalNum,
            tenantId: ""
        }).then((response: AxiosResponse) => {
            toast.success("Terminal desvinculada con exito!")
        }).catch(error => {
            toast.error("Ha ocurrido un error. Por favor comuníquese con soporte.")
            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        ).finally(() => {
            this.getMeliTerminals();
            var req = new GetITDTerminalsRequest();
            req.Branch = ITDTerm.branchId
            this.getITDTerminals(req);
        });

    }

    associateITDTerminal = (ITDTerm: {
        terminalNum: string;
        linked: boolean;
        branchId: string;
    }) => {


        MercadoPagoService.AssociateITDTerminal({
            externalStoreId: ITDTerm.branchId,
            externalPosId: this.state.externalPosIdToEdit,
            itdTerminalId: ITDTerm.terminalNum,
            tenantId: ""
        }).then((response: AxiosResponse) => {
            toast.success("Terminal vinculada con exito!")
        }).catch(error => {
            toast.error("Ha ocurrido un error. Por favor Comuníquese con soporte.")

            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        ).finally(() => {
            this.getMeliTerminals();
            var req = new GetITDTerminalsRequest();
            req.Branch = ITDTerm.branchId
            this.getITDTerminals(req);
        });

    }


    //#endregion

    //#region POS

    unassociateMPPos = (dto: IAssociateMPPos) => {
        delete dto['branchLinked' as keyof IAssociateMPPos];

        MercadoPagoService.UnasociateTerminal(dto).then((response: AxiosResponse) => {
            Swal.fire({
                title: "Exito!",
                html: "Sucursal desvinculada.",
                confirmButtonColor: '#f60'
            });

        }).catch(error => {

            Swal.fire({
                title: "Ha ocurrido un error",
                html: "Por favor Comuníquese con soporte.",
                confirmButtonColor: '#f60'
            });

            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        ).finally(this.getMeliTerminals);

    }

    associateMPPos = (dto: IAssociateMPPos) => {
        delete dto['branchLinked' as keyof IAssociateMPPos];
        // dto.externalStoreId = this.state.
        MercadoPagoService.AssociateMPPos(dto).then((response: AxiosResponse) => {
            Swal.fire({
                title: i18next.t("success") + "",
                html: i18next.t("qrCreated") + "",
                confirmButtonColor: '#f60'
            });

        }).catch(error => {

            Swal.fire({
                title:  i18next.t("errorTitle"),
                html: i18next.t("contactHelpDesk"),
                confirmButtonColor: '#f60'
            });

            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        ).finally(this.getMeliTerminals);

    }
    //#endregion

    //#region Purchase





    swalShowTransactions = () => {
        let selectForSwal =
            <>
                <br />
                <select
                    className="form-control form-select select2"
                    id='selectTerminal'
                >
                    <option
                        disabled
                        key={"void"}
                        value={""}
                        selected
                    >
                        {i18next.t("selectTerminal") as string}
                    </option>
                    {
                        this.state.terminalsITDToEdit.map(terminal => {
                            return (
                                terminal.linked &&
                                <option
                                    key={terminal.terminalNum}
                                    value={terminal.terminalNum}
                                >
                                    {terminal.terminalNum}
                                </option>
                            )
                        })
                    }
                </select>
            </>

        return (
            <>
                {
                    Swal.fire({
                        title: i18next.t("selectTerminal"),
                        html: ReactDOMServer.renderToString(selectForSwal),
                        confirmButtonColor: '#f60',
                        showCloseButton: true,
                        preConfirm: () => {
                            var input = (document.getElementById('selectTerminal') as HTMLInputElement)?.value;
                            if (input != "" && input.length > 1) {
                                window.location.href = `/#/sales/transactions?shortcuts=%5B
                                %7B"data":%7B"code":"0700","acquirerName":"MercadoPago","acquirerId":"0700","issuerName":"MercadoPago","cardType":"D"%7D,"type":"ISSUER","description":"Tarjeta=MercadoPago"%7D,
                                %7B"data":"` + input + `","type":"TERMINAL_NUMBER","description":"Terminal=` + input + `"%7D%5D
                                `
                                Swal.close()
                            }
                            else
                                Swal.showValidationMessage(i18next.t("selectTerminal"))

                        }
                    })
                }
            </>

        )
    }
    //#endregion

    render() {
        // @ts-ignorec
        const { t } = this.props;


        return (

            <div className="main-container container-fluid" >
                {this.ModalHtmlCreateMPPos()}
                {this.ModalHtmlTerminalsABM()
                }
                < div className="page-header" ></div >
                <ToastContainer
                    autoClose={3000}
                ></ToastContainer>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{t("QRsMP")}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-10"></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2">
                                        <br></br>
                                        <br></br>
                                        <div className="">
                                            <button type="button"
                                                className="btn btn-primary mb-3 btn-block" onClick={this.getMeliTerminals}>Consultar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                    </div>
                                    <div className="col-md-2">
                                        <br></br>
                                        <br></br>
                                        <div className="">
                                            <button type="button"
                                                className="btn btn-primary mb-3 btn-block"
                                                onClick={() => {
                                                    this.getBranchs();
                                                    this.setState({ ...this.state, showModalCreateMPPos: true })
                                                }}
                                            >{t("createQR")}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <p />
                                <div className="table-responsive">
                                    <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                                        <thead className="table-primary">
                                            <tr key={"tr-head"}>
                                                {
                                                    this.state.columns.length > 0 &&
                                                    this.state.columns.map(coll => {
                                                        return (
                                                            <th key={`li-${coll}`}>{t(coll)}</th>
                                                        )
                                                    })
                                                }

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.terminals.slice(this.state.pages.indexOf(this.state.activePage) * pageSize, (this.state.pages.indexOf(this.state.activePage) * pageSize) + pageSize).map((terminal, Tindex) => {
                                                    const rows = [];
                                                    let rowStyle = "";
                                                    for (let prop in terminal) {

                                                        let value = (terminal as any)[prop];
                                                        const val = { name: prop, value: "", icon: "", aux: { externalStoreId: "", externalId: "", name: "", branchLinked: false } };
                                                        rows.push(val);
                                                        if (typeof value == 'string' || typeof value == 'number')
                                                            if (value == '')
                                                                val.value = "-";
                                                            else
                                                                val.value = value + "";
                                                        else if (typeof value == 'boolean')
                                                            if (value)
                                                                val.value = t("yes")
                                                            else
                                                                val.value = t("no")
                                                        else
                                                            val.value = "-";
                                                    }
                                                    return (
                                                        <tr key={Tindex} className={`${rowStyle}`}>
                                                            {rows.map((val: TerminalRow, index) => {
                                                                return (
                                                                    <>
                                                                        {/* {val.name == "terminalLinked" ?
                                                                            <>
                                                                                <td key={index.toString() + Tindex.toString() + Tindex.toString()} className={`${val.name}`}>
                                                                                    {val.value ?
                                                                                        <>
                                                                                            <i style={{ color: "#54B34A", marginRight: "40%", fontSize: "20px" }} className="ion ion-checkmark-round"></i>
                                                                                            {this.DownloadTooltip(val.aux)}
                                                                                        </>
                                                                                        :
                                                                                        val.aux.branchLinked ?
                                                                                            <i style={{ color: "red", marginRight: "40%", fontSize: "20px" }} className="ion ion-close-round"></i>
                                                                                            :
                                                                                            <>
                                                                                                <i style={{ color: "red", marginRight: "40%", fontSize: "20px" }} className="ion ion-close-round"></i>
                                                                                                {this.InfoTooltip()}
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                                <td key={index.toString() + Tindex.toString()} className={`${val.name}`}>

                                                                                    <button type="button"
                                                                                        className="btn btn-primary"
                                                                                        style={{ width: "100%" }}
                                                                                        onClick={() =>
                                                                                            val.value ?
                                                                                                this.unassociateTerminal(val.aux)
                                                                                                :
                                                                                                val.aux.branchLinked ?
                                                                                                    this.associateTerminal(val.aux) :
                                                                                                    window.location.href = '/integrations/MercadoPagoStoreABM'
                                                                                        }
                                                                                        >
                                                                                        {val.value ? t("Desvincular terminal") : val.aux.branchLinked ? t("Vincular terminal") : t("Vincular sucursal")}
                                                                                        </button>
                                                                                        </td>
                                                                                    </> */}

                                                                        <td key={index.toString() + Tindex.toString()} className={`${val.name}`}>
                                                                            {val.name.toLowerCase() != "qr" ?
                                                                                val.value
                                                                                :
                                                                                this.DownloadTooltip({
                                                                                    image: val.value as string,
                                                                                    templateDocument: "",
                                                                                    templateImage: ""
                                                                                })

                                                                            }
                                                                        </td>
                                                                    </>

                                                                )
                                                            })}
                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={"associate" + Tindex.toString() + "ter"} >
                                                                <button type="button"
                                                                    className="btn btn-primary "
                                                                    style={{ width: "90%" }}
                                                                    onClick={() => {
                                                                        var getITDTerminalsRequest = new GetITDTerminalsRequest()
                                                                        getITDTerminalsRequest.Branch = terminal.externalStoreId
                                                                        this.setState({
                                                                            ...this.state, externalPosIdToEdit: terminal.externalId
                                                                        })
                                                                        this.getITDTerminals(getITDTerminalsRequest, () => {
                                                                            this.setState({
                                                                                ...this.state, showModalTerminasABM: true
                                                                            })
                                                                        })
                                                                    }}
                                                                >
                                                                    {t("terminals")}
                                                                </button>

                                                            </td >

                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={"associate" + Tindex.toString()} >
                                                                <button type="button"
                                                                    className="btn"
                                                                    style={{ width: "90%", backgroundColor: "#CC0000", color: 'white' }}
                                                                    onClick={() => {
                                                                        this.unassociateMPPos({
                                                                            externalId: terminal.externalId,
                                                                            externalStoreId: terminal.externalStoreId,
                                                                            name: terminal.name
                                                                        })
                                                                    }}>
                                                                    {t("delete")}
                                                                </button>
                                                            </td >



                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={"associate" + Tindex.toString()} >
                                                                <button type="button"
                                                                    className="btn btn-primary "
                                                                    style={{ width: "90%" }}
                                                                    onClick={() => {
                                                                        var getITDTerminalsRequest = new GetITDTerminalsRequest()
                                                                        getITDTerminalsRequest.Branch = terminal.externalStoreId
                                                                        this.getITDTerminals(getITDTerminalsRequest, () => {
                                                                            if (this.state.terminalsITDToEdit.filter((t) => t.linked).length == 1)
                                                                                window.location.href = `/#/sales/transactions?shortcuts=%5B
                                                                                                %7B"data":%7B"code":"0700","acquirerName":"MercadoPago","acquirerId":"0700","issuerName":"MercadoPago","cardType":"D"%7D,"type":"ISSUER","description":"Tarjeta=MercadoPago"%7D,
                                                                                                %7B"data":"` + this.state.terminalsITDToEdit.filter((t) => t.linked)[0].terminalNum + `","type":"TERMINAL_NUMBER","description":"Terminal=` + this.state.terminalsITDToEdit[0].terminalNum + `"%7D%5D
                                                                                                `
                                                                            else if (this.state.terminalsITDToEdit.filter((t) => t.linked).length > 1)
                                                                                this.swalShowTransactions()
                                                                            else
                                                                                alert(t("noAssociatedTerminals"))

                                                                        })
                                                                    }
                                                                    }
                                                                >
                                                                    {t("visualTran")}
                                                                </button>
                                                            </td >

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <Pagination
                                            pageSize={pageSize}
                                            pageQuantity={pageQuantity}
                                            modifyMainPage={(value: number, newPages: Array<number>) => { this.setState({ mainPage: this.state.mainPage + value, pages: newPages }, () => this.getMeliTerminals()) }}
                                            changePage={(page: number) => this.setState({ activePage: page })}
                                            dataL={this.state.terminals.length}
                                            mainPage={this.state.mainPage}
                                        ></Pagination>

                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    };
}



export default withTranslation()(MercadoPagoPageTerminalsABM);
