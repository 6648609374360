import React from 'react';
import { TFunction, withTranslation } from "react-i18next";
import './ChartReference.scss';

interface IProps {
    datasets: Array<IPropDataset>
    t: TFunction
}

interface IPropDataset {
    label: string,
    data: Array<any>,
    backgroundColorReference: string,
    currencySymbol: string,
    GrossValue:number
}

const ChartReference: React.FC<IProps> = ({ t, datasets }) => {
    return (
        <table className="chart-reference-container">
            <tbody className="chart-reference-body">
                {   
                    datasets.map((dataset, index) => {
                        if (index < 5) {
                            return (
                                <tr key={`${dataset.label}`} >
                                    <td className="chart-reference-body__margin"></td>
                                    <td><span className={`chart-reference-body__reference-color chart-reference-body__reference-color--${dataset.backgroundColorReference}`} ></span></td>
                                    <td className="chart-reference-body__label">
                                        <span>{dataset.label}</span>
                                    </td>
                                    <td className="chart-reference-body__value" >
                                        {
                                            dataset.currencySymbol==""?Intl.NumberFormat('es-UY', {  minimumFractionDigits: 0 }).format(dataset.GrossValue):
                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: dataset.currencySymbol, minimumFractionDigits: 0 }).format(dataset.GrossValue)
                                        }

                                    </td>
                                </tr>
                            )
                        }
                        else {
                            return <></>
                        }
                    })
                }
            </tbody>
        </table>
    );
}

export default withTranslation()(ChartReference);