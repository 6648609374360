import { AxiosResponse } from 'axios';
import React from 'react';
import Pagination from '../Pagination';
import { withTranslation } from 'react-i18next';
import TerminalLocation from "../TerminalLocation";
import { ITerminalWebModelRequest } from "../../requests/ITerminalWebModelRequest";
import { TerminalsService } from "../../services/TerminalsService";
import {WebCache} from "../../services/WebCache";
interface ITerminal {
    id: string,
    transactionDate: Date,
    terminal: string,
    state: number
}



const pageSize = 13;
const pageQuantity = 3

interface IProps {
}

interface IState {
}




class MercadoPagoPage extends React.Component<IProps, IState>{


    getUrlToAssociate = () => {
        window.location.href = WebCache.getMPConfiguration().grantAccessUrl;
    }



    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <div className="main-container container-fluid">
                <div className="page-header">

                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">MercadoPago</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>
                                            {t("mpAccessLine1")}
                                            <br />
                                            {t("mpAccessLine2")}
                                        </p>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2">
                                        <br></br>
                                        <br></br>
                                        <div className="">
                                            <button
                                                onClick={this.getUrlToAssociate}
                                                type="button"
                                                className="btn btn-primary mb-3 btn-block" >{t("grantAccess")}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-10'></div>
                                    <div className="col-md-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
export default withTranslation()(MercadoPagoPage);
