import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


/* Font Awesom */
import "../node_modules/@fortawesome/fontawesome-free/css/all.css"

/* Bootstrap */
import "../node_modules/bootstrap/dist/css/bootstrap.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"

/* sash*/
import "../node_modules/@popperjs/core/dist/cjs/popper"
import "../node_modules/perfect-scrollbar/dist/perfect-scrollbar.min"
import "../node_modules/jquery-circle-progress/dist/circle-progress.min"
import "../node_modules/jquery-sparkline/jquery.sparkline"

import "../node_modules/itd-common/assets/css/icons.css"
import "../node_modules/itd-common/assets/css/style.css"
import "../node_modules/itd-common/assets/css/dark-style.css"
import "../node_modules/itd-common/assets/css/transparent-style.css"
import "../node_modules/itd-common/assets/css/skin-modes.css"
import "../node_modules/itd-common/assets/colors/color1.css"

import {BrowserRouter, HashRouter} from "react-router-dom";
import {LoadScript} from "@react-google-maps/api";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const libraries:("places" | "drawing" | "geometry" | "localContext" | "visualization")[]=["places","drawing"];
const onLoadMap=function() {


}


root.render(
  <React.StrictMode>
      <LoadScript

          googleMapsApiKey="AIzaSyBTLMac6l0XebkMVToaWaNgaxzuUD83xMI"
          onLoad={onLoadMap}
          libraries={libraries}
      >
      <HashRouter>
          <App />
      </HashRouter>

      </LoadScript>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals



reportWebVitals();

