import React from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { IRegisterUserMPTenant } from '../../requests/MPRequest/Register/IRegisterUserMPTenant';
import { MercadoPagoService } from '../../services/MercadoPagoService';
import { WebCache } from '../../services/WebCache';


interface IProps {
}

interface IState {
    loaded: boolean,
    callState: boolean | null
}



let initialized=false;
class MercadoPagoPageConfirm extends React.Component<IProps, IState>{

    constructor(props: IProps) {

        super(props);
        this.state = {
            loaded: false,
            callState: null
        }


    }

    componentDidMount() {
        try {
            if(initialized)
                return;
            initialized=true;
            let token = null;
            var query = window.location.href.split('?')[1].split('&')
            if(query.length==1)
                query=query[0].split(',')
            query.forEach(q => {  if (q.split('=')[0] == "code") {token = q.split('=')[1]} });

            if (token != null) {
                let registerUser = new IRegisterUserMPTenant();
                registerUser.refreshToken = token;

                MercadoPagoService.AssociateUser(registerUser).then((response) => {
                    if (response.status == 200){
                        WebCache.updateFeatures().then((response) => {
                            this.setState({ ...this.state, callState: true });
                        })
                    }
                    else{
                        this.setState({ ...this.state, callState: false })
                    }

                }).catch((response) => {
                    this.setState({ ...this.state, callState: false })
                });
            }
        } catch { this.setState({ ...this.state, callState: false }) }
    }


    swal = () => {
        return (
            <>

                {Swal.fire({
                    title: this.state.callState ? '¡Asociado con éxito!' :
                        this.state.callState == false ? "¡Ha ocurrido un problema!" : "Confirmando...",
                    html: this.state.callState ? "Ya puede asociar sucursales y crear QR" :
                        this.state.callState == false ? "Intentelo nuevamente" : "",
                    confirmButtonColor: '#f60',
                    showConfirmButton:false,
                    timer: 2000,
                    timerProgressBar: true,
                }).finally(() => {
                    if(this.state.callState)
                        window.location.href = '/#/integrations/MercadoPagoStoreABM'
                    else
                        window.location.href = '/#/integrations/MPAssociate'
                })}
            </>
        )
    }
    render() {


        // @ts-ignore
        const { t } = this.props;

        return (
            <div className="main-container container-fluid">
                <div className="row">
                    {this.state.callState != null && this.swal()}
                </div>
            </div>
        )
    };
}
export default withTranslation()(MercadoPagoPageConfirm);
