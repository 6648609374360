import { withTranslation } from "react-i18next";
import { useModel } from "../../contexts/modelContext";
import { ITranslationPros } from "../controlsPropertiesInterface";
import { IPropsField, IPropsInputChange } from "./fieldPropertiesInterface";
import modelHelper from "./modelHelper";
import "./radioButtonField.scss";

type IPropsIcon = { icon?: string };
type IPropsRadioButtonField = IPropsField & IPropsInputChange & ITranslationPros & IPropsIcon;

function RadioButtonField({
  id,
  model,
  attr,
  value,
  label,
  name,
  icon,
  onChange,
  t
}: IPropsRadioButtonField) {
    const modelContext = useModel();
    const modelState = model || modelContext;
    const { getValue, onValueChange } = modelHelper({ model: modelState, attr, value: undefined, onChange });

    const getKey = (): string => {
        return `input-${id || attr || label}-${name}`;
    }
  
    return (
        <>
            <input 
                type="radio"
                className="btn-check"
                name={name || `radio-button-${attr}`}
                key={getKey()}
                id={getKey()}
                onChange={onValueChange}
                value={value}
                checked={getValue() === value}
                placeholder=" "
            >
            </input>
            {!label || <label htmlFor={getKey()} className="radio-button-field-label btn btn-outline-primary">{t(label)}</label>}
            {!icon  || <label htmlFor={getKey()} className="radio-button-field-label" ><i className={`${icon} format-button${getValue() === value ? '-active' : ''}`} ></i></label>}
        </>
    );
}

export default withTranslation()(RadioButtonField);