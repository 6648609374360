import { AxiosResponse } from 'axios';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Pagination from "../Pagination";
import DatePicker, { DateObject, DatePickerProps, getAllDatesInRange } from "react-multi-date-picker"
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {Link} from "react-router-dom";
import {ILoyaltyTransaction, ITransactionLoyaltyWebModelRequest} from "../../models/loyalty/IClient";
import {ClientService} from "../../services/loyalty/ClientService";
import {UtilService} from "../../services/UtilService";
import Page from '../../components/containers/page'
import { DateHelper } from '../../helpers/DateHelper';

const pageSize = 10;
const pageQuantity = 3
interface IProps {
}

interface IState {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    transactions: ILoyaltyTransaction[],
    errors: string[],
    fromDate: DateObject,
    toDate: DateObject,
    periodBalance:number
}

interface TransactionRow {
    style: string;
    value: string;
    icon: string;
}


class LoyaltyAccountReport extends React.Component<IProps, IState>{
    private doQuery:boolean= true;
    constructor(props: IProps) {
        super(props);
        var date = new Date();
        date.setDate(new Date().getDate() - 20);
        let [ fromDate, toDate ] = DateHelper.buildDateRange();

        this.state = {
            activePage: 1,
            mainPage: 0,
            pages: [1, 2, 3, 4, 5],
            columns: ["serverDate","client","concept","positive","negative","balanceDebt"],
            transactions: [],
            errors: [],
            fromDate: fromDate,
            toDate: toDate,
            periodBalance:0
        }
    }

    componentDidMount() {
        if(this.doQuery){
            this.doQuery=false;
            //let merchantId = new URLSearchParams(window.location.search).get("merchant");
            //LoyaltyService.getLoyaltyBalanceByIdAndPromotions(merchantId+"").then(this.LoadMerchant);
            this.getTransactions();
        }
    }



    getTransactionRequest = (all: boolean) => {
        let merchantId = new URLSearchParams(window.location.search).get("merchant");
        let from_year: number;
        let from_month: number;
        let from_day: number;

        let to_year: number;
        let to_month: number;
        let to_day: number;

        from_year = this.state.fromDate.year;
        from_month = this.state.fromDate.month.number;
        from_day = this.state.fromDate.day;

        to_year = this.state.toDate.year;
        to_month = this.state.toDate.month.number;
        to_day = this.state.toDate.day;

        const tranWebModel: ITransactionLoyaltyWebModelRequest =
            {
                From: new Date(from_year, from_month - 1,
                    from_day,
                    this.state.fromDate.hour - 3,
                    this.state.fromDate.minute).toJSON(),
                To: new Date(to_year,
                    to_month - 1,
                    to_day,
                    this.state.toDate.hour - 3,
                    this.state.toDate.minute).toJSON(),
                SystemId:merchantId+"",
                Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
                Take: all ? 0 : ((pageSize * pageQuantity) + 1),
            }
            return tranWebModel;
        }

    getTransactions = () => {
        ClientService.getAllTransactions(this.getTransactionRequest(false)).then((response: AxiosResponse<any>) => {
            var periodBalance=0;
            for(var i in response.data.result){
                periodBalance+=response.data.result[i].totalAmountForReport;
            }
            this.setState({...this.state,
                transactions: response.data.result,
                periodBalance:periodBalance
            });
        });
    }

    updatefromDate = (e: DateObject[] | any) => {
        this.setState({ ...this.state, fromDate: e });
    }

    updateToDate = (e: DateObject[] | any) => {
        this.setState({ ...this.state, toDate: e });
    }

    /*
        getTransactionsToExcel = () => {
            TerminalsService.getAllToExcel(this.getTerminalRequest(true)).then((response: AxiosResponse<Blob>) => {
                const outputFilename = `TerminalsReport${Date.now()}.xlsx`;

                let filename = response.headers['content-disposition'];
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                link.click();

            }).catch(error => {
                this.setState({ errors: error.response.data });
                console.log('error:', error);
            });
        }
    */

    handleChange(event: any) {


    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page>
                <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h3>{t("transaccionSummary")}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label"> {t('authDateTimeFrom')}</label>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <i className="fa fa-clock-o tx-16 lh-0 op-6"></i>
                                            </div>
                                            <DatePicker inputClass="form-control"
                                                        format="DD/MM/YYYY"
                                                        value={this.state.fromDate}
                                                        onChange={(e) => this.updatefromDate(e)}
                                                        plugins={[
                                                            <TimePicker position="bottom" />
                                                        ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label"> {t('authDateTimeTo')}</label>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <i className="fa fa-clock-o tx-16 lh-0 op-6"></i>
                                            </div>
                                            <DatePicker inputClass="form-control"
                                                        format="DD/MM/YYYY"
                                                        value={this.state.toDate}
                                                        onChange={(e) => this.updateToDate(e)}
                                                        plugins={[
                                                            <TimePicker position="bottom" />
                                                        ]}


                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2">
                                        <br></br>
                                        <br></br>
                                        <div className="">
                                            <button type="button"
                                                className="btn btn-primary mb-3 btn-block" onClick={this.getTransactions}>{t("query")}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <p />
                                <div className="table-responsive">
                                    <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                                        <thead className="table-primary">
                                            <tr key={"tr-head"}>
                                                {
                                                    this.state.columns.length > 0 ?
                                                        this.state.columns.map(coll => {
                                                            return (
                                                                <th key={`li-${coll}`}>{t(coll)}</th>
                                                            )
                                                        }) :
                                                        <></>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.transactions.slice(this.state.pages.indexOf(this.state.activePage) * pageSize, (this.state.pages.indexOf(this.state.activePage) * pageSize) + pageSize).map((transaction, Tindex) => {
                                                    return (
                                                        <tr key={Tindex} >
                                                            <td className="text-dark-shadow">{transaction.serverDate.replace("T"," ")}</td>
                                                            <td className="text-dark-shadow">{transaction.clientName}</td>
                                                            <td className="text-dark-shadow"> <img alt="avatar" className="br-7" src={UtilService.getImageTag(t("issuer-"+transaction.issuer))}></img>{"  "+t("transactionType-"+transaction.transactionType)}</td>
                                                            <td className="text-dark-shadow">{transaction.amount<0?Intl.NumberFormat('es-UY', ).format(Number((transaction.amount?transaction.amount*-1:0).toFixed(0))):""}</td>
                                                            <td className="text-dark-shadow">{transaction.amount>0?Intl.NumberFormat('es-UY', ).format(Number((transaction.amount?transaction.amount:0).toFixed(0))):""}</td>
                                                            <td className="text-dark-shadow">{Intl.NumberFormat('es-UY', ).format(Number(transaction.loyaltyMerchantBalance))}</td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <Pagination
                                            pageSize={pageSize}
                                            pageQuantity={pageQuantity}
                                            modifyMainPage={(value: number, newPages: Array<number>) => { this.setState({ mainPage: this.state.mainPage + value, pages: newPages }, () => this.getTransactions()) }}
                                            changePage={(page: number) => this.setState({ activePage: page })}
                                            dataL={this.state.transactions.length}
                                            mainPage={this.state.mainPage}
                                        ></Pagination>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            </Page>
        )
    };
}
export default withTranslation()(LoyaltyAccountReport);
