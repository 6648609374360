import React, {ChangeEvent, Component, RefObject, useRef} from 'react';
import { Modal, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { TFunction, withTranslation } from "react-i18next";
import { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";
import { ReactComponent as IconDownloadWhiteSVG } from "../../../node_modules/itd-common/assets/icons/icon-download-white.svg";
import { ReactComponent as IconSettingSVG } from "../../../node_modules/itd-common/assets/icons/settings.svg";
import { IDailySettlement, IDailySettlements } from "../../models/ISettlementData";
import { Option } from "../../models/Option";
import { IDailySettlementsRequest, IDownloadDailyESettlement } from '../../requests/ISettlementsRequest';
import { FiscalSettlementService } from '../../services/FiscalSettlementService';
import { UtilService } from '../../services/UtilService';
import { WebCache } from "../../services/WebCache";
import CustomReCAPTCHA from '../CustomCaptcha';
import ErrorsView from '../ErrorsView';
import Card from '../containers/card';
import FormFilter from '../containers/form-filter';
import Page from '../containers/page';
import Button from '../controls/buttons/button';
import DateField from '../controls/fields/dateField';
import NumericField from '../controls/fields/numericField';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import { ColumnDefinition, actionColumnDefinition, currencyColumnDefinition, currencyTypeColumnDefinition, dateColumnDefinition, numericColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { AxiosResponse } from 'axios';
import './Settlement.scss'
import {UserService} from "../../services/UserService";
import i18next from "i18next";
import ReactToPrint from "react-to-print";
import NavigationTabLinksForSettlements from "./NavigationTabLinksForSettlements";
import {DateHelper} from "../../helpers/DateHelper";
import { ReactComponent as IconPrintSVG } from "../../../node_modules/itd-common/assets/icons/icon-print-white.svg";
import CheckBoxField from "../controls/fields/checkBoxField";
import Tooltip from '../Tooltip';
import {CurrencyHelper} from "../../helpers/CurrencyHelper";
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { IPageRequest } from '../../models/IPageRequest';

interface IProps {
    t: TFunction
}

interface IFilters {
    dateRange: Array<DateObject>,
    settlementNumber: string | null,
    type: string,   
    currency: string,
    taxPayer: string,
    amount:string,
    brand:string,
    merchantDocument:string
}

interface IState {
    mainPage: number,
    pageRequest: IPageRequest,
    dailySettlements: IDailySettlements,
    errors: string[],
    columnDefinitions: Array<ColumnDefinition>,
    filters: IFilters,
    currencyOptions: Array<HTMLOptionElement>,
    brandOptions: Array<HTMLOptionElement>,
    typeOptions: Array<HTMLOptionElement>,
    isModalOpen: boolean,
    hiddenColumnsMap: Map<string, boolean>,
    allFiscalDocuments: Array<Option>,
    isCaptchaVerified:boolean,
    selectedRowsToDowndload: IDailySettlement[],
    showTextInfo: number //se setea el numero del text info a mostrar, por default 0,
    merchantNumbers:Option[],
    maxDayRange:number
}

const T_PREFIX: string = 'pages.settlementsByDay.';
let lastFiscalDocument:string=""


class SettlementsByDay extends Component<IProps, IState> {
    private componentRef:RefObject<any> = React.createRef();
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);

    constructor(props: IProps) {
        super(props);
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();
        const currencyFilter: string = currencyOptions[0].value;
        const typeOptions: Array<HTMLOptionElement> = this.props.t(`${T_PREFIX}tipos`, { returnObjects: true });
        const type: string = typeOptions[0].value;
        const brandOptions: Array<HTMLOptionElement> = [];
        
        let columnDefinitions = [
            dateColumnDefinition({ key: 'paymentDate', label: `${T_PREFIX}columns.paymentDate`, header: true, mapValue: (settlement: IDailySettlement) => {
                const date = new Date(parseInt(settlement.paymentDate));
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear());

                //return `${day}/${month}/${year}`;
                return settlement.paymentDate;
            } }),
            dateColumnDefinition({ key: 'presentationDate', label: `${T_PREFIX}columns.presentationDate`, hidden: true, mapValue: (settlement: IDailySettlement) => {
                const date = new Date(parseInt(settlement.presentationDate));
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear());

                //return `${day}/${month}/${year}`;
                return settlement.presentationDate;
            } }),
            textColumnDefinition({ key: 'settlmentNumber', label: `${T_PREFIX}columns.settlmentNumber`, header: true }),
            numericColumnDefinition({ key: 'merchantNumber', label: `${T_PREFIX}columns.merchantNumber`, header: true }),
            textColumnDefinition({ key: 'saleType', label: `${T_PREFIX}columns.saleType`, header: true }),
            currencyTypeColumnDefinition({ key: 'currencyCode', label: `${T_PREFIX}columns.currencyCode`, header: true }),
            textColumnDefinition({ key: 'payerEntityDesc', label: `${T_PREFIX}columns.payerEntityDesc`, hidden: true }),
            textColumnDefinition({ key: 'bankAccount', label: `${T_PREFIX}columns.bankAccount`, hidden: true }),
            currencyColumnDefinition({ key: 'grossAmount', label: `${T_PREFIX}columns.grossAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            currencyColumnDefinition({ key: 'discountAmount', label: `${T_PREFIX}columns.discountAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            currencyColumnDefinition({ key: 'netAmount', label: `${T_PREFIX}columns.netAmount`, header: true , columnDefinitionKey:'currencyCode'}),
            textColumnDefinition({ key: 'productDesc', label: `${T_PREFIX}columns.productDesc`, hidden: true }),
            numericColumnDefinition({ key: 'marketingTariff', label: `${T_PREFIX}columns.marketingTariff`, hidden: true }),//ArancelAdicional
            numericColumnDefinition({ key: 'refundTariff', label: `${T_PREFIX}columns.refundTariff`, hidden: true }),//ReintegroArancel
            numericColumnDefinition({ key: 'gainsTaxArg', label: `${T_PREFIX}columns.gainsTaxArg`, hidden: true }),//RetenciónGanancias
            numericColumnDefinition({ key: 'settlementTaxRNI', label: `${T_PREFIX}columns.settlementTaxRNI`, hidden: true }),//Iva S/Arancel IVA RNI O SNC
            numericColumnDefinition({ key: 'settlementTaxPCP', label: `${T_PREFIX}columns.settlementTaxPCP`, hidden: true }),//IVA PERC
            numericColumnDefinition({ key: 'settlementTaxRI', label: `${T_PREFIX}columns.settlementTaxRI`, hidden: true }),//iva s/arancel
            numericColumnDefinition({ key: 'retentionGrossAmount', label: `${T_PREFIX}columns.retentionGrossAmount`, hidden: true }),//RetenciónIB
            numericColumnDefinition({ key: 'retentionTax', label: `${T_PREFIX}columns.retentionTax`, hidden: true }),//Retención Iva  s/ventas
            numericColumnDefinition({ key: 'discountAmountArg', label: `${T_PREFIX}columns.discountAmountArg`, hidden: true }),//ImporteArancel
            numericColumnDefinition({ key: 'paymentFinancingDiscount', label: `${T_PREFIX}columns.paymentFinancingDiscount`, hidden: true }),//Dto Financiamiento
            numericColumnDefinition({ key: 'otherConceptsAmount', label: `${T_PREFIX}columns.otherConceptsAmount`, hidden: true }),//Otros conceptos
            actionColumnDefinition({ key: 'edit', header: true, action: { function: (rowObject: IDailySettlement) => {
                //console.log("Download...");
                toast.info(this.props.t("settlementsDownloadFileNotification"));
                this.getDowloadESettlement(rowObject.settlmentNumber,rowObject.settlmentNumber,rowObject.merchantNumber,rowObject.productCode);
            }, icon: <IconDownloadSVG className='icon-download ms-5'></IconDownloadSVG>} })
        ];

        const hiddenColumnsMap: Map<string, boolean> = new Map();
        columnDefinitions.forEach(columnDefinition => hiddenColumnsMap.set(columnDefinition.key, columnDefinition.hidden || false));

        this.state = {
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            showTextInfo: 0,
            maxDayRange:2,
            selectedRowsToDowndload: [],
            dailySettlements: {
                dailySettlements: [] as IDailySettlement[]
            } as IDailySettlements,
            errors: [],
            columnDefinitions,
            filters: {
                dateRange: [],
                settlementNumber: '',
                amount:'',
                type,
                currency: currencyFilter,
                taxPayer: WebCache.getAllMerchantDocuments(false)[0]?.key+"",
                brand:'',
                merchantDocument:""
            },
            brandOptions,
            currencyOptions,
            typeOptions,
            isModalOpen: false,
            hiddenColumnsMap,
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions(WebCache.getAllMerchantDocuments(false)[0]?.label,true),
            allFiscalDocuments: WebCache.getAllMerchantDocuments(false),
            isCaptchaVerified: false
        };
        this.getProducts()
    }  
    
    getDownloadDailyESettlementRequest = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string): IDownloadDailyESettlement => {
        const result: IDownloadDailyESettlement = {
            FileName: fileName,
            SettlementNumber: parseInt(settlmentNumber),
            FiscalNumber: this.state.filters.taxPayer,
            MerchantNumber:merchantNumber,
            ProductCode:productCode
        };  
        return result;
    }

    getProducts = () => {
        FiscalSettlementService.getAllProducts()
                            .then((response: Array<HTMLOptionElement>) => {     
                                
                                const item: HTMLOptionElement = {
                                    value: "",
                                    label: "Todas"
                                } as HTMLOptionElement;
                                response = [item, ...response];   
            this.setState({
                ...this.state,
                brandOptions: response
            });

        }).catch((error: any) => {   
        });         
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getDowloadESettlement = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string) => {
        if (this.state.isCaptchaVerified) {
            console.log(fileName);
            FiscalSettlementService.getDowloadDailyESettlement(this.getDownloadDailyESettlementRequest(fileName, settlmentNumber, merchantNumber,productCode))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({  errors: ["Error obteniendo datos del servidor"] });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };

    getRequest = (all: boolean): IDailySettlementsRequest => {
        const tranWebModel: IDailySettlementsRequest = {
            SettlementNumber: this.state.filters.settlementNumber,
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            Type: "",
            Amount:this.state.filters.amount,
            TypeSell: this.mapType(this.state.filters.type) ,
            DateRangeType:"",
            Currency: this.state.filters.currency,
            MerchantDocument: this.state.filters.taxPayer,
            MerchantNumber:this.state.filters.merchantDocument,
            ProductDesc:this.state.filters.brand,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
        return tranWebModel;
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.taxPayer){
            lastFiscalDocument=this.state.filters.taxPayer;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }
    }

    mapType = (type:string) => {
        if(type=="Diario")
            return "D"
        if(type=="Recupero")
            return "R"
        if(type=="Adelanto")
            return "A"
        if(type=="A")
            return "Adelanto"
        if(type=="R")
            return "Recupero"
        if(type=="D")
            return "Diario"
        return ""
    }
    
    getLiquidations = () => {

        let dateFrom= DateHelper.dateObjectToDate(this.state.filters.dateRange[0]);
        let dateTo= DateHelper.dateObjectToDate(this.state.filters.dateRange[1]);
        let diffDays =
            Math.round
            ((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24));
        if(diffDays>this.state.maxDayRange){
            toast.error(("El maximo de días entre las fechas es" )+" "+this.state.maxDayRange);
            return;
        }

        FiscalSettlementService.getDailySettlements(this.getRequest(false)).then((response: IDailySettlements) => {
            for(let i in response.dailySettlements){
                response.dailySettlements[i].saleType=this.mapType( response.dailySettlements[i].saleType);
            }
            this.setState({...this.state,
                dailySettlements: response,
                errors: []
            });
        }).catch(error => {
            this.setState({...this.state,
                errors: ["Error obteniendo datos del servidor"],
                dailySettlements: {
                    dailySettlements: [] as IDailySettlement[]
                } as IDailySettlements
            })
        });
    }

    searchFilter = () => {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.getLiquidations();
        });
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

     clearFilter = () => {
        this.setFilters({
            ...this.state.filters,
            type: "",
            dateRange: DateHelper.buildDateRange(),
            settlementNumber: "",            
            currency: '',
            taxPayer: '',
            amount:''            
        });
    }


    downloadGrid = () => {
        let fileName="Liquidación diaria.csv";
        UserService.getCsvOnline(this.state.columnDefinitions.filter(x=>x.action==null).map(x=>i18next.t(x.label+"")).join(";")+"\r\n"+
            // @ts-ignore
            this.state.dailySettlements.dailySettlements.map(y=>this.state.columnDefinitions.map(x=>y[x.key]).join(";")).join("\r\n"), fileName).then((response: AxiosResponse<any>) => {
            //const outputFilename = `TerminalsReport${Date.now()}.xlsx`;
            // @ts-ignore
            /*let outputFilename = response.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .trim();*/
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
        }).catch(error => {
            console.log('error:', error);
        });
    }
    handleClickModal = () => {
        this.setState({
            ...this.state, isModalOpen: !this.state.isModalOpen
        })
    }

    handleClickOptionModal = (event: ChangeEvent<HTMLInputElement>) => {
    let values: Map<string, boolean> = this.state.hiddenColumnsMap;
        values.set(event.target.id, !values.get(event.target.id));
        this.setState({
            ...this.state, hiddenColumnsMap: values
        });
    }

    changeColumnsTable = () => {
        let columnDefinitions: Array<ColumnDefinition> = [...this.state.columnDefinitions];
        this.state.columnDefinitions.forEach((columnDefinition, columnDefinitionIndex) => {
            columnDefinitions[columnDefinitionIndex].hidden = this.state.hiddenColumnsMap.get(columnDefinition.key);
        });
        
        this.setState({...this.state, columnDefinitions, isModalOpen: false});
    }

    renderTotalNetAmounts = (dailySettlements: IDailySettlements, isCurrencyUSD: Boolean) : JSX.Element=> {
        const { t } = this.props;
        let totalNetAmount= "";
        if(isCurrencyUSD){
            // Neto total para la moneda Pesos uruguayos
            totalNetAmount = Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol("858"), maximumFractionDigits: 0 }).format(
                    dailySettlements.dailySettlements?.reduce((acc, dailySettlement) => {
                        return dailySettlement.currencyCode != '840' ? acc + dailySettlement.netAmount : acc;
                    }, 0)
                );
        }else {
            // Neto total para la moneda de USD
            totalNetAmount = Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol("840"), maximumFractionDigits: 0 }).format(
                    dailySettlements.dailySettlements?.reduce((acc, dailySettlement) => {
                        return dailySettlement.currencyCode === '840' ? acc + dailySettlement.netAmount : acc;
                    }, 0)
                );
        }
        return <>
            <div className='d-flex d-inline'>
                <label className="form-label mt-0 w-70 fs-5"> {t('netTotal')}</label>
                <span className='w-50'>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <BootstrapTooltip>
                                {this.renderTextInfo()}
                            </BootstrapTooltip>
                        }
                    >
                        <a className='float-right button-text-info-1' type='button'>
                            <IconInfoSVG className='fs-3 pe-2'></IconInfoSVG>
                        </a>
                    </OverlayTrigger>
                </span>
            </div>
            <label  className="border-start-0 ms-0 mt-2 amount-label" >
                {totalNetAmount}
            </label>
        </>
    }

    renderTextInfo = () : JSX.Element => {
        const { t } = this.props;
        return <div className={`text-info`}>
                    <p className='p-3 text-white'>{t(`${T_PREFIX}text-info-total`)}</p>
                </div>
    }

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let currency;
        let taxPayerId;
        

        if (currentUser !== undefined && currentUser === 2) {
            currency =                                             <div className="col-md-3">
            <SelectField attr='currency' label='currency' options={this.state.currencyOptions} ></SelectField>
        </div>
            taxPayerId=`${T_PREFIX}merchantURU`
        } else {
            currency = ''
            taxPayerId=`${T_PREFIX}merchantARG`
        }	
        
        return (
            <Page >
                <>
                    <h2>{t(`settlements`)}</h2>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row" ref={this.componentRef}>
                        <div className="col-lg-12">
                            <div className="col-lg-12 mt-2">
                                <NavigationTabLinksForSettlements></NavigationTabLinksForSettlements>
                            </div>
                            <FormFilter 
                                onSubmit={this.searchFilter} 
                                clear={this.clearFilter}
                                model={[ this.state.filters, this.setFilters.bind(this) ]} >
                                    <>
                                        <div className="row form-Settlement mt-2">
                                            <div className="col-md-3">
                                                <DateField attr="dateRange" label="period" range ></DateField>
                                            </div>
                                            <div className="col-md-3">
                                                <TextField attr="settlementNumber" label={`${T_PREFIX}searchSettlementNumber`} ></TextField>
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField
                                                    attr='taxPayer'
                                                    label={taxPayerId}
                                                    options={this.state.allFiscalDocuments}
                                                ></SelectField>
                                            </div>
                                        <div className="col-md-3">
                                            <SelectField attr='merchantDocument' label={`${T_PREFIX}merchantNumber`}
                                                        options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                        </div>
                                            <div className='col-md-3'>
                                                <SelectField attr='brand' label={t(`${T_PREFIX}brand`)}
                                                            options={this.state.brandOptions}></SelectField>
                                            </div> 
                                            {currency}
                                            <div className="col-md-3">
                                                <NumericField attr="amount" label={`${T_PREFIX}amount`} ></NumericField>
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField attr='type' label='type' options={this.state.typeOptions} ></SelectField>
                                            </div>
                                        </div>
                                    </>
                            </FormFilter>
                            <section className="row pb-4 ms-1">
                                {
                                    WebCache.getCurrentUser()?.countryId != 1 &&
                                        <div className="col-md-3 div-info me-4" style={{textAlign:"start"}}>
                                            {this.renderTotalNetAmounts(this.state.dailySettlements, false)}
                                        </div>
                                }
                                <div className="col-md-3 div-info" style={{textAlign:"start"}}>
                                    {this.renderTotalNetAmounts(this.state.dailySettlements, true)}
                                </div>
                            </section>
                            <nav className="nav mt-3">
                                <span className="vertical-align">
                                    <Button color="transparent" className="border-0 mt-0" noBlock onClick={()=> this.handleClickModal()}>
                                        <>
                                            <IconSettingSVG></IconSettingSVG>
                                            <span className='ms-2 text-dark' >{t(`common.action.tableConfig`)}</span>
                                        </>
                                    </Button>   
                                </span>                                    
                                <span className="navbar-text ms-auto mb-3 col-md-6 fs-5 pe-0 d-flex justify-content-end">
                                    <ReactToPrint
                                        trigger={() => <Button color="primary" className="border-0 mt-0 me-3" noBlock>
                                            <>
                                                <span className='ms-2 me-2 text-white'>{t(`common.action.print`)}</span>
                                                <IconPrintSVG></IconPrintSVG>
                                            </>
                                        </Button>}
                                        content={() => this.componentRef.current}
                                    />
                                    <Button color="primary" className="border-0 mt-0" onClick={()=> this.downloadGrid()} noBlock>
                                        <>
                                            <span className='ms-2 me-2'>{t(`common.action.downloadCsv`)}</span>
                                            <IconDownloadWhiteSVG></IconDownloadWhiteSVG>
                                        </>
                                    </Button>
                                </span>
                            </nav>
                            <div className='row'>
                                <div className="col-md-12">
                                    <CustomTable
                                        rowObjects={this.state.dailySettlements.dailySettlements}
                                        columnDefinitions={this.state.columnDefinitions}
                                        config={{
                                            mode: TableModeEnum.GRID,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getLiquidations())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6' >
                                </div>
                                <div className="col-md-6">
                                    <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.isModalOpen} size='lg'>
                        <>
                            <div className='mb-2'>
                                <h2>{t(`${T_PREFIX}tableConfigTitle`)}</h2>
                                <h6>{t(`${T_PREFIX}tableConfigDescription`)}</h6>
                            </div>
                            <div className="row">
                                { 
                                    Array.from(this.state.hiddenColumnsMap).map((entry: [string, boolean]) => {
                                        const [key, value]: [string, boolean] = entry;
                                        return (
                                            <div className="col-md-4" key={key} >
                                                <CheckBoxField id={key} checked={!value} label={`${T_PREFIX}columns.${key}`} onChange={(event) => this.handleClickOptionModal(event)} ></CheckBoxField>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='d-flex flex-row-reverse gap-4'>
                                <Button className='mt-2 ps-8 pe-8' label='accept' onClick={() => this.changeColumnsTable()}/>
                                <Button className='mt-2 ps-8 pe-8' color='white' label='cancel' onClick={() => this.setState({...this.state, isModalOpen: false})} />
                            </div>
                        </>
                    </Modal>
                </>
            </Page>
                        
        )
    }
} 
export default withTranslation() (SettlementsByDay);
