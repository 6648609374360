import { AxiosResponse } from 'axios';
import React from 'react';
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import { toast } from "react-toastify";
import { CurrencyOption } from "../../models/Currency";
import {
    DateRangeType,
    ISettlement,
    ISettlementRequest
} from "../../models/ISettlementData";
import { IShortcutCaptureResult } from "../../models/IShortcut";
import { SettlementService } from "../../services/SettlementService";
import { UtilService } from "../../services/UtilService";
import ErrorsView from '../ErrorsView';
import FormFilter from '../containers/form-filter';
import DateField from '../controls/fields/dateField';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import PaymentTransactionReport from "./PaymentTransactionReport";
import "./SettlementReport.css";
import SettlementTotal from "./SettlementTotal";
import SettlementUploader from "./SettlementUploader";
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { ColumnDefinition, actionColumnDefinition, currencyColumnDefinition, currencyTypeColumnDefinition, dateColumnDefinition, numericColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import { IPageRequest } from '../../models/IPageRequest';
import Button from '../controls/buttons/button';
import Page from '../containers/page'
import NavigationTabLinksForReports from './NavigationTabLinksForReports';
import Card from '../containers/card';
import { DateHelper } from '../../helpers/DateHelper';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { WebCache } from '../../services/WebCache';
import CustomReCAPTCHA from '../CustomCaptcha';
import { t } from 'i18next';
import './PaymentReport.scss'
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";
import { FiscalSettlementService } from '../../services/FiscalSettlementService';
import {Option} from "../../models/Option";
import {IDownloadDailyESettlement} from "../../requests/ISettlementsRequest";

type BuildTotalDetailOptions = { object: any, title: string, accumulateTotal: boolean };
interface IProps {
    t:any
}

interface IFilters {
    dateRangeType: DateRangeType,
    dateRange: DateObject[],
    settlementNumber:string,
    currency: string,
    fiscalDocument: string,
    merchantDocument: string
}
interface IState {
    filters: IFilters,
    currencyOptions: Array<HTMLOptionElement>,
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    mainPage: number,
    pageRequest: IPageRequest,
    settlements: ISettlement[],
    errors: string[],
    rowDetail: { title: string, total: string, data: Array<{ prop: string, value: string }> } | null,
    transactionDetailSettlement?: ISettlement,
    showUploadModal: boolean,
    tableMode: TableModeEnum,
    isCaptchaVerified:boolean,
    renderCuponDetails?: JSX.Element | null
 }
let doQuery = false;

let lastFiscalDocument:string=""
class PaymentReport extends React.Component<IProps, IState> {

    private translateFnc: any;
    private paymentTransactionReportDiv: HTMLDivElement | null = null;
    private columnDefinitions: Array<ColumnDefinition>;

    updateDimensions = () => {
        /*
        const { innerWidth: width, innerHeight: height } = window;
        if(UtilService.isSmallScreen()&&this.state.columnsToShow.length === 0){
            this.setState({...this.state,columnsToShow:[
                    "TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"
                ]});
        }else if(width>992&&this.state.columnsToShow.length>0){
            this.setState({...this.state,columnsToShow:[]});
        }
        */
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        if (doQuery) {
            doQuery = false;
            this.getSettlements();
        }
        this.translateFnc = this.props.t;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    constructor(props: IProps) {
        super(props);

        lastFiscalDocument="";
        let shortcut = null;
        if (window.location.href.indexOf("shortcuts=") > 0) {
            shortcut = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("shortcuts="))).get("shortcuts")
        }
        let settlementNumber = "";
        let dateRangeType: DateRangeType = "PAYMENT_DATE";
        let currency = "";
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();
        let [ fromDate, toDate ] = DateHelper.buildDateRange();

        if (shortcut != null && shortcut.length > 0) {
            doQuery = true;
            let shortcutDto: IShortcutCaptureResult[] = JSON.parse(shortcut);
            for (var index = 0; index < shortcutDto.length; index++) {
                switch (shortcutDto[index].type) {
                    case "CURRENCY":
                        currency = currencyOptions.filter(currency => currency.value === (shortcutDto[index].data + ""))[0].value;
                        break;
                    case "SETTLEMENT_NUMBER":
                        settlementNumber = shortcutDto[index].data + "";
                        break;
                    case "PAYMENT_DATE":
                        dateRangeType="PAYMENT_DATE";
                        fromDate = new DateObject(shortcutDto[index].data);
                        toDate = new DateObject(shortcutDto[index].data);
                        break;
                }
            }
        }

        const dateRange = [ fromDate, toDate ];
        this.state = {
            filters: {
                fiscalDocument:WebCache.getAllMerchantDocuments(true)[0].label+"",
                merchantDocument:"" ,
                dateRangeType,
                dateRange,
                settlementNumber,
                currency,
            },
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false)[0].label,true),
            fiscalDocuments:WebCache.getAllMerchantDocuments(true),
            currencyOptions,
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            settlements: [],
            errors: [],
            rowDetail: null,
            showUploadModal: false,
            tableMode: TableModeEnum.LIST,
            isCaptchaVerified: false
        }

        const currentUser = WebCache.getCurrentUser()?.countryId;

        let merchantDocument;
        if (currentUser !== undefined && currentUser === 2) {
            merchantDocument = t("merchantDocument") ;
        } else {
            merchantDocument =  t("merchantDocumentArg");
        }


        const MAGNIFYING_CLASS_ICON: string = 'fa-solid fa-magnifying-glass';
        this.columnDefinitions = [
            dateColumnDefinition({ key: 'fileDate', label: 'aggregationPresentationDate', header: true,fixedWidth:50 }),
            textColumnDefinition({ key: 'productCode', label: 'productCode', header: true,fixedWidth:100  }),
            currencyTypeColumnDefinition({ key: 'currency', label: 'currency', header: true,fixedWidth:50 }),
            dateColumnDefinition({ key: 'presentationDate', label: 'presentationDate', header: true,fixedWidth:50 }),
            dateColumnDefinition({ key: 'vtoDate', label: 'vtoDate', header: true,fixedWidth:50 }),
            dateColumnDefinition({ key: 'netTotalPaymentDate', label: 'netTotalPaymentDate', header: true,fixedWidth:50 }),
            numericColumnDefinition({ key: 'merchantNumber', label: 'merchantNumber', header: true,fixedWidth:80 }),
            textColumnDefinition({ key: 'payingEntity', label: 'payingEntity', header: true,fixedWidth:100 }),
            actionColumnDefinition({ key: 'edit', label: 'Download', header: true, action: { function: (rowObject: ISettlement) => {
                //console.log("Download...");
                toast.info(this.props.t("settlementsDownloadFileNotification"));                
                this.getDowloadESettlement(rowObject.settlementNumber,rowObject.settlementNumber,rowObject.merchantNumber,rowObject.mfProductCode,rowObject.merchantDocument);
            }, icon: <IconDownloadSVG></IconDownloadSVG>} }),
            numericColumnDefinition({ key: 'settlementNumber', label: 'settlementNumber' }),
            numericColumnDefinition({ key: 'merchantDocument', label: merchantDocument }),
            numericColumnDefinition({ key: 'wholesalerDocument', label: 'wholesalerDocument' }),
            numericColumnDefinition({ key: 'transactionCount', label: 'transactionCount', action: { function: this.showTransactionDetailSettlementAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'grossAmount', label: 'grossAmount', columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'amountWithoutDiscounts', label: 'amountWithoutDiscounts', columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'chargesAndPaymentsAmount', label: 'chargesAndPaymentsAmount', columnDefinitionKey: 'currency', action: { function: this.showChargesAndPaymentsAmountDetailAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'contrapartsAmount', label: 'contrapartsAmount', columnDefinitionKey: 'currency', action: { function: this.showContrapartsAmountDetailAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'tariffAndFinancialCosts', label: 'tariffAndFinancialCosts', columnDefinitionKey: 'currency', action: { function: this.showTotalDetailAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'taxWithholdings', label: 'taxWithholdings', columnDefinitionKey: 'currency', action: { function: this.showTaxDetailAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'originalSettlementNetAmount', label: 'originalSettlementNetAmount', columnDefinitionKey: 'currency', action: { function: this.showTotalDetailAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'netSaleFinancialDiscount', label: 'netSaleFinancialDiscount', columnDefinitionKey: 'currency', action: { function: this.showValDetailAction, icon: MAGNIFYING_CLASS_ICON } }),
            currencyColumnDefinition({ key: 'taxFinancialDiscount', label: 'taxFinancialDiscount', columnDefinitionKey: 'currency'}),
            currencyColumnDefinition({ key: 'netAmountToMerchant', label: 'netAmountToMerchant', columnDefinitionKey: 'currency'})
        ];
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    getSettlementsRequest = (all:boolean) => {
        const tranWebModel: ISettlementRequest = {
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            DateRangeType: this.state.filters.dateRangeType,
            SettlementNumber: (this.state.filters.settlementNumber === null || this.state.filters.settlementNumber === "") ? "" : this.state.filters.settlementNumber,// Number(this.state.filters.settlementNumber),
            Currency: (this.state.filters.currency === null || this.state.filters.currency === "" ) ? "" : this.state.filters.currency,
            MerchantDocument: this.state.filters.fiscalDocument=="all"?"":this.state.filters.fiscalDocument,
            MerchantNumber: this.state.filters.merchantDocument=="all"?"":this.state.filters.merchantDocument,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
        return tranWebModel;
    }

    getDownloadDailyESettlementRequest = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string,taxPayer:string): IDownloadDailyESettlement => {
        const result: IDownloadDailyESettlement = {
            FileName: fileName+"",
            SettlementNumber: parseInt(settlmentNumber),
            FiscalNumber: taxPayer,
            MerchantNumber:merchantNumber,
            ProductCode:productCode

        };
        return result;
    }

    getDowloadESettlement = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string,taxPayer:string) => {
        if (this.state.isCaptchaVerified) {
            console.log(fileName);
            FiscalSettlementService.getDowloadDailyESettlement(this.getDownloadDailyESettlementRequest(fileName, settlmentNumber, merchantNumber,productCode,taxPayer))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({ errors: error.response.data });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };

    getFromBuscar = () => {
        this.setState({
            ...this.state, mainPage: 0, transactionDetailSettlement: undefined
        }, () => {
            this.getSettlements();
        });
    }

    getSettlements = () => {
        SettlementService.getSettlements(this.getSettlementsRequest(false)).then((response: AxiosResponse<ISettlement[]>) => {
            if( response === null ||
                response.data === null ||
                response.data.length === 0
            ) {
                this.setState({...this.state,
                    settlements: [],
                    errors: []
                });

                return;
            }

            this.setState({...this.state,
                settlements:  response.data,
                errors: []
            });

        }).catch(error => {
            this.setState({...this.state,
                errors: error.response.data,
                settlements: []
            })
            console.log('error:', error);
        });
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    genericToExcelFunction = (toExcelFunction: (settlement: ISettlementRequest) => Promise<AxiosResponse<any, any>> ) => {
        toExcelFunction.call<any, ISettlementRequest[], Promise<AxiosResponse<Blob>>>(this, this.getSettlementsRequest(true))
        .then((response: AxiosResponse<Blob>) => {})
        .catch(error => {
            this.setState({...this.state,errors: error.response.data});
            console.log('error:', error);
        });    
    }

    getSettlementsWithCouponsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            this.genericToExcelFunction(SettlementService.getAllWithCouponsToExcel);
        }  else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }

    getSettlementsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            this.genericToExcelFunction(SettlementService.getAllToExcel);
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };    
    }

    downloadUploadedFileAction: (rowObject: ISettlement) => void = (rowObject: ISettlement) => {
        SettlementService.downloadUploadedFile(rowObject.aggregatorHeaderId);
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.fiscalDocument){
            lastFiscalDocument=this.state.filters.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }
    }

    showTransactionDetailSettlementAction = (rowObject: ISettlement) => {
        console.log('Transaction Detail Settlement:', rowObject);
        const scrollToTransactions: () => void = () => {
            this.paymentTransactionReportDiv?.scrollIntoView({ behavior: "smooth" });
        };
    
        const renderCuponDetails = this.renderTransactionsCupon();
    
        this.setState({ 
            ...this.state, 
            transactionDetailSettlement: rowObject,
            renderCuponDetails
        });
    
        setTimeout(scrollToTransactions);
    };

    showDetailAction: (rowObject: ISettlement, detailOptions: BuildTotalDetailOptions) => void = (rowObject: ISettlement, detailOptions: BuildTotalDetailOptions): void => {       
        if (detailOptions) {
            const currencySymbol: string = CurrencyHelper.getCurrencySymbol(rowObject.currency);
            this.setState({
                ...this.state,
                rowDetail: SettlementService.buildTotalDetail(detailOptions.object, detailOptions.title, currencySymbol, detailOptions.accumulateTotal),
                transactionDetailSettlement: undefined 
            });
        }
    };

    showTotalDetailAction: (rowObject: ISettlement) => void = (rowObject: ISettlement): void => {
        this.showDetailAction(rowObject, { object: rowObject.totalDetail, title: "totalDetail", accumulateTotal: false });
    };
    
    showTaxDetailAction: (rowObject: ISettlement) => void = (rowObject: ISettlement): void => {
        this.showDetailAction(rowObject, { object: rowObject.taxDetail, title: "taxDetail", accumulateTotal: true });
    };

    showValDetailAction: (rowObject: ISettlement) => void = (rowObject: ISettlement): void => {
        this.showDetailAction(rowObject, { object: rowObject.valDetail, title: "valDetail", accumulateTotal: false });
    };

    showContrapartsAmountDetailAction: (rowObject: ISettlement) => void = (rowObject: ISettlement): void => {
        this.showDetailAction(rowObject, { object: rowObject.totalDetail.contraparts, title: "contrapartsAmount", accumulateTotal: false });
    };

    showChargesAndPaymentsAmountDetailAction: (rowObject: ISettlement) => void = (rowObject: ISettlement): void => {
        this.showDetailAction(rowObject, { object: rowObject.totalDetail.chargesAndPayments, title: "chargesAndPaymentsAmount", accumulateTotal: false });
    };

    processAction = (columnName: string, object: ISettlement): void => {
        if (columnName === 'transactionCount') {
            const scrollToTransactions: () => void = () => {
                this.paymentTransactionReportDiv?.scrollIntoView({ behavior: "smooth" });
            };

            this.setState({ ...this.state, transactionDetailSettlement: object });
            
            setTimeout(scrollToTransactions);
            return;
        }

        type BuildTotalDetailOptions = { object: any, title: string, accumulateTotal: boolean };
        const currencySymbol: string = CurrencyHelper.getCurrencySymbol(object.currency);
        const setStateWithTotalDetail: ({ object, title, accumulateTotal }: BuildTotalDetailOptions) => void = 
            ({ object, title, accumulateTotal }: BuildTotalDetailOptions) => {
                this.setState({
                    ...this.state,
                    rowDetail: SettlementService.buildTotalDetail(object, title, currencySymbol, accumulateTotal),
                    transactionDetailSettlement: undefined 
                });
            };
        
        let buildTotalDetailOptions: BuildTotalDetailOptions | undefined;
        switch (columnName) {
            case 'netTotal':
            case 'originalSettlementNetAmount':
            case 'tariffAndFinancialCosts':
                buildTotalDetailOptions = { object: object.totalDetail, title: "totalDetail", accumulateTotal: false };
                break;
            case 'taxWithholdings':
                buildTotalDetailOptions = { object: object.taxDetail, title: "taxDetail", accumulateTotal: true };
                break;
            case 'valCharges':
            case 'netSaleFinancialDiscount':
                buildTotalDetailOptions = { object: object.valDetail, title: "valDetail", accumulateTotal: false };
                break;
            case 'contrapartsAmount':
                buildTotalDetailOptions = { object: object.totalDetail.contraparts, title: "contrapartsAmount", accumulateTotal: false };
                break;
            case 'chargesAndPaymentsAmount':
                buildTotalDetailOptions = { object: object.totalDetail.chargesAndPayments, title: "chargesAndPaymentsAmount", accumulateTotal: false };
                break;
        }

        if (buildTotalDetailOptions) {
            setStateWithTotalDetail(buildTotalDetailOptions);
        }
    }

    successUpload = (success: AxiosResponse<any>) => {
        this.setState({ ...this.state, showUploadModal: false });
        toast.success(this.translateFnc("uploadSuccess"));
    }

    errorUpload = (error: any) => {
        this.setState({ ...this.state, showUploadModal: false });
        toast.error(error);
    }

    uploadData = (file: File) => {
        SettlementService.uploadSettlement(file).then(this.successUpload,this.errorUpload);
    }

    handleBackClick = () => {
        this.setState({
            transactionDetailSettlement: undefined,
        });
    };

    renderTransactionsCupon() {
        return (
            <div ref={(el) => { this.paymentTransactionReportDiv = el }} className='position-relative'>
                <div className='backSettlement'>
                    <Button
                        onClick={this.handleBackClick}
                        label={t("returnSettlement")}
                        noBlock
                    ></Button>
                </div>
                <PaymentTransactionReport
                    show={Boolean(this.state.transactionDetailSettlement)} 
                    settlementNumber={this.state.transactionDetailSettlement?.settlementNumber}
                    showOnlyVal={false}
                ></PaymentTransactionReport>
            </div>
        );
    }
    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = t("merchantDocument") ;
        } else {
            clientDocument = t("merchantDocumentArg");
        }

        return (
            <>
                {this.state.transactionDetailSettlement ? this.renderTransactionsCupon() : (
            <Page >
                <>
                    <SettlementTotal rowDetail={this.state.rowDetail} link={null}></SettlementTotal>            
                    <Modal show={this.state.showUploadModal} size="lg">
                        <SettlementUploader
                        onCancel={() => this.setState({ ...this.state, showUploadModal: false })}
                        onSuccess={this.uploadData}
                        ></SettlementUploader>
                    </Modal>
                    <div className='row'>
                        <h2>{t('fileReports')}</h2>
                        <div className='col-lg-12'>
                        {/* <ErrorsView errors={this.state.errors}/> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Card actions={
                                <>
                                <div className='row w-100'>
                                    <div className="col-lg-10">
                                        <NavigationTabLinksForReports></NavigationTabLinksForReports>
                                    </div>
                                    <div className="col-lg-2 d-flex justify-content-end align-items-center">
                                        <Button onClick={() => this.setState({ ...this.state, showUploadModal: true })} noMargin >
                                            <>
                                                <i className="fe fe-upload-cloud me-1"></i>
                                                {t("uploadFile")}
                                            </>
                                        </Button>
                                    </div>
                                </div>
                                </>
                            }> 
                                <>
                                <FormFilter
                                    onSubmit={this.getFromBuscar}
                                    model={[this.state.filters, this.setFilters.bind(this)]}
                                    header={
                                    <div className="d-flex align-items-center justify-content-end pt-2" >
                                        <RadioButtonGroup
                                        model={[this.state, this.setState.bind(this)]}
                                        attr="tableMode"
                                        fieldDefinitions={[
                                            { key: TableModeEnum.LIST, value: TableModeEnum.LIST, icon: 'fe fe-list' },
                                            { key: TableModeEnum.GRID, value: TableModeEnum.GRID, icon: 'fe fe-grid' }
                                        ]}
                                        ></RadioButtonGroup>
                                    </div>
                                    }>
                                    <>
                                    <div className="row">
                                        <div className="col-md-4">
                                        <RadioButtonGroup
                                            attr="dateRangeType"
                                            className='radioStyle'
                                            fieldDefinitions={[
                                            { key: 'paymentDate', value: 'PAYMENT_DATE', label: 'paymentDate' },
                                            { key: 'presentationDate', value: 'PRESENTATION_DATE', label: 'presentationDate' }
                                            //,{ key: 'operationDate', value: 'OPERATION_DATE', label: 'operationDate' }
                                            ]}
                                        ></RadioButtonGroup>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <DateField attr="dateRange" label="dateRange" range></DateField>
                                            </div>
                                            <div className="col-md-4">
                                                <SelectField attr='fiscalDocument' label={clientDocument}
                                                            options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}></SelectField>
                                            </div>
                                            <div className="col-md-4">
                                                <SelectField attr='merchantDocument' label={`merchantNumber`}
                                                            options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                            </div>
                                            <div className="col-md-4">
                                                <TextField attr="settlementNumber" label="settlementNumber"></TextField>
                                            </div>
                                            {WebCache.getCurrentUser()?.countryId == 2 ? 
                                            <div className="col-md-4">
                                                <SelectField attr="currency" label="currency"
                                                            options={this.state.currencyOptions}></SelectField>
                                            </div>
                                            : ""}
                                        </div>
                                    </>
                                </FormFilter>
                                    <CustomTable
                                        rowObjects={this.state.settlements}
                                        columnDefinitions={this.columnDefinitions}
                                        config={{
                                            mode: this.state.tableMode,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getSettlements())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                    <div className='row'>
                                    <div className='col-md-6' >
                                    </div>
                                    <div className="col-md-6">
                                        <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6' >
                                    </div>
                                    <div className="col-md-3 buttonExcel">
                                        <Button disabled={this.state.settlements.length === 0} onClick={this.getSettlementsWithCouponsToExcel} noMargin >
                                            {t("exportSettlementsWithCoupons")}
                                        </Button>
                                    </div>
                                    <div className="col-md-3">
                                        <Button disabled={this.state.settlements.length === 0} onClick={this.getSettlementsToExcel} noMargin >
                                            {t("exportSettlements")}
                                        </Button>
                                    </div>
                                </div>
                                </>
                        </Card>
                        </div>
                    </div>
                </>
            </Page>
            )}
        </>
        );
    }
}

export default withTranslation()(PaymentReport);
