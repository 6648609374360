import { ApexOptions } from "apexcharts";
import React from 'react';
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";
import { IPeriodIndicator } from "../../models/IBarChartData";
import { DashboardService } from "../../services/DashboardService";
import Tooltip from '../Tooltip';
import "./chart.css";


interface IProps {
    chartData: IPeriodIndicator
    currencySymbol: string,
    t: any
    title:string,
    infoTooltip: string,
    dateRange: string,
}
interface IState {
    chartData: IPeriodIndicator,
    currencySymbol: string,
    changePercentage: number
    dateRange:string
}

const defaultData: IState = {
    currencySymbol: "UYU",
    changePercentage: 0,
    dateRange: "",
    chartData: DashboardService.createIPeriodIndicator([0,0])

}

class ApprovalRateIndicator extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = defaultData
    }

    chartTemplate: { options: ApexOptions, series: ApexOptions['series'] } = {
        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                }

            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                }
            },
            tooltip: {
                enabled: false
            },
            yaxis: {
                labels: {
                    show: false
                },
                show: false,
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                }
            },
            legend: {
                show: false
            }
        }
    }


    componentWillReceiveProps(nextProps: IProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.chartData !== this.state.chartData) {
            if (nextProps.chartData.countTotals.length < 2) {
                this.setState(defaultData);
                return;
            }

            const totalsDividend = (nextProps.chartData.countTotals[1] | 0) * 100;
            const totalsDivisor = nextProps.chartData.countTotals[0] | 0;
            const totalsDivision = totalsDividend / totalsDivisor
            const changePercentage = (isFinite(totalsDivision) && !isNaN(totalsDivision)) ? totalsDivision : 0;

            this.setState({
                chartData: nextProps.chartData,
                currencySymbol: nextProps.currencySymbol,
                //changePercentage: changePercentage > 0 ? changePercentage + 100 : -100 - changePercentage
                changePercentage: (changePercentage === 100) ? 0 : 100 - changePercentage,
                dateRange: nextProps.dateRange
            });


        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="col-xl-3">
                <div className="card overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex ">
                            <div className="ms-auto text-center">
                                <h2>
                                    {Intl.NumberFormat('es-UY', {  minimumFractionDigits: 0 }).format(Number(this.state.chartData.countTotals[0].toFixed(0)))+"%"}
                                    <Tooltip message={this.props.infoTooltip}>
                                        <IconInfoSVG className={'info-icon'} ></IconInfoSVG>
                                    </Tooltip>

                                </h2>

                                <h3>{this.props.title}</h3>
                                <div hidden={this.state.dateRange=="1year"}>
                                    <i className={"fe " + (this.state.changePercentage < 0 ? "fe-arrow-down text-red" : "fe-arrow-up-circle text-green")}></i>
                                    <span className={"mb-0 number-font " + (this.state.changePercentage < 0 ? "text-red" : "text-green")}>
                                        {Intl.NumberFormat('es-UY', { minimumFractionDigits: 0 }).format(Number(Math.abs(this.state.changePercentage).toFixed(1)))+"% X "+t(this.state.chartData.titleLabel)}</span>
                                </div>
                            </div>

                            <div className="ms-auto">
                                <div className="overlay-chart-panel">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    };
}
export default ApprovalRateIndicator;
