import { AxiosResponse } from 'axios';
import React from 'react';
import Pagination from '../Pagination';
import { withTranslation } from 'react-i18next';
import { TerminalsService } from "../../services/TerminalsService";
import { WebCache } from '../../services/WebCache';
import { IDepartment, ILocation, ITerminal } from '../../models/ITerminal';
import { IGetTerminalsAndLocationsRequest } from '../../models/IGetTerminalsAndLocationsRequest';
import { IBarnchTableData, ITerminalDto } from '../../models/IBarnchTableData';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { MercadoPagoService } from '../../services/MercadoPagoService';
import Swal from 'sweetalert2';
import { IAssociateBranch, Loc } from '../../requests/MPRequest/Register/IAssociateBranch';
import AutocompleteLocation from '../loyalty/AutocompleteLocation';
import "./MercadoPago.css"




const pageSize = 13;
const pageQuantity = 3

interface IProps {
}

interface IState {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    terminals: ITerminal[],
    errors: string[],
    terminalFilter: string,
    departmentFilter: string
    locationFilter: string
    branchFilter: string


    FullAddress: string
    locationName: string
    branchName: string
    branchLinked: boolean,
    showModal: boolean,

    taxPayerName: string,
    position: { lat: number; lng: number; },

    // LO NUEVO

    allBranchs: IBarnchTableData[],

    externalId: string,
    name: string,
    nicknameMp: string,


    allLocations: ILocation[],
    allDepartments: IDepartment[],
    allTerminals: ITerminal[],
    branchsShow: Map<string, string | ITerminal[] | undefined>[],
    LocationsAux: string,
    DepAux: string,
    streetNumber:string,
    cityName:string,
    streetName:string,
    stateName:string,
}


let doQuery = false;


class MercadoPagoPageBranchABM extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);


        this.state = {
            activePage: 1,
            mainPage: 0,
            pages: [1, 2, 3, 4, 5],
            columns: ["Nombre", "Departamento", "Locación", "Terminales", "Vinculado"],
            terminals: [],
            errors: [],
            terminalFilter: "",
            departmentFilter: "",
            locationFilter: "",
            branchFilter: "",
            FullAddress: "",
            locationName: "",
            showModal: false,
            branchName: "",
            taxPayerName: "",
            position: { lat: 0, lng: 0 },
            allBranchs: [],
            allLocations: WebCache.getAllLocations(),
            allDepartments: WebCache.getAllDepartments(),
            allTerminals: WebCache.getAllTerminals(),
            branchsShow: [],
            LocationsAux: "",
            DepAux: "",
            externalId: "",
            name: "",
            nicknameMp: "",
            branchLinked: false,
            streetNumber:"",
            cityName:"",
            streetName:"",
            stateName:"",
        }





    }

    componentDidMount() {
        if (doQuery) {
            doQuery = false;
            this.getBranchs();
        }
    }

    // #region Calls

    //#region GetBranchs
    getBranchRequest = (all: boolean) => {
        const tranWebModel: IGetTerminalsAndLocationsRequest = {
            Department: (this.state.departmentFilter == null || this.state.departmentFilter == "0000") ? "" : this.state.departmentFilter,
            Location: (this.state.locationFilter == null || this.state.locationFilter == "0000") ? "" : this.state.locationFilter,
            Branch: (this.state.branchFilter == null || this.state.branchFilter == "0000") ? "" : this.state.branchFilter,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
        };
        return tranWebModel;
    }
    getBranchs = () => {
        TerminalsService.getAllTerminalsAndLocationsFiltered(this.getBranchRequest(false)).then((response: AxiosResponse<IBarnchTableData[]>) => {
            MercadoPagoService.GetBranchslinked().then((response1: AxiosResponse<string[]>) => {
                response.data.map((b) => b.linked = (response1.data.find((mb) => mb == b.id) ? true : false))
                this.setState({ allBranchs: response.data });
                if (response == null || response.data == null) {
                    this.setState({
                        allBranchs: []
                    })
                    return;
                }
            }).catch(error => {
                this.setState({
                    errors: error.response.data
                });
                this.setState({
                    terminals: []
                })
                console.log('error:', error);
            }
            );
        });
    }
    //#endregion

    getAssociateBranchRequest = () => {
        var loc = new Loc
        loc.latitude = this.state.position.lat
        loc.longitude = this.state.position.lng
        const tranWebModel: IAssociateBranch = {
            externalId: this.state.externalId,
            name: this.state.name,
            location: {
                streetNumber:this.state.streetNumber,
                cityName:this.state.cityName,
                streetName:this.state.streetName,
                stateName:this.state.stateName,
                latitude:this.state.position.lat,
                longitude:this.state.position.lng,
                reference: ""
            },
            nicknameMp: this.state.nicknameMp
        };
        return tranWebModel;
    }

    associateBranch = () => {
        MercadoPagoService.AssociateBranch(this.getAssociateBranchRequest()).then((response: AxiosResponse) => {
            Swal.fire({
                title: "Exito!",
                html: "Sucursal asociada.",
                confirmButtonColor: '#f60',
                showConfirmButton : false,
                timer : 1500,
                timerProgressBar:true,
                
            }).finally(() => { this.clearStates(); this.getBranchs() });

        }).catch(error => {

            Swal.fire({
                title: "Ha ocurrido un error",
                html: "Por favor Comuníquese con soporte.",
                confirmButtonColor: '#f60',
                showConfirmButton : false,
                timer : 1500,
                timerProgressBar:true
            }).finally(() => { this.clearStates(); this.getBranchs() });

            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        )

    }

    unassociateBranch = () => {
        MercadoPagoService.UnassociateBranch(this.getAssociateBranchRequest()).then((response: AxiosResponse) => {
            Swal.fire({
                title: "Exito!",
                html: "Sucursal eliminada.",
                confirmButtonColor: '#f60',
                showConfirmButton : false,
                timer : 1500,
                timerProgressBar:true
            }).finally(() => { this.clearStates(); this.getBranchs() });

        }).catch(error => {

            Swal.fire({
                title: "Ha ocurrido un error",
                html: "Por favor Comuníquese con soporte.",
                confirmButtonColor: '#f60',
                showConfirmButton : false,
                timer : 1500,
                timerProgressBar:true
            }).finally(() => { this.clearStates(); this.getBranchs() });

            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        )
    }

    // #endregion

    //#region Utils


    clearStates = () => {
        this.setState({
            branchLinked: false,
            branchName: "",
            externalId: "",
            FullAddress: "",
            position: { lat: 0, lng: 0 },
            name: "",
            nicknameMp: "",
            showModal:false
        })
    }

    popover = (terminals: ITerminalDto[], t: any) => (
        <Popover style={{ margin: 0 }} id="popover-basic">

            <Popover.Header as="h3">{t("terminals")}</Popover.Header>
            <Popover.Body>
                <ul>
                    {terminals.map((terminal) => {


                        return (
                            <li
                                style={{ borderBottomStyle: "solid", borderBottomWidth: 0.5, textAlign: "center", padding: 2 }}
                            ><b>{terminal.terminalNumber}</b>
                            </li>

                        )
                    })}

                </ul>
            </Popover.Body>
        </Popover >
    );

    TerminalsTooltip = (terminals: ITerminalDto[], t: any) => (
        <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={this.popover(terminals, t)}>
            <button
                className="buttonP btn"
                style={{ borderWidth: 0 }}
            >{t("terminals")}
            </button>
        </OverlayTrigger>
    );



    onSuccessLocation = (res: { fullAddress: string, position: { lat: number, lng: number } }) => {
        this.setState({position:res.position,FullAddress:res.fullAddress});
    }


    //#endregion

    handleChange(event: any) {
    }


    render() {

        // @ts-ignore
        const { t } = this.props;
        return (

            <div className="main-container container-fluid">
                <div className="page-header">
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{t("BranchsManagment")}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div hidden={!this.state.showModal}>
                                        <div className="modal-content modal-content-demo meli-branch-abm-panel">
                                            <div className="modal-header bg-primary">
                                                <h5 className="modal-title text-white"> {t("VBranch")} </h5>
                                                <button aria-label="Close" onClick={() => this.setState({ ...this.state, showModal: false,FullAddress:"",position:{lat:0,lng:0} })} className="btn-close" ><span className='text-white' aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div className="modal-body">
                                                <form autoComplete='off' style={{ textAlign: "left" }}>
                                                    <div className="row">
                                                        <label className="col-form-label">
                                                            <sub>{t("MustEqualMP")}</sub>
                                                            <br />
                                                            <sub>{t("PWFail")}</sub>
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-4 mb-3">
                                                        <input autoComplete='off' placeholder={t("NMercadoPago")} value={this.state.nicknameMp} onChange={(event) => this.setState({ ...this.state, nicknameMp: event.target.value })} autoFocus type="text" className="form-control"  />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <input autoComplete='off' placeholder={t("streetName")} value={this.state.streetName} onChange={(event) => this.setState({ ...this.state, streetName: event.target.value })} autoFocus type="text" className="form-control"  />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <input autoComplete='off' placeholder={t("streetNumber")} value={this.state.streetNumber} onChange={(event) => this.setState({ ...this.state, streetNumber: event.target.value })} autoFocus type="text" className="form-control"  />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <input autoComplete='off' placeholder={t("cityZone")}  value={this.state.cityName} onChange={(event) => this.setState({ ...this.state, cityName: event.target.value })} autoFocus type="text" className="form-control"  />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <input autoComplete='off' placeholder={t("stateName")}  value={this.state.stateName} onChange={(event) => this.setState({ ...this.state, stateName: event.target.value })} autoFocus type="text" className="form-control" />
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 mb-3">
                                                        <label className="col-form-label ">{t("Dir")} : <br /></label>
                                                        <AutocompleteLocation
                                                            onSuccess={this.onSuccessLocation}
                                                            fullAddress={this.state.FullAddress}
                                                            position={this.state.position}
                                                            showCancelButton={false}
                                                        ></AutocompleteLocation>

                                                    </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">

                                                <button className="btn btn-primary"
                                                        onClick={() => {
                                                            this.associateBranch()
                                                        }}
                                                >{t("confirm")}</button>
                                                <button className="btn btn-light" onClick={() => this.setState({ ...this.state, showModal: false,FullAddress:"",position:{lat:0,lng:0} })} data-bs-dismiss="modal">{("close")}</button>
                                            </div>
                                        </div>
                                        <div className="overlay-branch-abm-panel"></div>
                                    </div>

                                    <React.Fragment>
                                        <div className="col-md-2">
                                            <label className="form-label"> {t('department')}</label>
                                            <select
                                                name="departmentFilter"
                                                className="form-control form-select select2"
                                                value={this.state.departmentFilter}
                                                onChange={(event) => this.setState({
                                                    ...this.state,
                                                    departmentFilter: event.target.value,
                                                    locationFilter: this.state.allLocations.filter((l) => {
                                                        if (l.id == "0000")
                                                            return true
                                                        if (event.target.value != "" && event.target.value != "0000")
                                                            return l.departmentId == event.target.value
                                                        else
                                                            return true
                                                    })[0].id,
                                                    branchFilter: "0000"
                                                })}>
                                                {
                                                    this.state.allDepartments.length > 0 &&
                                                    this.state.allDepartments.map(department => <option key={department.id} value={department.id}>{t(department.name)}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label"> {t('location')}</label>
                                            <select name="locationFilter" className="form-control form-select select2"
                                                value={this.state.locationFilter}
                                                onChange={(event) => {
                                                    this.setState({
                                                        ...this.state,
                                                        locationFilter: event.target.value,
                                                        branchFilter: "0000"
                                                    })
                                                }}>
                                                {
                                                    this.state.allLocations.length > 0 &&
                                                    this.state.allLocations.filter((location) => {
                                                        if (location.id == "0000")
                                                            return true
                                                        if (this.state.departmentFilter != "" && this.state.departmentFilter != "0000")
                                                            return location.departmentId == this.state.departmentFilter
                                                        else
                                                            return true
                                                    }).map(location => <option key={location.id} value={location.id}>{t(location.name)}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label"> {t('branch')}</label>
                                            <select name="branchFilter" onBlur={(event) => this.setState({ ...this.state, branchFilter: event.target.value })} className="form-control form-select select2" value={this.state.branchFilter} onChange={(event) => this.setState({ ...this.state, branchFilter: event.target.value })}>
                                                {
                                                    WebCache.getAllBranches().length > 0 &&
                                                    WebCache.getAllBranches().filter((branch, i) => {
                                                        var locF = true
                                                        var depF = true

                                                        if (this.state.departmentFilter != "" && this.state.departmentFilter != "0000")
                                                            depF = branch.departmentId == this.state.departmentFilter
                                                        if (this.state.locationFilter != "" && this.state.locationFilter != "0000")
                                                            locF = branch.locationId == this.state.locationFilter

                                                        return depF && locF || branch.id == "0000"
                                                    }).map(branch => <option selected={true} key={branch.id} value={branch.id}>{t(branch.name)}</option>)
                                                }
                                            </select>
                                        </div>
                                    </React.Fragment>

                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-2">
                                        <br></br>
                                        <br></br>
                                        <div className="">
                                            <button type="button"
                                                className="btn btn-primary mb-3 btn-block" onClick={this.getBranchs}>
                                                {t("consult")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <p />
                                <div className="table-responsive">
                                    <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                                        <thead className="table-primary">
                                            <tr key={"tr-head"}>
                                                {
                                                    this.state.columns.length > 0 &&
                                                    this.state.columns.map(coll => <th key={`li-${coll}`}>{t(coll)}</th>)
                                                }
                                                <th key={`li-action`}>{t("Acción")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.allBranchs.slice(this.state.pages.indexOf(this.state.activePage) * pageSize, (this.state.pages.indexOf(this.state.activePage) * pageSize) + pageSize).map((branch, Tindex) => {



                                                    return (
                                                        <tr key={Tindex} >

                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={branch.nombre + Tindex.toString()} >
                                                                {branch.nombre}
                                                            </td>
                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={branch.id + Tindex.toString()} >
                                                                {branch.departmentName}
                                                            </td>
                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={branch.locationName + branch.id + Tindex.toString()} >
                                                                {branch.locationName}
                                                            </td>
                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={"terminals" + Tindex.toString() + Tindex.toString()} >
                                                                {this.TerminalsTooltip(branch.terminals, t)}
                                                            </td>
                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={"migrated" + Tindex.toString() + branch.id}>
                                                                {branch.linked ?
                                                                    <i style={{ color: "#54B34A" }} className="ion ion-checkmark-round"></i> :
                                                                    <i style={{ color: "red" }} className="ion ion-close-round"></i>}
                                                            </td>
                                                            <td style={{ verticalAlign: "middle", textAlign: "center" }} key={"associate" + Tindex.toString() + branch.id} >
                                                                <button type="button"
                                                                    className="btn btn-primary "
                                                                    style={{ width: "90%" }}
                                                                    onClick={() =>
                                                                        this.setState({ ...this.state, branchLinked: branch.linked, externalId: branch.id, name: branch.nombre },
                                                                            () => this.state.branchLinked ?
                                                                                this.unassociateBranch() :
                                                                                this.setState({ showModal: true })
                                                                        )}

                                                                >
                                                                    {branch.linked ? t("unbind") : t("bind")}
                                                                </button>
                                                            </td >
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <Pagination
                                            pageSize={pageSize}
                                            pageQuantity={pageQuantity}
                                            modifyMainPage={(value: number, newPages: Array<number>) => { this.setState({ mainPage: this.state.mainPage + value, pages: newPages }, () => this.getBranchs()) }}
                                            changePage={(page: number) => this.setState({ activePage: page })}
                                            dataL={this.state.allBranchs.length}
                                            mainPage={this.state.mainPage}
                                        ></Pagination>

                                    </div>
                                    <div className="col-md-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >


            </div >
        )
    };


}
export default withTranslation()(MercadoPagoPageBranchABM);
