import { AxiosResponse } from "axios";
import { IBaseDevice, ICreateDevice } from "../models/ITOTPDevice";
import { ActivateDeviceRequest, DeviceRequest, UnpairOtpDevicesRequest,AuthenticateTotpDevice,GetMFADevices } from "../requests/ITOTPRequest";
import {axiosApiInstance} from "./HttpService";

export class TOTPService {

    public static createDevice(request: DeviceRequest): Promise<AxiosResponse<ICreateDevice>> {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/TOTP/CreateDevice`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static activateDevice(request: ActivateDeviceRequest): Promise<AxiosResponse<IBaseDevice>> {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/TOTP/ActivateDevice`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static unpairOtpDevices(request: UnpairOtpDevicesRequest): Promise<AxiosResponse<IBaseDevice>> {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/TOTP/UnpairOtpDevices/`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static authenticateTotpDevice(request:AuthenticateTotpDevice): Promise<AxiosResponse<IBaseDevice>> {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/TOTP/authenticateTotpDevice`;
        return axiosApiInstance.post (dataURL,request)
    }

    public static getMfaDevices (): Promise <AxiosResponse<IBaseDevice>>{
        let dataURL: string= `${process.env.REACT_APP_API_URL}/api/TOTP/GetMFADevices`;
        return axiosApiInstance.post (dataURL)
    }
}