import React, {ChangeEvent, Component, RefObject} from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import { TFunction, withTranslation } from "react-i18next";
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";
import { ReactComponent as IconPrintSVG } from "../../../node_modules/itd-common/assets/icons/print.svg";
import { ReactComponent as IconSettingSVG } from "../../../node_modules/itd-common/assets/icons/settings.svg";
import { FiscalSettlementService } from '../../services/FiscalSettlementService';
import { UtilService } from '../../services/UtilService';
import ErrorsView from '../ErrorsView';
import Card from '../containers/card';
import FormFilter from '../containers/form-filter';
import Page from '../containers/page';
import Button from '../controls/buttons/button';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import NavigationTabLinksForSettlements from './NavigationTabLinksForSettlements';
import { ColumnDefinition, actionColumnDefinition, currencyColumnDefinition, currencyTypeColumnDefinition, dateColumnDefinition, numericColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import CheckBoxField from '../controls/fields/checkBoxField';
import Tooltip from '../Tooltip';
import { IDownloadMonthlyESettlement, IMonthlySettlementsRequest } from '../../requests/ISettlementsRequest';
import { IMonthlySettlements, IMonthlySettlement, ISettlementHTMLSelectProduct } from '../../models/ISettlementData';
import { AxiosResponse } from 'axios';
import { Option } from '../../models/Option';
import { toast } from 'react-toastify';
import CustomReCAPTCHA from '../CustomCaptcha';
import {WebCache} from "../../services/WebCache";
import './Settlement.scss'
import {OptionMapperHelper} from "../../helpers/OptionMapperHelper";
import {UserService} from "../../services/UserService";
import i18next from "i18next";
import ReactToPrint from "react-to-print";

interface IProps {
    t: TFunction    
}

interface IFilters {
    period: number,
    //accountNumber: number,
    fiscalDocument: string;
    merchantDocument: string;
    //amount: number,
    //type: string,
    currency: string,
    brand: string
}

interface IState {
    mainPage: number,
    pageRequest: IMonthlySettlementsRequest,
    monthlySettlements: IMonthlySettlements,
    errors: string[],
    columnDefinitions: Array<ColumnDefinition>,
    filters: IFilters,
    periodOptions: Array<HTMLOptionElement>,
    brandOptions: Array<HTMLOptionElement>,
    currencyOptions: Array<HTMLOptionElement>,
    isModalOpen: boolean,
    hiddenColumnsMap: Map<string, boolean>,
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    isCaptchaVerified:boolean
}

const T_PREFIX: string = 'pages.settlementsByMonth.';
let lastFiscalDocument:string=""
class SettlementsByMonth extends Component<IProps, IState> {
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private componentRef:RefObject<any> = React.createRef();
    constructor(props: IProps) {
        super(props);

        const periodOptions: Array<HTMLOptionElement> = this.props.t(`${T_PREFIX}months`, { 
            returnObjects: true 
        });        
        const brandOptions: Array<HTMLOptionElement> = [];
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();
        const currencyFilter: string = currencyOptions[0].value;
        const typeOptions: Array<HTMLOptionElement> = this.props.t(`${T_PREFIX}tipos`, { 
            returnObjects: true 
        });
        const type: string = typeOptions[0].value;
        
        let columnDefinitions = [
            dateColumnDefinition({ key: 'period', label: `${T_PREFIX}columns.period`, header: true, mapValue: (settlement: IMonthlySettlement) => {
                if (!settlement.period) {
                    return '';
                }
                const period: string = settlement.period.toString();
                const year: string = period.substring(0, 4);
                const month: string = period.substring(4, 6);
                return `${month}/${year}`;
            } }),
            textColumnDefinition({ key: 'productDesc', label: `${T_PREFIX}columns.productDesc`, header: true, mapValue: (settlement: IMonthlySettlement) => {
                const product= settlement.productDesc;
                if (product == "Visa Débito"){
                    return <div>
                                <img src={UtilService.getImageTag(product)} alt={`${product}`}/>
                                <p >Débito</p>
                            </div>  ;    
                }else if (product == "Visa Crédito"){
                    return <div>
                                <img src={UtilService.getImageTag(product)} alt={`${product}`}/>
                                <p>Crédito</p>
                            </div>  ;                  
                } else {
                return <img src={UtilService.getImageTag(product.toUpperCase())} alt={`${product}`} ></img>;
            }
            }}),
            numericColumnDefinition({ key: 'merchantNumber', label: `${T_PREFIX}columns.merchantNumber`, header: true }),
            currencyTypeColumnDefinition({ key: 'currencyCode', label: `${T_PREFIX}columns.currencyCode`, header: true }),
            //currencyColumnDefinition({ key: 'grossAmount', label: `${T_PREFIX}columns.grossAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            //currencyColumnDefinition({ key: 'discountAmount', label: `${T_PREFIX}columns.discountAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            //currencyColumnDefinition({ key: 'netAmount', label: `${T_PREFIX}columns.netAmount`, header: true, columnDefinitionKey:'currencyCode' }),
            actionColumnDefinition({ key: 'download', label: `${T_PREFIX}columns.download`, header: true, action: { function: (rowObject: IMonthlySettlement) => {
                //console.log("Download...");
                toast.info(this.props.t("settlementsDownloadFileNotification"));
                this.getDowloadESettlement(rowObject.id);
            }, icon: <IconDownloadSVG className="icon-download"></IconDownloadSVG> } }),
        ];

        const hiddenColumnsMap: Map<string, boolean> = new Map();
        columnDefinitions.forEach(columnDefinition => hiddenColumnsMap.set(columnDefinition.key, columnDefinition.hidden || false));

        this.state = {
            mainPage: 0,
            pageRequest: {
                AccountNumber: '',
                MerchantDocument: '',
                From: '',
                To: '',
                Type: '',
                Amount:'',
                Currency: '',
                Skip: 0,
                Take: 0,
                MerchantNumber: '',
                Brand: ''
            },
            monthlySettlements: {
                monthlySettlements: [] as IMonthlySettlement[],
                totalNetAmount: '',
                totalNetAmountUS: '',
                recordsNumber: ''
            } as IMonthlySettlements,
            errors: [],
            columnDefinitions,
            filters: {
                //period: 1,
                //accountNumber: 0,
                //amount: 0,
                //type,
                //currency: currencyFilter
                period: 1,
                fiscalDocument: WebCache.getAllMerchantDocuments(false)[0]?.value+"",
                merchantDocument: "",
                currency: currencyFilter,
                brand:''
            },            
            periodOptions,
            brandOptions,
            currencyOptions,
            isModalOpen: false,
            hiddenColumnsMap,
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false)[0]?.label,true),
            fiscalDocuments:WebCache.getAllMerchantDocuments(false),
            isCaptchaVerified:false
        };    
        this.getProducts();    
    }

    getCustomMonths = (): Array<Option>=>{            
        let date: Date = new Date();            
        let monthValues: Array<Option> = [];

        for(var i = 0; i <= 6; i++){
            let month: number = date.getMonth();           
            let text = this.state.periodOptions[month].label + "-" + date.getFullYear();  
            let period = date.getFullYear() + "-" + this.state.periodOptions[month].value;
            let option: Option = {
            value: period,
            label: text
            };
            
            monthValues.push(option);
            date.setMonth(date.getMonth() -1);
        }

        return monthValues;
    }

    downloadGrid = () => {
        let fileName="Liquidación mensual.csv";
        UserService.getCsvOnline(this.state.columnDefinitions.filter(x=>x.action==null).map(x=>i18next.t(x.label+"")).join(";")+"\r\n"+
            // @ts-ignore
            this.state.monthlySettlements.monthlySettlements.map(y=>this.state.columnDefinitions.map(x=>y[x.key]).join(";")).join("\r\n"), fileName).then((response: AxiosResponse<any>) => {
            //const outputFilename = `TerminalsReport${Date.now()}.xlsx`;
            // @ts-ignore
            /*let outputFilename = response.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .trim();*/
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
        }).catch(error => {
            console.log('error:', error);
        });
    }

    getDownloadMonthlyESettlementRequest = (fileName: string): IDownloadMonthlyESettlement => {
        const result: IDownloadMonthlyESettlement = {
            FileName: fileName
        };
        return result;
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    handleClickProofOfWithholdings = (event: ChangeEvent<HTMLInputElement>) => {
        if (this.state.isCaptchaVerified) {
            toast.info(this.props.t("settlementsDownloadFileNotification"));

            const selectYear: number = Number(this.state.filters.period
                .toString()
                .substring(0, 4));
            const selectMonth: number = Number(this.state.filters.period
                .toString()
                .substring(5));
            FiscalSettlementService.downloadProofOfWithholdings({FiscalDocument:this.state.filters.fiscalDocument,Year:selectYear,Month:selectMonth})
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({  errors: ["Error obteniendo datos del servidor"] });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    }

    getDowloadESettlement = (fileName: string) => {
        if (this.state.isCaptchaVerified) {
            FiscalSettlementService.getDowloadMonthlyESettlement(this.getDownloadMonthlyESettlementRequest(fileName))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error: any) => {
                    this.setState({  errors: ["Error obteniendo datos del servidor"], });
                    console.log('error:', error);
                });
    } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };

    getRequest = (all: boolean): IMonthlySettlementsRequest => {
        //const currentYear: number = new Date().getFullYear();
        //const currentYear: number = 2023;
        const selectYear: number = Number(this.state.filters.period
                                        .toString()
                                        .substring(0, 4));
        const selectMonth: number = Number(this.state.filters.period
                                            .toString()
                                            .substring(5));
        //const firstDayOfTheMonth: Date = new Date(selectYear, this.state.filters.period - 1, 1);
        //const lastDayOfTheMonth: Date = new Date(selectYear, this.state.filters.period, 0);
        const firstDayOfTheMonth: Date = new Date(selectYear, selectMonth - 1, 1);
        const lastDayOfTheMonth: Date = new Date(selectYear, selectMonth, 0);

        const tranWebModel: IMonthlySettlementsRequest = {
            AccountNumber: "", //this.state.filters.accountNumber.toString(),
            Brand: this.state.filters.brand,
            MerchantDocument: this.state.filters.fiscalDocument.toString(),
            MerchantNumber: this.state.filters.merchantDocument.toString(),
            From: firstDayOfTheMonth.toJSON(),
            To: lastDayOfTheMonth.toJSON(),
            Amount:"",
            Type: "", //this.state.filters.type,
            Currency: this.state.filters.currency,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
        return tranWebModel;
    }

    getLiquidations = () => {
        FiscalSettlementService.getMonthlySettlements(this.getRequest(false)).then((response: IMonthlySettlements) => {        
            this.setState({...this.state,
                monthlySettlements: response,
                errors: []
            });
        }).catch((error: any) => {
            this.setState({...this.state,
                errors: ["Error obteniendo datos del servidor"],
                monthlySettlements: {
                    monthlySettlements: [] as IMonthlySettlement[],
                    totalNetAmount: '',
                    totalNetAmountUS: '',
                    recordsNumber: ''
                } as IMonthlySettlements
            })
        });
    }

    getProducts = () => {
        FiscalSettlementService.getAllProducts()
                            .then((response: Array<HTMLOptionElement>) => {     
                                
                                const item: HTMLOptionElement = {
                                    value: "",
                                    label: "Todas"
                                } as HTMLOptionElement;
                                response = [item, ...response];   
            this.setState({
                ...this.state,
                brandOptions: response
            });

        }).catch((error: any) => {   
        });         
    }

    searchFilter = () => {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.getLiquidations();
        });
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    clearFilter = () => {
        this.setFilters({
            ...this.state.filters,
            period: 1,
            fiscalDocument: WebCache.getAllMerchantDocuments(false)[0]?.value+"",
            merchantDocument: "",
            currency: '',
            brand:''
        });
    }

    handleClickModal = () => {
        this.setState({
            ...this.state, isModalOpen: !this.state.isModalOpen
        })
    }


    handleClickOptionModal = (event: ChangeEvent<HTMLInputElement>) => {
    let values: Map<string, boolean> = this.state.hiddenColumnsMap;
        values.set(event.target.id, !values.get(event.target.id));
        this.setState({
            ...this.state, hiddenColumnsMap: values
        });
    }

    changeColumnsTable = () => {
        let columnDefinitions: Array<ColumnDefinition> = [...this.state.columnDefinitions];
        this.state.columnDefinitions.forEach((columnDefinition, columnDefinitionIndex) => {
            columnDefinitions[columnDefinitionIndex].hidden = this.state.hiddenColumnsMap.get(columnDefinition.key);
        });
        
        this.setState({...this.state, columnDefinitions, isModalOpen: false});
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.filters.fiscalDocument){
            lastFiscalDocument=this.state.filters.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true)
            this.setState({...this.state,filters:{...this.state.filters,merchantDocument:merchantNumbers[0].value},merchantNumbers:merchantNumbers})
        }


    }

    renderProofOfWithholdings = () => {
        const { t } = this.props;

        const year: string = new Date().getFullYear().toString();
        const month: string = this.state.filters.period?.toString().padStart(2, '0');
        const formattedPeriod: string = `${month}/${year}`;

        return (
            <div className='mt-6'>
                <h1>Constancia de Retenciones</h1>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <table>
                                <tbody>
                                    <tr className="text-gray" >
                                        <td className="table-row p-4">{t(`${T_PREFIX}proofOfWithholdingsColumns.period`)}</td>
                                        <td className="table-row p-4">{t(`${T_PREFIX}proofOfWithholdingsColumns.description`)}</td>
                                        <td className="table-row p-4">{t(`${T_PREFIX}proofOfWithholdingsColumns.download`)}</td>
                                    </tr>
                                    {
                                        this.state.monthlySettlements && this.state.monthlySettlements.monthlySettlements.length > 0 ?
                                            <tr className="text-strong">
                                                <td className="table-row p-4">{formattedPeriod}</td>
                                                <td className="table-row p-4">{t(`${T_PREFIX}proofOfWithholdingsColumns.descriptionText`)}</td>
                                                <td className="table-row p-4">
                                                    <a href={window.location.href} onClick={(event: any) =>  this.handleClickProofOfWithholdings(event) }>
                                                        {t(`${T_PREFIX}proofOfWithholdingsColumns.downloadLink`)}
                                                    </a>
                                                </td>
                                            </tr>
                                        :
                                            <></>
                                    }
                                </tbody>
                            </table>
                        </Accordion.Header>
                    </Accordion.Item>
                </Accordion>
            </div>
        );
    }   

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let currency;
        let merchantDocument;

        if (currentUser !== undefined && currentUser === 2) {
            currency =<div className="col-md-4"><SelectField attr='currency' label='currency' options={this.state.currencyOptions} ></SelectField></div>
            merchantDocument = `${T_PREFIX}merchantDocumentUY`
        } else {
            currency = '';
            merchantDocument = `${T_PREFIX}merchantDocumentARG`
        }	
        
        return (
            <Page>
                <>
                    <h2>{t(`settlements`)}</h2>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row" ref={this.componentRef}>
                        <div className="col-lg-12">
                            <div className="col-lg-12 mt-2">
                                <NavigationTabLinksForSettlements></NavigationTabLinksForSettlements>
                            </div>
                            <FormFilter
                                onSubmit={this.searchFilter}
                                clear={this.clearFilter}
                                model={[this.state.filters, this.setFilters.bind(this)]}>
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-4">
                                            <SelectField attr="period" label={t(`${T_PREFIX}period`)}
                                                        options={this.getCustomMonths()}></SelectField>
                                        </div>
                                        <div className="col-md-4">
                                            <SelectField attr='fiscalDocument' label={merchantDocument}
                                                        options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}></SelectField>
                                        </div>
                                        <div className="col-md-4">
                                            <SelectField attr='merchantDocument' label={`${T_PREFIX}merchantNumber`}
                                                        options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}></SelectField>
                                        </div>
                                        <div className="col-md-4">
                                            <SelectField attr='brand' label={t(`${T_PREFIX}brand`)}
                                                        options={this.state.brandOptions}></SelectField>
                                        </div>
                                        {currency}
                                    </div>
                                </>
                            </FormFilter>
                            <nav className="nav mt-3">
                                <Button color="transparent" className="border-0 mt-0"
                                        onClick={() => this.downloadGrid()} noBlock>
                                    <>
                                        <IconDownloadSVG></IconDownloadSVG>
                                        <span className='ms-2 text-dark'>{t(`common.action.downloadCsv`)}</span>
                                    </>
                                </Button>
                                <ReactToPrint
                                    trigger={() => <Button color="transparent" className="border-0 mt-0" noBlock>
                                        <>
                                            <IconPrintSVG></IconPrintSVG>
                                            <span className='ms-2 text-dark'>{t(`common.action.print`)}</span>
                                        </>
                                    </Button>}
                                    content={() => this.componentRef.current}
                                />
                                <Button color="transparent" className="border-0 mt-0" noBlock
                                        onClick={() => this.handleClickModal()}>
                                    <>
                                        <IconSettingSVG></IconSettingSVG>
                                        <span className='ms-2 text-dark'>{t(`common.action.tableConfig`)}</span>
                                    </>
                                </Button>
                                {/*<span className="navbar-text ms-auto col-md-3 fs-5">
                                        <Tooltip message={t(`${T_PREFIX}netTotalInfoTooltip`)}>
                                            <IconInfoSVG className='d-inline-block'></IconInfoSVG>
                                        </Tooltip>
                                        <p className='ms-2 d-inline-block net-total'>
                                            {t(`common.business.netTotal`)}: {t(`common.amount`, { symbol: t(`common.currencySymbols.uy`), value: this.state.monthlySettlements.totalNetAmount })}  | {t(`common.amount`, { symbol: t(`common.currencySymbols.us`), value: this.state.monthlySettlements.totalNetAmountUS })}
                                        </p>
                                    </span>*/}
                            </nav>
                            <div className='row'>
                                <div className="col-md-12">
                                    <CustomTable
                                        rowObjects={this.state.monthlySettlements.monthlySettlements}
                                        columnDefinitions={this.state.columnDefinitions}
                                        config={{
                                            mode: TableModeEnum.LIST,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getLiquidations())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                </div>
                                <div className="col-md-6">
                                    <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                </div>
                            </div>
                            {this.renderProofOfWithholdings()}
                        </div>
                    </div>
                    <Modal show={this.state.isModalOpen} size='lg'>
                        <Card>
                            <>
                                <div className='mb-2'>
                                    <h2>{t(`${T_PREFIX}tableConfigTitle`)}</h2>
                                    <h6>{t(`${T_PREFIX}tableConfigDescription`)}</h6>
                                </div>
                                <div className="row">
                                    { 
                                        Array.from(this.state.hiddenColumnsMap).map((entry: [string, boolean]) => {
                                            const [key, value]: [string, boolean] = entry;
                                            return (
                                                <div className="col-md-4" key={key} >
                                                    <CheckBoxField id={key} checked={!value} label={`${T_PREFIX}columns.${key}`} onChange={(event) => this.handleClickOptionModal(event)} ></CheckBoxField>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='d-flex flex-row-reverse gap-4'>
                                    <Button className='mt-2 ps-8 pe-8' label='accept' onClick={() => this.changeColumnsTable()} noBlock/>
                                    <Button className='mt-2 ps-8 pe-8' color='white' label='cancel' onClick={() => this.setState({...this.state, isModalOpen: false})}  noBlock />
                                </div>
                            </>
                        </Card>
                    </Modal>
                </>
            </Page>
        )
    }
}
export default withTranslation()(SettlementsByMonth);