import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import "./FileUpload.css"
import {useTranslation} from "react-i18next";
interface IProps {
    onCancel: () => void,
    onSuccess: (formData:File) => void,
}


let FileUploader: React.FC<IProps> = (props) => {

    function onSuccess(){
        props.onSuccess(acceptedFiles[0])
    }

    function callback(file:any) {

    }
    const { t } = useTranslation();

    const onDrop = useCallback(callback, [])
    const {acceptedFiles,getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const files = acceptedFiles.map(file => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    ));

    return (
        <div className="modal-content modal-content-demo">
            <div className="modal-header bg-primary">
                <h5 className="modal-title text-white"> {t("uploadFile")}  </h5>
                <button aria-label="Close" className="btn-close" onClick={() => props.onCancel()}><span className='text-white' aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                <div className="col-md-12 mb-3">
                    <div className="table-responsive">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-demo">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        <div className="file-uploader">

                                        </div>
                                    }
                                    <aside>
                                        <ul>{files}</ul>
                                    </aside>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button disabled={acceptedFiles.length==0} onClick={() => onSuccess()} className="btn ripple btn-primary" type="button">{t("Add")}</button>
                <button onClick={() => props.onCancel()} className="btn btn-light" data-bs-dismiss="modal">{t("cancel")}</button>
            </div>
        </div>

    );
}
export default FileUploader;
