import { useState } from "react";
import { IPropsInputField } from "./fieldPropertiesInterface";
import InputField from "./inputField";
import "./passwordField.scss";

function PasswordField({
  model,
  attr,
  value,
  label,
  onChange,
  required,
  autoFocus
}: IPropsInputField) {
  const [showPasswordEnabled, setShowPasswordEnabled] = useState(false);
  return (
    <InputField
        type={showPasswordEnabled ? 'text' : 'password'}
        attr={attr}
        value={value}
        label={label}
        model={model}
        onChange={onChange}
        required={required}
        autoFocus={autoFocus}
        sufix={
          <a href={window.location.href} className="password-field-sufix input-group-text right h-100" onClick={() => {setShowPasswordEnabled(!showPasswordEnabled);}}>
              <i className={`eye-icon zmdi zmdi-eye${showPasswordEnabled ? '' : '-off'} text-muted`} ></i>
          </a>
        }
    ></InputField>
  );
}

export default PasswordField;