import React, {RefObject} from 'react';
import './PaymentCalendar.css'
import {withTranslation} from "react-i18next";
import {
    ICalendarTotals,
} from "../../models/ISettlementData";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import Accordion from "react-bootstrap/Accordion";
import {Card} from "react-bootstrap";
import AccordionBody from "react-bootstrap/AccordionBody";
import {CurrencyHelper} from "../../helpers/CurrencyHelper";



interface IState {
    settlementAccordionRef:RefObject<any>
    pressentedAccordionRef:RefObject<any>
    balanceAccordionRef:RefObject<any>

}

interface IProps{
    title: string,
    totals: ICalendarTotals
}
class CalendarTotal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {

            settlementAccordionRef:React.createRef(),
            pressentedAccordionRef:React.createRef(),
            balanceAccordionRef:React.createRef(),
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {


    }


    render() {

        // @ts-ignore
        const { t } = this.props;
        return (
             <div className="card">
                <div className="card-header">
                    <h2>{this.props.title}</h2>
                </div>
                <div className="card-body calendar-side-acordions">
                    <Accordion defaultActiveKey="none">
                        <AccordionHeader className="col-md-12" ref={this.state.pressentedAccordionRef} >
                            <ul>
                                <li className="calendar-side-acordions-title">{t("presentedCalendarTitle")}</li>
                                <li className="calendar-side-acordions-amount">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency) }).format(this.props.totals.presentedAmount)}</li>
                            </ul>
                        </AccordionHeader>
                        <AccordionBody>
                            <table className="table calendar-total-detail">
                                <tbody>
                                <tr>
                                    <td>{t("grossAmount")}</td>
                                    <td>{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.presentedGrossAmount)}</td>
                                </tr>
                                <tr className="bg-gray-lightest">
                                    <td>{t("otherCharges")}</td>
                                    <td className="text-danger">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.presentedChargeAmount)}</td>
                                </tr>
                                <tr>
                                    <td>{t("contrapartsAmount")}</td>
                                    <td className="text-danger">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.presentedContapartAmount)}</td>
                                </tr>
                                <tr className="bg-gray-lightest">
                                    <td>{t("othersDiscountsAmount")}</td>
                                    <td className="text-danger">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.presentedOthersAmount)}</td>
                                </tr>
                                <tr>
                                    <td>{(t("total")+"").toUpperCase()}</td>
                                    <td >{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency) }).format(this.props.totals.presentedAmount)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </AccordionBody>
                    </Accordion>
                    <Accordion defaultActiveKey="filter">
                        <AccordionHeader className="col-md-12" ref={this.state.settlementAccordionRef} >
                            <ul>
                                <li className="calendar-side-acordions-title">{t("settlementCalendarTitle")}</li>
                                <li className="calendar-side-acordions-amount">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency) }).format(this.props.totals.settlementAmount)}</li>
                            </ul>
                        </AccordionHeader>
                        <AccordionBody>
                            <table className="table calendar-total-detail">
                                <tbody>
                                <tr>
                                    <td>{t("grossAmount")}</td>
                                    <td>{t(Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementGrossAmount)+" ")}</td>
                                </tr>
                                <tr className="bg-gray-lightest">
                                    <td>{t("otherCharges")}</td>
                                    <td className="text-danger">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementChargeAmount)+" "}</td>
                                </tr>
                                <tr>
                                    <td>{t("contrapartsAmount")}</td>
                                    <td className="text-danger">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementContapartAmount)}</td>
                                </tr>
                                <tr className="bg-gray-lightest">
                                    <td>{t("othersDiscountsAmount")}</td>
                                    <td className="text-danger">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementOthersAmount)}</td>
                                </tr>
                                <tr>
                                    <td>{t("taxAmount")}</td>
                                    <td className="text-danger">{"-"+Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementTaxAmount)}</td>
                                </tr>
                                <tr>
                                    <td>{(t("total")+"").toUpperCase()}</td>
                                    <td >{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementAmount)+" "}</td>
                                </tr>
                                </tbody>
                            </table>
                        </AccordionBody>
                    </Accordion>
                    <Accordion defaultActiveKey="filter">
                        <AccordionHeader className="col-md-12" ref={this.state.balanceAccordionRef} >
                            <ul>
                                <li className="calendar-side-acordions-title">{t("balanceCalendarTitle")}</li>
                                <li className="calendar-side-acordions-amount">{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.balanceAmount)}</li>
                            </ul>
                        </AccordionHeader>
                        <AccordionBody>
                            <table className="table calendar-total-detail">
                                <tbody>
                                <tr>
                                    <td>{t("presentedCalendarTitle")}</td>
                                    <td>{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.presentedAmount)+" "}</td>
                                </tr>
                                <tr className="bg-gray-lightest">
                                    <td>{t("settlementCalendarTitle")}</td>
                                    <td>{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.settlementAmount)+" "}</td>
                                </tr>
                                <tr>
                                    <td>{(t("balanceCalendarTitle")+"").toUpperCase()}</td>
                                    <td >{Intl.NumberFormat('es-UY', { style: 'currency',currency:CurrencyHelper.getCurrencySymbol(this.props.totals.currency)  }).format(this.props.totals.balanceAmount)+" "}</td>
                                </tr>
                                </tbody>
                            </table>
                        </AccordionBody>
                    </Accordion>
                </div>
            </div>
        )
    };



}
export default withTranslation() (CalendarTotal);
