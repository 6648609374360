
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import i18next from "i18next";
import { ChangeEvent, Component } from 'react';
import {Bar, Pie} from 'react-chartjs-2';
import { IPieChartData } from "../../models/IPieChartData";
import { UtilService } from "../../services/UtilService";
import Card from '../containers/card';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import { innerBarText } from "../../services/DashboardService";
import './chart.css';

ChartJS.register(ArcElement, Tooltip, Legend);



interface IProps {
    chartData: IPieChartData,
    bgColor: string,
    title: string,
    name: string
    currencySymbol: string
}
interface IState{
    chartData: any,
    showDetail: boolean,
    dataType: string,
    show: boolean,
    issuersByTotal: [],
    tranId: string,
    currencySymbol:string
}
interface graftData{
    labels: string[],
    datasets: DataSets[]
}
interface DataSets{
    data: number[],
    backgroundColor: string[]
}

class RankingChart extends Component<IProps, IState> {
    private data: graftData= {
        datasets: [{
            data: [],
            backgroundColor: []
        }],
        labels: []
    }
    private options:any
    constructor(props: IProps) {
        super(props);
        this.state = {
            show: false,
            showDetail: false,
            issuersByTotal: [],
            tranId: "",
            chartData: null,
            dataType: "amount",
            currencySymbol:props.currencySymbol
        };

        this.options = {
            indexAxis: 'y' as const,
            elements: {
                bar: {
                    borderWidth: 0,
                }
            },
            scales:{
                y: {
                    display: false,
                    grid: {
                        display: false
                    }
                },
                x: {
                    ticks:{
                        callback: this.ticksCallback
                    },
                    grid: {
                        display: false
                    }
                }
            },
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                tooltip: {
                    bodyFont: {
                        size: 20
                    }
                }
            }
        };

    }

    ticksCallback=(value:any, index:any, values:any)=> {

        try{
            if(this.state.dataType=="amount") {
                return Intl.NumberFormat('es-UY', {
                    style: 'currency',
                    currency: this.state.currencySymbol,
                    maximumFractionDigits: 0
                }).format(parseInt(value));
            }
        }catch(e){}
        return value;
    }

    componentWillReceiveProps(nextProps:IProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.chartData !== this.state.chartData) {
            this.setState({ chartData: nextProps.chartData,currencySymbol:nextProps.currencySymbol });

            this.recalculateChart(nextProps,this.state.dataType,this.state.showDetail);
        }else
            this.setState({ currencySymbol:nextProps.currencySymbol });
    }

    recalculateChart(nextProps: IProps, dataType: string, showDetail: boolean) {

        let data={
            labels:[],
            datasets: [{
                data: [],
                backgroundColor: []
            }],
        };
        let values: Array<{ index: number, val: never, label: never }> = [];
        if(dataType === "amount")
            values = nextProps.chartData.values
                .map((r, index) => { return { index, val: r, label: nextProps.chartData.labels[index] }; });
        else
            values = nextProps.chartData.counts
                .map((r, index) => { return { index, val: r, label: nextProps.chartData.labels[index] }; }) as never[];

        values.sort((x1,x2)=>{return Number(x1.val)>Number(x2.val)?-1:1});

        if(values.length > 5 && !showDetail)
            values.splice(5,values.length-5);

        data.datasets[0].data = values.map(r => r.val);
        data.labels = values.map(r => r.label);

        data.datasets[0].backgroundColor = data.datasets[0].data.map(t => nextProps.bgColor ) as never[];
        this.data= data;


    }

    render() {
        return (
            <div className="col-lg-4" >
                <Card  title={this.props.name} actions={
                    <RadioButtonGroup
                        name={this.props.name}
                        model={[ this.state, this.setState.bind(this) ]}
                        attr="dataType"
                        className='buttonDashboard'
                        fieldDefinitions={[
                            { key: 'quantity', value: 'quantity', label: 'quantity' },
                            { key: 'amount', value: 'amount', label: 'amount' }
                        ]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.recalculateChart(this.props, event.target.value, this.state.showDetail)}
                    ></RadioButtonGroup>
                }>
                    <>
                        <Bar id="Bar" redraw={true} plugins={[innerBarText]} options={this.options} data={this.data} className="h-300"/>
                        {

                            (!this.state.showDetail) ?
                                <div className="chart-detail-button" onClick={()=>this.recalculateChart(this.props,this.state.dataType,true)}>
                                    <a href={window.location.href}>
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </a>
                                </div>
                                :
                                <div className="chart-detail-button" onClick={()=>this.recalculateChart(this.props,this.state.dataType,false)}>
                                    <a href={window.location.href}>
                                        <i className="fa-solid fa-chevron-up"></i>
                                    </a>
                                </div>

                        }
                    </>
                </Card>
            </div>
        )
    };
}
export default RankingChart;
