import React from 'react';
import './ChartConciliation.scss';
import { ArcElement, Chart as ChartJS, Tooltip as ChartJSTooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { withTranslation } from "react-i18next";
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";
import { IPeriodIndicator } from "../../models/IBarChartData";
import { IPieChartData } from "../../models/IPieChartData";
import { DashboardService, innerVertBarText } from "../../services/DashboardService";
import Tooltip from '../Tooltip';

ChartJS.register(ArcElement, ChartJSTooltip, Legend);
const T_PREFIX: string = 'pages.concilationDashboard.';


interface IProps{
    chartData:IPeriodIndicator,
    currencySymbol:string,
    shortPeriodChartData:IPeriodIndicator,
    infoTooltip: string,
    dateRange:string
}

interface IState {
    chartData: IPeriodIndicator,
    currencySymbol: string,
    changePercentage: number
    dateRange:string
}

const defaultData: IState = {
    currencySymbol: "UYU",
    changePercentage: 0,
    dateRange:"",
    chartData: DashboardService.createIPeriodIndicator([0,0])

}


class ContraChargeAmountChart extends React.Component<IProps> {

    options = DashboardService.getBarChartOptions();

    data = {
        labels: [""],
        datasets: [{
            label:"",
            data: [],
            backgroundColor: []
        }]
    };

    state = {
        chartData:null,
        changePercentage: 0,
        dateRange:"",
        shortPeriodChartData: {
            titleLabel: "",
            total: 0,
            periodTotals: [0, 0],
            countTotals: [0, 0],
            promTotals: [0, 0],
            rows: [{
                label: "",
                color: "",
                values: []
            }],
            labels: [],
            currentPeriodLabel: "-",
            lastPeriodLabel: "-",

        },
        showDetail:false
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (!nextProps.chartData) {
            return;
        }
                 
        if (nextProps.chartData !== this.state.chartData) {
            if (nextProps.chartData.chargeAmountTotals && nextProps.chartData.chargeAmountTotals.length < 1) {
                this.setState(defaultData);
                return;
            }

            var series = [];
            var firstIndex = 10000;
            var lastIndex = -1;

            nextProps.chartData.rows.forEach(row => {
                row.values.forEach((value, index) => {
                    if (value != 0) {
                        if (firstIndex > index)
                            firstIndex = index;

                        if (lastIndex < index)
                            lastIndex = index;
                    }
                });
            });

            if (firstIndex < nextProps.chartData.rows[0].values.length)
                lastIndex -= firstIndex;

            nextProps.chartData.rows.forEach(row => {
                if (firstIndex < row.values.length)
                    row.values.splice(0, firstIndex)
                if (lastIndex > 0)
                    row.values.splice(lastIndex, row.values.length - lastIndex)
            });



            for (var i = 0; i < nextProps.chartData.rows.length; i++) {
                series.push({
                    name: nextProps.chartData.rows[i].label,
                    data: nextProps.chartData.rows[i].values,
                    color: nextProps.chartData.rows[i].color
                });
            }

            const totalsDividend = (nextProps.chartData.credVouchAmountTotals[1] | 0) + (nextProps.chartData.contrapartAmountTotals[1] | 0) * 100;
            const totalsDivisor = (nextProps.chartData.credVouchAmountTotals[0] | 0) + (nextProps.chartData.contrapartAmountTotals[0] | 0);
            const totalsDivision = totalsDividend / totalsDivisor
            const changePercentage = (isFinite(totalsDivision) && !isNaN(totalsDivision)) ? totalsDivision : 0;

            this.setState({
                chartData: nextProps.chartData,
                currencySymbol: nextProps.currencySymbol,
                changePercentage: (changePercentage === 100) ? 0 : 100 - changePercentage,
                dateRange: nextProps.dateRange
            });


        }
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
                <>
                        <div className="row">
                        {this.state.shortPeriodChartData.periodTotals.length>0?
                            <div className="col-sm-8">
                                <h2 hidden={this.state.shortPeriodChartData.periodTotals[0]==null}>
                                {Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, minimumFractionDigits: 0 }).format(Number((this.props.shortPeriodChartData.credVouchAmountTotals[0]+this.props.shortPeriodChartData.contrapartAmountTotals[0]).toFixed(0)))}
                                    <Tooltip message={this.props.infoTooltip}>
                                        <IconInfoSVG className={'info-icon'} ></IconInfoSVG>
                                    </Tooltip>
                                </h2>
                            </div>:<></>
                        }
                        {this.state.changePercentage!=null?
                            <div className="col-sm-4 text-center" hidden={this.state.dateRange==="1year"}>
                                <h3 className='mb-0'>
                                <i className={"fe " + (this.state.changePercentage < 0 ? "fe-arrow-down text-red" : "fe-arrow-up-circle text-green")}></i>
                                <span className={"text-center mb-0 number-font " + (this.state.changePercentage < 0 ? "text-red" : "text-green")}>
                                        {Intl.NumberFormat('es-UY', { minimumFractionDigits: 0 }).format(Number(Math.abs(this.state.changePercentage).toFixed(1)))+"%"}</span>
                                </h3>
                                <span className={"mb-0 number-font " + (this.state.changePercentage < 0 ? "text-red" : "text-green")} >{" X "+t(this.state.shortPeriodChartData.titleLabel)}</span>
                            </div>:<></>
                        }
                        {this.state.shortPeriodChartData.periodTotals.length>0?
                            <div className="col-md-6" >
                                <h4 >
                                    <span>{t(`${T_PREFIX}contracChargeAmount`)}</span>
                                </h4>

                            </div>:<></>
                        }
                        </div>
                        {/*@ts-ignore*/}
                        <Bar id="Bar" plugins={[innerVertBarText]}  redraw={true} options={this.options} data={this.data} className="h-600"/>
                            <table className='w-50'>
                                <tbody>
                                    {
                                        this.data.datasets.map((r, index) => {
                                            if (index < 5 || this.state.showDetail) {
                                                return (
                                                    <tr key={`${r.label}`} >
                                                        <td className='tableStyle'></td>
                                                        <td className='cellClass'>
                                                            <span style={{paddingLeft:"5px",borderLeft:"solid 15px "+r.backgroundColor[0]}}>{r.label}</span>
                                                        </td>
                                                        <td className='currencyClass'> {r.data[0] ? (Intl.NumberFormat('es-UY', { style: 'currency', currency: this.props.currencySymbol, maximumFractionDigits: 0 }).format(r.data[0])) : ("")}  </td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return <></>
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        {
                            (this.data.datasets !== null) ?
                            (
                                (!this.state.showDetail) ?
                                    <div className="chart-detail-button" onClick={()=>this.setState({showDetail: true})}>
                                        <a href={window.location.href}>
                                            <i className="fa-solid fa-chevron-down"></i>
                                        </a>
                                    </div>
                                :
                                    <div className="chart-detail-button" onClick={()=>this.setState({showDetail: false})}>
                                        <a href={window.location.href}>
                                            <i className="fa-solid fa-chevron-up"></i>
                                        </a>
                                    </div>
                            )
                            :
                            <></>
                        }
                </>
        )
    }
}
export default withTranslation() (ContraChargeAmountChart);