import { axiosApiInstance } from "./HttpService";

export class AutomaticDebitService {


    public static async getMpDaUrl(){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/AutomaticDebit/getMpDaUrl`;
        return await axiosApiInstance.post(dataURL);
    }
}
