import React, { useEffect, useState } from 'react';
import circle from "../../../node_modules/itd-common/assets/images/svgs/circle.svg";
import {useTranslation} from "react-i18next";
import {AxiosResponse} from "axios";
import {Modal} from "react-bootstrap";
import "../loyalty/clients/LoyaltyPromotions.css"
import "./LoyaltyVirtualCoupons.css"
import {ClientService} from "../../services/loyalty/ClientService";
import { ILoyaltyMerchant} from "../../models/loyalty/IClient";
import CoverageLocation from "../loyalty/CoverageLocation";
import {
    IAllPromotionCouponsResponse,
    IMerchantCouponsGenConfig,
    IPromotionCoupon, IPromotionCouponBalance,
    IPromotionCouponConfiguration, IUpdatePromotionCouponResponse
} from "../../models/loyalty/ILoyaltyCoupon";
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Page from '../../components/containers/page'

interface IState {
    errorMessage: string,
    initDahboard: boolean,
    reload: boolean,
    errors: string[],
    promotions:IPromotionCoupon[],
    myCouponBalances:IPromotionCouponBalance[],
    partnersCouponBalances:IPromotionCouponBalance[],
    selectedPromotion:IPromotionCoupon|null,
    updateCouponResponse:IUpdatePromotionCouponResponse|null,
    titlePromotionForm:string,
    textPromotionForm:string,
    footTextPromotionForm:string,
    daysToExpirePromotionForm:number,
    externalIdPromotionForm:string,
    merchant:ILoyaltyMerchant|null,
    polygonPath:{lat: number,lng: number}[],
    merchantCoupons:IMerchantCouponsGenConfig[],
    showEnableGeneration:boolean,
    validationError:string,
    couponIdToDelete:string
}
interface IProps { }




let LoyaltyVirtualCoupons: React.FC<IProps> = () => {

    const { t } = useTranslation();

    let [state, setState] = useState<IState>({
        errorMessage: "",
        initDahboard: false,
        reload: false,
        errors: [],
        promotions:[],
        validationError:"",
        selectedPromotion:null,
        titlePromotionForm:"",
        textPromotionForm:"",
        footTextPromotionForm:"",
        daysToExpirePromotionForm:0,
        externalIdPromotionForm:"",
        couponIdToDelete:"",
        merchant:null,
        polygonPath:[],
        myCouponBalances:[],
        partnersCouponBalances:[],
        merchantCoupons:[],//{name:"Acuarela",enabled:true,imagePath:"",systemId:"sadsa",lat:-34.8853674,lng:-56.0721116}
        showEnableGeneration:false,
        updateCouponResponse:null
    });




    let date =new Date();
    date.setFullYear(date.getFullYear()-1);
    const pageSize=13;





    useEffect(() => {
        setState({...state,initDahboard:true});
        if(!state.initDahboard){
            ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
        }

    }, [state.initDahboard]);

    useEffect(() => {
      validationForm();
    }, [state.textPromotionForm,state.titlePromotionForm,state.daysToExpirePromotionForm,state.footTextPromotionForm]);

    let initCouponConfiguration = (result: AxiosResponse<IPromotionCouponConfiguration>) => {
        ClientService.getLoyaltyMerchant().then(function(res:AxiosResponse<ILoyaltyMerchant>){
            if(res.data!=null||res.status==200){
                ClientService.getAllPromotionCoupons().then(function(couponRes:AxiosResponse<IAllPromotionCouponsResponse>){

                    setState({...state,
                        couponIdToDelete:"",
                        selectedPromotion:null,
                        polygonPath:result.data.polygonPath,
                        merchant:res.data,
                        promotions:couponRes.data.createdCoupons,
                        partnersCouponBalances:couponRes.data.partnersCouponBalances,
                        myCouponBalances:couponRes.data.myCouponBalances,
                        updateCouponResponse:null
                    });
                });

            }
        })

    }


    let updateSearchMerchant = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        if(state.updateCouponResponse!=null){
            setState({...state,updateCouponResponse:{
                    allPromotionBalances:state.updateCouponResponse.allPromotionBalances,
                    filteredPromotionBalances:state.updateCouponResponse.allPromotionBalances.filter(balance => balance.couponIssuerName.toUpperCase().indexOf(event.target.value.toUpperCase())>=0)
            }});
        }
    };

    let updateInput = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        setState({...state,[event.target.name]: event.target.value });
    };

    let saveCoupon = () => {

        var dto={id:"",text:state.textPromotionForm,
            footText:state.footTextPromotionForm,
            title:state.titlePromotionForm,
            daysToExpire:state.daysToExpirePromotionForm,
            externalIdPromotionForm:state.externalIdPromotionForm};

        if(state.selectedPromotion?.id!=null&&state.selectedPromotion?.id.length){
            dto.id=state.selectedPromotion?.id;
            ClientService.updateCoupon(dto).then(function(updateRes:AxiosResponse<IUpdatePromotionCouponResponse>){
                if(updateRes.status>=400)
                    toast.error(t("couponCreationError")+"");
                else{
                    setState({...state, selectedPromotion:null,updateCouponResponse:{allPromotionBalances:updateRes.data.allPromotionBalances,filteredPromotionBalances:updateRes.data.allPromotionBalances}})
                    toast.success(t("couponCreated")+"");
                }
                //ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
            }).catch(function(){
                toast.error(t("couponCreationError")+"");
            });
        }else{
            ClientService.createCoupon(dto).then(function(updateRes){
                if(updateRes.status>=400)
                    toast.error(t("couponCreationError")+"");
                else{
                    setState({...state, selectedPromotion:null,updateCouponResponse:{allPromotionBalances:updateRes.data.allPromotionBalances,filteredPromotionBalances:updateRes.data.allPromotionBalances}})
                    toast.success(t("couponCreated")+"");
                }
                //ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
            }).catch(function(){
                toast.error(t("couponCreationError")+"");
            });
        }
    };

    let updateCouponBalances = () => {
        ClientService.updateCouponBalances(state.updateCouponResponse?.allPromotionBalances).then(function(){
            ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
        }).catch(function(){
            toast.error(t("couponCreationError")+"");
        });
    };

    let validationForm = () => {
        let error = "";
        if (state.titlePromotionForm.length == 0) {
            error = "needTitle";
        }else if (state.textPromotionForm.length == 0) {
            error = "needText";
        }else if (state.footTextPromotionForm.length == 0) {
            error = "needfootText";
        }else if (state.daysToExpirePromotionForm<= 0) {
            error = "needdaysToExpire";
        }
            setState({
            ...state,
            validationError:error
        });
    }

    let closeBalanceCouponModal = () => {
        ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
    }
    let createPromotion = () => {
        var promo:IPromotionCoupon={
            title:"",
            text:"",
            footText:"",
            daysToExpire:0,
            externalIdPromotionForm:"",
            id:""
        }
        setState({...state,selectedPromotion: promo,
            titlePromotionForm:promo.title,
            textPromotionForm:promo.text,
            footTextPromotionForm:promo.footText,
            externalIdPromotionForm:promo.externalIdPromotionForm,
            daysToExpirePromotionForm:promo.daysToExpire
        });
    }
    let deleteCoupon = () => {
        ClientService.deleteCoupon(state.couponIdToDelete).then(function(state){
            if(state.status>=400)
                toast.error(t("couponDeleteError")+"");
            else
                toast.success(t("couponDeleted")+"");
            ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
        }).catch(function(){
            toast.error(t("couponDeleteError")+"");
        });
    }


    let showPromotionToEdit = (promo:IPromotionCoupon) => {
        setState({...state,selectedPromotion:promo,
            titlePromotionForm:promo.title,
            textPromotionForm:promo.text,
            footTextPromotionForm:promo.footText,
            externalIdPromotionForm:promo.externalIdPromotionForm,
            daysToExpirePromotionForm:promo.daysToExpire
        });
    }

    let onSuccessLocation = (res: { polygonPath:{lat: number,lng: number}[]}) => {
      ClientService.updateCouponCoverage(res)
    }

    let updateCouponBalancesByPartner=(balance:IPromotionCouponBalance)=>{
        ClientService.updateCouponBalancesByPartner(balance).then(function(){
            ClientService.getPromotionCouponsConfiguration().then(initCouponConfiguration)
        }).catch(function(){
            toast.error(t("couponDeleteError")+"");
        });
    }


    return (
        <Page>
            <>
                <ToastContainer
                    autoClose={3000}
                ></ToastContainer>
                <Modal show={state.updateCouponResponse!=null} key={"updateCouponResponse"} size="xl">
                    <div className="card-header border-0 pt-3">
                        <div className="card-options">
                            <a href="javascript:void(0)" className="card-options-remove" onClick={() => closeBalanceCouponModal()} ><i className="fe fe-x"></i></a>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        <div className="row">
                            <div className="table-responsive">
                                <input type="text" className="form-control" placeholder={t("searchPlaceholder")} onChange={updateSearchMerchant}/>
                                <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                                    <thead className="table-primary">
                                    <tr key={"tr-head"}>
                                        {
                                            ["","merchant info","enabledByIssuer","enabledByMerchant","couponIssuerBalance","couponIssuerLimit"].map(coll => {
                                                    return (
                                                        <th className="mercahts-table-header" key={`li-${coll}`}>{t(coll)}</th>
                                                    )
                                                })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        state.updateCouponResponse?.filteredPromotionBalances.map((row, Tindex) => {
                                            return (
                                                <tr key={Tindex} className={row.removed?"row-disabled":(!row.enabledByMerchant?"row-disabled-by-merchant":
                                                    (!row.enabledByIssuer?"row-disabled-by-issuer":"row-enabled"))}>
                                                    <td key={Tindex.toString() +"couponIssuerImage" }  >
                                                        <img src={row.couponIssuerImage} className="merchant-image-avatar"/>
                                                    </td>
                                                    <td key={Tindex.toString() +"couponIssuerName" } >
                                                        {row.couponIssuerName}
                                                    </td>
                                                    <td key={Tindex.toString() +"enabledByIssuer" } >
                                                        <div className="form-group mg-b-10">
                                                            <label className="custom-switch ps-0">
                                                                <input type="checkbox" name="custom-switch-checkbox" disabled={true}
                                                                       className="custom-switch-input" checked={row.enabledByIssuer} />
                                                                <span className="custom-switch-indicator me-3"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td key={Tindex.toString() +"enabledByMerchant" } >
                                                        <div className="form-group mg-b-10">
                                                            <label className="custom-switch ps-0">
                                                                <input type="checkbox" name="custom-switch-checkbox"
                                                                       value={row.enabledByMerchant+""}
                                                                       className="custom-switch-input" checked={row.enabledByMerchant} onChange={()=>{row.enabledByMerchant=!row.enabledByMerchant;setState({...state})}}/>
                                                                <span className="custom-switch-indicator me-3"></span>
                                                            </label>
                                                        </div>
                                                    </td>

                                                    <td key={Tindex.toString() +"couponIssuerBalance" } >
                                                        {row.couponIssuerBalance}
                                                    </td>
                                                    <td key={Tindex.toString() +"couponIssuerLimit" } >
                                                        <input type="number" className="form-control" value={row.couponIssuerLimit}onChange={(event)=>{row.couponIssuerLimit=Number(event.target.value);setState({...state})}}/>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-center border-0 pt-0">
                        <div className="row">
                            <div className="text-center">
                                <a href={window.location.href} className="btn btn-white me-2" onClick={() => closeBalanceCouponModal()}>{t('cancel')}</a>
                                <a href={window.location.href} className={"btn "+(state.validationError==""?"btn-primary":"btn-gray disabled")} onClick={() => updateCouponBalances()}>{t('confirm')}</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={state.couponIdToDelete != ""}>
                    <div className="card border p-0 pb-3">
                        <div className="card-header border-0 pt-3">
                            <div className="card-options">
                                <a href="javascript:void(0)" className="card-options-remove" onClick={() => setState({...state, couponIdToDelete: "" })} ><i className="fe fe-x"></i></a>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <span className=""><svg xmlns="http://www.w3.org/2000/svg" height="60" width="60"
                                                    viewBox="0 0 24 24"><path fill="#f07f8f"
                                                                              d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z" /><circle
                                cx="12" cy="17" r="1" fill="#e62a45" /><path fill="#e62a45"
                                                                             d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z" /></svg></span>
                            <h4 className="h4 mb-0 mt-3">{t('warning')}</h4>
                            <p className="card-text">{t('confirmdeleteCoupon')}</p>
                        </div>
                        <div className="card-footer text-center border-0 pt-0">
                            <div className="row">
                                <div className="text-center">
                                    <a href={window.location.href} className="btn btn-white me-2" onClick={() => setState({...state, couponIdToDelete: "" })}>{t('cancel')}</a>
                                    <a href={window.location.href} className="btn btn-danger" onClick={() => deleteCoupon()}>{t('delete')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={state.selectedPromotion!=null}>
                    <div className="card-header border-0 pt-3">
                        <div className="card-options">
                            <a href="javascript:void(0)" className="card-options-remove" onClick={() => setState({...state, selectedPromotion: null })} ><i className="fe fe-x"></i></a>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        <div className="row">
                                <div className="col-md-3">
                                    <label className="col-form-label">{t("title")}:</label>
                                </div>
                                <div className="col-md-9">
                                    <input autoComplete='off' type='text' value={state.titlePromotionForm}
                                           name={"titlePromotionForm"}
                                           onChange={updateInput} className="form-control" id="titlePromotionForm" />
                                </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("text")}:</label>
                            </div>
                            <div className="col-md-9">
                                <textarea autoComplete='off'  value={state.textPromotionForm}
                                       name={"textPromotionForm"}
                                       onChange={updateInput} className="form-control" id="descriptionPromotionForm" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("footText")}:</label>
                            </div>
                            <div className="col-md-9">
                                <textarea autoComplete='off'  value={state.footTextPromotionForm}
                                          name={"footTextPromotionForm"}
                                          onChange={updateInput} className="form-control" id="descriptionPromotionForm" />
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("daysToExpire")}</label>
                            </div>
                            <div className="col-md-9">
                                <input autoComplete='off' type='text' value={state.daysToExpirePromotionForm}
                                       name={"daysToExpirePromotionForm"}
                                       onChange={updateInput} className="form-control" id="pointsValuePromotionForm" />
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="col-form-label">{t("promotionExternalId")}</label>
                            </div>
                            <div className="col-md-9">
                                <input autoComplete='off' type='text' value={state.externalIdPromotionForm}
                                       name={"externalIdPromotionForm"}
                                       onChange={updateInput} className="form-control" id="externalIdPromotionForm" />
                            </div>
                        </div>
                        <div className="row">
                            <label className="custom-control mt-4 text-danger">
                                <span>{t(state.validationError)}</span>
                            </label>
                        </div>
                        <div className="row">
                            <p className="text-primary">
                                {t("editPromotionNote")}
                            </p>
                        </div>
                    </div>
                    <div className="card-footer text-center border-0 pt-0">
                        <div className="row">
                            <div className="text-center">
                                <a href={window.location.href} className="btn btn-white me-2" onClick={() => setState({ ...state,selectedPromotion: null })}>{t('cancel')}</a>
                                <a href={window.location.href} className={"btn "+(state.validationError==""?"btn-primary":"btn-gray disabled")} onClick={() => saveCoupon()}>{t('confirm')}</a>
                            </div>
                        </div>
                    </div>
                </Modal>


                <div className="page-header">

                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-primary">{t("myacceptedCouponTitle")}</h5>
                    </div>

                </div>
                <div className="row">
                    {
                        state.partnersCouponBalances.map((balance, Tindex) => {
                            return (
                                <div className="col-sm-6" key={Tindex}>
                                    <div className={"card "+(!balance.enabledByIssuer?"bg-gray-lighter":"")} >
                                        <div className="card-header">
                                            <h3 className="card-title">{balance.couponOwnerName.toUpperCase()}</h3>
                                            <div className="card-options" hidden={!balance.enabledByIssuer}>
                                                <a href={window.location.href} className="btn btn-sm btn-primary"  onClick={()=>{balance.enabledByIssuer=false;updateCouponBalancesByPartner(balance)}}>{t("disableGeneration")}</a>
                                            </div>
                                            <div className="card-options" hidden={balance.enabledByIssuer}>
                                                <a href={window.location.href} className="btn btn-sm btn-success"  onClick={()=>{balance.enabledByIssuer=true;updateCouponBalancesByPartner(balance)}}>{t("enableGeneration")}</a>
                                            </div>
                                        </div>
                                        <div className="card-alert alert alert-success mb-0" hidden={!balance.enabledByMerchant}>
                                            {t("enabledByOwner")}
                                        </div>
                                        <div className="card-alert alert alert-danger mb-0" hidden={balance.enabledByMerchant}>
                                            {t("disabledByOwner")}
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div hidden={balance.couponOwnerImage.length==0} className="">
                                                        <img src={balance.couponOwnerImage} alt="img"></img>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <h3 className="card-title">{balance.couponTitle}</h3>
                                                    <p className="small">{balance.couponText}</p>
                                                    <h3 className="">{t("balance")+" "+Intl.NumberFormat('es-UY', ).format(Number(balance.couponIssuerBalance))}</h3>
                                                    <p className="">{t("generationLimit")+" "+Intl.NumberFormat('es-UY', ).format(Number(balance.couponIssuerLimit))}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-primary">{t("acceptedCouponTitle")}</h5>
                    </div>
                </div>
                <div className="row">
                    {
                        state.myCouponBalances.map((balance, Tindex) => {
                            return (
                                <div className="col-sm-6" key={Tindex}>
                                    <div className={"card "+(!balance.enabledByMerchant?"bg-gray-lighter":"")} >
                                        <div className="card-header">
                                            <h3 className="card-title">{balance.couponIssuerName.toUpperCase()}</h3>
                                            <div className="card-options" >
                                                <button type="button" className="btn btn-icon  btn-primary"><i
                                                    className="fe fe-edit-2" onClick={()=>{setState({...state,updateCouponResponse:{filteredPromotionBalances:[balance],allPromotionBalances:[balance]}})}}></i></button>
                                            </div>
                                        </div>
                                        <div className="card-alert alert alert-success mb-0" hidden={!balance.enabledByIssuer}>
                                            {t("enabledByIssuer")}
                                        </div>
                                        <div className="card-alert alert alert-danger mb-0" hidden={balance.enabledByIssuer}>
                                            {t("enabledByIssuer")}
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div hidden={balance.couponIssuerImage.length==0} className="">
                                                        <img src={balance.couponIssuerImage} alt="img"></img>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <h3 className="card-title">{t("title")+": "+balance.couponTitle}</h3>
                                                    <span className="small">{balance.couponText}</span>
                                                    <h3 className="">{t("balance")+" "+Intl.NumberFormat('es-UY', ).format(Number(balance.couponIssuerBalance))}</h3>
                                                    <span className="">{t("generationLimit")+" "+Intl.NumberFormat('es-UY', ).format(Number(balance.couponIssuerLimit))}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

                <div className="card">
                    <div className="card-header">
                        <h5 className="text-primary">Defina un área de cobertura para emitir cupones promocionales en comercios ubicados dentro de la misma.</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <CoverageLocation
                                onSuccess={onSuccessLocation}
                                fullAddress={state.merchant?.fullAddress??""}
                                position={ {lat:state.merchant?.lat??0,lng:state.merchant?.lng??0}}
                                polygonPath={state.polygonPath}
                                showCancelButton = {true}
                                additionalMarkers={state.merchantCoupons}
                            ></CoverageLocation>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-10"></div>
                        <div className="col-md-2">
                            <button type="button"
                                    onClick={createPromotion}
                                    className="btn btn-primary mb-3 btn-block" >{t('createCoupon')}

                            </button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    {
                        state.promotions.map(promo => {
                            return (
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="row g-0">

                                            <div className="col-md-12">
                                                <div className="card-header">
                                                    <h5 className="card-title">{promo.title}</h5>
                                                    <div style={{position:"absolute",right:"20px"}}>
                                                        <button type="button" className="btn btn-icon  btn-primary"><i
                                                            className="fe fe-edit-2" onClick={()=>{showPromotionToEdit(promo)}}></i></button>
                                                        <label style={{width:"5px"}}></label>
                                                            <button type="button" className="btn btn-icon  btn-danger"><i
                                                                className="fe fe-trash-2" onClick={()=>{setState({...state,couponIdToDelete:promo.id})}}></i></button>
                                                    </div>
                                                </div>
                                                <div className="card-body">

                                                    <p className="card-text">{promo.text}</p>
                                                    <p className="card-text"><small className="text-muted">{promo.footText}</small></p>
                                                </div>
                                                <div className="card-footer">


                                                    <p className="card-text"><small className="text-muted">{t("daysToExpire")}</small><label className="text-primary">{promo.daysToExpire}</label></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }


                </div>
            </>
        </Page>
    )
}
export default LoyaltyVirtualCoupons;
