export class CurrencyHelper {


    static getCurrencySymbol = (currencyId: string): string => {
        let currencySymbol: string;
        switch (parseInt(currencyId)) {
            case 32:
                currencySymbol = "UYU";//se muestra $
                break;
            case 840:
                currencySymbol = "USD";
                break;
            case 858:
            default:
                currencySymbol = "UYU";
                break;
        }

        return currencySymbol;
    }



}