import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import { IPageRequest } from '../../models/IPageRequest';
import { DateRangeType, IFileSettlement, ISettlement, ISettlementRequest } from "../../models/ISettlementData";
import FormFilter from '../containers/form-filter';
import Page from '../containers/page';
import DateField from '../controls/fields/dateField';
import { actionColumnDefinition, ColumnDefinition, dateColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import NavigationTabLinksForReports from './NavigationTabLinksForReports';
import Card from '../containers/card';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import { UtilService } from '../../services/UtilService';
import { WebCache } from '../../services/WebCache';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { SettlementService } from '../../services/SettlementService';
import { DateHelper } from '../../helpers/DateHelper';
import { AxiosResponse } from 'axios';
import {ReactComponent as DownloadSVG } from "../../../node_modules/itd-common/assets/images/svgs/download.svg";
import CustomReCAPTCHA from '../CustomCaptcha';
import { toast} from 'react-toastify';
import { t } from 'i18next';
import {Option} from "../../models/Option";

enum TypeEnum {
    SETTLEMENT = 0,
    PRESENTATION = 1
}

interface IProps {
    t: TFunction
}

interface IFilters {
    dateRangeType: DateRangeType,
    fiscalDocument: string,
    dateRange: DateObject[],
    currency: string
}

interface IState {
    filters: IFilters,
    type: string
    mainPage: number,
    pageRequest: IPageRequest,
    fiscalDocuments:Option[],
    currencyOptions: Array<HTMLOptionElement>,
    typeOptions: Array<HTMLOptionElement>,
    fileSettlements: Array<IFileSettlement>,
    errors: Array<string>,
    tableMode: TableModeEnum,
    isCaptchaVerified:boolean

}

const T_PREFIX: string = 'pages.presentationDownloadsReport.';

class PresentationDownloadsReport extends React.Component<IProps, IState>{
    private columnDefinitions: Array<ColumnDefinition>;

    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    constructor(props: IProps) {
        super(props);
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();
        const allPayerTaxIdsOptionList: Array<HTMLOptionElement> = new OptionMapperHelper(this.props.t).buildSelectOptionsFromAnArrayWithDifferentInterface(WebCache.getAllPayerTaxIds(), 'id', 'name') as Array<HTMLOptionElement>;
        const typeOptions: Array<HTMLOptionElement> = [
            { value: TypeEnum.SETTLEMENT.toString(), label: this.props.t("settlements") } as HTMLOptionElement,
            { value: TypeEnum.PRESENTATION.toString(), label: this.props.t("presentations") } as HTMLOptionElement
        ];

        this.state = {
            filters: {
                dateRangeType: "SETT_DATE",
                fiscalDocument:WebCache.getAllMerchantDocuments(true)[0]?.label+"",
                dateRange: DateHelper.buildDateRange(),
                currency: currencyOptions[0].value,
            },
            type: TypeEnum.SETTLEMENT.toString(),
            fiscalDocuments:WebCache.getAllMerchantDocuments(true),
            currencyOptions,
            typeOptions,
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            fileSettlements: [],
            errors: [],
            tableMode: TableModeEnum.LIST,
            isCaptchaVerified: false
        }

        const currentUser = WebCache.getCurrentUser()?.countryId;
        let merchantDocument;
        if (currentUser !== undefined && currentUser === 2) {
            merchantDocument =t(`${T_PREFIX}merchantDocumentUru`);
        } else {
            merchantDocument = t(`${T_PREFIX}merchantDocumentArg`);
        }

        this.columnDefinitions = [
            textColumnDefinition({ key: 'aggregatorHeaderId', label: `${T_PREFIX}table.column.description`, percentageWidth: 25 }),
            //dateColumnDefinition({ key: 'aggregationPresentationDate', label: `${T_PREFIX}table.column.fileDate`, fixedWidth: 120 }), se saca porque una liquidacion puede tener varias fechas de pago. se saca para dejar solo una linea por liquidacion
            //dateColumnDefinition({ key: 'vtoDate', label: `${T_PREFIX}table.column.paymentDate`, fixedWidth: 120 }), se saca porque una liquidacion puede tener varias fechas de pago. se saca para dejar solo una linea por liquidacion
            textColumnDefinition({ key: 'merchantDocument', label: merchantDocument, fixedWidth: 140 }),
            actionColumnDefinition({ key: 'download', action: { function: this.downloadUploadedFileAction, icon: <DownloadSVG className="icon-download"></DownloadSVG> }, fixedWidth: 40 })
        ];
    }

    handleSubmit = () => {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.getPresentationDownloads();
        });
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    downloadUploadedFileAction: (rowObject: ISettlement) => void = (rowObject: ISettlement) => {
        if (this.state.isCaptchaVerified) {
        SettlementService.downloadUploadedFile(rowObject.aggregatorHeaderId);
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }

    getSettlementRequest(all: boolean): ISettlementRequest {
        return {
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            MerchantDocument: this.state.filters.fiscalDocument=="all"?"":this.state.filters.fiscalDocument,
            MerchantNumber: "",
            DateRangeType: this.state.filters.dateRangeType,
            Currency: this.state.filters.currency,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
    }

    getPresentationDownloads = () => {
        let presentationDownloadsPromise: Promise<AxiosResponse<Array<IFileSettlement>>> = {} as Promise<AxiosResponse<Array<IFileSettlement>>>;
        const request: ISettlementRequest = this.getSettlementRequest(false);

        if (this.state.type === TypeEnum.PRESENTATION.toString()) {
            presentationDownloadsPromise = SettlementService.getPresentationFileList(request);
        }
        else if (this.state.type === TypeEnum.SETTLEMENT.toString()) {
            presentationDownloadsPromise = SettlementService.getSettlementFileList(request);
        }

        presentationDownloadsPromise.then((response: AxiosResponse<Array<IFileSettlement>>) => {
            if (!response || !response.data || response.data.length === 0) {
                this.setState({...this.state, fileSettlements: [], errors: [] });
                return;
            }

            this.setState({...this.state, fileSettlements: response.data, errors: [] });
        }).catch(error => {
            this.setState({...this.state, errors: error.response.data, fileSettlements: [] });
            console.error('error:', error);
        });
    }
   
    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = t("merchantDocument") ;
        } else {
            clientDocument = t("merchantDocumentArg");
        }
        return (
            <Page>
                <>
                    <div className="row">
                        <h2>{t('fileReports')}</h2>
                        <div className="col-lg-12">
                            <Card actions={
                                <>
                                    <div className="col-lg-12">
                                        <NavigationTabLinksForReports></NavigationTabLinksForReports>
                                    </div>
                                </>
                            }>
                                <>
                                    <FormFilter
                                        onSubmit={this.handleSubmit} 
                                        model={[ this.state.filters, this.setFilters.bind(this) ]}
                                        >
                                        <>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <RadioButtonGroup
                                                        attr="dateRangeType" 
                                                        fieldDefinitions={[
                                                            //{ key: 'paymentDate', value: 'PAYMENT_DATE', label: `${T_PREFIX}paymentDate` },
                                                            //{ key: 'presentationDate', value: 'PRESENTATION_DATE', label: `${T_PREFIX}presentationDate` },
                                                            //{ key: 'settDate', value: 'SETT_DATE', label: `${T_PREFIX}fileDate` }
                                                        ]}                                                  
                                                    ></RadioButtonGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <DateField attr="dateRange" label={`${T_PREFIX}dateRange`} range></DateField>
                                                </div>

                                                <div className="col-md-4">
                                                    <SelectField attr='fiscalDocument' label={clientDocument}
                                                                 options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}></SelectField>
                                                </div>

                                                <div className="col-md-4 d-flex align-items-center">
                                                    <SelectField model={[ this.state, this.setState.bind(this) ]} attr="type" label={`${T_PREFIX}type`} options={this.state.typeOptions} className="w-100" ></SelectField>
                                                </div>
                                            </div>
                                        </>
                                    </FormFilter>
                                    <CustomTable
                                        rowObjects={this.state.fileSettlements}
                                        columnDefinitions={this.columnDefinitions}
                                        config={{
                                            mode: this.state.tableMode,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getPresentationDownloads())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                    <div className='row'>
                                        <div className='col-md-9'></div>
                                        <div className="col-md-3">
                                            <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                        </div>
                                    </div>
                                </>
                            </Card>
                        </div>
                    </div>
                </>
            </Page>
        )
    };

}
export default withTranslation()(PresentationDownloadsReport);
