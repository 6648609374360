import "./LabelMessage.scss";
interface IPropsLabelMessage {
    message: string
    valid: boolean 
}
 
export default function LabelMessage (props: IPropsLabelMessage) {
    return (
        <label className={`message ${props.valid ? 'success' : 'error'} text-primary text-end fs-11`}>
            {props.message}
        </label>
    );
}
 
    