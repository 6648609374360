import { AxiosResponse } from 'axios';
import i18next, { t } from 'i18next';
import React from 'react';
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { IManagementSendProfile, ISendProfile, ISendProfileWebModelRequest } from '../models/ISendProfile';
import { ITerminalWebModelRequest } from '../requests/ITerminalWebModelRequest';
import { SendProfileService } from '../services/SendProfileService';
import { TerminalsService } from '../services/TerminalsService';
import { WebCache } from '../services/WebCache';
import Pagination from './Pagination';
import Form from './containers/form';
import SubmitButton from './controls/buttons/submitButton';
import TextField from './controls/fields/textField';
import CronScheduler from "./cronscheduler/CronScheduler";
import Button from './controls/buttons/button';
import EmailField from './controls/fields/emailField';
import Page from '../components/containers/page'
import SelectField from './controls/fields/selectField';
import Card from '../components/containers/card';
import TypeaheadField from './controls/fields/typeaheadField';
import { ReactComponent as IconEditSVG } from "../../node_modules/itd-common/assets/icons/edit.svg";
import { ReactComponent as IconTrashSVG } from "../../node_modules/itd-common/assets/icons/trash.svg";
import { ReactComponent as IconAddSVG } from "../../node_modules/itd-common/assets/icons/icon-add-ligth.svg";
import { ReactComponent as SearchIconSVG } from "../../node_modules/itd-common/assets/icons/search-icon.svg";
import { ReactComponent as CloseIconSVG } from "../../node_modules/itd-common/assets/icons/close.svg";
import { ReactComponent as AlertInformationSvg } from "../../node_modules/itd-common/assets/icons/alert-information-new.svg";
import i18n from '../i18n';
import "./SendProfileManagement.scss"
import FormFilter from './containers/form-filter';
import CustomTable, { TableModeEnum } from './table/CustomTable';
import { IPageRequest } from '../models/IPageRequest';
import { ColumnDefinition, actionColumnDefinition, textColumnDefinition } from './table/ColumnDefinition';
import InputField from './controls/fields/inputField';

interface ITerminal {
    id: string,
    transactionDate: Date,
    terminal: string,
    state: number
}
interface IFilters {
    name: string,
    type: string,
    dispatchType: string,
    frequency: string,
    formatType: string,
    source: string,
}


interface IProps {
}

interface IState {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    sendProfile: IManagementSendProfile,
    sendProfileFilter: ISendProfile[]
    SendProfileIdToDelete: string,
    pageRequest: IPageRequest,
    profileIdForm: string,
    profileIdClient: string,
    profileNameForm: string,
    profileTypeForm: string,
    profileMailForm: string,
    profileFrequencyForm: string,
    profileTerminalsForm: Option[],
    profileSendTypeForm: string,
    profileFormatFomr: string,
    showErrorMessage: boolean,
    showViewCreateUser: boolean,
    errorMessage: string,
    terminalFilter: string,
    formatFilter: string,
    filters: IFilters,
    roleOptionList: Array<Option>,
    rowObjects: Array<ProfileRow>,
    sendTypeFilter: string,
    terminalAll: Option[],
    errors: string[],
    terminals: ITerminal[],
    nameFilter: string,
    columnsToShow:string[],
    sourceForm: string,
    optionsFormat:{value:string,label:string}[],
    isSearching: boolean,
    filter: string
}

interface TerminalRow {
    style: string;
    value: string;
    icon: string;
}

interface Option {
    value: any;
    label: string;
    key?: string;
    disabled?: boolean;
}

interface ProfileRow {
    style: string;
    value: string;
    icon: string;
}

const pageSize = 13;
const pageQuantity = 3

const allSources = [
    { value: "TRANSACTIONS", label: "Transacciones" },
    { value: "SETTLEMENTS", label:  'Liquidaciones'},
    { value: "SETTLEMENT_TRANSACTIONS", label: 'Cupones'}
];

const T_PREFIX: string = 'pages.sendProfileManagement.';


class SendProfileManagement extends React.Component<IProps, IState>{
    private columnDefinitions: Array<ColumnDefinition>;

    componentDidMount() {
        this.getSendProfile();
    }

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);



        this.state = {
            activePage: 1,
            mainPage: 0,
            columns: [],
            pages: [1, 2, 3, 4, 5],
            sendProfile: { data: [] },
            sendProfileFilter: [],
            SendProfileIdToDelete: "",
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            profileIdForm: "",
            profileIdClient: "",
            profileNameForm: "",
            profileTypeForm: "",
            profileMailForm: "",
            profileFrequencyForm: '0 * */1 * *',
            profileTerminalsForm: [],
            profileSendTypeForm: "",
            profileFormatFomr:  'Excel',
            showErrorMessage: false,
            showViewCreateUser: false,
            errorMessage: "",
            terminalFilter: "",
            formatFilter: "",
            roleOptionList: [],
            rowObjects: [],
            filters: {
                name: '',
                dispatchType: '',
                type: '',
                formatType: '',
                frequency: '',
                source: ''
            },
            sendTypeFilter: "",
            terminalAll: [],
            sourceForm: allSources[0].value,
            errors: [],
            terminals: [],
            columnsToShow: ["NAME","MAIL","TERMINALS","FREQUENCY","SENDTYPE","FORMATFILE","SOURCE"],
            nameFilter: "",
            optionsFormat : [
                { value: 'Excel', label: 'Excel' },
                { value: 'TxtWithComma', label: i18next.t('TxtWithComma')+"" },
                { value: 'TxtWithPipe', label: i18next.t('TxtWithPipe')+"" },
                { value: 'TxtWithNothing', label: i18next.t('TxtWithNothing')+"" }
            ],
            isSearching: false,
            filter: ""
        }

        for (let role of WebCache.getAllRoles()) {
            this.state.roleOptionList.push({
                value: role,
                label: i18n.t(role),
                key: role,
                disabled: false
            });
        }

        let terminals = WebCache.getAllTerminals()
        for (let j in terminals) {
            if (terminals[j].terminalNumber != "0000")
                this.state.terminalAll.push({
                    value: terminals[j],
                    label: terminals[j].terminalNumber
                        + " - " + terminals[j].branchName + " " + terminals[j].locationName + " " + terminals[j].departmentName,
                    key: terminals[j].terminalNumber,
                    disabled: false
                });
        }
        this.columnDefinitions = [
            textColumnDefinition({ key: 'name', label: `${T_PREFIX}columns.name`, percentageWidth: 25 }),
            textColumnDefinition({ key: 'mail', label: `${T_PREFIX}columns.mail`, percentageWidth: 25 }),
            textColumnDefinition({ key: 'terminals', label: `${T_PREFIX}columns.terminals`, percentageWidth: 25 }),
            textColumnDefinition({ key: 'frequency', label: `${T_PREFIX}columns.frecuency`, fixedWidth: 140 }),
            textColumnDefinition({ key: 'sendType', label: `${T_PREFIX}columns.sendType`, percentageWidth: 25 }),
            textColumnDefinition({ key: 'formatFile', label: `${T_PREFIX}columns.fileFormat`, percentageWidth: 25 }),
            textColumnDefinition({ key: 'source', label: `${T_PREFIX}columns.source`, percentageWidth: 25 }),
           
            actionColumnDefinition({ key: 'newProfile', action: { function: (rowObject: ISendProfile) => { this.handleEditeProfile(rowObject) }, icon: <IconEditSVG></IconEditSVG>}, fixedWidth: 40 }),
            actionColumnDefinition({ key: 'deleteProfile', action: { function: (rowObject: ISendProfile) => { this.setState({ SendProfileIdToDelete: rowObject.id }) }, icon: <IconTrashSVG></IconTrashSVG> }, fixedWidth: 40 })
        ];
    }

    cargaralsad = () => { }

    clearFilter= () => {
        this.setState({
            ...this.state,
            isSearching: false,
            filter: "",
            sendProfileFilter: []
        });
    }

    getProfiles=()=> {
        let result= this.state.sendProfile.data.filter((item)=> item.name.toLowerCase().includes(this.state.filter.toLowerCase()))
        this.setState({
            ...this.state,
            isSearching: true,
            sendProfileFilter: result
        });
    }

    getTerminalRequest = (all: boolean) => {
        const tranWebModel: ITerminalWebModelRequest = {
            TerminalId: (this.state.terminalFilter == null || this.state.terminalFilter == "") ? "" : this.state.terminalFilter,
            Department: "",
            Location: "",
            Branch: "",
            Skip: 0,
            Take: 1000,
            PayerTaxId: ""
        };
        return tranWebModel;
    }

    saveSendProfile = () => {
        this.setState({...this.state, showViewCreateUser: false})
        if (!this.profileValidation())
            return;
        if (this.state.profileIdForm.length === 0)
            SendProfileService.saveSendProfile({
                id: this.state.profileIdForm,
                name: this.state.profileNameForm,
                mail: this.state.profileMailForm,
                terminals: this.state.profileTerminalsForm.map((ter: Option) => ter.value.terminalNumber),
                frequency: this.state.profileFrequencyForm,
                sendType: "MAIL",
                formatFile: this.state.profileFormatFomr,
                source:this.state.sourceForm,
            }).then(this.successCallback, this.errorCallback)
        else
            SendProfileService.updateSendProfile({
                id: this.state.profileIdForm,
                name: this.state.profileNameForm,
                mail: this.state.profileMailForm,
                terminals: this.state.profileTerminalsForm.map((ter: Option) => ter.value.terminalNumber),
                frequency: this.state.profileFrequencyForm,
                sendType: this.state.profileSendTypeForm,
                formatFile: this.state.profileFormatFomr,
                source:this.state.sourceForm,
            }).then(this.successCallback, (error) => console.log(error))
    }

    deleteProfile() {
        SendProfileService.deleteSendProfile(this.state.SendProfileIdToDelete).then(this.successCallback, this.errorCallback)
    }

    getSendProfile = () => {
        SendProfileService.getAll(this.getProfileRequest(false)).then((response: AxiosResponse<IManagementSendProfile>) => {
            console.log(response)
            if (response === null || response.data === null || response.data.data.length === 0) {
                this.setState({
                    sendProfile: { data: [] },
                    showViewCreateUser: false,
                    SendProfileIdToDelete: ""
                })
                return;
            }

            let newColumns = [];
            for (let prop in response.data.data[0]) {
                for(let j in this.state.columnsToShow){
                    if(this.state.columnsToShow[j]==prop.toUpperCase())
                        newColumns.push(prop);
                }

            }
            this.setState({
                columns: newColumns,
                sendProfile: response.data,
                showViewCreateUser: false,
                SendProfileIdToDelete: ""
            });
        }).catch(error => {
            this.setState({
                errors: error.response.data,
                sendProfile: { data: [] },
                showViewCreateUser: false,
                SendProfileIdToDelete: ""
            });
            console.log('error:', error);
        });
    }

    getProfileRequest = (all: boolean) => {
        const tranWebModel: ISendProfileWebModelRequest = {
            Name: this.state.nameFilter,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
        return tranWebModel;
    }

    profileValidation = (): boolean => {
        if (this.state.profileSendTypeForm !== "MAIL") {
            if (this.state.profileNameForm === "") {
                this.errorCallback("Complete todos los datos requeridos");
                return false;
            }
            return true;
        }

        if (
            //this.state.profileIdForm === "" ||
            this.state.profileNameForm === "" ||
            this.state.profileFormatFomr === "" ||
            this.state.profileFrequencyForm === ""
            //|| this.state.profileTerminalsForm.map((ter: Option) => ter.value).length == 0
        ) {
            this.errorCallback("Complete todos los datos requeridos");
            return false;
        }

        if (this.validateEmail()) {
            this.setState({ showErrorMessage: false, errorMessage: "" });
            return true;
        }

        return false;
    }

    validateEmail(): boolean {
        const emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(this.state.profileMailForm)) {
            return true
        } else {
            this.setState({ showErrorMessage: true, errorMessage: i18next.t("invalidmail")+"" })
            return false
        }
    }

    successCallback = (success: AxiosResponse<any>) => {
        if (success.data.success) {
            toast.success(success.data.message);
            this.getSendProfile();
        } else
            toast.error(success.data.message);
    }

    errorCallback = (error: any = "") => {
        this.setState({ SendProfileIdToDelete: "" })
        if (error !== "" && typeof error === "string") {
            toast.error(JSON.stringify(error));
        }
        else {
            toast.error(JSON.stringify("Error creando perfil de envío"));
        }
    }

    handleChange(event: any) {
        if (event.target.name === "terminalFilter")
            this.setState({ ...this.state, terminalFilter: event.target.value });
    } 

    closeShowEditProfileModal() {
        this.setState({ ...this.state, showViewCreateUser: false });
    }

    chargeSelect = () => {
        TerminalsService.getTerminals(this.getTerminalRequest(false)).then((response: AxiosResponse<ITerminal[]>) => {
            if (response == null || response.data == null || response.data.length == 0) {
                return;
            }
            for (let j in response.data) {
                if (response.data[j].terminal != "0000")
                    this.state.terminalAll.push({
                        value: response.data[j],
                        label: response.data[j].terminal,
                        key: response.data[j].terminal,
                        disabled: false
                    });
            }
        }).catch(error => {
            this.setState({
                errors: error.response.data
            });
            console.log('error:', error);
        }
        );
    }

    handleEditeProfile = (profile: ISendProfile) => {
        this.setState({
            profileNameForm: profile.name,
            profileTerminalsForm: this.state.terminalAll.length > 0 ? profile.terminals.map((op) => {
                let terminals = this.state.terminalAll.filter((t: Option) => t.key == op);
                return terminals[0]
            }) : [],
            profileMailForm: profile.mail,
            profileIdForm: profile.id,
            profileFormatFomr: profile.formatFile,
            profileFrequencyForm: profile.frequency,
            profileSendTypeForm: profile.sendType,
            showErrorMessage: false,
            showViewCreateUser: true
        });
    }
    handleCreateProfile = () => {
        this.setState({
            profileIdForm: "",
            profileNameForm: "",
            profileTypeForm: "",
            profileMailForm: "",
            profileFrequencyForm: "0 0 0 ? * * *",
            profileTerminalsForm: [],
            profileSendTypeForm: "MAIL",
            profileFormatFomr: this.state.optionsFormat[0].value,
            profileIdClient: "",
            showErrorMessage: false,
            showViewCreateUser: true
        });
    }

    render() {
        // @ts-ignore
        const { t } = this.props;

        return (
            <Page>
                { !this.state.showViewCreateUser ?
                    <>
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    {/* <div className="card-header"> */}
                                        <h3 className="title mb-3">{t(`${T_PREFIX}title`)}</h3>
                                    {/* </div> */}
                                    <div className="div-box">
                                        <div className="row">
                                            <div className="col-md-3 filters">
                                                    <InputField
                                                        type="text"
                                                        attr='filter'
                                                        label={t(`searchForName`)}
                                                        model={[this.state, this.setState.bind(this)]}   
                                                        sufix={
                                                            this.state.isSearching ?
                                                                <a href={window.location.href} className="pe-2 h-100 aling-items-end d-flex"
                                                                onClick={()=> this.clearFilter()}
                                                                >
                                                                    <CloseIconSVG></CloseIconSVG>
                                                                </a>
                                                            :
                                                                <a href={window.location.href} className="pe-2 h-100 aling-items-end d-flex"
                                                                    onClick={()=> this.getProfiles()}
                                                                >
                                                                    <SearchIconSVG></SearchIconSVG>
                                                                </a>
                                                        }
                                                    ></InputField>
                                                </div>
                                            </div>
                                        <div className="row">
                                            <div className="col-md-10">
                                            </div>
                                            <div className="col-md-2 mb-5">
                                                <Button onClick={() => this.handleCreateProfile()} className='mt-0'>
                                                    <p className='mb-0'>
                                                        <span className='me-3'>{t("addUser")}</span>
                                                        <IconAddSVG></IconAddSVG> 
                                                    </p>
                                                </Button>

                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <CustomTable
                                                rowObjects={this.state.sendProfileFilter?.length > 0 ? this.state.sendProfileFilter: this.state.sendProfile.data}
                                                columnDefinitions={this.columnDefinitions}
                                                config={{
                                                    mode: TableModeEnum.LIST,
                                                    paginator: {
                                                        mainPage: this.state.mainPage,
                                                        modifyMainPage: (value: number) => {
                                                            this.setState({
                                                                mainPage: this.state.mainPage + value
                                                            }, () => this.getSendProfile())
                                                        },
                                                        pageRequest: this.state.pageRequest
                                                    }
                                                }}
                                            ></CustomTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={this.state.SendProfileIdToDelete !== ""} className="modal-delete">
                            <div className="container-fluid p-0">
                                <div className="card border p-0 pb-3 ps-3 mb-0">
                                    <div className="card-header border-0 pt-3">
                                        <div className="card-options">
                                            <a href={window.location.href} className="card-options-remove" onClick={() => this.setState({ SendProfileIdToDelete: "" })} ><i className="fe fe-x"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-body text-center">
                                        <div className="d-flex justify-content-start">
                                            <div className='d-block text-start ps-1'>
                                                <AlertInformationSvg></AlertInformationSvg>
                                                <h2 className="mb-0 mt-4">{t('warning')}</h2>
                                            </div>
                                        </div>
                                        <p className="mt-4 ps-1 card-text text-danger text-start">{t('confirmDeleteProfile')}</p>
                                    </div>
                                    <div className="card-footer text-center border-0 pt-0">
                                        <div className="row">
                                            <div className="d-flex justify-content-end pe-5">
                                                <Button onClick={() => this.setState({ SendProfileIdToDelete: "" })} label='cancel' color='white' className="me-4" noBlock ></Button>
                                                <Button onClick={() => this.deleteProfile()} label="delete" noBlock></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <div className="modal fade" id="modaldEditProfile"></div>
                    </>
                : 
                    <>
                        <Card title={t('createProfile')}>
                            <Form model={[ this.state, this.setState.bind(this) ]}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <TextField attr="profileNameForm" label="name" ></TextField>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <SelectField attr="sourceFrom" label="name" options={allSources} disabled={this.state.profileIdForm.length !== 0} ></SelectField>
                                    </div>

                                    <div className="col-md-6 mb-3" hidden={this.state.profileSendTypeForm !== "MAIL"}>
                                        <EmailField label="sendType" value="Email" disabled ></EmailField>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <CronScheduler  modifyFrequency={(freq:string)=>{this.setState({...this.state,profileFrequencyForm:freq})}} startValue={this.state.profileFrequencyForm} ></CronScheduler>
                                    </div>

                                    <div className="col-md-6 mb-3" hidden={this.state.profileSendTypeForm !== "MAIL"}>
                                        <SelectField attr="profileFormatFomr" label={`${T_PREFIX}formatFile`} options={this.state.optionsFormat} ></SelectField>
                                    </div>

                                    <div className="col-md-6 mb-3" hidden={this.state.profileSendTypeForm !== "MAIL"}>
                                        <EmailField label="Email" attr="profileMailForm" onChange={(event) => this.setState({ profileMailForm: event.target.value }, () => { this.profileValidation() })} ></EmailField>
                                    </div>

                                    <div className="col-md-6 mb-3" hidden={this.state.sourceForm !== "TRANSACTIONS"}>
                                        <TypeaheadField attr="roles" label="state" options={this.state.roleOptionList} ></TypeaheadField>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                        <br></br>
                        <div className="d-flex justify-content-end">
                            <Button className='m-3 me-0 ' color='light' onClick={() => this.setState({...this.state, showViewCreateUser: false})} label='cancel' noBlock></Button>
                            <Button className='m-3' onClick={() => this.saveSendProfile()} label='save' noBlock></Button>
                        </div>
                    </>
                } 
            </Page>
        )
    };
}

export default withTranslation()(SendProfileManagement);
