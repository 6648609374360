import { Component } from 'react';
import NavigationTabLinks from '../containers/navigationTabLinks';
import {WebCache} from "../../services/WebCache";

class NavigationTabLinksForTransactions extends Component {
    render() {
        return (
                WebCache.getCurrentUser()?.countryId == 2 ?
                    <NavigationTabLinks
                        items={[
                            {to: '/sales/transactions', icon: 'fe fe-credit-card', label: 'transactionReport'},
                            {to: '/sales/pendingOrders', icon: 'fe fe-calendar', label: 'pendingOrders'}
                        ]}
                    ></NavigationTabLinks> :
                    <NavigationTabLinks
                        items={[
                            {to: '/sales/transactions', icon: 'fe fe-credit-card', label: 'transactionReport'}
                        ]}
                    ></NavigationTabLinks>
        )
    };
}

export default NavigationTabLinksForTransactions;
