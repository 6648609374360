import React from 'react';
import $ from 'jquery';


class GlobalLoader extends React.Component {

    componentDidMount() {
        $("#global-loader").fadeOut("slow");
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div id="global-loader">
                <img src="../assets/images/loader.svg" className="loader-img" alt="Loader"/>
            </div>
        )
    };
}
export default GlobalLoader;