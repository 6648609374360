import { useModel } from "../../contexts/modelContext";
import BaseField from "./baseField";
import { IPropsSelectField } from "./fieldPropertiesInterface";
import modelHelper from "./modelHelper";
import "./selectField.scss";

function SelectField({
  options,
  model,
  attr,
  value,
  label,
  onChange,
  disabled,
  required,
  className
}: IPropsSelectField) {
  const modelContext = useModel();
  const modelState = model || modelContext;
  const { onValueChange, getValue } = modelHelper({ model: modelState, attr, value, onChange });
  
  const getOptions = () => {
    return options.map((option) => {
      let randomKey = (Math.random() + 1).toString(36).substring(7);
      return (
        <option value={option.value} key={`${option.value}-${randomKey}`}>
          {option.label}
        </option>
      )
    });
  };

  return (
    <BaseField attr={attr} label={label} required={required} value={getValue()} className={className} >
      <select
        className="custom-select"
        id={`input-${attr || label}`}
        value={getValue()}
        onChange={onValueChange}
        autoComplete="off"   
        placeholder=" "
        disabled={disabled}
        title={`input-${attr || label}`}
        >
        {getOptions()}
      </select>
    </BaseField>
  );
}

export default SelectField;