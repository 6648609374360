import { AxiosResponse } from 'axios';
import React, { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Modal, Button, Form, Toast} from "react-bootstrap";
import i18n from "i18next";
import {IBranche, IDepartment, ILocation, ITerminal} from "../../models/ITerminal";
import {WebCache} from "../../services/WebCache";
import {TerminalsService} from "../../services/TerminalsService";
import Pagination from "../Pagination";
import i18next from "i18next";
import AutocompleteLocation from "../loyalty/AutocompleteLocation";
import "./BranchManagement.css"
import "./TerminalReport.scss"
import { ClearButton, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {getCLS} from "web-vitals";
import {Option} from "../../models/Option";
import { ColumnDefinition, actionColumnDefinition, statusColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import { ReactComponent as IconEditSVG } from "../../../node_modules/itd-common/assets/icons/edit.svg";
import { ReactComponent as IconTrashSVG } from "../../../node_modules/itd-common/assets/icons/trash.svg"; 
import { ReactComponent as SearchIconSVG } from "../../../node_modules/itd-common/assets/icons/search-icon.svg";
import { ReactComponent as CloseIconSVG } from "../../../node_modules/itd-common/assets/icons/close.svg";
import { ReactComponent as LocationIconSVG } from "../../../node_modules/itd-common/assets/icons/location.svg";
import { ReactComponent as ReportsIconSVG } from "../../../node_modules/itd-common/assets/icons/reports.svg";
import { ReactComponent as SettingsDarkIconSVG } from "../../../node_modules/itd-common/assets/icons/settings-dark.svg";
import { ReactComponent as TerminalIconSVG } from "../../../node_modules/itd-common/assets/icons/terminal.svg";
import { ReactComponent as ActiveStateIconSVG } from "../../../node_modules/itd-common/assets/icons/active-state.svg";

import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { IPageRequest } from '../../models/IPageRequest';
import Page from '../containers/page';
import BaseField from '../controls/fields/baseField';
import InputField from '../controls/fields/inputField';
import { Link } from 'react-router-dom';

interface BranchRow {
    style: string;
    value: string;
    icon: string;
}
interface IProps {
}

interface IState {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    branches: IBranche[],
    terminals: ITerminal[],
    branchToDelete:IBranche|undefined
    nameFilter: string,
    validationError:string,
    idForm: string
    nameForm: string
    departmentNameForm: Option|undefined
    locationNameForm: Option|undefined
    showEditBranchModal:boolean,
    columnsToShow: string[],
    fullAddressForm:string,
    positionForm:{lat:number,lng:number}|null,
    allLocations:Option[],
    allDepartments:Option[],
    allTerminals:Option[],
    selectedTerminals:Option[],
    showWarning:boolean,
    tableMode: TableModeEnum,
    pageRequest: IPageRequest,
    searchState: string,
    isSearching: boolean
}

const pageSize = 20;
const pageQuantity = 10
const T_PREFIX: string = 'pages.branchManagement.';
class BranchManagement extends React.Component<IProps, IState>{
    private columnDefinitions: Array<ColumnDefinition> = [];

    constructor(props: IProps) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        this.state = {
            branchToDelete:undefined,
            activePage: 1,
            mainPage: 0,
            columns: [],
            pages: [],
            branches: [],
            terminals: [],
            tableMode: TableModeEnum.LIST,
            validationError:"",
            idForm:"",
            nameForm:"",
            departmentNameForm:undefined,
            locationNameForm:undefined,
            nameFilter:"",
            showEditBranchModal:false,
            positionForm: {lat: 0, lng: 0},
            fullAddressForm:"",
            columnsToShow:[],
            allLocations:[],
            allDepartments:[],
            selectedTerminals:[],
            showWarning:false,
            allTerminals:[],
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            searchState: "",
            isSearching: false
        }
        for (var i = 1; i <= pageQuantity; i++) {
            this.state.pages.push(i);
        }
        this.columnDefinitions = [
            textColumnDefinition({ key: 'name', label: `${T_PREFIX}columnTable.name`, header: true, percentageWidth: 10 }),
            textColumnDefinition({ key: 'locationName', label: WebCache.getCurrentUser()?.countryId == 2 ? `${T_PREFIX}columnTable.location`: `${T_PREFIX}columnTable.locationArg` , header: true, percentageWidth: 10 }),
            textColumnDefinition({ key: 'departmentName', label: WebCache.getCurrentUser()?.countryId == 2 ? `${T_PREFIX}columnTable.department`: `${T_PREFIX}columnTable.departmentArg`, header: true, percentageWidth: 10 }),
            statusColumnDefinition({ key: 'fullAddress', label: `${T_PREFIX}columnTable.state`, header: true,percentageWidth: 10}),
            actionColumnDefinition({ key: 'edit', header: true, action: { function: this.editBranch.bind(this), icon: <IconEditSVG className='iconEdit'></IconEditSVG>},fixedWidth: 2}),
            actionColumnDefinition({ key: 'delete', header: true, action: { function: this.changeDeleteBranch.bind(this), icon: <IconTrashSVG className='iconDelete'></IconTrashSVG>},fixedWidth: 2 }),
        ]
    }


    componentDidMount() {
        this.getBranches(this.state.nameFilter);
    }
    editBranch(branch: IBranche){
        this.setState({
            idForm: branch.id,
            nameForm: branch.name,
            locationNameForm: this.state.allLocations.find(val=>val.key==branch.locationId),
            departmentNameForm: this.state.allDepartments.find(val=>val.key==branch.departmentId),
            selectedTerminals:this.state.allTerminals.filter(terminal=>terminal.value!=null&&terminal.value.toUpperCase()==branch.name.toUpperCase()),
            showEditBranchModal: true,
            fullAddressForm:branch.fullAddress,
            positionForm:branch.position
        },this.validateErrors);
        setTimeout(this.scrollToModal,500);
    }
    changeDeleteBranch(branch: IBranche){
        this.setState({branchToDelete:branch});
        //this.deleteBranch();
    }

    validateErrors = () => {
        let error = "";
        if (this.state.nameForm.length == 0) {
            error = "needBranchName";
        }else if (!this.state.locationNameForm||this.state.locationNameForm.label.trim().length == 0) {
            error = "needLocationName";
        } else if (!this.state.departmentNameForm||this.state.departmentNameForm.label.trim().length == 0) {
            error = "needDepartmentName";
        } else if (this.state.fullAddressForm==null||this.state.fullAddressForm.length == 0) {
            error = "needfullAddress";
        }else if (this.state.selectedTerminals==null||this.state.selectedTerminals.length == 0) {
            error = "needToAddTerminalsToBranch";
        }
        this.setState({
            ...this.state,
            validationError:error
        });
    }

    saveBranch = () => {
        if(this.state.validationError.length==0){
            TerminalsService.updateBranch({
                id: this.state.idForm,
                name: this.state.nameForm,
                departmentName: this.state.departmentNameForm?.label?this.state.departmentNameForm?.label:"",
                locationName: this.state.locationNameForm?.label?this.state.locationNameForm?.label:"",
                departmentId: this.state.departmentNameForm?.key?this.state.departmentNameForm?.key:"",
                locationId: this.state.locationNameForm?.key?this.state.locationNameForm?.key:"",
                fullAddress:this.state.fullAddressForm,
                position:this.state.positionForm,
                fullDesc:this.state.fullAddressForm,
                branchTerminals:this.state.selectedTerminals.map(prop=>!prop.key?"":prop.key)
            }).then(this.successCallback, this.errorCallback);
        }
    }

    filterBranches = () =>{
        let result= this.state.branches.filter((item) => item.name.toLowerCase().includes(this.state.searchState));
        this.setState({
            ...this.state,
            branches: result,
            isSearching: true
        });
    }
    getBranches = (nameFilter:string) => {

        let tmp=WebCache.getAllBranches();
        let allBranches=[];
        let allLocations:Option[]=[];
        let allDepartments:Option[]=[];
        let allTerminals:Option[]=[];
        for(var i=1;i<tmp.length;i++){
            if(!tmp[i].locationName)
                tmp[i].locationName="";
            if(!tmp[i].departmentName)
                tmp[i].departmentName="";
            if(nameFilter.length<0
                ||tmp[i].departmentName.toUpperCase().indexOf(nameFilter.toUpperCase())>=0
                ||tmp[i].locationName.toUpperCase().indexOf(nameFilter.toUpperCase())>=0
                ||tmp[i].name.toUpperCase().indexOf(nameFilter.toUpperCase())>=0)
            allBranches.push(tmp[i]);
        }
        let newColumns = [];
        for (let prop in allBranches[0]) {
            newColumns.push(prop)
        }

        WebCache.getAllDepartments().forEach((val,index)=>index>0?allDepartments.push({key:val.id,label:val.name,value:val.id,disabled:false}):"");
        WebCache.getAllLocations().forEach((val,index)=>index>0?allLocations.push({key:val.id,label:val.name,value:val.id,disabled:false}):"");
        WebCache.getAllTerminals().forEach((val,index)=>index>0?allTerminals.push({key:val.terminalNumber,label:val.terminalNumber.replace("B24_",""),value:val.branchName,disabled:false}):"");
        var clearForm=this.clearForm();
        clearForm.columns=newColumns;
        clearForm.branches=allBranches;
        clearForm.nameFilter=nameFilter;
        clearForm.columnsToShow=["DEPARTMENTNAME","LOCATIONNAME","NAME"];
        clearForm.allDepartments=allDepartments;
        clearForm.allLocations=allLocations;
        clearForm.showWarning=WebCache.showBranchWarnings();
        clearForm.allTerminals=allTerminals;
        clearForm.showEditBranchModal=false;
        clearForm.isSearching= false;
        clearForm.searchState= "";
        clearForm.terminals= WebCache.getAllTerminals();
        this.setState(clearForm);
    }

    successCallback = (success: AxiosResponse<any>) => {
        if (success.data==true) {
            toast.success(i18next.t("branchUpdated")+"");
            WebCache.initCache().then(()=>this.getBranches(this.state.nameFilter));
        } else{
            toast.error(success.data.message);
        }

    }

    errorCallback = (error: any = "") => {
        if (error != "" && typeof error == "string") toast.error(JSON.stringify(error));
        else toast.error(i18next.t("error on branch creation")+"");
    }

    closeShowEditBranchModal() {
        var clearForm=this.clearForm();
        clearForm.showEditBranchModal=false;
        this.setState(clearForm);
    }

    updateInput = (event: React.ChangeEvent<HTMLInputElement>) => {

            this.setState({
                ...this.state,
                    [event.target.name]: event.target.value
            },this.validateErrors);

    };

    clearForm = ():IState=>{
        return {
            ...this.state,
            idForm: "",
            nameForm: "",
            locationNameForm: {label:"",key:undefined,disabled:false,value:undefined},
            departmentNameForm: {label:"",key:undefined,disabled:false,value:undefined},
            selectedTerminals:[],
            positionForm: WebCache.getCurrentUser()?.countryId==2?{lat: -34.9055016, lng: -56.1851147}:{lat:  -34.6284564, lng: -58.5776372},
            fullAddressForm:WebCache.getCurrentUser()?.countryId==2?"Montevideo, Departamento de Montevideo, Uruguay":"Ciudad Autónoma de Buenos Aires",
        };
    }

    onSuccessLocation = (res:{fullAddress:string, position:{lat:number,lng:number},departmentForm:string,localityForm:string}) => {
        let dep:Option|undefined=this.state.allDepartments.find(val=>val.label==res.departmentForm);
    this.setState({...this.state,
        departmentNameForm:dep==undefined?{label:res.departmentForm,key:undefined,disabled:false,value:undefined}:dep,
        positionForm:res.position,
        locationNameForm:this.state.departmentNameForm?.label==res.departmentForm?this.state.locationNameForm:{label:"",key:undefined,disabled:false,value:undefined},
        fullAddressForm:res.fullAddress },this.validateErrors);
    }

    // @ts-ignore
    private modalDiv: HTMLDivElement | null;
    scrollToModal = () => {
        this.modalDiv?.scrollIntoView({ behavior: "smooth" });
    }

    deleteBranch = () => {
        TerminalsService.deleteBranch(this.state.branchToDelete?.id,this.state.branchToDelete?.fullDesc).then((response)=>{
            if(response.data)
                toast.success(i18next.t("branchDeleted")+"");
            else
                toast.error(i18next.t("branchDeletedError")+"");
            this.setState({branchToDelete:undefined})
            WebCache.initCache().then(()=>this.getBranches(this.state.nameFilter));
        })
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page>
                <>
                    <Modal show={this.state.branchToDelete != undefined}>
                            <div className="card-border border p-0 pb-3">
                                <div className="card-header border-0 pt-3">
                                    <div className="card-options">
                                        <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({ branchToDelete: undefined })} ><i className="fe fe-x"></i></a>
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                    <span className=""><svg xmlns="http://www.w3.org/2000/svg" height="60" width="60"
                                                            viewBox="0 0 24 24"><path fill="#f07f8f"
                                                                                    d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z" /><circle
                                        cx="12" cy="17" r="1" fill="#e62a45" /><path fill="#e62a45"
                                                                                    d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z" /></svg></span>
                                    <h4 className="h4 mb-0 mt-3">{t('warning')}</h4>
                                    <p className="card-text">{t('confirmToDeleteBranch')}</p>
                                </div>
                                <div className="card-footer text-center border-0 pt-0">
                                    <div className="row">
                                        <div className="text-center">
                                            <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({ branchToDelete: undefined })}>{t('cancel')}</a>
                                            <a href={window.location.href} className="btn btn-danger" onClick={() => this.deleteBranch()}>{t('delete')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Modal>
                    <div hidden={!this.state.showEditBranchModal} ref={(el) => { this.modalDiv = el }}>
                        <div className="overlay-branch-panel"></div>
                        <div className="modal-content modal-content-demo branch-abm-panel">
                            <div className="modal-header bg-primary">
                                <h5 className="modal-title text-white">{this.state.idForm.length == 0 ? t("createBranch") : t("editbranch")}  </h5>
                                <button aria-label="Close" className="btn-close" onClick={() => this.closeShowEditBranchModal()}><span className='text-white' aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                <form autoComplete='off' style={{ textAlign: "left" }}>
                                    <div className="row">
                                        <div className="col-md-3 mb-3">
                                            <label className="col-form-label ">{t("name")}:</label>
                                            <input autoComplete='off' autoFocus type="text" className="form-control"
                                                value={this.state.nameForm}
                                                name="nameForm"
                                                onChange={this.updateInput}
                                                id="nameForm" />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label className="col-form-label ">{t("location")}:</label>
                                            <Typeahead
                                                id='location'
                                                inputProps={{autoComplete:"off"}}
                                                onChange={(selected:any) => {
                                                    if(selected.length>0){
                                                        // @ts-ignore
                                                        this.setState({locationNameForm:selected[0]},this.validateErrors);
                                                    }
                                                }}
                                                onInputChange={(text:string)=>{
                                                    this.setState({locationNameForm:{label:text.toUpperCase()
                                                            ,value:undefined,key:undefined,disabled:false}},this.validateErrors);
                                                }}
                                                selected={this.state.locationNameForm==undefined?undefined:[this.state.locationNameForm]}
                                                options={this.state.allLocations}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label className="col-form-label ">{t(WebCache.getCurrentUser()?.countryId==2?"department":"departmentArg")}:</label>
                                            <Typeahead
                                                id='department'
                                                inputProps={{autoComplete:"off"}}
                                                onChange={(selected:any) => {
                                                    if(selected.length>0){
                                                        // @ts-ignore
                                                        this.setState({departmentNameForm:selected[0]},this.validateErrors);
                                                    }
                                                }}
                                                onInputChange={(text:string)=>{
                                                    this.setState({departmentNameForm:{label:text.toUpperCase(),
                                                            value:undefined,key:undefined,disabled:false}},this.validateErrors);
                                                }}
                                                selected={this.state.departmentNameForm==undefined?undefined:[this.state.departmentNameForm]}
                                                options={this.state.allDepartments}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label ">{t("terminals")}:</label>
                                            <Typeahead
                                                id='terminals'
                                                multiple
                                                inputProps={{autoComplete:"off"}}
                                                onChange={(selected:any) => {
                                                    // @ts-ignore
                                                    this.setState({selectedTerminals:selected},this.validateErrors);
                                                }}
                                                selected={this.state.selectedTerminals}
                                                options={this.state.allTerminals}
                                            />
                                        </div>
                                        <AutocompleteLocation
                                            onSuccess={this.onSuccessLocation}
                                            fullAddress={this.state.fullAddressForm}
                                            position={this.state.positionForm}
                                            showCancelButton = {true}
                                        ></AutocompleteLocation>
                                    </div>
                                </form>
                                <label className="custom-control mt-4 text-danger">
                                    <span>{t(this.state.validationError)}</span>
                                </label>
                            </div>
                            <div className="card-footer">
                                    <button  type="button"  className="btn btn-light"
                                            onClick={() => this.closeShowEditBranchModal()} ><i className="fe fe-save me-2"></i>{t("cancel")}
                                    </button>
                                    <button style={{marginLeft:"10px"}} type="button"  className={"btn btn-primary "+(this.state.validationError==""?"btn-primary":"btn-gray disabled")}
                                            onClick={() => this.saveBranch()} ><i className="fe fe-save me-2"></i>{t(this.state.idForm.length == 0 ? 'createBranch' : "editbranch")}
                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="main-container container-fluid">
                        <ToastContainer
                            autoClose={3000}
                        ></ToastContainer>
                        <div className="text-wrap mb-4 row" hidden={!this.state.showWarning}>
                            <div >
                                <div className="alert alert-danger">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-hidden="true">×
                                    </button>
                                    <span className=""><svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"
                                                            viewBox="0 0 24 24"><path fill="#fad383"
                                                                                    d="M15.728,22H8.272a1.00014,1.00014,0,0,1-.707-.293l-5.272-5.272A1.00014,1.00014,0,0,1,2,15.728V8.272a1.00014,1.00014,0,0,1,.293-.707l5.272-5.272A1.00014,1.00014,0,0,1,8.272,2H15.728a1.00014,1.00014,0,0,1,.707.293l5.272,5.272A1.00014,1.00014,0,0,1,22,8.272V15.728a1.00014,1.00014,0,0,1-.293.707l-5.272,5.272A1.00014,1.00014,0,0,1,15.728,22Z"/><circle
                                        cx="12" cy="16" r="1" fill="#f7b731"/><path fill="#f7b731"
                                                                                    d="M12,13a1,1,0,0,1-1-1V8a1,1,0,0,1,2,0v4A1,1,0,0,1,12,13Z"/></svg></span>
                                    <strong>{t("needToFillBranchMapAddress")}</strong>
                                    {/*
                                    <hr className="message-inner-separator">
                                        <p>Best check yo self, you're not looking too good</p>
                                    </hr>
                                */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h3 className="title">{t("branches")}</h3>
                                    </div>
                                    {/* <div className="col-md-8 d-flex align-items-center justify-content-end">
                                        <h6 className='pt-1 text-center h-10 subtitle'>{t(`${T_PREFIX}seeAllBranchs`)}</h6>
                                    </div> */}
                                </div>    
                                <div className='row'>
                                        <div className="col-md-2">
                                            <br />
                                            <a className="modal-effect btn btn-primary mb-3 btn-block" data-bs-effect="effect-scale" data-bs-toggle="modal" onClick={() => {
                                                var clearForm=this.clearForm();
                                                clearForm.showEditBranchModal=true;
                                                this.setState(clearForm,this.validateErrors);
                                                setTimeout(this.scrollToModal,500);
                                            }} href={window.location.href} > {t("createBranch")}
                                            </a>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-md-3 mb-5 filters">
                                        <InputField
                                            type="text"
                                            attr='searchState'
                                            label={t(`${T_PREFIX}searchBranch`)}
                                            model={[this.state, this.setState.bind(this)]}   
                                            sufix={
                                                this.state.isSearching ?
                                                    <a href={window.location.href} className="pe-2 h-100 aling-items-end d-flex"
                                                    onClick={()=> this.getBranches("")}
                                                    >
                                                        <CloseIconSVG></CloseIconSVG>
                                                    </a>
                                                :
                                                    <a href={window.location.href} className="pe-2 h-100 aling-items-end d-flex"
                                                        onClick={()=> this.filterBranches()}
                                                    >
                                                        <SearchIconSVG></SearchIconSVG>
                                                    </a>
                                            }
                                        ></InputField>
                                    </div>
                                </div>
                                <div className='terminal-accordion-table'>
                                    <div className='row'>
                                        <CustomTable
                                                rowObjects={this.state.branches}
                                                columnDefinitions={this.columnDefinitions}
                                                config={{
                                                    mode: this.state.tableMode,
                                                    paginator: {
                                                        mainPage: this.state.mainPage,
                                                        modifyMainPage: (value: number) => {
                                                            this.setState({
                                                                mainPage: this.state.mainPage + value
                                                            }, () => this.getBranches(this.state.nameFilter))
                                                        },
                                                        pageRequest: this.state.pageRequest
                                                    }
                                                    // customButtonAction: this.state.trxToMerge !== "" ? { action: this.mergeTrx, buttonText: t("mergeTransaction") } : undefined
                                                }}
                                        ></CustomTable>
                                    </div>
                                    <div className='row mt-5 mb-5 col-md-12'>
                                        <div className="col-md-5 div-infoTerminals " typeof='button'>
                                            <Link to={"/terminals/reports"}>
                                                <div className='p-0 h-100 w-100'>
                                                    <ReportsIconSVG className='h-50 ps-2'></ReportsIconSVG>
                                                    <h6 className='mt-3 ps-2 text-dark'>{t("reports")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='col-md-2'></div>
                                        <div className="col-md-5 div-infoTerminals" typeof='button'>
                                            <Link to={"/terminals/map"}>
                                                <div className='p-0 h-100 w-100'>
                                                    <LocationIconSVG className='h-50 ps-2'></LocationIconSVG>
                                                    <h6 className='mt-3 ps-2 pt-1 text-dark'>{t("location")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="col-md-3 div-info div-info-3 ms-md-4" typeof='button'>
                                            <Link to={"/terminals/reports"}>
                                                <div className='p-0 h-100 w-100'>
                                                    <SettingsDarkIconSVG className='h-50 ps-2'></SettingsDarkIconSVG>
                                                    <h6 className='mt-3 ps-2 text-dark'>{t("settings")}</h6>
                                                </div>
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 mt-5 mb-5">
                                            <h3 className="title">{t("terminals")}</h3>
                                        </div>
                                        <div className="col-md-9 d-flex align-items-center justify-content-end">
                                            <Link to={"/terminals/reports"}>
                                                <h6 className='pt-1 text-center text-dark h-10 subtitle textAllTerminals'>{t(`${T_PREFIX}seeAllTerminals`)}</h6>
                                            </Link>
                                        </div>
                                    </div>   
                                    <div className='row mt-5 mb-5'>
                                        {
                                            this.state.terminals.map((item, index) => {
                                                    if(index < 4 && item.terminalNumber !== "0000"){
                                                        return <div className="col-md-3 div-info div-info-3 h-100 ms-md-3">
                                                            <div>
                                                                <TerminalIconSVG className='h-30'></TerminalIconSVG>
                                                            </div>
                                                            <h4 className='pt-3 pb-1 ps-3'>{item.branchName}</h4>
                                                            <ul className='ps-3 card-detail'>
                                                                <li><p><span className='span-detail-ligth'>{t(`${T_PREFIX}card-detail.terminal`)}: </span><span className='span-detail-dark'>{item.terminalNumber.replace("B24_","")}</span></p></li>
                                                                <li><p><span className='span-detail-ligth'>{t(`${T_PREFIX}card-detail.address`)}: </span><span className='span-detail-dark'>{item.locationName}</span></p></li>
                                                                <li><p><span className='span-detail-ligth'>{WebCache.getCurrentUser()?.countryId == 2 ? t(`${T_PREFIX}card-detail.department`):t(`${T_PREFIX}card-detail.departmentArg`)}: </span><span className='span-detail-dark'>{item.departmentName}</span></p></li>
                                                            </ul>
                                                        </div>
                                                    }
                                            })
                                        }
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Page>
        )
    };



}
export default withTranslation()(BranchManagement);
