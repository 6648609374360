export class StringHelper {

    static toCamelCase(text: string): string {
        let words: Array<string> = [];

        let textMatches: Array<RegExpMatchArray> = Array.from(text.matchAll(/[-_\s(A-Z)]/g));

        for (let textMatchIndex = 0; textMatchIndex < textMatches.length; textMatchIndex++) {
            const textMatchItem: RegExpMatchArray = textMatches[textMatchIndex];
            const textMatchNextItem: RegExpMatchArray = textMatches[textMatchIndex + 1];

            let startTextIndex: number = textMatchItem.index || 0;
            let endTextIndex: number = (textMatchNextItem) ? textMatchNextItem.index || 0 : text.length;
            words.push(text.substring(startTextIndex, endTextIndex));
        }

        const capitalizedWords: Array<string> = words.map((word: string, index: number) => {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });

        return capitalizedWords.join("");
    }

    static base64DecodeURL(b64urlstring: string) {
        return new Uint8Array(atob(b64urlstring.replace(/-/g, '+').replace(/_/g, '/')).split('').map(val => {
            return val.charCodeAt(0);
        }));
    }

    static toBinary(string: []) {
        const charCodes = new Uint8Array(string);
        let result = '';
        for (let i = 0; i < charCodes.byteLength; i++) {
            result += String.fromCharCode(charCodes[i]);
        }
        return result;
    }

    static base64ToBuffer(base64: string) {
        return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
    }
    
    static base64Encode(string: []) {
        return btoa(StringHelper.toBinary(string));
    }
}