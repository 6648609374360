import {axiosApiInstance} from "../HttpService";
import {IClientUserWebModelRequest} from "../../requests/loyalty/IGetClientWebModelRequest";
import {ILoyaltyMerchant, ILoyaltyPromotion, ITransactionLoyaltyWebModelRequest} from "../../models/loyalty/IClient";
import {IPromotionCoupon, IPromotionCouponBalance} from "../../models/loyalty/ILoyaltyCoupon";

export class ClientService{

    public static getAll(param: IClientUserWebModelRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Loyalty/getAllClients`;
        return axiosApiInstance.post(dataURL, param);
    }

    public static getLoyaltyMerchant() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Loyalty/getLoyaltyMerchant`;
        return axiosApiInstance.post(dataURL);
    }
    static getAllPromotionCoupons() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/getAllPromotionCoupons`;
        return axiosApiInstance.post(dataURL);
    }
    static getPromotionCouponsConfiguration() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/getConfiguration`;
        return axiosApiInstance.post(dataURL);
    }

    static updateCouponCoverage(request: { polygonPath: { lat: number; lng: number }[] }) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/updateCouponCoverage`;
        return axiosApiInstance.post(dataURL,request);
    }


    static updateMerchant(merchant: ILoyaltyMerchant) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Loyalty/updateMerchant`;
        return axiosApiInstance.post(dataURL,merchant);
    }

    static uploadFile(file: File) {
            let dataURL:string = `${process.env.REACT_APP_API_FILE_SRV_URL}/apiFileUploader/fileServer/uploadLoyaltyImage`;
            const formData = new FormData();
            formData.append(`formFile`, file);
            formData.append(`fileName`, file.name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            return axiosApiInstance.post(dataURL, formData, config);
    }

    static getLastAuthorization() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Loyalty/getLastAuthorization`;
        return axiosApiInstance.post(dataURL);
    }

    static redeemPoints(qrInput: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Loyalty/redeemPoints`;
        return axiosApiInstance.post(dataURL,{code:qrInput});
    }

    static getAllPromotions() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/LoyaltyPromotion/getAllPromotions`;
        return axiosApiInstance.post(dataURL);
    }

    static createPromotions(request: ILoyaltyPromotion) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/LoyaltyPromotion/createPromotions`;
        return axiosApiInstance.post(dataURL,request);
    }

    static updatePromotions(request: ILoyaltyPromotion) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/LoyaltyPromotion/updatePromotions`;
        return axiosApiInstance.post(dataURL,request);
    }
    static deletePromotions(request: ILoyaltyPromotion) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/LoyaltyPromotion/deletePromotions`;
        return axiosApiInstance.post(dataURL,request);
    }

    static getAllTransactions(request: ITransactionLoyaltyWebModelRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Loyalty/getAllTransactions`;
        return axiosApiInstance.post(dataURL,request);
    }


    static createCoupon(request: IPromotionCoupon|null) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/createCoupon`;
        return axiosApiInstance.post(dataURL,request);
    }

    static updateCoupon(request: IPromotionCoupon|null) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/updateCoupon`;
        return axiosApiInstance.post(dataURL,request);
    }

    static deleteCoupon(couponId: string) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/deleteCoupon`;
        return axiosApiInstance.post(dataURL,{id:couponId});
    }

    static updateCouponBalances(allPromotionBalances: IPromotionCouponBalance[] | undefined) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/updateCouponBalances`;
        return axiosApiInstance.post(dataURL,{balances:allPromotionBalances});
    }
    static updateCouponBalancesByPartner(balance: IPromotionCouponBalance) {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/PromotionCoupon/updateCouponBalancesByPartner`;
        return axiosApiInstance.post(dataURL,balance);
    }

}
