import axios, { AxiosResponse, AxiosResponseHeaders } from 'axios';
import React, { useLayoutEffect,useState } from 'react';

import DatePicker, { DateObject, DatePickerProps, getAllDatesInRange } from "react-multi-date-picker"
import Swal from "sweetalert2"
import Pagination from '../../Pagination';
import { withTranslation } from 'react-i18next';
import "./ClientReport.css"
import {WebCache} from "../../../services/WebCache";
import {UtilService} from "../../../services/UtilService";
import ClientGrid from "./ClientGrid";
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {IClient} from "../../../models/loyalty/IClient";
import {IClientUserWebModelRequest} from "../../../requests/loyalty/IGetClientWebModelRequest";
import {ClientService} from "../../../services/loyalty/ClientService";
import Page from '../../containers/page'


interface IProps {
}

interface Option {
    value: any;
    label: string;
    key?: string;
    disabled?: boolean;
}

interface IState {
    msg: string,
    activePage: number,
    mainPage: number,
    pages: number[],
    clients: IClient[],
    columns: Array<string>
    errors: string[],

    nameFilter:string,
    allClientMails:string,
    columnsToShow: string[ ]
}
const pageSize = 13;
const pageQuantity = 3
let doQuery = false;
const allClientColumns:string[]=[
    "EMAIL","PHONENUMBER","NOMBRE","CREATEDATE","LOYALTYBALANCE","LASTLOYALTYGENERATEDAMOUNT","TOTALLOYALTYGENERATEDAMOUNT","TOTALREDEEMPOINTS","FIRTSACTIVEMOVEMENTAMOUNT", "LOYALTYPOINTSNEXTVTO"
];

class ClientReport extends React.Component<IProps, IState>{


    updateDimensions = () => {

        const { innerWidth: width, innerHeight: height } = window;
        if(UtilService.isSmallScreen()&&this.state.columnsToShow.length==0){
            this.setState({...this.state,columnsToShow:allClientColumns});
        }else if(width>992&&this.state.columnsToShow.length>0){
            this.setState({...this.state,columnsToShow:allClientColumns});
        }
    };
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        if(doQuery){
            doQuery=false;
            this.getClients();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    constructor(props: IProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            msg: "",
            activePage: 1,
            mainPage: 0,
            pages: [],
            columns: [],
            clients: [],
            errors: [],
            nameFilter:"",
            columnsToShow:allClientColumns,
            allClientMails:""

        }
        for(var i=1;i<=pageQuantity;i++){
            this.state.pages.push(i);
        }
    }

    getClientsRequest = (all:boolean) => {

        const tranWebModel: IClientUserWebModelRequest = {
            Name:(this.state.nameFilter==null||this.state.nameFilter=="")?"":this.state.nameFilter,
            Skip: all?0:(this.state.mainPage * pageSize * pageQuantity),
            Take:  all?0:((pageSize * pageQuantity) + 1)
        };
        return tranWebModel;
    }

    getClients = () => {
        ClientService.getAll(this.getClientsRequest(false)).then((response: AxiosResponse<IClient[]>) => {
            if(response==null||response.data==null||response.data.length==0){
                this.setState({...this.state,
                    clients: [],
                    errors: []
                })
                return;
            }
            let newColumns = [];
            for (let prop in response.data[0]) {
                newColumns.push(prop)
            }


            var allClientMails="";
            for (let prop in response.data) {
                allClientMails+=response.data[prop].email+";";
            }

            this.setState({...this.state,
                columns: newColumns,
                clients: response.data,
                allClientMails:allClientMails,
                errors: []
            });

        }).catch(error => {
            this.setState({...this.state,
                errors: error.response.data
            });
            this.setState({...this.state,
                clients: []
            })
            console.log('error:', error);
        }
         );
    }

    handleChange(event:any) {
         this.setState({...this.state,[event.target.name]:event.target.value});

    }
    handleSubmit(event:any) {
        event.preventDefault();
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page>
            <>
            <form onSubmit={this.handleSubmit}>
                    <div className="main-container container-fluid">
                        <div className="page-header">

                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{t("clientReport")}</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="form-label"> {t('name')}</label>
                                                <input name="nameFilter" className="border-start-0 ms-0 form-control" type="text"
                                                       placeholder={t('name')}  value={this.state.nameFilter}  onChange={(event)=>this.handleChange(event)}/>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label"> {t('clientWithoutSalesInPeriod')}</label>
                                                <input name="nameFilter" className="border-start-0 ms-0 form-control" type="text"
                                                       placeholder={t('daysWithoutPurchase')}  value={this.state.nameFilter}  onChange={(event)=>this.handleChange(event)}/>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label"> {t('clientsWithMostSales')}</label>
                                                <input name="nameFilter" className="border-start-0 ms-0 form-control" type="text"
                                                       placeholder={t('topclients')}  value={this.state.nameFilter}  onChange={(event)=>this.handleChange(event)}/>
                                            </div>
                                            <div className="col-md-1"></div>
                                            <div className="col-md-2">
                                                <br></br>
                                                <br></br>
                                                <div className="">
                                                    <button type="button"
                                                            className="btn btn-primary mb-3 btn-block" onClick={this.getClients}>{t('query')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <p />
                                        <ClientGrid
                                            clients={this.state.clients}
                                            columns={this.state.columns}
                                            columnsToShow={this.state.columnsToShow}
                                            mainPage={this.state.mainPage}
                                            pages={this.state.pages}
                                            activePage={this.state.activePage}></ClientGrid>
                                       <div className='row'>
                                            <div className='col-md-10'>
                                                <Pagination
                                                    pageSize={pageSize}
                                                    pageQuantity={pageQuantity}
                                                    modifyMainPage={(value: number, newPages: Array<number>) => { this.setState({ mainPage: this.state.mainPage + value, pages: newPages }, () => this.getClients()) }}
                                                    changePage={(page: number) => this.setState({ activePage: page })}
                                                    dataL={this.state.clients.length}
                                                    mainPage={this.state.mainPage}
                                                ></Pagination>
                                            </div>

                                        </div>
                                </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-md-2">
                                                    <a href={"mailto:"+this.state.allClientMails}>
                                                        <button type="button"
                                                                className="btn btn-primary mb-3 btn-block" >{t('sendMailing')}
                                                        </button>
                                                    </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </>
            </Page>
        )
    };
}
export default withTranslation() (ClientReport);
