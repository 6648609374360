import { axiosApiInstance } from "./HttpService";
import { IManagementSendProfile, ISendProfile, ISendProfileWebModelRequest} from '../models/ISendProfile';

export class SendProfileService {
    static saveSendProfile(sendProfile: ISendProfile) {
        let dataURL: string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/SendProfile/createProfile`;
        return axiosApiInstance.post(dataURL, sendProfile);
    }

    static updateSendProfile(sendProfile: ISendProfile) {
        let dataURL: string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/SendProfile/updateProfile`;
        return axiosApiInstance.post(dataURL, sendProfile);
    }

    static deleteSendProfile(id: string) {
        let dataURL: string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/SendProfile/deleteProfile`;        
        return axiosApiInstance.post(dataURL, { id: id });
    }

    static getSendProfile(sendProfileTable: ISendProfileWebModelRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/SendProfile/getAllProfile`;
        return axiosApiInstance.post(dataURL, { sendProfileTable });
    }

    public static getAll(sendProfileRequest: ISendProfileWebModelRequest) {
        let dataURL: string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/SendProfile/getAllProfile`;
        return axiosApiInstance.post(dataURL, {
            Name: sendProfileRequest.Name,          
            skip: sendProfileRequest.Skip,
            take: sendProfileRequest.Take

        });
    }

}
