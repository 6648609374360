export const allOperationTypesFilters = [
    "all",
    "C",
    "A",
    "D",
    "T",
    "L",
    "M",
    "N",
    "O",
    "R",
    "P",
    "F",
    "G",
    "H",
    "B",
    "I"
]

export const allTransactionStatesFilter = {
    "0":[],
    "1":[1],
    "2":[2,3,4,13,14,16],
    "5":[5],
    "6":[6],
    "7":[7,8,9],
    "10":[10],
    "11":[11],
    "12":[12]
}

export const allAcquirersFilters = [
    "0000",
    "0001",
    "0002",
    "0019",
    "0005",
    "0008",
    "0004",
    "0006",
    "0003",
    "0050",
    "0078",
    "0025" ,
    "0090",
    "0700"
]

export const allResponseCodes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "10",
    "11",
    "12",
    "13",
    "14",
    "21",
    "25",
    "30",
    "38",
    "41",
    "43",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "61",
    "65",
    "76",
    "77",
    "85",
    "89",
    "91",
    "94",
    "95",
    "96",
    "CT",
    "TO",
    "EI",
    "EB",
    "TN",
    "LL",
    "LV",
    "EC",
    "EE",
    "MK",
    "NR",
    "NP",
    "ER",
    "OF",
    "EA"
]

export const allPaymentModes = ["C","D","ECOM","QRTRANSFER","QRCARD"]
