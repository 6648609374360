import { TFunction } from "react-i18next";
import { Option } from "../models/Option";
import {WebCache} from "../services/WebCache";

interface InputInterface {
    [key: string]: any;
}

export class OptionMapperHelper {
    
    constructor(private t: TFunction) {}

    public buildSelectOptionsFromEnum = (operationList: Array<string>, translationPrefix?: string): Array<Option> => {
        return operationList.map(operation => {
            return this.translateOptionValue({ value: operation, label: operation }, translationPrefix);
        });
    }
        
    public translateOptionValue = (option: Option, prefix?: string): Option => {
        return { value: option.value, label: this.t(`${prefix || ''}${option.label}`) };
    }

    public translateOptionValues = (optionList: Array<Option>): Array<Option> => {
        return optionList.map(option => {
            return this.translateOptionValue(option);
        });
    }

    public buildSelectOptionsFromAnArrayWithDifferentInterface(array: InputInterface[], valueKey: string, labelKey: string): Option[] {
        const mappedOptionList: Option[] = array.map((item) => {
            return {
                value: item[valueKey],
                label: item[labelKey].toString()
            };
        });
  
        return this.translateOptionValues(mappedOptionList);
    }

    static getAllMerchantNumbersOptions(fiscalDocument: string,includeAll:boolean) {
        return WebCache.getAllMerchantNumbers(includeAll).filter(x=>fiscalDocument.length==0||
            (includeAll&&x.key=="")||
            x.key?.indexOf(fiscalDocument)==0).map(x=>{return {key:x.label=="all"?"":x.label,label:x.label,value:x.label=="all"?"":x.label,disabled:x.disabled}})
    }
}