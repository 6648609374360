import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//import DateTime  from 'luxon';
import Backend from 'i18next-http-backend';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(Backend)
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: ['es','en'],
        supportedLngs: ['es','en'],
        ns: ['common', 'translation', 'translation-help'],
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
        /*,
        resources: {
            es: {
                translation: {
                   "forgotPassword":"Olvido?"
                }
            }
        }*/
    });

// new usage
/*
i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
    return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE)
});*/

export default i18n;