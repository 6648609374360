import { useModel } from "../../contexts/modelContext";
import { IClassNameProps } from "../controlsPropertiesInterface";
import "./dateField.scss";
import { IPropsFieldModel, IPropsFieldValue, IPropsInputChange, IPropsName } from "./fieldPropertiesInterface";
import RadioButtonField from "./radioButtonField";

interface IFieldDefinition {
    key: string;
    value: string;
    icon?: string;
    label?: string;
};

interface IPropsFieldDefinitions {
    fieldDefinitions: Array<IFieldDefinition>
}

type IPropsRadioButtonGroup = (IPropsFieldValue | IPropsFieldModel) & IPropsName & IPropsFieldDefinitions & IPropsInputChange & IClassNameProps;

function RadioButtonGroup({
  model,
  attr,
  name,
  onChange,
  fieldDefinitions,
  className
}: IPropsRadioButtonGroup) {
    const modelContext = useModel();

    return (
        <div className={`btn-group radiobtns ${className}`}>
            {
                fieldDefinitions.map((fieldDefinition) => (
                    <RadioButtonField
                        model={model || modelContext}
                        attr={attr || ''}
                        key={fieldDefinition.key}
                        id={fieldDefinition.key}
                        value={fieldDefinition.value}
                        label={fieldDefinition.label || ''}
                        icon={fieldDefinition.icon || ''}
                        name={name}
                        onChange={onChange}
                    ></RadioButtonField>
                ))
            }
        </div>
    );
}

export default RadioButtonGroup;