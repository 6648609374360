import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { UserService } from "../services/UserService";
import LabelMessage from './LabelMessage';
import "./ResetPassword.scss";
import { ReactComponent as LogoSVG } from "../../node_modules/itd-common/assets/images/brand/logo.svg";
import Footer from './layout/Footer';
import SubmitButton from './controls/buttons/submitButton';
import Form from './containers/form';
import PasswordField from './controls/fields/passwordField';

interface IProps { }
let ResetPassword: React.FC<IProps> = () => {
    const T_PREFIX: string = 'pages.resetPassword.';
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, reSetNewPassword] = useState('');
    let aux = "";
    const [isValid, setIsValid] = useState(false);
    const [has8Chars, setHas8Chars] = useState(false);
    const [has1Uppercase, setHas1Uppercase] = useState(false);
    const [has1Lowercase, setHas1Lowercase] = useState(false);
    const [has1Number, setHas1Number] = useState(false);
    const [has1SpecialChar, setHas1SpecialChar] = useState(false);
    const [hasDifferentConsecutiveChars, setHasDifferentConsecutiveChars] = useState(true);
    const [showPasswordEnabled, setShowPasswordEnabled] = useState(false);
    const [message, setMessage] = useState('');

    let update = (event: React.ChangeEvent<HTMLInputElement>): void => {

            const emailRegex = /^(?!.*(.)\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#^])[A-Za-z\d@$!%*?&.#^]{8,}$/;
            aux = event.target.value;
            passwordValidate(aux);
            if (emailRegex.test(aux)) {
                setIsValid(true);
            } else {
                setIsValid(false);
                setMessage('please enter a valid password');
            }
    };

    let resetPassword = (): void => {
        if (isValid && newPassword == reNewPassword) {
            let token ="";
            if(window.location.href.indexOf("token=")>0){
                token = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("token="))).get("token")+""
            }
            UserService.RecoverPass(newPassword, token).then((response) => {
                if (response.status != 200) {
                    Swal.fire({
                        title: t('errorOcurred'),
                        html: t("contactSupport"),
                        confirmButtonColor: '#f60',
                        timer: 6000,
                        timerProgressBar: true,
                        icon:"warning"
                    });
                } else {
                    Swal.fire({
                        title: t('PasswordChanged'),
                        html: t("TryLogin"),
                        confirmButtonColor: '#f60',
                        icon:"success"
                    }).finally(() => {
                        UserService.logout();
                    });
                }
            }).catch((data) => {
               var message= "contactSupport";
               if(data.response.data!=null&&data.response.data.length>0)
                   message=data.response.data[0];
                Swal.fire({
                    title: t('errorOcurred'),
                    html: t(message),
                    confirmButtonColor: '#f60',
                    timer: 6000,
                    timerProgressBar: true,
                    icon:"warning"
                });
            });
        }
    };
    
    return (
        <React.Fragment>
            <div className="page reset-password">
                <div className="">
                    <div className="container-reset">
                        <div className="form">
                                <div className="backward">
                                    <div className="backward__init">
                                        <Link to={'/login'} className="link">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="ico/arrow-left">
                                                    <path id="Vector 1" d="M10 4L2 12L10 20" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <line id="Line 7" x1="3" y1="12" x2="21" y2="12" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                            </svg>            
                                            <span className='backward__init__callback'>{t(`${T_PREFIX}returnToLogin`)}</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className='brand-logo-container'>
                                    <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                                </div>
                                <span className="login100-form-title pb-5">
                                    {t(`${T_PREFIX}title`)}
                                </span>
                                <Form model={[reNewPassword, reSetNewPassword]}>
                                    <div>
                                        <PasswordField
                                            attr="newPassword"
                                            value={newPassword}
                                            label={t(`${T_PREFIX}newPassword`)}
                                            onChange={(value) => { setNewPassword(value.target.value); update(value) }}
                                            required={true}
                                            autoFocus
                                        ></PasswordField>
                                        <PasswordField
                                            attr="reNewPassword"
                                            value={reNewPassword}
                                            label={t(`${T_PREFIX}reEntryNewPassword`)}
                                            onChange={(value) => { reSetNewPassword(value.target.value) }}
                                            required={true}
                                        ></PasswordField>
                                        <LabelMessage valid={newPassword === reNewPassword} message={t(`${T_PREFIX}passwordsNotMatch`)} ></LabelMessage>
                                            {isValid ?
                                                <></>
                                                :
                                                <div>
                                                    <br />
                                                    <div className='text'>{t(`${T_PREFIX}passwordMustInclude`)}</div>
                                                    <div style={{ textDecoration: has8Chars ? 'line-through' : 'none' }}>{t(`${T_PREFIX}passwordMustHave8`)}</div>
                                                    <div style={{ textDecoration: has1Uppercase && has1Lowercase ? 'line-through' : 'none'}}>{t(`${T_PREFIX}passwordUppercaseAndLowercase`)}</div>
                                                    <div style={{ textDecoration: has1Number ? 'line-through' : 'none'}}>{t(`${T_PREFIX}passwordOneNumber`)}</div>
                                                    <div style={{ textDecoration: has1SpecialChar ? 'line-through' : 'none'}}>{t(`${T_PREFIX}passwordSpecialCharacter`)}</div>
                                                    <div style={{ textDecoration: hasDifferentConsecutiveChars ? 'line-through' : 'none'}}>{t(`${T_PREFIX}passwordTwoCharacters`)}</div>
                                                    <br />
                                                </div>
                                            }
                                        <div className="boton" >
                                            <div className="boton__click"  >
                                                <SubmitButton onClick={resetPassword} className='btn btn-primary d-grid envio' label={t(`${T_PREFIX}finish`)}></SubmitButton>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer isValid={false} />
        </React.Fragment>
    )

    function passwordValidate(password:string) {
        setHas8Chars(password.length > 7);
        setHas1Uppercase(/[A-Z]/.test(password));
        setHas1Lowercase(/[a-z]/.test(password));
        setHas1Number(/\d/.test(password));
        setHas1SpecialChar(/[@$!%*?&.#^]/.test(password));
        setHasDifferentConsecutiveChars(!(/(.)\1{2}/.test(password)));

    }
}
export default ResetPassword


