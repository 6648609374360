import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { UserService } from "../services/UserService";
import LabelMessage from './LabelMessage';
import "./ResetPassword.scss";
import { ReactComponent as LogoSVG } from "../../node_modules/itd-common/assets/images/brand/logo.svg";
import Footer from './layout/Footer';
import SubmitButton from './controls/buttons/submitButton';
import Form from './containers/form';
import PasswordField from './controls/fields/passwordField';

interface IProps { }
let ResetDevice: React.FC<IProps> = () => {
    const T_PREFIX: string = 'pages.resetDevice.';
    const { t } = useTranslation();

    let resetDevice = (): void => {
        let token ="";
        if(window.location.href.indexOf("token=")>0){
            token = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("token="))).get("token")+""
        }
        UserService.FinishResetDevices(token).then((response) => {
            if (response.status != 200) {
                Swal.fire({
                    title: t('errorOcurred'),
                    html: t("contactSupport"),
                    confirmButtonColor: '#f60',
                    timer: 6000,
                    timerProgressBar: true,
                    icon:"warning"
                });
            } else {
                Swal.fire({
                    title: t('DeviceReset'),
                    html: t("ReEntry"),
                    confirmButtonColor: '#f60',
                    icon:"success"
                }).finally(() => {
                    window.location.href = '/#/login'
                });
            }
        }).catch(() => {
            Swal.fire({
                title: t('errorOcurred'),
                html: t("contactSupport"),
                confirmButtonColor: '#f60',
                timer: 6000,
                timerProgressBar: true,
                icon:"warning"
            });
        });
    };
    
    return (
        <React.Fragment>
            <div className="page reset-password">
                <div className="">
                    <div className="lostphone-reset">
                        <div className="form">
                                <div className="backward">
                                    <div className="backward__init">
                                        <Link to={'/login'} className="link">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="ico/arrow-left">
                                                    <path id="Vector 1" d="M10 4L2 12L10 20" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <line id="Line 7" x1="3" y1="12" x2="21" y2="12" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                            </svg>            
                                            <span className='backward__init__callback'>{t(`${T_PREFIX}returnToLogin`)}</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className='brand-logo-container'>
                                    <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                                </div>
                                <span className="login100-form-title pb-5">
                                    {t(`${T_PREFIX}title`)}
                                </span>
                                <Form>
                                    <div className="boton col-md-8">
                                        <div className="boton">
                                            <div className="boton__click">
                                                <SubmitButton onClick={resetDevice} className='btn btn-primary d-grid envio' label={t(`${T_PREFIX}unlink`)}></SubmitButton>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer isValid={false} />
        </React.Fragment>
    )
}
export default ResetDevice
