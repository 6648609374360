import { Component } from 'react';
import NavigationTabLinks from '../containers/navigationTabLinks';

const T_PREFIX: string = 'pages.liquidation.';

class NavigationTabLinksForSettlements extends Component {
    render() {
        return (
            <NavigationTabLinks
                items={[
                    { to: '/settlements/daily', icon: 'fe fe-credit-card', label: `pages.settlementsByDay.title`, tooltip: `pages.settlementsByDay.titleTooltip` },
                    { to: '/settlements/monthly', icon: 'fe fe-calendar', label: `pages.settlementsByMonth.title`, tooltip: `pages.settlementsByMonth.titleTooltip` },
                    { to: '/settlements/charge-backs', icon: 'fe fe-settings', label: `pages.settlementChargeBacks.title`, tooltip: `pages.settlementChargeBacks.titleTooltip` }
                ]} 
            ></NavigationTabLinks>
        )
    };
}

export default NavigationTabLinksForSettlements;
