import { IPropsInputField } from "./fieldPropertiesInterface";
import InputField from "./inputField";

function EmailField({
  model,
  attr,
  value,
  label,
  onChange,
  disabled,
  required,
  autoFocus
}: IPropsInputField) {
  return (
    <InputField
      type='email'
      attr={attr}
      value={value}
      label={label}
      model={model}
      onChange={onChange}
      disabled={disabled}
      required={required}
      autoFocus={autoFocus}
    ></InputField>
  );
}

export default EmailField;