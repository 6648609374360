import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import "./AutomaticDebit.scss";
import {IBarChartData, IPeriodIndicator} from "../../models/IBarChartData";
import {IPieChartData, IRejectionIndicator} from "../../models/IPieChartData";
import {ITransaction} from "../../requests/ITransactionWebModelRequest";
import {DashboardService} from "../../services/DashboardService";
import {AutomaticDebitService} from "../../services/AutomaticDebitService";
import Swal from "sweetalert2";
import Page from "../containers/page";

interface IState {
    initDahboard: boolean,
    adUrl:string
}

interface IProps { }
let AutomaticDebit: React.FC<IProps> = () => {
    const T_PREFIX: string = 'pages.automaticDebit.';
    const { t } = useTranslation();
    let [state, setState] = useState<IState>({
        initDahboard: true,
        adUrl:""
    });

    useEffect(() => {
        setState(prevState => ({...prevState, initDahboard: false}));

        if (!state.initDahboard) {
            AutomaticDebitService.getMpDaUrl().then(function(response){
                if (response.status != 200) {
                    Swal.fire({
                        title: t('errorOcurred'),
                        html: t("contactSupport"),
                        confirmButtonColor: '#f60',
                        timer: 6000,
                        timerProgressBar: true,
                        icon:"warning"
                    });
                } else {
                    setState(prevState => ({...prevState, adUrl:response.data.url }));
                }
            })
        }
    }, [state.initDahboard]);


    return (
        <React.Fragment>
            <Page>
                <iframe className='aDframe' src={state.adUrl}></iframe>
            </Page>
        </React.Fragment>
    )
}
export default AutomaticDebit
