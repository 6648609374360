import { Component, ReactElement, RefObject, createRef } from "react";
import { Accordion } from "react-bootstrap";
import { TFunction, withTranslation } from "react-i18next";
import { CurrencyHelper } from "../../helpers/CurrencyHelper";
import {
    ActionIcon,
    ActionText,
    ColumnDefinition,
    isCurrencyColumnDefinition,
    isCurrencyTypeColumnDefinition,
    isDateColumnDefinition,
    isNumericColumnDefinition,
    isPercentageColumnDefinition,
    isTextColumnDefinition,
    isActionColumnDefinition,
    isBooleanColumnDefinition,
    isStatusColumnDefinition
} from "./ColumnDefinition";
import "./CustomTable.scss";
import Pagination from '../Pagination';
import { IPageRequest } from "../../models/IPageRequest";
import { ReactComponent as NoResultsFoundSVG } from "../../../node_modules/itd-common/assets/images/svgs/no_results_found.svg";
import { ReactComponent as ActiveStateIconSVG } from "../../../node_modules/itd-common/assets/icons/active-state.svg";
import { ReactComponent as InActiveStateIconSVG } from "../../../node_modules/itd-common/assets/icons/inactive-state.svg";
import { ReactComponent as SmallTopArrowSVG } from "../../../node_modules/itd-common/assets/icons/icon-small-top-arrow.svg";
import { ObjectHelper } from "../../helpers/ObjectHelper";
import React from "react";

export interface IPaginator {
    quantity?: number,
    mainPage: number,
    modifyMainPage: (value: number, newPages: Array<number>) => void,
    pageRequest: IPageRequest
}

export enum TableModeEnum {
    GRID = 'grid',
    LIST = 'list'
}
export interface ITableConfig {
    mode?: TableModeEnum,
    debugMode?: boolean,
    pageSize?: number,
    paginator?: IPaginator
    customButtonAction?: { action: ((rowObject: any) => void), buttonText: string } | undefined
}

interface IProps {
    columnDefinitions: Array<ColumnDefinition>,
    rowObjects: Array<any>,
    config: ITableConfig,
    t: TFunction
}

interface IState {
    mainPage: number,
    activePage: number
}

interface IObjectToRender {
    style: string,
    value: string | ReactElement,
    icon: string | ReactElement | ((rowObject: any) => void),
    image: string,
    columnDefinition: ColumnDefinition,
    object: any,
    width: number
}

interface IObjectsToRender {
    headerObjects: Array<IObjectToRender>,
    bodyObjects: Array<IObjectToRender>, 
    rowObject: any, 
    rowObjectIndex: number
}

class CustomTable extends Component<IProps, IState> {

    private containerRef: RefObject<HTMLDivElement> = createRef();

    constructor(props: IProps) {
        super(props);

        this.state = {
            activePage: 1,
            mainPage: this.props.config.paginator?.mainPage || 0
        };
    }

    isTableModeGrid: () => boolean = (): boolean => {
        return this.props.config.mode === TableModeEnum.GRID;
    }

    getPageSize: () => number = (): number => {
        return this.props.config.pageSize || 13;
    }
    
    getPaginatorQuantity: () => number = (): number => {
        return this.props.config.paginator?.quantity || 3;
    }

    customButtonAction = (rowObject: any) => {
        if (this.props.config.customButtonAction) {
            this.props.config.customButtonAction.action(rowObject);
        }
    }

    processAction(event: any, objectToRender: IObjectToRender) {
        event.preventDefault();

        const columnDefinition: ColumnDefinition = objectToRender.columnDefinition;
        if (columnDefinition.action?.function) {
            const rowObject: any = objectToRender.object;
            columnDefinition.action.function(rowObject);
        }
    }

    chunkRows(objectToRenderList: Array<IObjectToRender>): Array<Array<IObjectToRender>> {
        let arrays: Array<Array<IObjectToRender>> = [];
        
        const objectToRenderLength: number = objectToRenderList.length;
        const ceil: number = Math.ceil(objectToRenderLength / 9);
        
        let chunkSize: number = 9;
        for (let index = 8; index > 0; index--) {
            const approachToCeiling: number = objectToRenderLength / index;
            if (approachToCeiling > ceil) {
                chunkSize = index + 1;
                break;
            }
        }
        
        for (let chunkIndex = 0; chunkIndex < objectToRenderLength; chunkIndex += chunkSize) {
            arrays.push(objectToRenderList.slice(chunkIndex, chunkIndex + chunkSize));
        }

        return arrays;
    }

    renderColGroup = (objectsToRender: Array<IObjectToRender>) => {
        return (
            <colgroup>
                { objectsToRender.map((objectToRender: IObjectToRender, index: number) => 
                    <col key={`${objectToRender.columnDefinition.key}-${index}`} style={{ maxWidth: objectToRender.width }} />
                )}
            </colgroup>
        );
    }

    renderCell = (objectToRender: IObjectToRender, key: string) => {
        let sizeType: string= "";
        if(objectToRender.columnDefinition.fixedWidth){
            sizeType=  `${objectToRender.columnDefinition.fixedWidth}px`;
        }else if(objectToRender.columnDefinition.percentageWidth){
            sizeType=  `${objectToRender.columnDefinition.percentageWidth}%`;
        }

        const renderIcon =(icon:string|React.ReactElement|((rowObject:any)=>void))=>{
            if (typeof icon ==="string"){
                return <i className={`${icon}`}></i>
            } else if (React.isValidElement(icon)){
                return icon
            } else if (typeof icon === "function"){
                const iconElement = icon(objectToRender.object)
                return (iconElement!==null) ? iconElement:null
            } else {
                return null
            }
        }
        return (
            <td key={`td-${key}`} className={`${objectToRender.style} table-td customTableMaxium` } align="left" >
                { !objectToRender.image || <img src={objectToRender.image} alt="" ></img>}
                <p className="table-text">{objectToRender.value}</p>
                <a href={window.location.href} onClick={(event: any) => this.processAction(event, objectToRender)}>
                    {renderIcon(objectToRender.icon)|| ""}
                </a>
            </td>
        )
    }

    renderRow = (objectsToRender: Array<IObjectToRender>, rowObject: IObjectToRender, rowObjectIndex: number) => {
        return (
            <tr key={`tr-${rowObjectIndex}`} className={`row-body ${this.isTableModeGrid() || 'text-strong'}` }>
                {
                    this.props.columnDefinitions.map((columnDefinition: ColumnDefinition) => {
                        const objectToRender: IObjectToRender | undefined = objectsToRender.find((objectToRender) => objectToRender.columnDefinition.key === columnDefinition.key);
                        if (objectToRender && !objectToRender.columnDefinition?.hidden) {
                            return this.renderCell(objectToRender, `${columnDefinition.key}${rowObjectIndex.toString()}`);
                        }
                        else {
                            return <></>;
                        }
                    })
                }
                {  
                    this.props.config.customButtonAction !== undefined ?
                    <td align="center" >
                        <button type="button" className="btn btn-primary mb-3 btn-block" onClick={() => this.customButtonAction(rowObject)}>
                            {this.props.config.customButtonAction?.buttonText}
                        </button>
                    </td> : ""
                }
            </tr>
        );
    }
    
    renderBody = (objectsToRender: Array<IObjectToRender>, rowObject: IObjectToRender, rowObjectIndex: number) => {
        const { t } = this.props;
        return (
            <>
                {
                    this.chunkRows(objectsToRender).map((chunk, index) => {
                        return (
                            <table id="datetime-table" className="table table-borderless text-center" key={`table-body-${index}-${rowObjectIndex}`}>
                                { this.renderColGroup(chunk) }
                                <tbody>
                                    <tr key={`tr-body-${rowObjectIndex}`} className="text-gray">
                                        {
                                            this.props.columnDefinitions.map((columnDefinition: ColumnDefinition) => {
                                                const column: IObjectToRender | undefined = chunk.find((column) => column.columnDefinition.key === columnDefinition.key);
                                                if (column && !column.columnDefinition?.hidden) {
                                                    return <td className="table-row" align="left" key={`li-body-${column.columnDefinition.key}-${rowObjectIndex}`}>{t(column.columnDefinition.label || '')}</td>
                                                }
                                                else {
                                                    return <></>
                                                }
                                            })
                                            // chunk.map(column => {
                                            //     return (
                                            //         <td className="table-row" key={`li-body-${column.columnDefinition.key}-${rowObjectIndex}`}>{t(column.columnDefinition.label || '')}</td>
                                            //     )
                                            // })
                                        }
                                    </tr>
                                    { this.renderRow(chunk, rowObject, rowObjectIndex) }
                                </tbody>
                            </table>
                        ) 
                    })
                }
            </>
        );
    }

    renderAccordionHeader = (objectsToRender: Array<IObjectToRender>, rowObject: IObjectToRender, rowObjectIndex: number) => {
        const { t } = this.props;
        return (
            (objectsToRender.length === 0) ||
            <Accordion.Header>
                <table id="datetime-table" className="table table-borderless text-center" key={`table-header${rowObjectIndex}`}>
                    { this.renderColGroup(objectsToRender) }
                    <tbody>
                        <tr key={`tr-header-${rowObjectIndex}`} className="text-gray">
                            {
                                this.props.columnDefinitions.map(columnDefinition => {
                                    return (
                                        (columnDefinition.header && !columnDefinition?.hidden) ?
                                            <td className="table-row" align="left" key={`li-header-${columnDefinition.key}-${rowObjectIndex}`}>{t(columnDefinition.label || '')}</td> : ""
                                    )
                                })
                            }
                            {
                                this.props.config.customButtonAction !== undefined ? <th key={`li-header-custom-action-${rowObjectIndex}`}>{t("ACTION")}</th> : ""
                            }
                        </tr>
                        { this.renderRow(objectsToRender, rowObject, rowObjectIndex) }
                    </tbody>
                </table>
            </Accordion.Header>
        );
    }

    renderAccordionBody = (objectsToRender: Array<IObjectToRender>, rowObject: IObjectToRender, rowObjectIndex: number) => {
        return (
            (objectsToRender.length === 0) ||
            <Accordion.Body>
                { this.renderBody(objectsToRender, rowObject, rowObjectIndex) }
            </Accordion.Body>
        );
    }

    renderAccordion = () => {
        const objectsToRender: Array<IObjectsToRender> = this.buildObjectsToRender();

        return (
            (objectsToRender.length === 0) ?
                <div className="d-flex justify-content-center">
                    <NoResultsFoundSVG></NoResultsFoundSVG>
                </div>
            :
                objectsToRender.map(({ headerObjects, bodyObjects, rowObject, rowObjectIndex }) => 
                    <Accordion key={`accordion-${rowObjectIndex}`} >
                        <Accordion.Item eventKey="0" className="tableAcordionItem">
                            { 
                                (headerObjects.length === 0) && (bodyObjects.length > 0) ? 
                                    this.renderBody(bodyObjects, rowObject, rowObjectIndex)
                                :
                                    <>
                                        { this.renderAccordionHeader(headerObjects, rowObject, rowObjectIndex) }
                                        { this.renderAccordionBody(bodyObjects, rowObject, rowObjectIndex) }
                                    </>
                            }
                        </Accordion.Item>
                    </Accordion>
                )
        );
    }

    renderTable = () => {
        const { t } = this.props;
        const objectsToRender: Array<IObjectsToRender> = this.buildObjectsToRender();

        return (
            (objectsToRender.length === 0) ||
            <table id="datetime-table" className="table table-grid text-nowrap mb-0" >
                {/* { this.renderColGroup(...) } */}
                <thead className="">
                    {
                        <tr className="row-header" key={"tr-head"}>
                            {
                                this.props.columnDefinitions.map(columnDefinition => {          
                                        return columnDefinition?.hidden || <th className="text-dark text-capitalize text-center" key={`li-${columnDefinition.key}`}>{t(columnDefinition.label || '')}{/* {columnDefinition.label && <SmallTopArrowSVG className="ms-3"></SmallTopArrowSVG>} */}</th>
                                })
                            }
                        </tr>
                    }
                </thead>
                <tbody>
                    { 
                        objectsToRender.map(({ bodyObjects, rowObject, rowObjectIndex }) => 
                            this.renderRow(bodyObjects, rowObject, rowObjectIndex) 
                        )
                    }
                </tbody>
            </table>    
        );
    }

    calculateWidth = (columnDefinition: ColumnDefinition): number => {
        let width: number = 0;

        if (columnDefinition.percentageWidth) {
            width = ((this.containerRef.current?.offsetWidth || 1) * columnDefinition.percentageWidth) / 100;
        }
        else if (columnDefinition.fixedWidth) {
            width = columnDefinition.fixedWidth;
        }
        return width;
    }

    createObjectToRender = (columnDefinition: ColumnDefinition, rowObject: any): IObjectToRender => {
        return {
            style: "",
            value: "",
            icon: "",
            image: "",
            columnDefinition: columnDefinition,
            object: rowObject,
            width: this.calculateWidth(columnDefinition)
        }
    }

    buildObjectsToRender = () => {
        const { t } = this.props;
        const tableModeGrid: boolean = this.isTableModeGrid();

        let pages: Array<number> = [];
        const fromIndex = (this.props.config.paginator?.mainPage === 0) ? 1 : ((this.props.config.paginator?.mainPage || 0) * this.getPaginatorQuantity()) + 1;
        const targetIndex = fromIndex + this.getPaginatorQuantity();

        for (let index = fromIndex; index <= targetIndex; index++) {
            pages.push(index);
        }

        const rowObjectsStartIndex: number = pages.indexOf(this.state.activePage) * this.getPageSize(); // this.props.pages
        const rowObjectsEndIndex: number = rowObjectsStartIndex + this.getPageSize();

        if (this.props.config.paginator) {
            const pageRows: number = this.getPageSize() * this.getPaginatorQuantity();
            this.props.config.paginator.pageRequest.Take = pageRows + 1;
            this.props.config.paginator.pageRequest.Skip = this.props.config.paginator.mainPage * pageRows;
        }

        return this.props.rowObjects?.slice(rowObjectsStartIndex, rowObjectsEndIndex)
            .map((rowObject: any, rowObjectIndex: number) => {
                const bodyObjects: Array<IObjectToRender> = [];
                const headerObjects: Array<IObjectToRender> = [];

                for (const rowObjectProperty in rowObject) {
                    const rowObjectValue = (rowObject as any)[rowObjectProperty];

                    if (rowObjectValue && typeof rowObjectValue === 'object' && !Array.isArray(rowObjectValue)) {
                        continue;
                    }

                    const columnDefinition: ColumnDefinition | undefined = this.props.columnDefinitions.find((columnDefinition: ColumnDefinition) => columnDefinition.key === rowObjectProperty );

                    if (!columnDefinition) {
                        if (this.props.config.debugMode) {
                            console.warn(`Key ${rowObjectProperty} not found in columnDefinitions, so this data will not be shown.`);
                        }
                        continue;
                    }

                    const objectToRender: IObjectToRender = this.createObjectToRender(columnDefinition, rowObject);

                    if (columnDefinition.action) { //  && typeof columnDefinition.action === 'ActionIcon'
                        objectToRender.icon = `${(columnDefinition.action as ActionIcon).icon} text-primary`;
                    }

                    if (['string', 'number', 'boolean'].indexOf(typeof rowObjectValue) > -1) {
                        if (isCurrencyTypeColumnDefinition(columnDefinition)) {
                            objectToRender.value = t(`currency-${rowObjectValue}`);
                        }
                        else if (isNumericColumnDefinition(columnDefinition) && !isNaN(Number(rowObjectValue))) {
                            objectToRender.value = rowObjectValue;
                            objectToRender.icon = (rowObjectValue === 0) ? '' : objectToRender.icon;
                        }
                        else if (isPercentageColumnDefinition(columnDefinition) && !isNaN(Number(rowObjectValue))) {
                            objectToRender.value = Intl.NumberFormat('es-UY', { minimumSignificantDigits: 3, maximumSignificantDigits: 3 }).format(Number(rowObjectValue)) + "%";
                            objectToRender.icon = (rowObjectValue === 0) ? '' : objectToRender.icon;
                        }
                        else if (isCurrencyColumnDefinition(columnDefinition) && !isNaN(Number(rowObjectValue))) {
                            let currencyAsString: string = ''; 
                            if (columnDefinition.hasOwnProperty('currency')) {
                                currencyAsString = ObjectHelper.getPropertyValue(columnDefinition, 'currency');
                            }
                            else if (columnDefinition.hasOwnProperty('columnDefinitionKey')) {
                                const columnDefinitionKey: string = ObjectHelper.getPropertyValue(columnDefinition, 'columnDefinitionKey');
                                currencyAsString = ObjectHelper.getPropertyValue(rowObject, columnDefinitionKey);
                            }
                            const currencySymbol = CurrencyHelper.getCurrencySymbol(currencyAsString);
                            objectToRender.value = Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(Number(rowObjectValue)) + " ";
                            objectToRender.style = objectToRender.value.indexOf("-") >= 0 ? "text-danger" : "";
                        }
                        else if (isDateColumnDefinition(columnDefinition)) {
                            const isDateValid = (value: string) => {
                                var parsedDate = Date.parse(value);
                                return isNaN(Number(value)) && !isNaN(parsedDate);
                            }

                            if (isDateValid(rowObjectValue)) {
                                objectToRender.value = new Date(rowObjectValue.toString()).toLocaleDateString('es-UY');
                            }
                        }
                        else if (isTextColumnDefinition(columnDefinition)) {
                            objectToRender.value = rowObjectValue;
                        }
                        else if (isBooleanColumnDefinition(columnDefinition)) {
                            objectToRender.value = rowObjectValue ? t("yes") : t("no");
                        }
                        else if (isStatusColumnDefinition(columnDefinition)) {
                            objectToRender.icon = objectToRender.object[columnDefinition.key]?.length > 0 ? <ActiveStateIconSVG></ActiveStateIconSVG> : <InActiveStateIconSVG></InActiveStateIconSVG>;
                        }
                        else if (!rowObjectValue || rowObjectValue === '') {
                            objectToRender.value = '-';
                        }
                    }
                    else if (Array.isArray(rowObjectValue) && rowObjectValue.every((value) => typeof value === 'string')) {
                        objectToRender.value = rowObjectValue.join(', ');
                    }

                    if (columnDefinition.mapValue) {
                        objectToRender.value = columnDefinition.mapValue(rowObject);
                    }
                    
                    if (columnDefinition.header && !tableModeGrid) {
                        headerObjects.push(objectToRender);
                    }
                    else {
                        bodyObjects.push(objectToRender);
                    }
                }

                const actionColumnDefinitions: Array<ColumnDefinition> | undefined = this.props.columnDefinitions.filter((columnDefinition: ColumnDefinition) => isActionColumnDefinition(columnDefinition));
                actionColumnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
                    const objectToRender: IObjectToRender = this.createObjectToRender(columnDefinition, rowObject);
                    objectToRender.icon = (columnDefinition.action as ActionIcon).icon;

                    if (columnDefinition.header && !tableModeGrid) {
                        headerObjects.push(objectToRender);
                    }
                    else {
                        bodyObjects.push(objectToRender);
                    }
                });

                return { headerObjects, bodyObjects, rowObject, rowObjectIndex };
            })
    }

    render() {
        if (!this.props.columnDefinitions || this.props.columnDefinitions.length === 0) {
            throw new Error("columnDefinitions prop is missing or empty.");
        }

        return (
            <div className="div-container">
                <div ref={this.containerRef} className="item-list">
                    { 
                        (this.isTableModeGrid()) ?
                            this.renderTable()
                        :
                            this.renderAccordion()
                    }
                </div>
                {
                    !this.props.config.paginator ||
                    <div className='row'>
                        <div className='col-md-12'>
                            <Pagination
                                activePageProp={this.state.activePage}
                                pageSize={this.getPageSize()}
                                pageQuantity={this.getPaginatorQuantity()}
                                modifyMainPage={this.props.config.paginator?.modifyMainPage}
                                changePage={(page: number) => {
                                    this.setState({ activePage: page }, () => {});
                                }}
                                dataL={this.props.rowObjects.length}
                                mainPage={this.props.config.paginator?.mainPage}
                            ></Pagination>
                        </div>
                    </div>
                }
            </div>
        );
    }
    
}

export default withTranslation()(CustomTable);