import React, { Dispatch, ReactElement, SetStateAction,  useState} from 'react';
import { TFunction, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LabelMessage from './LabelMessage';
import "./Onboarding.scss";
import Form from './containers/form';
import SubmitButton from './controls/buttons/submitButton';
import EmailField from './controls/fields/emailField';
import { ReactComponent as LogoSVG } from "../../node_modules/itd-common/assets/images/brand/logo.svg";
import TextField from './controls/fields/textField';
import Footer from './layout/Footer';
import { ToastContainer, toast } from 'react-toastify';
import CustomReCAPTCHA from "./CustomCaptcha";
import Swal from 'sweetalert2';
import { OnboardingService } from '../services/OnboardingService';
import { Button, Modal } from "react-bootstrap";
import TermsAndContions from './layout/TermsAndCondition'
import {
IOnboardingQuestionResponse,
IOnboardingQuestionValidationRequest,
IOnboardingQuestionValidationResponse
} from "../models/IOnboardingData";
import {AxiosResponse} from "axios";
import i18next from "i18next";

enum PageMode {
ONBOARDING,
SECURITY_QUESTION /* ,
ONBOARDING_RESULT */
}

interface SecurityQuestionSectionProps {
    question: string;
    options: string[];
    sectionIndex:number
}

interface QuestionOptionProps {
    label: string;
    name: string;
    questionIndex: number
    answerIndex: number
}

interface IProps {
t: TFunction
}

type RenderObject = {
title: string,
renderFunction: () => ReactElement,
initialize?: {
    state: [any, Dispatch<SetStateAction<any>>],
    function: <T>(state: [T, Dispatch<SetStateAction<T>>]) => void 
} 
};

type OnboardingFormType = { rut: string, merchantNumber: string,email:string,emailConfirmation:string};
const T_PREFIX: string = 'pages.onBoarding.';
const URUGUAY= "UYU";

const Onboarding: React.FC<IProps> = ({ t }: IProps) => {
const [pageMode, setPageMode] = useState<PageMode>(PageMode.ONBOARDING);
const [model, setModel] = useState({ email: '' });
//const [isEmpty, setIsEmpty] = useState(false);
//const [emailSent, setEmailSent] = useState(false);
const [securityQuestions, setSecurityQuestions] = useState<IOnboardingQuestionResponse>({ questions:[],answers:[],errorLog:''});
const [onBoardingForm, setOnBoardingForm]
    = useState<OnboardingFormType>({ rut: '', merchantNumber: '',email:'',emailConfirmation:''});
const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
//const [isValid, setIsValid] = useState<boolean>(true);
//const [email, setEmail] = useState('');
//const [reEmail, setReEmail] = useState('');
const [showResult, setShowResult] = useState<boolean>(false);
const [termsAccepted, setTermsAccepted] = useState(false);
const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);
const [userAnswers, setUserAnswers] =useState<IOnboardingQuestionValidationRequest>({ answers:[],merchantNumber:'',fiscalDocument:'',email:'' });
const [country, setCountry] = useState<string>(URUGUAY);

/*
useEffect(() => {
    const fetchQuestionsAndValidate = async () => {
        try {
            const response = await OnboardingService.validateInitialQuestions(userAnswers);
            const { questions } = response.data;
            setQuestions(questions);

            await OnboardingService.validateInitialQuestions();
        } catch (error) {
            console.error("Error al obtener o validar las preguntas iniciales:", error);
        }
    };

    fetchQuestionsAndValidate();
}, []);
*/

const validateEmail = (emailValue: string,emailConfirmation:string): boolean => {
    const emailRegex: RegExp = /\S+@\S+\.\S+/;     
    return emailRegex.test(emailValue)&&emailConfirmation===emailValue;
};
const handleCaptchaVerify = (onVerify: boolean) => {
    setIsCaptchaVerified(onVerify);
};

const openTermsAndConditionsModal = () => {
    setCountry(URUGUAY);
    setShowTermsAndConditionsModal(true);
};

const closeTermsAndConditionsModal = () => {
    setShowTermsAndConditionsModal(false);
};
/*
let aux = "";

let update = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const emailRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
    aux = event.target.value;
    if (emailRegex.test(aux)) {
        setIsValid(true);
    } else {
        setIsValid(false);
    }
};    */

const getInitialQuestions = async () => {
    try {
        await OnboardingService.getInitialQuestions(onBoardingForm.rut,onBoardingForm.merchantNumber,onBoardingForm.email).then(function(res:AxiosResponse<IOnboardingQuestionResponse>){
            if(res.data.errorLog!=null&&res.data.errorLog.length>0){
                toast.error(i18next.t(res.data.errorLog)+"");
                return;
            }
            setSecurityQuestions(res.data);
            let userAnswers:IOnboardingQuestionValidationRequest={ answers:[],merchantNumber:onBoardingForm.merchantNumber,fiscalDocument:onBoardingForm.rut,email:onBoardingForm.email }
            for(var i=0;i<res.data.answers.length;i++){
                userAnswers.answers.push({respID:(i+1)+"",selectedResponse:""})
            }
            setUserAnswers(userAnswers);
            setPageMode(PageMode.SECURITY_QUESTION);
        });
    } catch (error) {
        console.error("Error al crear el comercio:", error);
        throw new Error("Error al crear el comercio");
    }
};

/*
const updateValues = (emailValue: string): void => {
    setIsValid(!emailValue || validateEmail(emailValue));
    setIsEmpty(!emailValue || emailValue.length === 0);
}
*/
const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
};

const isAnswerSelected = (questionIndex:number,answerIndex:number) => {
    return  userAnswers.answers[questionIndex].selectedResponse == (answerIndex+1)+"";
};
const handleAnswerChange = (questionIndex:number,answerIndex:number) => {
    const updatedAnswers = [...userAnswers.answers];
    updatedAnswers[questionIndex].selectedResponse = (answerIndex+1)+"";
    setUserAnswers({ ...userAnswers, answers: updatedAnswers }); 
};


const validateAnswers = (): boolean => {
    const allQuestionsAnswered = userAnswers.answers.every(answer => answer.selectedResponse.trim() !== '');
    if (!allQuestionsAnswered) {
        toast.error("Por favor, responda todas las preguntas de seguridad.");
        return false;
    }
    return true;
};

const QuestionOption: React.FC<QuestionOptionProps> = ({ label, name, answerIndex,questionIndex }) => (
    <>
        <input type="radio" name={name} onClick={(event) => handleAnswerChange(questionIndex,answerIndex)} checked={isAnswerSelected(questionIndex,answerIndex)}/>
        <label>{label}</label>
    </>
);

const SecurityQuestionSection: React.FC<SecurityQuestionSectionProps> = ({ question, options, sectionIndex }) => (
    <>
    <p className='textQuestion'>{question}</p>
        <div className='mapQuestion'>
        {options.map((option, index) => (
            <QuestionOption
            key={index}
            name={`pregunta-${sectionIndex}`}
            label={option}
            questionIndex={sectionIndex}
            answerIndex={index}
        />
        ))}
        </div>
    <div className='lineQuestion'></div>
    </>
);
/*
const sendEmail = (): void => {
    const email: string = model.email;
    if (!isSubmitDisabled()) { 
        if (validateEmail(email) && isCaptchaVerified) {
            OnboardingService.onboardUser(email)
                .then(() => {
                    UserService.sendEmailToRecoverPass(email)
                        .then((response) => {
                            if (response.status !== 200) {
                                Swal.fire ('errorTitle', 'contactHelpDesk');
                            } else {
                                setEmailSent(true);
                            }
                        })
                        .catch(() => {
                            Swal.fire ('errorTitle', 'contactHelpDesk');
                        });
                })
                .catch((error) => {
                    Swal.fire ('errorTitle', 'contactHelpDesk');
                    console.error("Error al activar el usuario:", error);
                });
        } else {
            updateValues(email);
        }
    } else {
        toast.error(JSON.stringify("Por favor, ingrese el email y acepte los términos y condiciones"));
    }
};
*/

const ErrorPage : React.FC= () => {    
    return (
        <React.Fragment>
            <div className="page mail-password">
                <div className=""> 
                    <div className="container-mail">
                        <div className="form-mail">
                            <div>
                                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Computers Devices Electronics  x2F  Power Supply  x2F  phone charger 1">
                                        <path id="Vector" d="M10.4165 16.6667C9.2498 16.6667 8.33313 15.75 8.33313 14.5833V2.08333C8.33313 0.916667 9.2498 0 10.4165 0C11.5831 0 12.4998 0.916667 12.4998 2.08333V14.5833C12.4998 15.75 11.5831 16.6667 10.4165 16.6667Z" fill="black"/>
                                        <path id="Vector_2" d="M43.7502 16.6667C42.5835 16.6667 41.6669 15.75 41.6669 14.5833V2.08333C41.6669 0.916667 42.5835 0 43.7502 0C44.9169 0 45.8335 0.916667 45.8335 2.08333V14.5833C45.8335 15.75 44.9169 16.6667 43.7502 16.6667Z" fill="black"/>
                                        <path id="Vector_3" d="M35.4169 29.1667H18.7502C17.5835 29.1667 16.6669 28.25 16.6669 27.0833C16.6669 25.9167 17.5835 25 18.7502 25H35.4169C36.5835 25 37.5002 25.9167 37.5002 27.0833C37.5002 28.25 36.5835 29.1667 35.4169 29.1667Z" fill="black"/>
                                        <path id="Vector_4" d="M43.75 100C33.4167 100 25 91.5837 25 81.2503V68.7503C25 67.5837 25.9167 66.667 27.0833 66.667C28.25 66.667 29.1667 67.5837 29.1667 68.7503V81.2503C29.1667 89.292 35.7083 95.8337 43.75 95.8337C51.7917 95.8337 58.3333 89.292 58.3333 81.2503V78.1253C58.3333 64.917 69.0833 54.167 82.2917 54.167H97.9167C99.0833 54.167 100 55.0837 100 56.2503C100 57.417 99.0833 58.3337 97.9167 58.3337H82.2917C71.375 58.3337 62.5 67.2087 62.5 78.1253V81.2503C62.5 91.5837 54.125 100 43.75 100Z" fill="black"/>
                                        <path id="Vector_5" d="M31.2502 70.8334H22.9169C19.4585 70.8334 16.6669 68.0418 16.6669 64.5834V54.8336C16.6669 53.6669 17.5835 52.7502 18.7502 52.7502C19.9169 52.7502 20.8335 53.6669 20.8335 54.8336V64.5834C20.8335 65.7501 21.7502 66.6668 22.9169 66.6668H31.2502C32.4169 66.6668 33.3335 65.7501 33.3335 64.5834V54.8336C33.3335 53.6669 34.2502 52.7502 35.4169 52.7502C36.5835 52.7502 37.5002 53.6669 37.5002 54.8336V64.5834C37.5002 68.0418 34.7085 70.8334 31.2502 70.8334Z" fill="black"/>
                                        <path id="Vector_6" d="M27.0833 58.3333C12.1667 58.3333 0 46.1667 0 31.25V18.75C0 15.2917 2.79167 12.5 6.25 12.5H47.9167C51.375 12.5 54.1667 15.2917 54.1667 18.75V31.25C54.1667 46.2083 42.0417 58.3333 27.0833 58.3333ZM6.25 16.6667C5.08333 16.6667 4.16667 17.5833 4.16667 18.75V31.25C4.16667 43.875 14.4583 54.1667 27.0833 54.1667C39.7083 54.1667 50 43.875 50 31.25V18.75C50 17.5833 49.0833 16.6667 47.9167 16.6667H6.25Z" fill="black"/>
                                    </g>
                                </svg>
                            </div>
                            <h2 className='text-check' > {t(`${T_PREFIX}serviceNotAvailable`)}</h2>
                            <span className='merchantInfoText'>{t(`${T_PREFIX}tryLater`)} </span>
                            <Button><Link to={'/login'} className="textBackToLogin"> {t(`${T_PREFIX}tryAgain`)}</Link></Button> 
                        </div>
                    </div>
                </div>
            </div>
    </React.Fragment>
    )
}

// ######################################## Render Methods ########################################

//Render de registro de comercio
const renderOnBoardingForm = (): ReactElement => {
    const isSubmitDisabled = (): boolean => {
        const isAnyFieldEmpty = !onBoardingForm.rut || !onBoardingForm.merchantNumber;
        const isCaptchaNotVerified = !isCaptchaVerified;

        var isEmailInvalid=
            !(onBoardingForm.email && onBoardingForm.emailConfirmation && onBoardingForm.email === onBoardingForm.emailConfirmation && termsAccepted&&validateEmail(onBoardingForm.email,onBoardingForm.emailConfirmation));

        return isAnyFieldEmpty || isCaptchaNotVerified || isEmailInvalid;
    };

    const handleChangeSecurity = async () => {
        try {
            await getInitialQuestions();
        } catch (error) {
            console.error("Error al registrar comercio", error);
            toast.error("Error al registrar comercio");
        }
    };
    return (
        <React.Fragment>
        <Form model={[onBoardingForm, setOnBoardingForm]} onSubmit={handleChangeSecurity}>
            <>
                <div className="row ">
                    <div className="col-12 merchantInfoText">
                        <p>{t(`${T_PREFIX}merchantInfo`)} </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <TextField attr='rut' label={`${T_PREFIX}insertFiscalNumber`} required autoFocus  ></TextField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <TextField attr='merchantNumber' label={`${T_PREFIX}insertMerchant`} required ></TextField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <EmailField attr='email'  label={`${T_PREFIX}emailUser`} required ></EmailField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <EmailField attr='emailConfirmation'  label={`${T_PREFIX}confirmEmail`} required></EmailField>
                        <LabelMessage valid={validateEmail(onBoardingForm.email,onBoardingForm.emailConfirmation)} message={t(`${T_PREFIX}emailNotMatch`)}></LabelMessage>
                    </div>
                </div>
                <div>
                    <div>
                        <input type="checkbox" name="terms" onChange={handleTermsChange} />
                        <label>
                            Al continuar estas aceptando los{' '}<Link onClick={openTermsAndConditionsModal} className="linkTo" to={''}> términos y condiciones</Link>
                        </label>
                    </div>
                </div>
                <div className='confirm-onboarding row' >
                    <div className="col-md-6">
                        <CustomReCAPTCHA onVerify={handleCaptchaVerify} />
                    </div>
                    <div className="boton col-md-6">
                        <div className="boton__click">
                            <SubmitButton className="mb-4 sendingButton" disabled={isSubmitDisabled()}>
                                <div className='submit-button-container'>
                                    <span className='ms-3' >{t(`${T_PREFIX}continue`)}</span>
                                    <i className='fe fe-arrow-right submit-button-container__icon me-3' ></i>
                                </div>
                            </SubmitButton>
                        </div>
                    </div>
                </div>
            </>
        </Form>
        <Modal show={showTermsAndConditionsModal} dialogClassName="footer-modal">
            <div className="modal-content modal-content-demo">
                <div className="modal-header bg-primary align-items-center">
                    <h4 className="modal-title text-white">Términos y condiciones generales</h4>
                    <Button aria-label="Close" className="btn-close p-0" onClick={() => closeTermsAndConditionsModal()}>
                        <span className='text-white' aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="modal-body">
                    <div className="scrollable-container">
                        <TermsAndContions country={country} />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button onClick={() => closeTermsAndConditionsModal()}>
                        <span className='text-white' aria-hidden="true">{t(`close`)}</span>
                    </Button>
                </div>
            </div>
        </Modal>
    </React.Fragment>
);
}

//Render de preguntas de seguridad
const renderQuestionRequest = (): ReactElement => {
    const handleValidateSecurityQuestions = () => {
        if (!validateAnswers()) {
            return;
        }
        OnboardingService.validateInitialQuestions(userAnswers).then(function(res:AxiosResponse<IOnboardingQuestionValidationResponse>) {
            try{
                if(Number.parseInt(res.data.returnCode)==0){
                    setShowResult(true);
                    return;
                }
            }catch(e){
            }
            Swal.fire ('Error', (res!=null&&res.data!=null&&res.data.errorLog!=null&&res.data.errorLog.length>0)?res.data.errorLog:'securityQuestionError').then(function(){
                setPageMode(PageMode.ONBOARDING);
            });
        })
    };
    return (
        showResult ? renderOnboardingResult() : 
        <>
            {securityQuestions.questions.map((questionData, index) => (
                <SecurityQuestionSection
                    key={index}
                    question={questionData.questionDescription}
                    options={securityQuestions.answers[index]}
                    sectionIndex={index}
                />
            ))}
            <div className='confirmContainer row'>
                <div className='boton col-md-6'>
                    <div className='boton__click'>
                        <SubmitButton className='mb-4 sendingButton' onClick={handleValidateSecurityQuestions}>
                            <div className='submit-button-container'>
                                <span className='ms-3'>{t(`${T_PREFIX}continue`)}</span>
                                <i className='fe fe-arrow-right submit-button-container__icon me-3'></i>
                            </div>
                        </SubmitButton>
                    </div>
                </div>
            </div>
        </>
    );
};


//Render de registro de correo y aceptación de términos
const renderOnboardingResult = (): ReactElement => {
    return (
        <React.Fragment>
            <div className="form-mailOnboarding">                            
                <div className='svg'>
                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Emails  x2F  Email Actions  x2F  email action read 1">
                            <path id="Vector" d="M27.1874 27.3332H2.82876C1.46457 27.3332 0.349609 26.2266 0.349609 24.873V11.5837C0.349609 11.3755 0.454543 11.1803 0.611949 11.0632L14.6211 0.79341C14.8572 0.624202 15.172 0.624202 15.395 0.79341L29.4042 11.0632C29.5747 11.1803 29.6665 11.3755 29.6665 11.5837V24.873C29.6665 26.2266 28.5516 27.3332 27.1874 27.3332ZM1.64822 11.9091V24.873C1.64822 25.5108 2.17291 26.0316 2.81565 26.0316H27.1743C27.817 26.0316 28.3417 25.5108 28.3417 24.873V11.9091L14.9884 2.12107L1.64822 11.9091Z" fill="#333333"/>
                            <path id="Vector_2" d="M4.65199 23.1031C4.44211 23.1031 4.24535 23.012 4.11418 22.8298C3.9043 22.5304 3.98302 22.1267 4.2716 21.9184L10.358 17.6882C10.4629 17.6101 10.5941 17.5713 10.7384 17.5713H19.2645C19.3957 17.5713 19.5269 17.6101 19.6449 17.6882L25.7313 21.9184C26.033 22.1267 26.0986 22.5304 25.8887 22.8298C25.6788 23.1292 25.2722 23.1941 24.9705 22.9858L19.0547 18.8729H10.9482L5.0324 22.9858C4.91434 23.0639 4.78316 23.1031 4.65199 23.1031Z" fill="#333333"/>
                            <path id="Vector_3" d="M21.7043 17.0632C21.4944 17.0632 21.2845 16.9591 21.1533 16.7769C20.9566 16.4775 21.0353 16.0741 21.337 15.8789L28.6433 11.05C28.945 10.8548 29.3516 10.9327 29.5483 11.2321C29.7451 11.5315 29.6664 11.9349 29.3647 12.1301L22.0584 16.9593C21.9535 17.0244 21.8223 17.0632 21.7043 17.0632Z" fill="#333333"/>
                            <path id="Vector_4" d="M8.29849 17.0632C8.16731 17.0632 8.04926 17.0244 7.93121 16.9593L0.624941 12.1301C0.323246 11.9349 0.244544 11.5315 0.441301 11.2321C0.638059 10.9327 1.04468 10.8548 1.34637 11.05L8.65266 15.8789C8.95435 16.0741 9.03305 16.4775 8.8363 16.7769C8.71824 16.9591 8.52148 17.0632 8.29849 17.0632Z" fill="#333333"/>
                        </g>
                    </svg>
                </div>
                <span className='text-checkOnboarding'> {t(`${T_PREFIX}checkEmail`)}</span>
                <span className='text-send'>{t(`${T_PREFIX}sendEmail`)} </span>
            </div>
        </React.Fragment>
);
}

//###############################################################################################################################
const buildRenderObject = (
    title: string,
    renderFunction: () => ReactElement,
    initialize?: { state: [any, Dispatch<SetStateAction<any>>], function: <T>(state: [T, Dispatch<SetStateAction<T>>]) => void }
): RenderObject => {
    return { title, renderFunction, initialize };
}

const buildTitle = (sufix: string, options?: any): string => {
    return t(`${T_PREFIX}${sufix}`, options);
}

const renderObjectsMap: Map<PageMode, RenderObject> = new Map([
    [
        PageMode.ONBOARDING,
        buildRenderObject(
            buildTitle('title'),
            renderOnBoardingForm,
        )
    ], 
    [
        PageMode.SECURITY_QUESTION,
        buildRenderObject(
            buildTitle('title'),
            renderQuestionRequest,
        )
    ]/* ,
      [
        PageMode.ONBOARDING_RESULT,
        buildRenderObject(
            buildTitle('title'),
            renderOnboardingResult,
        )
    ],   */
]);

const getRenderObjectByPageMode = (pageMode: PageMode): RenderObject => {
    return renderObjectsMap.get(pageMode) || { title: "", renderFunction: () => <></> } as RenderObject;
}
const renderTitle = () => {
    return getRenderObjectByPageMode(pageMode).title;
}
const renderByPageMode = () => {
    try {
        return (
            <>
                { getRenderObjectByPageMode(pageMode).renderFunction() }
            </>
        );
    } catch (error) {
        return <ErrorPage />;
    }
};

return (
    <React.Fragment>
        <ToastContainer autoClose={3000} ></ToastContainer>
            <div className="login">
                <div className="page">
                    <div className="container-login100 ">
                        <div className="wrap-login100 p-6 onboardingForm">
                            <div className="backward">
                                <div className="backward__init">
                                    <Link to={'/login'} className="backToLogin">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="ico/arrow-left">
                                                <path id="Vector 1" d="M10 4L2 12L10 20" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <line id="Line 7" x1="3" y1="12" x2="21" y2="12" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                        </svg>            
                                        <span className='backward__init__callback'>{t(`${T_PREFIX}returnToLogin`)}</span>
                                    </Link>
                                </div>
                            </div>
                            <div className='brand-logo-container'>
                                <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                            </div>
                            <span className="login100-form-title mb-5">
                                { renderTitle() }
                            </span>
                            <>
                                { renderByPageMode() }
                                <p className='registerMerchant'> {t(`${T_PREFIX}alreadyMerchant`)} <Link to={'/login'} className='linkTo'>{t(`${T_PREFIX}enterPortal`)}</Link> </p>  
                            </>
                        </div>                        
                    </div>
                </div>
                <Footer isValid={true}  />
            </div>
    </React.Fragment>
)
}
export default withTranslation() (Onboarding);
