import { AxiosResponse } from 'axios';
import React from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import { Link } from "react-router-dom";
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { IIssuer } from "../../models/IIssuer";
import { IShortcutCaptureResult } from "../../models/IShortcut";
import { allOperationTypesFilters, allTransactionStatesFilter } from "../../models/TransactionReportConstants";
import { ITransaction, ITransactionWebModelRequest } from "../../requests/ITransactionWebModelRequest";
import { TransactionService } from '../../services/TransactionService';
import { UtilService } from "../../services/UtilService";
import { WebCache } from "../../services/WebCache";
import ErrorsView from '../ErrorsView';
import Pagination from '../Pagination';
import TerminalLocation from "../TerminalLocation";
import DateField from '../controls/fields/dateField';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import TransactionGrid from "./TransactionGrid";
import "./TransactionReport.css";
import Page from '../containers/page'
import FormFilter from '../containers/form-filter';
import { DateHelper } from '../../helpers/DateHelper';
import NavigationTabLinksForTransactions from './NavigationTabLinksForTransactions';
import {CurrencyHelper} from "../../helpers/CurrencyHelper";

interface IProps {
    t: TFunction
}

interface Option {
    value: any;
    label: string;
    key?: string;
    disabled?: boolean;
}

interface IState {
    msg: string,
    fromDate: DateObject,
    toDate: DateObject,
    activePage: number,
    mainPage: number,
    pages: number[],
    transactions: ITransaction[],
    columns: Array<string>
    errors: string[],
    operationTypeFilter: string
    transactionStateFilter: string,
    terminalFilter: string,
    merchantFilter: string,
    initQuestionFilter: string,
    groupFilter: string,
    transactionIdFilter: string
    currentIssuers: IIssuer[],
    departmentFilter: string
    locationFilter: string
    branchFilter: string
    departmentName: string
    locationName: string
    branchName: string
    taxPayerName: string,
    taxPayerFilter: string,
    columnsToShow: string[],
    currencys: Option[],
    currencySelected: string | null
}
const pageSize = 13;
const pageQuantity = 3
const defaultPages = [1, 2, 3]
let doQuery = false;
const T_PREFIX: string = 'pages.transactions.';

const columnsToShow =[
    "ID","AUTHDATETIME","TERMINAL","TRANSACTIONTYPE","STATE","CURRENCY","TOTALAMOUNTFORREPORT","MERCHANT",
    "AUTHTERMINAL","TIPAMOUNTFORREPORT","TAXAMOUNTFORREPORT","QUOTAS","BILLNUMBER",
    "ADDITIONALDATA","EXPIRATIONDATE","ORIGINALDATE","BILLAMOUNTFORREPORT","TAXABLEAMOUNTFORREPORT",
    "INITQUESTION","TERMINALGROUP","ADDRESS","LOCATION","DEPARTMENT","WHOLESALERNAME","SPECIALDATA",
    "USERID"
]
class CurrentTransactionReport extends React.Component<IProps, IState>{
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);

    updateDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        if (UtilService.isSmallScreen() && this.state.columnsToShow.length == 0) {
            this.setState({
                ...this.state, columnsToShow: columnsToShow
            });
        } else if (width > 992 && this.state.columnsToShow.length > 0) {
            this.setState({ ...this.state, columnsToShow: columnsToShow });
        }
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        if (doQuery) {
            doQuery = false;
            this.getTransactions();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    constructor(props: IProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        let shortcut ="";
        if(window.location.href.indexOf("shortcuts=")>0){
            shortcut = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("shortcuts="))).get("shortcuts")+""
        }
        let acquirerFilter = "";
        let issuerFilter = "";
        let transactionStateFilter = "";
        let operationTypeFilter = "";
        let terminalFilter = "";
        let [ fromDate, toDate ] = DateHelper.buildDateRange();

        if (shortcut != null && shortcut.length > 0) {
            doQuery = true;
            let shortcutDto: IShortcutCaptureResult[] = JSON.parse(shortcut);
            for (var i = 0; i < shortcutDto.length; i++) {
                switch (shortcutDto[i].type) {
                    case "ACQUIRER":
                        acquirerFilter = shortcutDto[i].data.acquirerId + "";
                        break;
                    case "ISSUER":
                        issuerFilter = shortcutDto[i].data.code + "";
                        break;
                    case "TRANSACTION_STATUS":
                        if (shortcutDto[i].data == "REVERSADA") {
                            transactionStateFilter = "10";
                        }//todo falta aprobadas, rechazadas, etc.
                        break;
                    case "TRANSACTION_TYPE":
                        if (shortcutDto[i].data == "COMPRA") {
                            operationTypeFilter = "C";
                        } else if (shortcutDto[i].data == "CIERRE") {
                            operationTypeFilter = "L";
                        } else if (shortcutDto[i].data == "ANULACION") {
                            operationTypeFilter = "A";
                        } else if (shortcutDto[i].data == "DEVOLUCION") {
                            operationTypeFilter = "D";
                        }
                        break;
                    case "TERMINAL_NUMBER":
                        terminalFilter = shortcutDto[i].data + "";
                        break;
                    case "DATE":
                        fromDate = new DateObject(shortcutDto[i].data);// [new DateObject(shortcutDto[i].data),new DateObject(shortcutDto[i].data)];
                        toDate = new DateObject(shortcutDto[i].data);
                        break;


                }
            }
        }

        this.state = {
            msg: "",
            fromDate: fromDate,
            toDate: toDate,
            activePage: 1,
            mainPage: 0,
            pages: defaultPages,
            columns: [
            ],
            transactions: [],
            errors: [],
            operationTypeFilter: operationTypeFilter,
            transactionStateFilter: transactionStateFilter,
            terminalFilter: terminalFilter,
            initQuestionFilter: "",
            groupFilter: "",
            merchantFilter: "",
            transactionIdFilter: "",
            departmentFilter: "",
            locationFilter: "",
            branchFilter: "",
            departmentName: "",
            locationName: "",
            branchName: "",
            currentIssuers: WebCache.getAllIssuers(),
            columnsToShow: columnsToShow,
            currencys:  UtilService.getCurrencyOptions(),
            currencySelected: "",
            taxPayerName: "",
            taxPayerFilter: ""
        }

    }

    updatefromDate = (e: DateObject[] | any) => {
        this.setState({ ...this.state, fromDate: e });
    }

    updateToDate = (e: DateObject[] | any) => {
        this.setState({ ...this.state, toDate: e });
    }

    getTransactionRequest = (all: boolean) => {

        let from_year: number;
        let from_month: number;
        let from_day: number;

        let to_year: number;
        let to_month: number;
        let to_day: number;

        from_year = this.state.fromDate.year;
        from_month = this.state.fromDate.month.number;
        from_day = this.state.fromDate.day;

        to_year = this.state.toDate.year;
        to_month = this.state.toDate.month.number;
        to_day = this.state.toDate.day;


        const tranWebModel: ITransactionWebModelRequest = {
            From: new Date(from_year, from_month - 1,
                from_day,
                this.state.fromDate.hour - 3,
                this.state.fromDate.minute).toJSON(),
            To: new Date(to_year,
                to_month - 1,
                to_day,
                this.state.toDate.hour - 3,
                this.state.toDate.minute).toJSON(),

            Terminals: (this.state.terminalFilter == null ||
                this.state.terminalFilter == "") ? [] : [this.state.terminalFilter], //todo falta filtrar las terminales del usuario

            // @ts-ignore
            TransactionState: (this.state.transactionStateFilter == null || this.state.transactionStateFilter == "0") ? [] : allTransactionStatesFilter[this.state.transactionStateFilter],
            OperationType: (this.state.operationTypeFilter == null || this.state.operationTypeFilter == "" || this.state.operationTypeFilter.toLowerCase() == "all") ? "" : this.state.operationTypeFilter,
            TerminalGroup: (this.state.groupFilter == null || this.state.groupFilter == "") ? "" : this.state.groupFilter,
            InitQuestion: (this.state.initQuestionFilter == null || this.state.initQuestionFilter == "") ? "" : this.state.initQuestionFilter,
            MerchantCode: (this.state.merchantFilter == null || this.state.merchantFilter == "") ? "" : this.state.merchantFilter,
            Department: (this.state.departmentFilter == null || this.state.departmentFilter == "0000") ? "" : this.state.departmentFilter,
            Location: (this.state.locationFilter == null || this.state.locationFilter == "0000") ? "" : this.state.locationFilter,
            PayerTaxId: (this.state.taxPayerFilter == null || this.state.taxPayerFilter == "0000") ? "" : this.state.taxPayerFilter,
            Branch: (this.state.branchFilter == null || this.state.branchFilter == "0000") ? "" : this.state.branchFilter,
            TransactionId: (this.state.transactionIdFilter == null || this.state.transactionIdFilter == "") ? "" : this.state.transactionIdFilter,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
            Currency: this.state.currencySelected,
        };
        return tranWebModel;
    }

    getTransactions = () => {
        TransactionService.getCurrentTransactions(this.getTransactionRequest(false)).then((response: AxiosResponse<ITransaction[]>) => {
            if (response == null || response.data == null || response.data.length == 0) {
                this.setState({
                    transactions: [],
                    errors: []
                })
                return;
            }

            let newColumns = [];
            newColumns.push("ticket")
            for (let prop in response.data[0]) {
                newColumns.push(prop)
            }
            this.setState({
                columns: newColumns,
                transactions: response.data,
                errors: []
            });

        }).catch(error => {
            this.setState({
                errors: error.response.data
            });
            this.setState({
                transactions: []
            })
            console.log('error:', error);
        }
        );
    }


    getFromBuscar = () => {
        this.setState({
            ...this.state, activePage: 1, pages: defaultPages,
            mainPage: 0
        }, this.getTransactions);
    }
    clearFilters = () => {
        let [ fromDate, toDate ] = DateHelper.buildDateRange();
        this.setState({
            ...this.state,
            fromDate: fromDate,
            toDate: toDate,
            operationTypeFilter: "", 
            transactionStateFilter: "",
            terminalFilter: "",
            merchantFilter: "",
            initQuestionFilter: "",
            groupFilter: "",
            transactionIdFilter: "",
            currencySelected: "",
            taxPayerFilter: " ",
            branchFilter: " ", 
            departmentFilter: " ", 
            locationFilter: " ",
            departmentName: "", 
            locationName: "", 
            branchName: "", 
            taxPayerName: ""
        })
    }

    handleSubmit(event: any) {
        alert('Your favorite flavor is: ' + this.state);
        event.preventDefault();
    }



    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page>
                <>
                    <h2>{t(`sales`)}</h2>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <NavigationTabLinksForTransactions></NavigationTabLinksForTransactions>
                                </div>
                                <div className="card-body">
                                    <FormFilter
                                        onSubmit={this.getFromBuscar.bind(this)} 
                                        model={[ this.state, this.setState.bind(this) ]}
                                        clear={()=> this.clearFilters()}
                                    >
                                        <>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <DateField attr='fromDate' label='authDateTimeFrom' ></DateField>
                                                </div>
                                                <div className="col-md-3">
                                                    <DateField attr='toDate' label='authDateTimeTo' ></DateField>
                                                </div>
                                                <div className="col-md-3">
                                                    <SelectField attr='operationTypeFilter' label='transactionType' options={this.optionMapper.buildSelectOptionsFromEnum(allOperationTypesFilters, 'transactionType-')} ></SelectField>
                                                </div>
                                                <div className="col-md-3">
                                                    <SelectField attr='transactionStateFilter' label='state' options={this.optionMapper.buildSelectOptionsFromEnum(Object.keys(allTransactionStatesFilter), 'state-')} ></SelectField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='terminalFilter' label='terminal' ></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='merchantFilter' label='merchant' ></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='initQuestionFilter' label='initQuestion' ></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='groupFilter' label='terminalGroup' ></TextField>
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField attr='transactionIdFilter' label='id' ></TextField>
                                                </div>
                                                <TerminalLocation changeFilter={(taxPayerFilter, branchFilter, departmentFilter, locationFilter, departmentName, locationName, branchName, clientDocument) =>
                                                    this.setState({
                                                        ...this.state, taxPayerFilter: taxPayerFilter, branchFilter: branchFilter, departmentFilter: departmentFilter, locationFilter: locationFilter,
                                                        departmentName: departmentName, locationName: locationName, branchName: branchName, taxPayerName: clientDocument
                                                    })} filter={{
                                                        taxPayerFilter: this.state.taxPayerFilter,
                                                        branchFilter: this.state.branchFilter,
                                                        departmentFilter: this.state.departmentFilter,
                                                        locationFilter: this.state.locationFilter
                                                    }}
                                                />
                                                <div className="col-md-3">
                                                    <SelectField attr='currencySelected' label='currency' options={this.state.currencys} ></SelectField>
                                                </div>
                                            </div>
                                        </>
                                    </FormFilter>
                                    <TransactionGrid
                                        transactions={this.state.transactions}
                                        columns={this.state.columns}
                                        columnsToShow={this.state.columnsToShow}
                                        mainPage={this.state.mainPage}
                                        pages={this.state.pages}
                                        activePage={this.state.activePage}
                                        customButtonAction={undefined}
                                    ></TransactionGrid>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Pagination
                                                activePageProp={this.state.activePage}
                                                pageSize={pageSize}
                                                pageQuantity={pageQuantity}
                                                modifyMainPage={(value: number, newPages: Array<number>) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value, pages: newPages
                                                    }, () => this.getTransactions())
                                                }}
                                                changePage={(page: number) => {
                                                    this.setState({ activePage: page }, () => { });
                                                }}
                                                dataL={this.state.transactions.length}
                                                mainPage={this.state.mainPage}
                                            ></Pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Page>
        )
    };
}
export default withTranslation()(CurrentTransactionReport);
