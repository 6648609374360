import React, { useLayoutEffect,useState } from 'react';
import { withTranslation } from 'react-i18next';
import "./SettlementReport.css"
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";

interface IProps {
    rowDetail: {title:string,data:{ prop:string,value:string }[],total:string} |null,
    link:{text:string,path:string}|null
}

interface IState {
    show:boolean
}
class SettlementTotal extends React.Component<IProps, IState>{


    constructor(props: IProps) {
        super(props);


        this.state = {
            show:false
        }
    }

    componentWillReceiveProps(nextProps:IProps) {
        if(nextProps.rowDetail!=null&&nextProps.rowDetail!=this.props.rowDetail){
            this.setState({show:true});
        }
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
                <Modal show={this.state.show} size="lg">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header bg-primary">
                            <h5 className="modal-title text-white"> {t(this.props.rowDetail?.title)}  </h5>
                            <button aria-label="Close" className="btn-close" onClick={() => this.setState({...this.state,show:false})}><span className='text-white' aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12 mb-3">
                                <div className="table-responsive">
                                    <table className="table text-nowrap text-md-nowrap mb-0">
                                        <thead>
                                        <tr >
                                            <th style={{color:"black"}}>{t("paymentConcept")}</th>
                                            <th style={{color:"black"}}>{t("amount")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {

                                            this.props.rowDetail?.data.map((val: {prop:string,value:string} ) => {
                                                return (
                                                    <tr >
                                                        <td key={`li-${val.prop}-prop`}>{t(val.prop)}</td>
                                                        <td key={`li-${val.prop}-val`} className={val.value.indexOf("-")<0?"text-success":"text-danger"}>{t(val.value)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                        <tfoot hidden={this.props.rowDetail?.total==null||this.props.rowDetail?.total.length==0} style={{"background": "lightgray"}}>
                                            <tr >
                                                <td key={`li-total-prop1`}>{t("total").toUpperCase()}</td>
                                                <td key={`li-total-prop2`} className={ this.props.rowDetail?.total!=null&&this.props.rowDetail?.total.indexOf("-")<0?"text-success":"text-danger"}>{t(this.props.rowDetail?.total!=null?this.props.rowDetail.total:"")}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link hidden={this.props.rowDetail?.total==null||this.props.rowDetail?.total.length==0||this.props.link==null} to={this.props.link?.path+""}><button  className="btn btn-primary" data-bs-dismiss="modal">{t(this.props.link?.text)}</button></Link>
                            <button onClick={() => this.setState({...this.state,show:false})} className="btn btn-light" data-bs-dismiss="modal">{t("ok")}</button>
                        </div>
                    </div>
                </Modal>
        )
    };


}
export default withTranslation() (SettlementTotal);
