import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, LoadScript, Autocomplete, MarkerF } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import {WebCache} from "../../services/WebCache";

interface IProps {
    position: {
        lat: number,
        lng: number
    }|null,
    fullAddress: string,
    onSuccess: (res: { fullAddress: string, position: { lat: number, lng: number },departmentForm:string,localityForm:string }) => void,
    showCancelButton: boolean
}
//const ScriptLoaded = require("../../docs/ScriptLoaded").default;
interface IState {
    center: {
        lat: number,
        lng: number
    },
    position: {
        lat: number,
        lng: number
    },
    autocomplete: any,
    fullAddressForm: string,
    departmentForm:string,
    localityForm:string,
    editEnabled: boolean
}




let AutocompleteLocation: React.FC<IProps> = (props) => {

    const { t } = useTranslation();

    let [state, setState] = useState<IState>({
        center: {
            lat: -34.9070181,
            lng: -56.2019864
        },
        position: {
            lat: 0,
            lng: 0
        },
        autocomplete: null,
        fullAddressForm: "",
        editEnabled: false,
        localityForm:"",
        departmentForm: "",
    });

    const containerStyle = {
        width: '100%',
        height: '400px',

    };

    let onSuccess = function () {
        setState({ ...state, editEnabled: false });
        props.onSuccess({ fullAddress: state.fullAddressForm, position: state.position,departmentForm:state.departmentForm,localityForm:state.localityForm});
    }

    let onCancel = function () {
        window.location.reload();
    }

    useEffect(() => {

        if (props.position != null && props.position.lng != 0) {
            setState({
                ...state,
                position: props.position,
                center: props.position,
                fullAddressForm:
                    props.fullAddress
            })
        }

    }, [props])

    let onLoad = function (autocomplete: any) {
        state.autocomplete = autocomplete
        //setState({...state,position:props.position,center:props.position,fullAddressForm:props.fullAddress})
    }

    let onPlaceChanged = function () {

        if (state.autocomplete !== null) {
            console.log(state.autocomplete.getPlace().address_components)
            // @ts-ignore
            let department=state.autocomplete.getPlace().address_components.filter(comp=>comp.types.filter(type=>type=="administrative_area_level_1").length>0);
            // @ts-ignore
            let locality=state.autocomplete.getPlace().address_components.filter(comp=>comp.types.filter(type=>type=="locality").length>0);

            let departmentForm=department.length>0?department[0].short_name.toUpperCase():"";
            if(departmentForm.indexOf("DEPARTAMENTO DE")==0)
                departmentForm=departmentForm.substring("DEPARTAMENTO DE ".length);

            setState({
                ...state, center: {
                    lat: state.autocomplete.getPlace().geometry.location.lat(),
                    lng: state.autocomplete.getPlace().geometry.location.lng()
                }, position: {
                    lat: state.autocomplete.getPlace().geometry.location.lat(),
                    lng: state.autocomplete.getPlace().geometry.location.lng()
                },
                fullAddressForm: state.autocomplete.getPlace().formatted_address,
                departmentForm:departmentForm,
                localityForm:locality.length>0?locality[0].short_name.toUpperCase():""
            })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }



    let onMarkLoad = function (marker: any) {
        console.log('marker: ', marker)
        //alert(marker.lat)
    }

    let onChangeInput = function (event: React.ChangeEvent<HTMLInputElement>) {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{t("address")}</h3>
                    <div style={{ position: "absolute", right: "10px" }}>
                        <button disabled={state.editEnabled} type="button" className="btn btn-icon  btn-primary" onClick={() => setState({ ...state, editEnabled: true })}><i
                            className="fe fe-edit-2"></i></button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">

                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            options={{
                                componentRestrictions: WebCache.getCurrentUser()?.countryId==2?{ country: "uy" }:{ country: "ar" },
                                //  types: ["establishment","address"],
                            }
                            }
                        >
                            <input
                                disabled={!state.editEnabled}
                                onChange={onChangeInput}
                                type="text"
                                placeholder={t("address")}
                                name="fullAddressForm"
                                value={state.fullAddressForm}
                                className="form-control"></input>
                        </Autocomplete>
                        <p></p>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={state.center}
                            zoom={15}>
                            <MarkerF
                                onLoad={onMarkLoad}
                                position={state.position}
                            />
                        </GoogleMap>
                    </div>
                </div>
                <div className="card-footer">
                    <button style={{ marginLeft: "10px" }} type="button" disabled={!state.editEnabled} className="btn btn-success"
                        onClick={() => onSuccess()} ><i className="fe fe-save me-2"></i>{t("save")}
                    </button>

                    {props.showCancelButton &&
                        <button type="button" disabled={!state.editEnabled} className="btn btn-danger"
                            onClick={() => onCancel()} ><i className="fe fe-save me-2"></i>{t("cancel")}
                        </button>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default AutocompleteLocation;
