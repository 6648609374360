import React, {useState} from 'react';
import {ReQuartzCron,CronLocalization} from '@sbzen/re-cron';
import {Type} from "@sbzen/cron-core";
import i18next from "i18next";
import "./scheduler.scss";
import InputField from '../controls/fields/inputField';
import { ReactComponent as IconEditSVG } from "../../../node_modules/itd-common/assets/icons/edit.svg";

interface IProps {
    modifyFrequency: (freq:string) => void,
    startValue:string
}

interface IState {
    value: string,
    localization:CronLocalization
}

function CronScheduler ({modifyFrequency, startValue}: IProps) {
    const [hidden, setHidden] = useState(true);

    const modifyValue = (value:string) => {
        setState({...state,value:value})
        modifyFrequency(value)
    }
    const buildLocalization = () => {
        return {
            common: {
                month: {
                    january: i18next.t('january')+"",
                    february: i18next.t('february')+"",
                    march: i18next.t('march')+"",
                    april: i18next.t('april')+"",
                    may: i18next.t('may')+"",
                    june: i18next.t('june')+"",
                    july: i18next.t('july')+"",
                    august: i18next.t('august')+"",
                    september: i18next.t('september')+"",
                    october: i18next.t('october')+"",
                    november: i18next.t('november')+"",
                    december: i18next.t('december')+"",
                },
                dayOfWeek: {
                    sunday: i18next.t('sunday')+"",
                    monday: i18next.t('monday')+"",
                    tuesday: i18next.t('tuesday')+"",
                    wednesday: i18next.t('wednesday')+"",
                    thursday: i18next.t('thursday')+"",
                    friday: i18next.t('friday')+"",
                    saturday: i18next.t('saturday')+"",
                },
                dayOfMonth: {
                    '1st': i18next.t('1')+"",
                    '2nd': i18next.t('2')+"",
                    '3rd': i18next.t('3')+"",
                    '4th': i18next.t('4')+"",
                    '5th': i18next.t('5')+"",
                    '6th': i18next.t('6')+"",
                    '7th': i18next.t('7')+"",
                    '8th': i18next.t('8')+"",
                    '9th': i18next.t('9')+"",
                    '10th': i18next.t('10')+"",
                    '11th': i18next.t('11')+"",
                    '12th': i18next.t('12')+"",
                    '13th': i18next.t('13')+"",
                    '14th': i18next.t('14')+"",
                    '15th': i18next.t('15')+"",
                    '16th': i18next.t('16')+"",
                    '17th': i18next.t('17')+"",
                    '18th': i18next.t('18')+"",
                    '19th': i18next.t('19')+"",
                    '20th': i18next.t('20')+"",
                    '21st': i18next.t('21')+"",
                    '22nd': i18next.t('22')+"",
                    '23rd': i18next.t('23')+"",
                    '24th': i18next.t('24')+"",
                    '25th': i18next.t('25')+"",
                    '26th': i18next.t('26')+"",
                    '27th': i18next.t('27')+"",
                    '28th': i18next.t('28')+"",
                    '29th': i18next.t('29')+"",
                    '30th': i18next.t('30')+"",
                    '31st': i18next.t('31')+"",
                }
            },
            tabs: {
                seconds: i18next.t('seconds')+"",
                day: i18next.t('day')+"",
                month: i18next.t('month')+"",
                minutes: i18next.t('minutes')+"",
                hours: i18next.t('hours')+"",
            },
            quartz: {
                month: {
                    every: {
                        label: i18next.t('everyMonth')+"",
                    },
                    increment: {
                        label1: i18next.t('incrementPrefix')+"",
                        label2: i18next.t('incrementMonth')+"",
                    },
                    and: {
                        label: i18next.t('specificMonth')+"",
                    },
                    range: {
                        label1:i18next.t('monthRangePrefix')+"",
                        label2: i18next.t('and')+"",
                    }
                },
                day: {
                    every: {
                        label: i18next.t('everyDay')+"",
                    },
                    dayOfWeekIncrement: {
                        label1: i18next.t('every')+"",
                        label2: i18next.t('daysStartingOn')+"",
                    },
                    dayOfMonthIncrement: {
                        label1: i18next.t('every')+"",
                        label2: i18next.t('daysStartingOn')+"",
                        label3:i18next.t('ofTheMonth')+""
                    },
                    dayOfWeekAnd: {
                        label: i18next.t('specificDayOfWeekOneOrMany')+""
                    },
                    dayOfWeekRange: {
                        label1: i18next.t('everyDayBetween')+"",
                        label2:i18next.t('and')+""
                    },
                    dayOfMonthAnd: {
                        label: i18next.t('specificDayOfMonthOneOrMany')+""
                    },
                    dayOfMonthLastDay: {
                        label: i18next.t('onTheLastDayOfTheMonth')+""
                    },
                    dayOfMonthLastDayWeek: {
                        label: i18next.t('onTheLastWeekdayOfTheMonth')+"",
                    },
                    dayOfWeekLastNTHDayWeek: {
                        label1: i18next.t('onTheLast')+"",
                        label2: i18next.t('ofTheMonth')+""
                    },
                    dayOfMonthDaysBeforeEndMonth: {
                        label: i18next.t('daysBeforeTheEndOfTheMonth')+""
                    },
                    dayOfMonthNearestWeekDayOfMonth: {
                        label1: i18next.t('nearestWeekdayMondayToFridayToThe')+"",
                        label2: i18next.t('ofTheMonth')+""
                    },
                    dayOfWeekNTHWeekDayOfMonth: {
                        label1: i18next.t('onThe')+"",
                        label2: i18next.t('ofTheMonth')+""
                    }
                },
                minute: {
                    every: {
                        label: i18next.t('everyMinute')+""
                    },
                    increment: {
                        label1: i18next.t('every')+"",
                        label2:i18next.t('minutesStartingAtMinute')+""
                    },
                    and: {
                        label: i18next.t('specificMinuteChooseOneOrMany')+""
                    },
                    range: {
                        label1: i18next.t('everyMinuteBetweenMinute')+"",
                        label2: i18next.t('andMinute')+""
                    }
                },
                hour: {
                    every: {
                        label: i18next.t('everyHour')+""
                    },
                    increment: {
                        label1: i18next.t('every')+"",
                        label2: i18next.t('hoursStartingAtHour')+""
                    },
                    and: {
                        label: i18next.t('specificHourChooseOneOrMany')+""
                    },
                    range: {
                        label1: i18next.t('everyHourBetweenHour')+"",
                        label2: i18next.t('andHour')+""
                    }
                }
            }
        };
    }
    const [state, setState] = useState<IState>({
        value: startValue,
        localization: buildLocalization()
    });

    return (
        <div>
            <InputField
                type='text'
                attr='value'
                label="Frecuencia"
                model={[state, setState]}
                disabled
                sufix={
                    <a type='button' className="input-icon right h-100 w-100" onClick={() => {setHidden(!hidden)}}>
                        { hidden ?
                            <IconEditSVG ></IconEditSVG>
                            :
                            <i className="fas fa-window-close"></i>
                        }
                    </a>
                  }
            ></InputField>
            {   
                hidden ||
                        <ReQuartzCron
                            value={state.value}
                            onChange={(value: string) => modifyValue(value)}
                            tabs={[Type.MONTH, Type.DAY, Type.HOURS]}
                            localization={state.localization}
                        />
                
            }
        </div>
    );
}
export default CronScheduler;
