import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { axiosApiInstance } from "./HttpService";
export class ServiceorkerRegister {

    public static async registerWorkers() {
       return new Promise((resolve, reject) => {

        serviceWorkerRegistration.register({
            onInit: function (registration: any) {
                Notification.requestPermission().then((result) => {
                    if (result === "granted") {
                        axiosApiInstance.get(`${process.env.REACT_APP_API_URL}/api/PushNotifications/vapidPublicKey`).then(function (response){
                            const subscription = registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: urlBase64ToUint8Array(response.data)
                            }).then((newSubscription: PushSubscription) => {

                                const endpoint = newSubscription.endpoint;
                                const key = newSubscription.getKey('p256dh');
                                const auth = newSubscription.getKey('auth');

                                return axiosApiInstance.post(`${process.env.REACT_APP_API_URL}/api/PushNotifications/subscribe`, {
                                    endpoint: endpoint,  // @ts-ignore
                                    p256DH: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,  // @ts-ignore
                                    auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null
                                }).then(t=>resolve("Promise resolved successfully"));
                            }).catch((error: any) => {
                                resolve("Promise resolved successfully");
                                console.error("Failed to subscribe:", error);
                            });
                        });
                    }else{
                        resolve("Promise resolved successfully");
                    }
                });

            },
            onUpdate: function (registration: any) {
                //alert("onUpdate")
            },
            onSuccess: function (registration: any) {
                //alert("onSuccess")
            }
        });

        function urlBase64ToUint8Array(base64String: string) {
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, "+")
                .replace(/_/g, "/");
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }
        });
    }


}