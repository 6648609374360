import {TransactionService} from "./TransactionService";
import {AxiosResponse} from "axios";
import {IIssuer} from "../models/IIssuer";
import {
    IAllTerminalResponse,
    IBranche,
    IDepartment,
    ILocation,
    IPayerTaxId,
    ITerminal
} from "../models/ITerminal";
import {TerminalsService} from "./TerminalsService";
import {IUserProfile} from "../models/IUserProfile";
import {ClientService} from "./loyalty/ClientService";
import {ILoyaltyMerchant} from "../models/loyalty/IClient";
import { MercadoPagoService } from "./MercadoPagoService";
import {IMPConfigData} from "../models/IMPConfigData";
import {IPromotionCouponConfiguration} from "../models/loyalty/ILoyaltyCoupon";
import {Option} from "../models/Option";
import {SettlementService} from "./SettlementService";

let _TaskRuning: boolean = false;
let  _allIssuersFilters: IIssuer[] = [{id:"0000",issuerName:"issuer-0000",acquirerId:"0000",acquirerName:"acquirer-0000",cardType:"0"}];
let  _allTerminals: ITerminal[] = [{serialNumber:undefined,terminalNumber: "0000",clientDocument: "",isPinpad: false,branchId: "0000",departmentId: "0000",locationId: "0000",departmentName:"",branchName:"",locationName:""}];
let  _allBranches: IBranche[] = [{id: "0000",name: "all",locationId: "0000",departmentId: "0000",departmentName:"",locationName:"",fullDesc:"",position:null,fullAddress:"",branchTerminals:[]}];
let  _allPayerTaxIds: IPayerTaxId[] = [{id: "0000",name: "all",branchId: "0000",locationId: "0000",departmentId: "0000"}];
let  _allLocations: ILocation[] = [{id: "0000",name: "all",departmentId: "0000"}];
let  _allDepartments: IDepartment[] = [{id: "0000",name: "all"}];
let currentUser:IUserProfile|null=null;
let _loyaltyEnabled:string="";
let _promotionCouponsEnabled:string="";
let _mpUserConfigData: IMPConfigData = {enabled:false,initialized:false, loaded:false,grantAccessUrl:""};
let _adUser: {loaded:boolean,userMail?:string,roles?:{rolName:string,rolDescription:string}[],fiscalNumbers:{fiscalNumber:number,businessName:string,commerces:{commerceName:string,commerceNumber:number,status:string,presentationGroup:number}[]}[]}={loaded:false,fiscalNumbers:[]};
let _currencies: {currencies:{id:string,name:string}[],loaded:boolean}={currencies:[],loaded:false}
let _supportTicketSections: {sections:{description:string,subSections:string[],additionalFields:string[]}[],loaded:boolean}={sections:[],loaded:false}


let _allPayerBanks: Option[] = [{ value: "", label: "all", key: "",disabled: false}];
let _allProducts: Option[] = [{ value: "", label: "allFemale", key: "",disabled: false}];
let _allPayerBanksBranchs: Option[] = [{ value: "", label: "allFemale", key: "",disabled: false}];
let _allMerchantDocuments: Option[] = [{ value: "", label: "all", key: "",disabled: false}];
let _allMerchantNumbers: Option[] = [{ value: "", label: "all", key: "",disabled: false}];
let  _allFiscalDocuments: Option[] = [{ value: "", label: "all", key: "",disabled: false}];


export class WebCache {

    public  static initSettlements() {

        if(localStorage._allPayerBanks!=null)
            return;

        SettlementService.getAllPayerBanksAndProductsFromServer().then(function(banksAndProducts){
            if(_allPayerBanks.length === 1)
                _allPayerBanks=_allPayerBanks.concat(banksAndProducts.data.banks.sort((x1,x2)=>x1.value>x2.value?1:-1).map(x=>{return {value: x.id, label: x.value, key: x.id,disabled: false}}));
            if(_allProducts.length === 1)
                _allProducts=_allProducts.concat(banksAndProducts.data.products.sort((x1,x2)=>x1.value>x2.value?1:-1).map(x=>{return {value: x.id, label: x.value, key: x.id,disabled: false}}));
            if(_allPayerBanksBranchs.length === 1)
                _allPayerBanksBranchs=_allPayerBanksBranchs.concat(banksAndProducts.data.bankBranches.sort((x1,x2)=>x1.value>x2.value?1:-1).map(x=>{return {value: x.id, label: x.value, key: x.id,disabled: false}}));
            if(_allMerchantDocuments.length === 1)
                _allMerchantDocuments=_allMerchantDocuments.concat(banksAndProducts.data.merchantDocuments.sort((x1,x2)=>x1.value>x2.value?1:-1).map(x=>{return {value: x.id, label: x.value, key: x.id,disabled: false}}));
            if(_allMerchantNumbers.length === 1)
                _allMerchantNumbers=_allMerchantNumbers.concat(banksAndProducts.data.merchantNumbers.sort((x1,x2)=>x1.value>x2.value?1:-1).map(x=>{return {value: x.id, label: x.value, key: x.id,disabled: false}}));

            localStorage.setItem("_allPayerBanks",JSON.stringify(_allPayerBanks));
            localStorage.setItem("_allPayerBanksBranchs",JSON.stringify(_allPayerBanksBranchs));
            localStorage.setItem("_allProducts",JSON.stringify(_allProducts));
            localStorage.setItem("_allMerchantDocuments",JSON.stringify(_allMerchantDocuments));
            localStorage.setItem("_allMerchantNumbers",JSON.stringify(_allMerchantNumbers));
        });

    }

    public  static initCache() {
        _allIssuersFilters = [_allIssuersFilters[0]];
        _allTerminals = [_allTerminals[0]];
        _allBranches = [_allBranches[0]];
        _allPayerTaxIds = [_allPayerTaxIds[0]];
        _allLocations = [_allLocations[0]];
        _allDepartments = [_allDepartments[0]];
        _allPayerBanks = [_allPayerBanks[0]];
        _allProducts = [_allPayerBanks[0]];
        _allPayerBanksBranchs =[_allPayerBanksBranchs[0]];
        _allMerchantDocuments =[_allMerchantDocuments[0]];
        _allMerchantNumbers =[_allMerchantNumbers[0]];
        WebCache.initSettlements();

    var promise = new Promise((resolve, reject) => {

                ClientService.getPromotionCouponsConfiguration()
                    .then((response: AxiosResponse<IPromotionCouponConfiguration>) => {
                        if (response.status === 200 && response.data.enabled)
                            _promotionCouponsEnabled = "true";
                        else
                            _promotionCouponsEnabled = "false";
                        localStorage.setItem("_promotionCouponsEnabled", _promotionCouponsEnabled);

                    }).catch(() => localStorage.setItem("_promotionCouponsEnabled", "false")).finally(() => {
                        ClientService.getLoyaltyMerchant()
                            .then((response: AxiosResponse<ILoyaltyMerchant>) => {
                                if (response.status === 200)
                                    _loyaltyEnabled = "true";
                                else
                                    _loyaltyEnabled = "false";
                                localStorage.setItem("_loyaltyEnabled", _loyaltyEnabled);

                            }).catch(() => localStorage.setItem("_loyaltyEnabled", "false")).finally(() => {
                                TransactionService.getAllIssuers().then((response: AxiosResponse<IIssuer[]>) => {
                                    if (response === null || response.data === null || response.data.length === 0) {
                                        return;
                                    }
                                    for (var i in response.data) {
                                        _allIssuersFilters.push(response.data[i]);
                                    }
                                    localStorage.setItem("_allIssuersFilters", JSON.stringify(_allIssuersFilters));
                                    TerminalsService.getAllTerminalsAndLocations().then((response: AxiosResponse<IAllTerminalResponse>) => {
                                        if (response === null || response.data === null) {
                                            return;
                                        }
                                        let ruts: Map<string, string> = new Map<string, string>();
                                        if (response.data.terminals != null) {
                                            for (var i in response.data.terminals) {
                                                _allTerminals.push(response.data.terminals[i]);
                                                if (ruts.get(response.data.terminals[i].clientDocument) == null) {
                                                    _allPayerTaxIds.push({
                                                        id:
                                                            response.data.terminals[i].clientDocument,
                                                        name: response.data.terminals[i].clientDocument,
                                                        branchId: response.data.terminals[i].branchId,
                                                        locationId: response.data.terminals[i].locationId,
                                                        departmentId: response.data.terminals[i].departmentId
                                                    });
                                                    ruts.set(response.data.terminals[i].clientDocument, response.data.terminals[i].clientDocument);
                                                }
                                            }
                                        }
                                        if (response.data.branches != null) {
                                            for (var i in response.data.branches) {
                                                _allBranches.push(response.data.branches[i]);
                                                response.data.branches[i].fullDesc=response.data.branches[i].name
                                            }
                                    }
                                    if(response.data.locations !== null){
                                        for (var i in response.data.locations){
                                            _allLocations.push(response.data.locations[i]);
                                            _allBranches.filter(branch=>branch.locationId==response.data.locations[i].id).forEach(branch=> {branch.locationName=response.data.locations[i].name;
                                                branch.fullDesc+=", "+response.data.locations[i].name; })
                                        }
                                    }
                                    if(response.data.departments !== null){
                                        for (var i in response.data.departments){
                                            _allDepartments.push(response.data.departments[i]);
                                            _allBranches.filter(branch=>branch.departmentId==response.data.departments[i].id).forEach(branch=> {branch.departmentName=response.data.departments[i].name;
                                                branch.fullDesc+=", "+response.data.departments[i].name; })
                                        }
                                    }
                                    WebCache.fetchAllFiscalDocuments().then(function(){
                                        WebCache.updateFeatures().then(function(){
                                            localStorage.setItem("_allPayerTaxIds",JSON.stringify(_allPayerTaxIds));
                                            localStorage.setItem("_allTerminals",JSON.stringify(_allTerminals));
                                            localStorage.setItem("_allBranches",JSON.stringify(_allBranches));
                                            localStorage.setItem("_allDepartments",JSON.stringify(_allDepartments));
                                            localStorage.setItem("_allLocations",JSON.stringify(_allLocations));
                                            resolve("Promise resolved successfully");
                                        });
                                    });
                                }).catch(error => {
                                        console.log('error:', error);
                                        reject(Error(error));
                                    }
                                    );
                                }).catch(error => {
                                    debugger
                                    console.log('error:', error);
                                    reject(Error(error));
                                }
                                );
                            });
                    });
        });
        return promise;
    }

    public  static getAllIssuers() {
        if(_allIssuersFilters.length === 1 && localStorage._allIssuersFilters)
            _allIssuersFilters = JSON.parse(localStorage._allIssuersFilters);
        return _allIssuersFilters;
    };

    public  static getAllDepartments() {
        if(_allDepartments.length === 1 && localStorage._allDepartments)
            _allDepartments = JSON.parse(localStorage._allDepartments);
        return _allDepartments;
    };

    public  static getAllLocations() {
        if(_allLocations.length === 1 && localStorage._allLocations)
            _allLocations = JSON.parse(localStorage._allLocations);
        return _allLocations;
    };

    public  static getAllBranches() {
        if(_allBranches.length === 1 && localStorage._allBranches)
            _allBranches = JSON.parse(localStorage._allBranches);
        return _allBranches;
    };

    public  static getAllPayerTaxIds():IPayerTaxId[] {
        if(_allPayerTaxIds.length === 1 && localStorage._allPayerTaxIds)
            _allPayerTaxIds = JSON.parse(localStorage._allPayerTaxIds);
        // @ts-ignore
        return _allPayerTaxIds.filter(x=>WebCache.getCurrentUser()?.clientDocuments==null||WebCache.getCurrentUser()?.clientDocuments.length==0||WebCache.getCurrentUser()?.clientDocuments.filter(y=>y==x.id).length>0);

    };

    public  static getAllTerminals() {
        if(_allTerminals.length === 1 && localStorage._allTerminals)
            _allTerminals = JSON.parse(localStorage._allTerminals);
        return _allTerminals;
    };

    static getAllRoles() {
        let userLoged = WebCache.getCurrentUser();
        if(userLoged && userLoged.roles && userLoged.countryId===1) {
            return ["Usuario de Cliente","Estadistico", "Operator","OnlyQr","Conciliator","AutomaticDebit","Minorista"]; //TODO sacar por base
        } else {
            return ["Usuario de Cliente","Estadistico", "Operator","Conciliator","AutomaticDebit"];//TODO sacar por base
        }
    }

    public static getCurrentUser():IUserProfile|null {
        if(!currentUser) {
            currentUser = localStorage.user ? JSON.parse(localStorage.user) : null;
        }
        return currentUser
    }

    public static isDashboardAllowed() {
        let userLoged = WebCache.getCurrentUser();
        if (userLoged && userLoged.roles) {
            return userLoged.roles.includes("Estadistico")|| userLoged.roles.includes("Minorista") || userLoged.roles.includes("Usuario de Cliente")||userLoged.roles.includes("Administrador");
        }
        return false;
    }

    public static isConciliatorAllowed(){
        let userLoged = WebCache.getCurrentUser();
        if (userLoged && userLoged.roles) {
            return userLoged.roles.includes("Usuario de Cliente") || userLoged.roles.includes("Minorista") || userLoged.roles.includes ("Conciliator")|| userLoged.roles.includes("Administrador");
        }
        return false;
    }
    
    public static isTransactionsAllowed(){
        let userLoged = WebCache.getCurrentUser();
        if (userLoged && userLoged.roles) {
            return userLoged.roles.includes("Operator") || userLoged.roles.includes("Usuario de Cliente") || userLoged.roles.includes("Minorista") || userLoged.roles.includes("Administrador");
        }
        return false;
    }

    static getCurrencies(){
        if (!_currencies.loaded) {
            if (localStorage._currencies) {
                _currencies.currencies = JSON.parse(localStorage._currencies);
                _currencies.loaded = true;
            }
        }
        return _currencies?.currencies;
    }
    static getSupportTicketSections():{description:string,subSections:string[],additionalFields:string[]}[]{
        if (!_supportTicketSections.loaded) {
            if (localStorage._supportTicketSections) {
                _supportTicketSections.sections = JSON.parse(localStorage._supportTicketSections);
                _supportTicketSections.loaded = true;
            }
        }
        return _supportTicketSections?.sections;
    }

    public static isTransferReturnAllowed(){
        let userLoged = WebCache.getCurrentUser();
        if(userLoged && userLoged.roles && userLoged.countryId===1) {
            return userLoged.roles.includes("OnlyQr") || userLoged.roles.includes("Usuario de Cliente") || userLoged.roles.includes("Minorista") || userLoged.roles.includes("Administrador");
        }
        return false;
    }

    public static isDaAllowed(){
        let userLoged = WebCache.getCurrentUser();
        if(userLoged && userLoged.roles) {
            if(userLoged.roles.includes("Usuario de Cliente") || userLoged.roles.includes("AutomaticDebit") || userLoged.roles.includes("Minorista")||userLoged.roles.includes("Administrador")){
                //sacar cuando este el refactor de DA
                if (!_adUser.loaded) {
                    if (localStorage._adUser) {
                        _adUser = JSON.parse(localStorage._adUser);
                        _adUser.loaded = true;
                    }
                }
                return _adUser?.fiscalNumbers.filter(x=>x.commerces.filter(y=>y.status="A").length>0).length>0;
            }
        }
        return false;
    }

    static isPromotionCouponsEnabled() {
        
        if (_promotionCouponsEnabled.length === 0) {
            if (localStorage._promotionCouponsEnabled) {
                _promotionCouponsEnabled = localStorage._promotionCouponsEnabled;
            }
            else {
                _promotionCouponsEnabled = "false";
            }
        }

        return _promotionCouponsEnabled === "true";
    }

    static isLoyaltyEnabled() {
        
        if (_loyaltyEnabled.length === 0) {
            if (localStorage._loyaltyEnabled) {
                _loyaltyEnabled = localStorage._loyaltyEnabled;
            }
            else {
                _loyaltyEnabled = "false";
            }
        }

        return _loyaltyEnabled === "true";
    }

    static getMPConfiguration() {
        
        if (!_mpUserConfigData.loaded) {
            if (localStorage._mpUserConfigData) {
                _mpUserConfigData = JSON.parse(localStorage._mpUserConfigData);
                _mpUserConfigData.loaded = true;
            }
        }

        return _mpUserConfigData;
    }

    static fetchAllFiscalDocuments() {
        var promise = new Promise((resolve, reject) => {
            TerminalsService.getAllFiscalDocuments().then((response) => {
                if (response.status === 200) {
                    _allFiscalDocuments = _allFiscalDocuments.concat(response.data.map(x=>{return { value: x, label: x, key: x,disabled: false}}));
                    localStorage.setItem("_allFiscalDocuments",JSON.stringify(_allFiscalDocuments));
                }
            }).finally(function(){
                resolve(_allFiscalDocuments)
            })
        });
        return promise;

    }

    static updateFeatures() {
        var responseData:any=null;
        var promise = new Promise((resolve, reject) => {
            TransactionService.getFeatures().then((response) => {
                if (response.status === 200) {
                    responseData=response.data;
                    if(responseData.mPUserDto!=null)
                        _mpUserConfigData = responseData.mPUserDto;
                    if(responseData.adUserResponseDto!=null&&responseData.adUserResponseDto.fiscalNumbers!=null)
                        _adUser = responseData.adUserResponseDto;
                    if(responseData.currencies!=null&&responseData.currencies.length>0)
                        _currencies = responseData.currencies;
                    if(responseData.supportTicketSections!=null&&responseData.supportTicketSections.length>0){
                        let _supportTicketSections = responseData.supportTicketSections;
                        try{

                            if(WebCache.getCurrentUser()?.countryId==2){
                                let res:{description:string,subSections:string[],additionalFields:string[]}[]=[];
                                for(var i=0;i<_supportTicketSections.length;i++){
                                    if(_supportTicketSections[i].description.toUpperCase().indexOf("QR PCT")>=0)
                                        continue;
                                    res.push(_supportTicketSections[i]);
                                    _supportTicketSections[i].description=_supportTicketSections[i].description.split("CUIT").join("RUT");
                                    for(var j=0;j<_supportTicketSections[i].subSections.length;j++){
                                        _supportTicketSections[i].subSections[j]=_supportTicketSections[i].subSections[j].split("CUIT").join("RUT");
                                    }
                                    for(var j=0;j<_supportTicketSections[i].additionalFields.length;j++){
                                        _supportTicketSections[i].additionalFields[j]=_supportTicketSections[i].additionalFields[j].split("CUIT").join("RUT");
                                    }
                                }
                                _supportTicketSections=res;
                            }
                        }catch (e){console.log(e)}
                        localStorage.setItem("_supportTicketSections",JSON.stringify(_supportTicketSections));
                    }
                    localStorage.setItem("_mpUserConfigData",JSON.stringify(_mpUserConfigData));
                    localStorage.setItem("_adUser",JSON.stringify(_adUser));
                    localStorage.setItem("_currencies",JSON.stringify(_currencies));

                }
            }).finally(function(){
                resolve(responseData)
            })
        });
        return promise;

    }

    static isManagementAllowed() {
        let userLoged = WebCache.getCurrentUser();
        if(userLoged && userLoged.roles) {
            return userLoged.roles.includes("Usuario de Cliente")|| userLoged.roles.includes("Minorista") || userLoged.roles.includes("Administrador");
        }
        return false;
    }

    static isTerminalUpdateAllowed() {
        let userLoged = WebCache.getCurrentUser();
        if(userLoged && userLoged.roles) {
            return userLoged.roles.find(r => r === "TerminalManager" ) !== null
        }
        return false;
    }

    static isTaskRuning = function () { return _TaskRuning }

    static clearCache() {
        localStorage.removeItem("user");
        localStorage.removeItem("_allIssuersFilters");
        localStorage.removeItem("_allTerminals");
        localStorage.removeItem("_allBranches");
        localStorage.removeItem("_allLocations");
        localStorage.removeItem("_allDepartments");
        localStorage.removeItem("_loyaltyEnabled");
        localStorage.removeItem("_promotionCouponsEnabled");
        localStorage.removeItem("_allPayerTaxIds");
        localStorage.removeItem("_mpUserConfigData");
        localStorage.removeItem("_adUser");
        localStorage.removeItem("_currencies");
        localStorage.removeItem("_supportTicketSections");
        localStorage.removeItem("_allPayerBanks");
        localStorage.removeItem("_allProducts");
        localStorage.removeItem("_allPayerBanksBranchs");
        localStorage.removeItem("_allMerchantDocuments");
        localStorage.removeItem("_allMerchantNumbers");
        localStorage.removeItem("_allFiscalDocuments");

        currentUser=null;
    }

    static disableTasks() {
        _TaskRuning = false;
    }

    static enableTasks() {
        _TaskRuning = true;
    }

    static showBranchWarnings() {
        let branches=this.getAllBranches();
        if(branches.length === 1)
            return true;
        return this.getAllBranches().filter((branch, index) => index > 0 && (branch.fullAddress === null || branch.fullAddress.length === 0)).length > 0;
    }

    static getAllPayerBanks(includeALl:boolean): Option[] {
        if(_allPayerBanks.length === 1 && localStorage._allPayerBanks) {
            _allPayerBanks = JSON.parse(localStorage._allPayerBanks);
        }
        if(includeALl)
            return _allPayerBanks;
        return _allPayerBanks.filter((x,index)=>index!=0);
    }
    
    static getAllProducts(includeALl:boolean): Option[] {
        if(_allProducts.length === 1 && localStorage._allProducts)
            _allProducts = JSON.parse(localStorage._allProducts);
        if(includeALl)
            return _allProducts;
        return _allProducts.filter((x,index)=>index!=0);
    }


/*
    static getAllFiscalDocuments(includeALl:boolean): Option[] {
        if(_allFiscalDocuments.length === 1 && localStorage._allFiscalDocuments)
            _allFiscalDocuments = JSON.parse(localStorage._allFiscalDocuments);
        // @ts-ignore
        if(WebCache.getCurrentUser()!=null&&WebCache.getCurrentUser()?.clientDocuments!=null&&WebCache.getCurrentUser().clientDocuments.length>0)
            // @ts-ignore
            return _allFiscalDocuments.filter(x=>WebCache.getCurrentUser()?.clientDocuments.filter(y=>y==x.label).length>0);
        if(includeALl)
            return _allFiscalDocuments;
        return _allFiscalDocuments.filter((x,index)=>index!=0);
    }*/

    static getAllPayerBankBranches(includeALl:boolean): Option[] {
        if(_allPayerBanksBranchs.length === 1 && localStorage._allPayerBanksBranchs)
            _allPayerBanksBranchs = JSON.parse(localStorage._allPayerBanksBranchs);
        if(includeALl)
            return _allPayerBanksBranchs;
        return _allPayerBanksBranchs.filter((x,index)=>index!=0);
    }

    static getAllMerchantNumbers(includeALl:boolean): Option[] {
        if(_allMerchantNumbers.length === 1 && localStorage._allMerchantNumbers)
            _allMerchantNumbers = JSON.parse(localStorage._allMerchantNumbers);
        if(includeALl)
            return _allMerchantNumbers;
        return _allMerchantNumbers.filter((x,index)=>index!=0);
    }
    
    static getAllMerchantDocuments(includeALl:boolean): Option[] {
        if(_allMerchantDocuments.length === 1 && localStorage._allMerchantDocuments)
            _allMerchantDocuments = JSON.parse(localStorage._allMerchantDocuments);
        // @ts-ignore
        if(WebCache.getCurrentUser()!=null&&WebCache.getCurrentUser()?.clientDocuments!=null&&WebCache.getCurrentUser().clientDocuments.length>0)
            // @ts-ignore
            return _allMerchantDocuments.filter(x=>WebCache.getCurrentUser()?.clientDocuments.filter(y=>y==x.label).length>0);
        if(includeALl)
            return _allMerchantDocuments;
        return _allMerchantDocuments.filter((x,index)=>index!=0);
    }


}
