import React from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import { HttpService } from "./services/HttpService";
import ForgotPassword from "./components/ForgotPassword";
import { Suspense } from 'react';
import './i18n';
import "./loading.css";
import ResetPassword from './components/ResetPassword';
import Onboarding from './components/Onboarding';
import LostPhone from "./components/LostPhone";
import ResetDevice from "./components/ResetDevice";

HttpService.setupInterceptors()


function App() {
  return (
      <Suspense fallback="...is loading">
        <React.Fragment>
          <Routes>
            <Route path={'/*'} element={<Home />} />
            <Route path={'/login'} element={<Login />} />
            <Route path={'/onboarding'} element={<Onboarding/>}/>
            <Route path={'/forgot-password'} element={<ForgotPassword />} />
            <Route path={'/lost-phone'} element={<LostPhone />} />
            <Route path={'/reset-password'} element={<ResetPassword />} />
            <Route path={'/reset-device'} element={<ResetDevice />} />
          </Routes>
        </React.Fragment>
      </Suspense>
  );
}

export default App;
