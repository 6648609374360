import {axiosApiInstance} from "./HttpService";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {ITerminalWebModelRequest} from "../requests/ITerminalWebModelRequest";
import { IGetTerminalsAndLocationsRequest } from "../models/IGetTerminalsAndLocationsRequest";
import {IBranche} from "../models/ITerminal";

export class TerminalsService{

    public static getTerminals(request: ITerminalWebModelRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal`;
        return axiosApiInstance.post(dataURL,request);
    }
    public static async getAllToExcel(request: ITerminalWebModelRequest){
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateTerminalsExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }
    public static getAllTerminalsAndLocations(){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/getAllTerminalsAndLocations`;
        return axiosApiInstance.get(dataURL);
    }
    public static getAllTerminalsAndLocationsFiltered(request : IGetTerminalsAndLocationsRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/GetAllTerminalsAndLocationsFiltered`;
        return axiosApiInstance.post(dataURL,request);
    }

    public static getAllFiscalDocuments():Promise<AxiosResponse<any[]>>{
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/getAllFiscalDocuments`;
        return axiosApiInstance.post(dataURL);
    }

    static () {

    }

    static UpdateTerminalConfiguration(param: { terminal: string | undefined; isPinpad: boolean | undefined }) {
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/UpdateTerminalConfiguration`;
        return axiosApiInstance.post(dataURL,param);
    }

    static changeBranchToTerminal(param: { terminalId: string | undefined;branchId: string | undefined}) {
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/changeBranchToTerminal`;
        return axiosApiInstance.post(dataURL,param);
    }

    static updateBranch(param: IBranche) {
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/updateBranch`;
        return axiosApiInstance.post(dataURL,param);
    }


    static deleteOrRestoreTerminal(param: { deleted: boolean | undefined; terminalId: string | undefined }) {
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/deleteOrRestoreTerminal`;
        return axiosApiInstance.post(dataURL,param);
    }


    static deleteBranch(id: string | undefined,description:string| undefined) {
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Terminal/deleteBranch`;
        return axiosApiInstance.post(dataURL,{id:id,description:description});
    }


}
