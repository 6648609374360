import "./dateField.scss";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import modelHelper from "./modelHelper";
import BaseField from "./baseField";
import { useModel } from "../../contexts/modelContext";
import { IPropsField, IPropsInputChange } from "./fieldPropertiesInterface";
import {ReactComponent as CalendarIconSVG } from "../../../../node_modules/itd-common/assets/icons/calendar.svg";
import { t } from 'i18next';

interface IPropsRange {
    range?: boolean
}

const T_PREFIX: string = 'pages.dateFieldSettings.';

type IPropsDateField = IPropsField & IPropsRange & IPropsInputChange;

function DateField({
    model,
    attr,
    value,
    label,
    onChange,
    disabled,
    required,
    range
}: IPropsDateField) {
    const modelContext = useModel();
    const modelState = model || modelContext;
    const { getValue } = modelHelper({ model: modelState, attr, value, onChange });
    const [state, setState] = modelState;

    const onValueChange = (event: DateObject[] | any) => {
        if (state && attr) {
            const modelWithChange = { ...state };
            modelWithChange[attr] = event;
            setState(modelWithChange);
        } else if (onChange) {
            onChange(event);
        }
    }
    const spanishMonths = Array.from({ length: 12 }, (_, i) => t(`${T_PREFIX}months.${i + 1}.label`));
    const spanishDays = Array.from({ length: 7 }, (_, i) => t(`${T_PREFIX}days.${i + 1}.label`));
    
    return (
        <BaseField
            attr={attr}
            label={label}
            required={required}
            value={getValue()}
            sufix={
                <div className="pt-2 ps-2 pe-2 bg-white w-100 h-100">
                    <CalendarIconSVG></CalendarIconSVG>
                </div>
            }
        >
            <DatePicker
                disabled={disabled}
                inputClass="date-picker"
                containerClassName="date-picker-container"
                format="DD/MM/YYYY"
                value={getValue() as string}
                onChange={onValueChange}
                plugins={[
                    <TimePicker position="bottom" />
                ]}
                placeholder=" "
                range={range}
                months={spanishMonths} 
                weekDays={spanishDays}
            />
        </BaseField>
    );
}

export default DateField;