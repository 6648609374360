import React, {useEffect} from "react";
import Page from "../containers/page";
import i18next from 'i18next';
import { TFunction, withTranslation } from 'react-i18next';
import SelectField from "../controls/fields/selectField";
import Form from "../containers/form";
import Card from "../containers/card";
import TextField from "../controls/fields/textField";
import { Option } from "../../models/Option";
import Button from "../controls/buttons/button";
import {HelpService, ISupportTicket} from "../../services/HelpService";
import { AxiosResponse } from "axios";
import DateField from "../controls/fields/dateField";
import {Link} from "react-router-dom";
import {OptionMapperHelper} from "../../helpers/OptionMapperHelper";
import {WebCache} from "../../services/WebCache";

interface IResponse {
    trackId: string,
}

interface IState {
    ticket: ISupportTicket,
    trackId: string,
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    subSections:Option[],
    additionalFields:string[],
}

interface IProps {
    t: TFunction
}

const T_PREFIX: string = 'pages.ticket.';

let lastSection:string=""
let lastFiscalDocument:string=""
class HelpIncident extends React.Component<IProps, IState> {

    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private allSections: Option[] = WebCache.getSupportTicketSections()?.map(x=>{return {key:x.description,label:x.description,disabled:false,value:x.description}});

    constructor(props: IProps) {
        super(props);

        let ticketToEdit:ISupportTicket|null=null
        if (window.location.href.indexOf("inc=") > 0) {
            let incId = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("inc="))).get("inc");
            HelpService.getTicket(incId+"").then(this.updateTicketToEdit)
        }
        const merchantDocuments = WebCache.getAllMerchantDocuments(true);
        const defaultMerchantDocument = merchantDocuments.length > 0 ? 'prub' : '';
        const merchantNumbers = OptionMapperHelper.getAllMerchantNumbersOptions(defaultMerchantDocument, true);
        lastSection="";
        lastFiscalDocument="";
        this.state = {
            ticket: {
                title: "",
                description: "",
                userName: this.getUserData()? JSON.parse(this.getUserData())?.name : "",
                eMail: this.getUserData()? JSON.parse(this.getUserData())?.email : "",
                trackId:"",
                section:"",
                subSection: "",
                state:"OPEN",
                closeDate:"",
                id:"",
                needToClose:false,
                openDate:"",
                reOpenDate:"",
                resolutionText:"",
                fiscalDocument: "",
                merchantDocument: "",
                terminal:"",
                fiscalNumberWholesaler:""
            },
            additionalFields:[],
            subSections: [],
            merchantNumbers:merchantNumbers,
            fiscalDocuments:WebCache.getAllMerchantDocuments(true),
            trackId: "",
        }
    }

    updateTicketToEdit = (res:AxiosResponse<ISupportTicket>) => {
        this.setState({ ...this.state, ticket:res.data});
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastSection!=this.state.ticket.section){
            lastSection=this.state.ticket.section;
            let currentSections=WebCache.getSupportTicketSections().filter(x=>x.description==lastSection)[0];
            let subSections=currentSections.subSections.map(x=>{return {key:x,label:x,disabled:false,value:x}});
            let ticketAdditionalFields:any={};
            if(currentSections.additionalFields.length>0){
                for(let i=0;i<currentSections.additionalFields.length;i++){
                    ticketAdditionalFields["field_"+i]="";
                }
            }
            this.setState({...this.state,ticket:{...this.state.ticket,subSection:subSections[0].label},subSections:subSections,additionalFields:currentSections.additionalFields})
        }
        if(lastFiscalDocument!=this.state.ticket.fiscalDocument){
            lastFiscalDocument=this.state.ticket.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,false)
            this.setState({...this.state,ticket:{...this.state.ticket,merchantDocument:merchantNumbers[0].label},merchantNumbers:merchantNumbers})
        }
    }

    closeTicket = () => {
        HelpService.updateTicket({Id:this.state.ticket.id,Description :this.state.ticket.resolutionText+"",Reopen:false}).then(function(){
            window.location.href = '/#/help';
        })
    }
    reopenTicket = () => {
        HelpService.updateTicket({Id:this.state.ticket.id,Description :this.state.ticket.resolutionText+"",Reopen:true}).then(function(){
            window.location.href = '/#/help';
        })
    }

    sendTicket = () => {
        this.setState({trackId: ""});
        let description="";
        if(this.state.additionalFields.length>0){
            for(let i=0;i<this.state.additionalFields.length;i++){
                // @ts-ignore
                description+=this.state.additionalFields[i]+" = "+this.state.ticket["field_"+i]+"\r\n"
            }
            description+="\r\n\r\n"
        }
        description+=this.state.ticket.description;
        HelpService.postGenerateTicket({
            Description: description,
            EMail: this.state.ticket.eMail,
            UserName: this.state.ticket.userName,
            Title: this.state.ticket.title,
            FiscalNumberWholesaler: this.state.ticket.fiscalNumberWholesaler,
            Section: this.state.ticket.section,
            SubSection: this.state.ticket.subSection,
            Terminal: this.state.ticket.terminal,
            MerchantDocument:this.state.ticket.merchantDocument,
            FiscalDocument:this.state.ticket.fiscalDocument
        }).then((response: AxiosResponse<IResponse>) => {
            this.setState({trackId: response.data.trackId});
            window.location.href = '/#/help';
        }).catch(error => {
                console.log('Error al obtener transacciones:', error);
            }
        );
    }

    getUserData(): string{
        let value= `${localStorage.getItem("user")}`;
        return value;
    }

    setForm(ticket: ISupportTicket) {
        this.setState({ ...this.state, ticket });
    }

    isFormCompleted = () : boolean => {
        let ticket= this.state.ticket;
        return ticket.description != "" && ticket.eMail != "" && ticket.userName != "" && ticket.title != "";
    }

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let fiscalDocument;
        let fiscalNumberWholesaler
        if (currentUser !== undefined && currentUser === 2) {
            fiscalDocument =t(`${T_PREFIX}fiscalDocumentUru`);
            fiscalNumberWholesaler = t(`${T_PREFIX}fiscalNumberWholesalerURU`);
        } else {
            fiscalDocument = t(`${T_PREFIX}fiscalDocumentArg`);
            fiscalNumberWholesaler = t(`${T_PREFIX}fiscalNumberWholesalerARG`);
        }

        return (
            <Page>
                <>
                    <div className="container-fluid">
                        <section>
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                            <div className="backward">
                                                <div className="backward__init">
                                                    <Link to={'/help'} className="link">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g id="ico/arrow-left">
                                                                <path id="Vector 1" d="M10 4L2 12L10 20" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <line id="Line 7" x1="3" y1="12" x2="21" y2="12" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                        </svg>
                                                        <span className='backward__init__callback'>{t(`${T_PREFIX}returnToHelp`)}</span>
                                                    </Link>
                                                </div>
                                    </div>
                                    <Card title={t(`${T_PREFIX}generateTicket`)} >
                                        <Form model={[this.state.ticket, this.setForm.bind(this)]}>
                                            <>
                                                <div className="row">
                                                    {this.state.ticket.id != "" ?
                                                        <div className="col-md-8 mb-3 ms-3">
                                                            <DateField attr="openDate" label={t(`${T_PREFIX}openDate`)}
                                                                    disabled={true}></DateField>
                                                        </div> : <></>}
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <SelectField attr='fiscalDocument'
                                                                    label={fiscalDocument}
                                                                    options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)}
                                                                    disabled={this.state.ticket.id != ""}
                                                                    required></SelectField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <SelectField attr='merchantDocument'
                                                                    label={t(`${T_PREFIX}merchantDocument`)}
                                                                    options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)}
                                                                    disabled={this.state.ticket.id != ""}
                                                                    required></SelectField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <TextField attr='terminal'
                                                                label={t(`${T_PREFIX}terminal`)}
                                                                disabled={this.state.ticket.id != ""}></TextField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <TextField attr='fiscalNumberWholesaler'
                                                                label={fiscalNumberWholesaler}
                                                                disabled={this.state.ticket.id != ""}></TextField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <SelectField attr="section" label={t(`${T_PREFIX}section`)}
                                                                    options={this.allSections}
                                                                    disabled={this.state.ticket.id != ""}
                                                                    required></SelectField>
                                                    </div>  
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <SelectField attr="subSection"
                                                                    label={t(`${T_PREFIX}subSection`)}
                                                                    options={this.state.subSections}
                                                                    disabled={this.state.ticket.id != ""}
                                                                    required></SelectField>
                                                    </div>
                                                    {this.state.additionalFields.map((field, index) => (
                                                        <div className="col-md-8 mb-3 ms-3">
                                                            <TextField className="w-50" attr={"field_" + index}
                                                                    label={field}
                                                                    disabled={this.state.ticket.id != ""}></TextField>
                                                        </div>
                                                    ))}
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <TextField className="w-50" attr="userName"
                                                                label={t(`${T_PREFIX}name`)}
                                                                disabled={this.state.ticket.id != ""}></TextField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <TextField className="w-80" attr="eMail"
                                                                label={t(`${T_PREFIX}email`)}
                                                                disabled={this.state.ticket.id != ""}></TextField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <TextField className="w-80" attr="title"
                                                                label={t(`${T_PREFIX}title`)}
                                                                disabled={this.state.ticket.id != ""}></TextField>
                                                    </div>
                                                    <div className="col-md-8 mb-3 ms-3">
                                                        <TextField className="w-100 vh-100" attr="description"
                                                                label={t(`${T_PREFIX}description`)}
                                                                disabled={this.state.ticket.id != ""}
                                                                isTextArea={true}></TextField>
                                                    </div>
                                                    {this.state.ticket.id != "" ?
                                                        <div className="col-md-8 mb-3 ms-3">
                                                            <TextField className="w-80 vh-100" attr="trackId"
                                                                    label={t(`${T_PREFIX}trackId`)}
                                                                    disabled={true}></TextField>
                                                        </div> : <></>}
                                                    {this.state.ticket.id != "" ?
                                                        <div className="col-md-8 mb-3 ms-3">
                                                            <TextField className="w-80 vh-100" attr="resolutionText"
                                                                    label={t(`${T_PREFIX}resolutionText`)}
                                                                    isTextArea={true}
                                                                    disabled={this.state.ticket.state=="1"}
                                                            ></TextField>
                                                        </div> : <></>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        {this.state.trackId != "" &&
                                                            <h6 className="text text-succesfull ms-3">
                                                                {t(`${T_PREFIX}successMessage`) + this.state.trackId}
                                                            </h6>
                                                        }
                                                    </div>
                                                    <div className="col-md-4">
                                                        {this.state.ticket.needToClose?
                                                            <Button  type="button" className="btn-close w-80 h-20 float-right" onClick={() => this.reopenTicket()}>
                                                                <small>{t(`${T_PREFIX}reopen`)}</small>
                                                            </Button>: <></>
                                                        }
                                                    </div>
                                                    <div className="col-md-4">
                                                        {this.state.ticket.id==""?
                                                        <Button disabled={!this.isFormCompleted()} type="button" className="btn-close w-80 h-20 float-right" onClick={() => this.sendTicket()}>
                                                            <small>{t(`${T_PREFIX}send`)}</small>
                                                        </Button>:
                                                            this.state.ticket.state=="0"?<Button  type="button" className="btn-close w-80 h-20 float-right" onClick={() => this.closeTicket()}>
                                                                <small>{t(`${T_PREFIX}close`)}</small>
                                                            </Button>:<></>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        </Form>
                                    </Card>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            </Page>
        )
    }
}
export default withTranslation()(HelpIncident);