import React from 'react';
import { withTranslation } from 'react-i18next';
import "./ClientReport.css"
import {IClient} from "../../../models/loyalty/IClient";
import {Modal} from "react-bootstrap";
import {ClientService} from "../../../services/loyalty/ClientService";

interface IProps {
    activePage: number,
    mainPage: number,
    pages: number[],
    columns: Array<string>
    clients: IClient[],
    columnsToShow: string[]
}

interface IState {
    selectedClient:IClient|null
}

interface ClientRow {
    style: string;
    value:string;
    icon:string;
    image:string;
    column:string
}
const pageSize=13;
class ClientGrid extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state={
            selectedClient:null
        };
     }

    private processAction(event: any, val: ClientRow, client: IClient) {
        this.setState({...this.state,selectedClient:client})
    }



    render() {
        // @ts-ignore
        const { t } = this.props;
        return (

                <div className="table-responsive">
                    <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                        <thead className="table-primary">
                        <tr key={"tr-head"}>
                            {

                                this.props.columns.length > 0 ?
                                    this.props.columns.map(coll => {
                                        return (
                                            (this.props.columnsToShow.length==0||
                                                this.props.columnsToShow.filter(ex=>ex==coll.toUpperCase()).length>0)?
                                                <th key={`li-${coll}`}>{t(coll)}</th>:""
                                        )
                                    }) :
                                    <></>
                            }

                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.clients.slice(this.props.pages.indexOf(this.props.activePage) * pageSize, (this.props.pages.indexOf(this.props.activePage) * pageSize) + pageSize).map((client, Tindex) => {
                                const rows :ClientRow[]=[];
                                let rowStyle = "";
                                let currencySymbol="UYU"
                                let i=0;
                                for (let prop in client) {
                                    let value = (client as any)[prop];
                                    const val:ClientRow={style:"",value:"",icon:"",image:"",column:this.props.columns[i]};
                                    if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean'||this.props.columns[i]?.toUpperCase()=="CLIENTDOCUMENTS"){
                                       if(this.props.columns[i]?.toUpperCase()=="TOTALPURCHASEAMOUNT"||
                                           this.props.columns[i]?.toUpperCase()=="LASTPURCHASEAMOUNT"){
                                           // @ts-ignore
                                           val.value=Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(value);
                                       }
                                       else if(this.props.columns[i]?.toUpperCase()=="LOYALTYBALANCE"||
                                            this.props.columns[i]?.toUpperCase()=="LASTLOYALTYGENERATEDAMOUNT"||
                                            this.props.columns[i]?.toUpperCase()=="TOTALLOYALTYGENERATEDAMOUNT"||
                                            this.props.columns[i]?.toUpperCase()=="TOTALREDEEMPOINTS"||
                                            this.props.columns[i]?.toUpperCase()=="FIRTSACTIVEMOVEMENTAMOUNT"){
                                           // @ts-ignore
                                           val.value=Intl.NumberFormat('es-UY').format(value);
                                           //val.icon="fa-solid fa-magnifying-glass text-primary";
                                       }else if(this.props.columns[i]?.toUpperCase()=="NAME"){
                                           // @ts-ignore
                                           val.value=value.toString().toUpperCase();
                                       }else if(this.props.columns[i]?.toUpperCase()=="CREATEDATE"||
                                           this.props.columns[i]?.toUpperCase()=="LASTPURCHASE"||
                                           this.props.columns[i]?.toUpperCase()=="FIRSTPURCHASE"||
                                           this.props.columns[i]?.toUpperCase()=="loyaltyPointsNextVto".toUpperCase()){
                                           // @ts-ignore
                                           val.value=value.toString().split("T")[0]
                                       }else {
                                            if (value == '')
                                                val.value="-";
                                            else {
                                                val.value=value+"";
                                            }
                                        }
                                    }else
                                        val.value="-";
                                    i++;
                                    if(this.props.columnsToShow.length==0||this.props.columnsToShow.filter(ex=>ex==prop.toUpperCase()).length>0)
                                        rows.push(val);
                                }
                                return (
                                        <tr key={Tindex} className={`${rowStyle}` }>



                                        {rows.map((val:ClientRow, index) => {
                                            return <td key={index.toString() + Tindex.toString()} className={`${val.style}`}>
                                                <img src={val.image}></img>
                                                <span>{val.value}</span>
                                                <a href={window.location.href} onClick={(event:any) => this.processAction(event,val,client)}><i className={`${val.icon}`}></i></a>
                                            </td>
                                        })}

                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>

        )
    };


}
export default withTranslation() (ClientGrid);
