import { ReactElement } from "react";
import { TFunction, withTranslation } from "react-i18next";
import { useModel } from "../../contexts/modelContext";
import BaseField from "./baseField";
import { IPropsInputField } from "./fieldPropertiesInterface";
import modelHelper from "./modelHelper";

interface IPropsType {
  type: string;
  prefix?: ReactElement;
  sufix?: ReactElement;
  t: TFunction;
}

type IPropsInputFieldWithType = IPropsInputField & IPropsType;

function InputField({
  type,
  model,
  attr,
  value,
  label,
  isTextArea,
  onChange,
  disabled,
  required,
  autoFocus,
  prefix,
  sufix,
  t
}: IPropsInputFieldWithType) {
  const modelContext = useModel();
  const modelState = model || modelContext;
  const { onValueChange, getValue } = modelHelper({ model: modelState, attr, value, onChange }); // modelState: useModel()

  return (
    <BaseField attr={attr} label={label} required={required} value={getValue()} prefix={prefix} sufix={sufix} >
      {!isTextArea?
      <input
        id={`input-${attr}`}
        type={type}
        value={getValue()}
        onChange={onValueChange}
        title={t(label)}
        autoComplete="off"
        placeholder=" "
        autoFocus={autoFocus}
        disabled={disabled}
        />:
          <textarea
              id={`input-${attr}`}
              value={getValue()}
              onChange={onValueChange}
              title={t(label)}
              autoComplete="off"
              placeholder=" "
              autoFocus={autoFocus}
              disabled={disabled}
          />
      }
    </BaseField>
  );
}

export default withTranslation() (InputField);