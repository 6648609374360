import React from 'react';
import { TFunction, withTranslation } from "react-i18next";
import "./TerminalReport.scss";
import Page from '../containers/page';
import FormFilter from '../containers/form-filter';
import Card from '../containers/card';
import SelectField from '../controls/fields/selectField';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { DateHelper } from '../../helpers/DateHelper';
import { WebCache } from '../../services/WebCache';
import DateField from '../controls/fields/dateField';
import { DateObject } from 'react-multi-date-picker';
import i18next from "i18next";
import CustomTable from '../table/CustomTable';
import { ColumnDefinition, actionColumnDefinition, dateColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import { Option } from '../../models/Option';
import { IInvoiceDocuement, ISearchInvoicesResponse } from '../../models/IFiscalDocument';
import { IPageRequest } from '../../models/IPageRequest';
import { IPayerTaxId } from "../../models/ITerminal";
import { ISearchInvoicesRequest } from '../../requests/IFiscalDocumentInvoicesRequest';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { FiscalDocumentService } from '../../services/FiscalDocumentService';
import NavigationTabLinksForReports from "../payments/NavigationTabLinksForReports";
import Button from "../controls/buttons/button";
import NavigationTabLinksForFiscalDocuments from "./NavigationTabLinksForFiscalDocuments";
import TaxDocumentationTemplate from "./FiscalDocumentInvoicesTemplate";

interface IFilters {
}

interface IProps {
}

interface IState {

}
class QrPctInvoicesDocumentation extends React.Component<IProps, IState> {

    getAllDocumentTypes = () => {
        return i18next.t<Array<Option>>('pages.qrPctInvoices.documentTypes', { returnObjects: true }).map((documentType, documentTypeIndex) => {
            return { key: String(documentTypeIndex), ...documentType, disabled: false };
        })
    }

    render() {
        return (
            <TaxDocumentationTemplate maxDayRange={60} documentTypeOptions={this.getAllDocumentTypes()}
                                      showDocumentTypes={true}
                                      invoiceOrigin={3}
                                      prefix={'pages.qrPctInvoices.'}></TaxDocumentationTemplate>
        )
    };
}

export default withTranslation()(QrPctInvoicesDocumentation);
