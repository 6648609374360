

import React from "react";

class ErrorsView extends React.Component<IErrorsViewProps, IState>{

    constructor(props: IErrorsViewProps) {
        super(props);

        this.state = {
            errors : [...this.props.errors]
        }
    }

    componentWillReceiveProps(nextProps: IErrorsViewProps) {
        if(this.props!=null)
            this.setState({
                errors : [...this.props.errors]
            });
    }

    removeError = (index: number) =>{        
        this.setState({
            errors: this.state.errors?.filter((v, i) => i !== index)
        });        
    }

    render() {
        return (<>            
             {      
             
                this.state.errors.map((er, index) => 
                <div className="row" key={index}>
                <div>
                <div className="col-md-12 toast align-items-center text-white bg-danger border-0 show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                    <div className="d-flex">
                       <div className="toast-body">{er}</div>                       
                       <button aria-label="Close" className="btn-close fs-20 ms-auto mt-2 pe-2"><span aria-hidden="true" onClick={() => this.removeError(index)}>×</span></button>
                    </div>
                </div>
            </div></div>)
                    
            }
        </>)
    }
}

export default ErrorsView;

interface IState{
    errors: string[];
}

interface IErrorsViewProps{
    errors: string[];
}
