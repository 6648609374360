import React from 'react';
import { TFunction, withTranslation } from "react-i18next";
import "./TerminalReport.scss";
import Page from '../containers/page';
import FormFilter from '../containers/form-filter';
import Card from '../containers/card';
import SelectField from '../controls/fields/selectField';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { DateHelper } from '../../helpers/DateHelper';
import { WebCache } from '../../services/WebCache';
import DateField from '../controls/fields/dateField';
import { DateObject } from 'react-multi-date-picker';
import i18next from "i18next";
import CustomTable from '../table/CustomTable';
import { ColumnDefinition, actionColumnDefinition, dateColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import { Option } from '../../models/Option';
import { IInvoiceDocuement, ISearchInvoicesResponse } from '../../models/IFiscalDocument';
import { IPageRequest } from '../../models/IPageRequest';
import { IPayerTaxId } from "../../models/ITerminal";
import { ISearchInvoicesRequest } from '../../requests/IFiscalDocumentInvoicesRequest';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { FiscalDocumentService } from '../../services/FiscalDocumentService';
import NavigationTabLinksForReports from "../payments/NavigationTabLinksForReports";
import Button from "../controls/buttons/button";
import NavigationTabLinksForFiscalDocuments from "./NavigationTabLinksForFiscalDocuments";
import CustomReCAPTCHA from '../CustomCaptcha';
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";

interface IFilters {
    taxPayer: string,
    dateRange: Array<DateObject>,
    documentType: string   
}

interface IProps {
    t: TFunction
    prefix:string,
    showDocumentTypes:boolean
    invoiceOrigin:number,
    documentTypeOptions: Array<Option>,
    maxDayRange:number
}

interface IState {
    filters: IFilters,
    allFiscalDocuments: Array<Option>,
    fiscalDocuementInvoices: Array<IInvoiceDocuement>,
    mainPage: number,
    pageRequest: IPageRequest,
    isCaptchaVerified:boolean
}

class TaxDocumentationTemplate extends React.Component<IProps, IState> {

    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private columnDefinitions: Array<ColumnDefinition>;

    constructor(props: IProps) {
        super(props);

        this.handleFormFilterSumbit = this.handleFormFilterSumbit.bind(this);
        this.state = {
            filters: {
                taxPayer: WebCache.getAllMerchantDocuments(false)[0]?.key+"",
                dateRange: [],
                documentType:this.props.documentTypeOptions[0]?.value+""
            },
            allFiscalDocuments: WebCache.getAllMerchantDocuments(false),
            fiscalDocuementInvoices: [],
            mainPage: 0,
            isCaptchaVerified:false,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
        }

        let url= window.location.href.split("/"); 
        let invoiceNumber= url[url.length-1] === "fiscalDocumentInvoices" ?   textColumnDefinition({ key: 'invoiceNumber', label: `${this.props.prefix}invoiceNumber` }): textColumnDefinition({ key: '', label: '' }) ;      

        this.columnDefinitions = [
            dateColumnDefinition({ key: 'date', label: `${this.props.prefix}date` }),
            textColumnDefinition({ key: 'typeDescription', label: `${this.props.prefix}documentType` }),
            invoiceNumber,
            textColumnDefinition({ key: 'documentNumber', label: `${this.props.prefix}documentNumber`  }),
            actionColumnDefinition({ key: 'download',label:`${this.props.prefix}download`, action: { function: this.downloadFileAction, icon: <IconDownloadSVG></IconDownloadSVG>},fixedWidth:2})
        ];
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    buildFiscalDocuementInvoicesRequest = (all: boolean): ISearchInvoicesRequest => {
        console.log(this.state.filters.taxPayer)
        return {
            merchantDocument: this.state.filters.taxPayer,
            dateFrom: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            dateTo: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            documentType: this.state.filters.documentType,
            invoiceOrigin:this.props.invoiceOrigin,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take)
        };
    }

    fetchFiscalDocumentInvoices = () => {

        let dateFrom= DateHelper.dateObjectToDate(this.state.filters.dateRange[0]);
        let dateTo= DateHelper.dateObjectToDate(this.state.filters.dateRange[1]);
        let diffDays =
            Math.round
            ((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24));
        if(diffDays>this.props.maxDayRange){
            toast.error(this.props.t(`${this.props.prefix}dateRageError`)+" "+this.props.maxDayRange);
            return;
        }

        FiscalDocumentService.searchInvoices(this.buildFiscalDocuementInvoicesRequest(false)).then((response: AxiosResponse<ISearchInvoicesResponse>) => {
            if (!response || !response.data || response.data.documents.length === 0) {
                this.setState({ ...this.state, fiscalDocuementInvoices: [] });
                return;
            }
            this.setState({ ...this.state, fiscalDocuementInvoices: response.data.documents });
        }).catch(error => {
            this.setState({ ...this.state, fiscalDocuementInvoices: [] });
            console.error('error:', error);
            toast.error(error.message);
        });
    }

    handleFormFilterSumbit() {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.fetchFiscalDocumentInvoices();
        });
    }

    getFileExt= (type:string) => {
        if(type=="99"||type=="98")
            return ".txt";
        return ".pdf";
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    downloadFileAction: (rowObject: IInvoiceDocuement) => void = (rowObject: IInvoiceDocuement) => {
        if (this.state.isCaptchaVerified) {
        FiscalDocumentService.searchInvoiceDocument({
            sapDocumentNumber: rowObject.documentNumber.toString(),
            company: rowObject.company,
            type: rowObject.type,
            format:rowObject.formats[0]
        }, `${rowObject.documentNumber.toString()}`+this.getFileExt(rowObject.type))
        .catch((error) => {
            console.error('error:', error);
            toast.error(`${this.props.t(`${this.props.prefix}downloadErrorMessage`)}`);
        });
    } else {
        toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
    }
}

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = t("merchantDocument") ;
        } else {
            clientDocument = t("merchantDocumentArg");
        }
        return (
            <Page>
                <>
                    <h2>{t("pages.fiscalDocumentInvoices.title")}</h2>
                    <>
                        <FormFilter 
                            model={[ this.state.filters, this.setFilters.bind(this) ]} 
                            onSubmit={this.handleFormFilterSumbit} 
                            clear={()=> console.log("")}
                        >
                            <>
                                <div className="row filters" >
                                    <div className="col-md-4">
                                        <SelectField
                                            attr='taxPayer'
                                            label={clientDocument}
                                            options={this.state.allFiscalDocuments}
                                        ></SelectField>
                                    </div>
                                    <div className="col-md-4">
                                        <DateField
                                            attr="dateRange"
                                            label="dateRange"
                                            range
                                        ></DateField>
                                    </div>  
                                    <div className="col-md-4" hidden={!this.props.showDocumentTypes}>
                                        <SelectField
                                            attr='documentType'
                                            label={`${this.props.prefix}documentType`}
                                            options={this.props.documentTypeOptions}
                                        ></SelectField>
                                    </div>
                                </div>
                            </>
                        </FormFilter>
                        <div className='terminal-accordion-table'>
                        <CustomTable
                            rowObjects={this.state.fiscalDocuementInvoices}
                            columnDefinitions={this.columnDefinitions}
                            config={{
                                pageSize:1000,
                                paginator: {
                                    mainPage: this.state.mainPage,
                                    modifyMainPage: (value: number) => {
                                        this.setState({
                                            mainPage: this.state.mainPage + value
                                        }, () => this.fetchFiscalDocumentInvoices())
                                    },
                                    pageRequest: this.state.pageRequest
                                }
                            }}
                        ></CustomTable>
                        </div>
                        <div className='row'>
                            <div className='col-md-6' >
                            </div>
                            <div className="col-md-6">
                                <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                            </div>
                        </div>
                    </>
                </>
            </Page>
        )
    };
}

export default withTranslation()(TaxDocumentationTemplate);
