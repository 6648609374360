import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Tooltip from '../Tooltip';
import {ITab} from "../../models/ITab";
import { isDisabledOption } from 'react-bootstrap-typeahead/types/utils/getUpdatedActiveIndex';




interface IProps {
    items: Array<ITab>,
    t: TFunction
}

const NavigationTabLinks = ({ items, t }: IProps) => {
    
return (
    <div className="tab-menu-heading">
        <div className="tabs-menu">
            <ul className="nav panel-tabs ">
                {
                    items.map((tab: ITab) =>  
                        <Tooltip message={t(`${tab.tooltip || ''}`)} key={tab.to}>
                            <li key={tab.to} className={`${window.location.href.endsWith(tab.to) ? "active-tab" : ""}`}>
                                <Link to={tab.to} >                                    
                                    <span>
                                        <i className={`${tab.icon} me-1`}></i>
                                    </span>
                                    {t(`${tab.label}`)}
                                </Link>
                            </li>                    
                        </Tooltip>
                    )
                }
            </ul>
        </div>
    </div>
);
};

export default withTranslation() (NavigationTabLinks);
