import React, { useEffect } from 'react';
import { Accordion } from "react-bootstrap";
import { TFunction, withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconHelpSVG } from "../../../../node_modules/itd-common/assets/icons/Icono_ayuda.svg";
import { useLayout } from "../LayoutContext";
import "./HelpSidebar.scss";

interface IProps {
    t: TFunction
}

interface DynamicObject {
    [key: string]: any;
}

type DynamicObjectOrString = DynamicObject | string;
const CONTAINER_CLASS: string = "help-sidebar-body-container";

const HelpSidebar: React.FC<IProps> = ({ t }) => {
    const { isHelpOpen, toggleHelp } = useLayout();
    const location = useLocation();
    const routeToKey = (route: string) => {
        return route.replace("/", "");
    }
    const getCurrentPathname = (): string => {
        return routeToKey(location.pathname);
    };
    const [activeKey, setActiveKey] = React.useState(getCurrentPathname());

    useEffect(() => {
        setActiveKey(getCurrentPathname());
    },
    [location]);
    
    const renderParagraph = (content: string, className: string, key: string): JSX.Element => {
        return <p key={`p_${key}`} className={`${className}`} >{content}</p>
    }

    const renderHeader = (number: number, content: string, className: string, key: string): JSX.Element => {
        return React.createElement(`h${number}`, { key, className: `${CONTAINER_CLASS}__header ${className}` }, content)
    }
    
    const renderOrderedList = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        return <ol key={`ol_${key}`} className={`${className}`}  >{renderHelpItem(content)}</ol>
    }
        
    const renderUnorderedList = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        return <ul key={`ul_${key}`} className={`${CONTAINER_CLASS}__unordered-list ${className}`} >{renderHelpItem(content)}</ul>
    }

    const renderListItem = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        return <li key={`li_${key}`} className={`${className}`}  >{renderHelpItem(content)}</li>
    }

    const renderHelpItem = (dynamicObject: DynamicObjectOrString): JSX.Element | string | undefined => {
        if (typeof dynamicObject === 'object') {
            return renderHelpItemObject(dynamicObject);
        }
        else if (typeof dynamicObject === 'string') {
            return dynamicObject;
        }
    }

    const renderHelpItemObject = (dynamicObject: DynamicObject): JSX.Element => {
        const dynamicObjectKeys: Array<string> = Object.keys(dynamicObject);
        return (
            <>
                {
                    dynamicObjectKeys.map((dynamicObjectKey: string) => {
                        let helpTag: JSX.Element = <></>;
                        const spplitedDynamicObjectKey: Array<string> = dynamicObjectKey.split('_');
                        const htmlTag = spplitedDynamicObjectKey[0];
                        const key = `${spplitedDynamicObjectKey[1]}_${Math.floor(Math.random() * 10000).toString()}`;
                        const className: string = spplitedDynamicObjectKey[2] || '';
                        const dynamicObjectValue = dynamicObject[dynamicObjectKey];
 
                        switch(htmlTag) {
                            case 'p':
                                helpTag = renderParagraph(dynamicObjectValue, className, key);
                                break;
                            case 'h1':
                            case 'h2':
                            case 'h3':
                            case 'h4':
                            case 'h5':
                            case 'h6':
                                const headerNumber: number = Number.parseInt(htmlTag.replace('h', ''));
                                helpTag = renderHeader(headerNumber, dynamicObjectValue, className, key);
                                break;
                            case 'ol':
                                helpTag = renderOrderedList(dynamicObjectValue, className, key);
                                break;
                            case 'ul':
                                helpTag = renderUnorderedList(dynamicObjectValue, className, key);
                                break;
                            case 'li':
                                helpTag = renderListItem(dynamicObjectValue, className, key);
                                break;
                        }
                        return (helpTag);
                    })
                }
            </>
        )
    }

    const renderHelpItems = (): JSX.Element => {
        const children: DynamicObject = t("children", { returnObjects: true });

        return (
            <div className={`${CONTAINER_CLASS}`} >
                {
                    (children) ?
                        children.map((child: DynamicObject) => 
                            <Accordion.Item key={routeToKey(child.route)} eventKey={routeToKey(child.route)} onClick={() => ((routeToKey(child.route) === activeKey) ? setActiveKey('') : setActiveKey(routeToKey(child.route)))}>
                                <Accordion.Header>
                                    {child.title}
                                </Accordion.Header>
                                <Accordion.Body>
                                    {renderHelpItem(child.body)}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    :
                        <></>
                }
            </div>
        )
    }

    return (
        <div className='help-sidebar' >
            <div className={`sidebar sidebar-right sidebar-animate ${isHelpOpen() ? 'sidebar-open' : ''}`}>
                <div className="panel panel-primary card mb-0 shadow-none border-0">
                    <div className="tab-menu-heading border-0 d-flex p-3">
                        <div className="card-title mb-0"><IconHelpSVG className="icon-svg icon-svg--size" ></IconHelpSVG>{t("title")}</div>
                        <div className="card-options ms-auto">
                            <a href={window.location.href} className="sidebar-icon text-end float-end me-3 mb-1" data-bs-toggle="sidebar-right" data-target=".sidebar-right" onClick={() => {toggleHelp()}} ><i className="fe fe-x text-white"></i></a>
                        </div>
                    </div>
                    <div className="panel-body tabs-menu-body latest-tasks p-0 border-0">
                        {/* <Accordion activeKey={activeKey}>
                            {renderHelpItems()}
                        </Accordion> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withTranslation("translation-help")(HelpSidebar);