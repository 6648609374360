import React, { ChangeEvent } from 'react';

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import i18next from "i18next";
import { Bar } from 'react-chartjs-2';
import { IBarChartData } from "../../models/IBarChartData";
import { DashboardService, innerVertBarText } from "../../services/DashboardService";
import Card from '../containers/card';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



interface IProps {
    chartData: IBarChartData,
    name: string
}


class SalesChart extends React.Component<IProps> {

    state = {
        showDetail:false,
        chartData:null,
        dataType:"amount",
    };

    data = {
        labels:[],
        datasets: [
            {
                label: '',
                data: [],
                borderColor: "#f60",
                backgroundColor: "#f60"
            }
        ]
    };

    options = DashboardService.getBarChartOptions();

    componentWillReceiveProps(nextProps:IProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.chartData !== this.state.chartData) {
            this.setState({ chartData: nextProps.chartData });
            this.recalculateChart(nextProps, this.state.dataType, this.state.showDetail);
        }
    }

    recalculateChart(nextProps:IProps,dataType:string,showDetail:boolean){
        this.setState({ chartData: nextProps.chartData });
        const labels = nextProps.chartData.labels.map(label => i18next.t((label + "").toLowerCase()) + "") as never[];
        
        this.data = {
            labels:labels,
            datasets: [],
        };
        
        let index = -1;
        for(let i in nextProps.chartData.rows){
            index++;
            this.data.datasets.push( {
                label: i18next.t(nextProps.chartData.rows[i].label.toLowerCase()),
                data: dataType === "amount" ? nextProps.chartData.rows[i].values : nextProps.chartData.rows[i].count,
                borderColor: nextProps.chartData.rows[i].color,
                backgroundColor: index === 0 ? "#9B9B9B" : "#823078" // nextProps.chartData.rows[i].color
            });
        }

        this.setState({dataType:dataType,showDetail:showDetail})
    }

    render() {
        return (
            <div className="col-lg-12">
                <Card title={this.props.name} actions={
                    <RadioButtonGroup
                        name={this.props.name}
                        model={[ this.state, this.setState.bind(this) ]} 
                        attr="dataType" 
                        fieldDefinitions={[
                            { key: 'quantity', value: 'quantity', label: 'quantity' },
                            { key: 'amount', value: 'amount', label: 'amount' }
                        ]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.recalculateChart(this.props, event.target.value, this.state.showDetail)}
                    ></RadioButtonGroup>
                }>
                    <>
                        <Bar id="Bar" plugins={[innerVertBarText]} redraw={true} options={this.options} data={this.data} className="h-700"/>
                        <div className="d-flex justify-content-start align-items-center">
                            {this.data.datasets.map((datasetItem) => (
                                <div className="d-flex align-items-center me-2" key={`${datasetItem.label}`} >
                                    <span className="reference" style={{ backgroundColor: datasetItem.backgroundColor }}></span>
                                    <span>{datasetItem.label}</span>                                           
                                </div>
                            ))}
                        </div>
                    </>
                </Card>
            </div>
        )
    };
}
export default SalesChart;
