import React, { useCallback, useEffect, useState } from 'react';

interface IState {
    showMenuButton: boolean,
    toggleHelp: boolean
    menu: {
        collapse: boolean,
        toggle: boolean
    }
}

interface ILayoutContext {
    setToggleMenu: (value: boolean) => void,
    setCollapsedMenu: (value: boolean) => void,
    setBrokenMenu: (value: boolean) => void,
    toggleMenu: () => void;
    isMenuOpen: () => boolean;
    toggleHelp: () => void;
    isHelpOpen: () => boolean;
    isMenuCollaped: () => boolean;
    showMenuButton: boolean
}

const LayoutContext = React.createContext<ILayoutContext>({} as ILayoutContext);

export function LayoutProvider(props: any) {
    const [state, setState] = useState<IState>({
        showMenuButton: true,
        menu: {
            collapse: true, 
            toggle: false
        },
        toggleHelp: false
    });

    const [broken, setBroken] = React.useState(false);

    const setToggleMenu = useCallback((value: boolean): void => {
        setState({...state, menu: { ...state.menu, toggle: value } });
    }, [state]);

    const toggleMenu = useCallback((): void => {
        setToggleMenu(!state.menu.toggle);
    }, [state, setToggleMenu]);

    const isMenuOpen = useCallback((): boolean => {
        return state.menu.toggle;
    }, [state]);

    const toggleHelp = useCallback((): void => {
        setState({...state, toggleHelp: !state.toggleHelp});
    }, [state]);
    
    const isHelpOpen = useCallback((): boolean => {
        return state.toggleHelp;
    }, [state]);
    
    const setCollapsedMenu = useCallback((value: boolean): void => {
        setState({...state, menu: { ...state.menu, collapse: value } });
    }, [state]);

    const isMenuCollaped = useCallback((): boolean => {
        return state.menu.collapse;
    }, [state]);
        
    const setBrokenMenu = useCallback((value: boolean): void => {
        setBroken(value);
    }, []);

    useEffect(() => {
        if (broken) {
            setToggleMenu(state.menu.toggle);
        } else {
            setCollapsedMenu(!state.menu.toggle);
        }
    }, [state.menu.toggle]);

    useEffect(() => {
        setState({ ...state, showMenuButton: broken });
    }, [broken]);

    const value = React.useMemo(() => {
        return {
            setToggleMenu,
            setCollapsedMenu,
            setBrokenMenu,
            toggleMenu,
            toggleHelp,
            isMenuOpen,
            isHelpOpen,
            isMenuCollaped,
            showMenuButton: state.showMenuButton
        };
    }, 
    [
        state,
        setToggleMenu,
        setCollapsedMenu,
        setBrokenMenu,
        toggleMenu,
        toggleHelp,
        isMenuOpen,
        isHelpOpen,
        isMenuCollaped,
    ]);

    return <LayoutContext.Provider value={value} {...props} />;
}

export function useLayout() {
  const context = React.useContext<ILayoutContext>(LayoutContext);

  if (!context) {
    throw new Error('useLayout should be inside the provider LayoutContext');
  }

  return context;
}
