import React, {RefObject, useEffect, useRef, useState} from 'react';
//import "../assets/plugins/select2/select2.full.min.js"
import SalesChart from "../charts/SalesChart";
import IssuerChart from "../charts/IssuersChart";
import CardTypeChart from "../charts/CardTypeChart";
import OperationsChart from "../charts/OperationsChart";
import {IBarChartData, IPeriodIndicator} from "../../models/IBarChartData";
import { DashboardService } from "../../services/DashboardService";
import { UtilService } from "../../services/UtilService";
import {IPieChartData, IRejectionIndicator} from "../../models/IPieChartData";
import {useTranslation} from "react-i18next";
import PeriodIndicator from "../charts/PeriodIndicator";
import TransactionGrid from "../transactions/TransactionGrid";
import {TransactionService} from "../../services/TransactionService";
import {AxiosResponse} from "axios";
import {allTransactionStatesFilter} from "../../models/TransactionReportConstants";
import {ITransaction} from "../../requests/ITransactionWebModelRequest";
import {Line} from "react-chartjs-2";
import ReverseIndicator from "../charts/ReverseIndicator";
import RejectionIndicator from "../charts/RejectionIndicator";
import {DateObject} from "react-multi-date-picker";
import AmountIndicator from "../charts/AmountIndicator";
import CountIndicator from "../charts/CountIndicator";
import CouponPromIndicator from "../charts/CouponPromIndicator";
import AuthorizationRateIndicator from "../charts/ApprovalRateIndicator";
import ApprovalRateIndicator from "../charts/ApprovalRateIndicator";
import AmountByIssuerChart from "../charts/AmountByIssuerChart";
import CountByIssuerChart from "../charts/CountByIssuerChart";
import RejectionByIssuerChart from "../charts/RejectionByIssuerChart";
import ReturnIndicator from "../charts/ReturnIndicator";
import TaxIndicator from "../charts/TaxIndicator";
import NetAmountIndicator from "../charts/NetAmountIndicator";
import ReactToPrint from "react-to-print";
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import Page from '../containers/page'
import ContraChargeAmountChart from '../charts/ContraChargeAmountChart';
import './ConciliationDashboard.scss';
import { UserService } from '../../services/UserService';
import {WebCache} from "../../services/WebCache";
import PercentageChart from "../charts/PercentageChart";

const T_PREFIX: string = 'pages.concilationDashboard.';

interface IState {
    dateRange: string,
    currency: ICurrency,
    allTotal: IBarChartData,
    longPeriodIndicator: IPeriodIndicator,
    shortPeriodIndicator: IPeriodIndicator,
    nextPeriodIndicator: IPeriodIndicator,
    approvalRateIndicator: IPeriodIndicator,
    rejectionIndicators: IRejectionIndicator,
    reverseIndicators: IPieChartData,
    issuerTotals: IPieChartData,
    cardTypeTotals: IPieChartData,
    transactionTypeTotals: IBarChartData,
    errorMessage: string,
    initDahboard: boolean,
    reload: boolean,
    transactions: ITransaction[],
    columns: Array<string>
    errors: string[],
    columnsToShow: string[ ],
    activePage: number,
    mainPage: number,
    pages: number[],
    userCurrencies: ICurrencyOption[]
}
interface IProps { }

interface ICurrency {
    id: string;
    name: string;
}

interface ICurrencyOption {
    id: string;
    name: string;
}
let ConciliationDashboard: React.FC<IProps> = () => {

    const { t } = useTranslation();
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");

    let [state, setState] = useState<IState>({
        dateRange: 'today',
        currency: WebCache.getCurrencies()[0],
        userCurrencies: [],
        allTotal: {
            rows: [{
                label: "",
                color: "",
                values: [],
                count:[]
            }],
            labels: []
        },
        transactionTypeTotals: {
            rows: [{
                label: "",
                color: "",
                values: [],
                count:[]
            }],
            labels: []
        },
        issuerTotals: {
            labels: [],
            colors: [],
            values: [],
            counts:[]
        },
        cardTypeTotals: {
            labels: [],
            colors: [],
            values: [],
            counts:[]
        },
        longPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        shortPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        nextPeriodIndicator:DashboardService.createIPeriodIndicator([]),
        approvalRateIndicator:DashboardService.createIPeriodIndicator([]),
        errorMessage: "",
        initDahboard: true,
        reload: false,
        rejectionIndicators: {
            labels: [],
            colors: [],
            values: [],
            authValues:[],
            authCounts:[],
            totalCount:0,
            counts:[]
        },
        reverseIndicators: {
            labels: [],
            colors: [],
            values: [],
            counts:[]
        },
        transactions:[],
        errors: [],
        columns:[],
        columnsToShow:["TERMINAL","AUTHDATETIME","TRANSACTIONTYPE","STATE","RESPONSECODE","TOTALAMOUNTFORREPORT","ISSUER","ACQUIRER"],
        activePage: 1,
        mainPage: 0,
        pages: [1]
    });

    let refreshData = (): void => {
        state.reload = true;
        let startDate = UtilService.getYYYY_MM_DDFormat(new Date());
        let endDate = UtilService.getYYYY_MM_DDFormat(new Date());
        let accumulationCriteria = "Day";
        if (state.dateRange === "yesterday") {
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(1);
            endDate=startDate;
        } else if (state.dateRange === "7days") {
            accumulationCriteria = "Week"
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(6);
        } else if (state.dateRange === "30days") {
            accumulationCriteria = "Month"
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(30);
        } else if (state.dateRange === "1year") {
            accumulationCriteria = "Year"
            startDate = UtilService.getYYYY_MM_DDMinusDaysFormat(365);
        }
        DashboardService.getSettlementDashboard(startDate, endDate, state.currency.id, accumulationCriteria).then((response) => {
            let data = response.data;

            state.allTotal = data.allTotal;
            state.issuerTotals = data.totalByIssuer;
            state.cardTypeTotals = data.totalByCardType;
            state.transactionTypeTotals = data.totalByTransactionType;
            state.longPeriodIndicator=data.longPeriodIndicator;
            state.shortPeriodIndicator=data.shortPeriodIndicator;
            state.nextPeriodIndicator=data.nextPeriodIndicator;
            state.approvalRateIndicator=data.approvalRateIndicator;

            state.rejectionIndicators=data.rejectionIndicators;
            state.reverseIndicators=data.reverseIndicators;
            setState({
                ...state,
                allTotal: data.allTotal,
                reload: false
            })
        }).catch((error) => {
            setState({
                ...state,
                errorMessage: error.message,
                reload: true
            })
        });

    }

    const pageSize=13;
    let getTransactions = (): void => {

        let fromDate =new Date();
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        TransactionService.getAll({
            From: fromDate.toJSON(),
            To: new Date().toJSON(),
            Skip: 0,
            Take: pageSize
        }).then((response: AxiosResponse<ITransaction[]>) => {
            if(response==null||response.data==null||response.data.length==0){
                setState({...state,
                    transactions: [],
                    errors: []
                })
                return;
            }

            let newColumns = [];
            for (let prop in response.data[0]) {
                newColumns.push(prop)
            }
            setState({...state,
                columns: newColumns,
                transactions: response.data,
                errors: []
            });

        }).catch(error => {
                setState({...state,
                    errors: error.response.data
                });
                setState({...state,
                    transactions: []
                })
                console.log('error:', error);
            }
        );
    }





    useEffect(() => {
        setState(prevState => ({...prevState, initDahboard: false}));
        if (!state.initDahboard) {
            refreshData();
            getTransactions();
        }
    }, [state.initDahboard, state.currency]); 
    
    const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedCurrencyId = event.target.value;
        setSelectedCurrency(selectedCurrencyId);
    
        const selectedCurrencyOption = WebCache.getCurrencies().find(currency => currency.id === selectedCurrencyId);
    
        if (selectedCurrencyOption) {
            setState(prevState => ({
                ...prevState,
                currency: {
                    id: selectedCurrencyOption.id,
                    name: selectedCurrencyOption.name
                }
            }));
        }
    };


    let handleTimeRangeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        state.dateRange = event.target.value;
        refreshData();

    }

    const getCurrencySymbol = (): string => {
        return CurrencyHelper.getCurrencySymbol(state.currency.id);
    }

    const netAmountIndicatorTitle = (): string => {
        return (state.dateRange === "today") ? t("amountToChargeToday") : t("chargedAmount");
    }

    const componentRef:RefObject<any> = useRef();
    return (
        <Page>
            <div ref={componentRef}>
                <div className="row">
                    <div className="form-group col-md-4">
                        <h2>{t("dashboard")}</h2>
                    </div>
                    <div className="form-group menuDashboard">
                    <select
                            name="country"
                            className="form-control form-select form-select-lg select2 currencyStyle"
                            value={selectedCurrency}
                            onChange={handleCurrencyChange}
                        >
                            {WebCache.getCurrencies().map((currency) => (
                                <option key={currency.id} value={currency.id}>
                                    {currency.name}
                                </option>
                            ))}
                        </select>
                        <div className='divFilter'></div>
                        <select className="form-control form-select form-select-lg select2 itemDashboard" value={state.dateRange} onChange={handleTimeRangeChange}>
                            <option value="today">{t('today')}</option>
                            <option value="yesterday">{t('yesterday')}</option>
                            <option value="7days">7 {t('days')}</option>
                            <option value="30days">30 {t('days')}</option>
                            <option value="1year">1 {t('year')}</option>
                        </select>
                        <div className='divFilter'></div>
                        <div className='divExport'>
                            <ReactToPrint
                                trigger={() => <button name="country" className="form-control form-select form-select-lg select2  bg-primary text-white"  >
                                    {t("Export")}
                                </button>}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <NetAmountIndicator t={t} dateRange={state.dateRange} title={netAmountIndicatorTitle()}
                                        infoTooltip={t(`${T_PREFIX}chargedAmountInfoTooltip`)}
                                        chartData={state.shortPeriodIndicator}
                                        currencySymbol={getCurrencySymbol()}></NetAmountIndicator>
                    <NetAmountIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}amountToCharge`)}
                                        infoTooltip={t(`${T_PREFIX}amountToChargeInfoTooltip`)}
                                        chartData={state.nextPeriodIndicator}
                                        currencySymbol={getCurrencySymbol()}></NetAmountIndicator>
                    <ReturnIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}contracChargeAmount`)}
                                     infoTooltip={t(`${T_PREFIX}contracChargeAmountInfoTooltip`)}
                                     chartData={state.shortPeriodIndicator}
                                     currencySymbol={getCurrencySymbol()}></ReturnIndicator>
                    <TaxIndicator t={t} dateRange={state.dateRange} title={t(`${T_PREFIX}taxAmount`)}
                                  infoTooltip={t(`${T_PREFIX}taxAmountInfoTooltip`)}
                                  chartData={state.shortPeriodIndicator}
                                  currencySymbol={getCurrencySymbol()}></TaxIndicator>
                    <AmountByIssuerChart currencySymbol={getCurrencySymbol()} dateRange={state.dateRange}
                                         shortPeriodChartData={state.shortPeriodIndicator}
                                         chartData={state.issuerTotals}
                                         infoTooltip={t(`${T_PREFIX}amountByIssuerInfoTooltip`)}></AmountByIssuerChart>
                    {WebCache.getCurrentUser()?.countryId == 2 ?
                        <CountByIssuerChart currencySymbol={getCurrencySymbol()}
                                            approvalRateIndicator={state.approvalRateIndicator}
                                            dateRange={state.dateRange}
                                            shortPeriodChartData={state.shortPeriodIndicator}
                                            chartData={state.issuerTotals}
                                            infoTooltip={t(`${T_PREFIX}countByIssuerInfoTooltip`)}></CountByIssuerChart> : ""}
                    <div className="col-lg-12">
                        <PercentageChart labels={["withPromotion", "withoutPromotion"]}
                                         chartData={state.shortPeriodIndicator}
                                         currencySymbol={getCurrencySymbol()}></PercentageChart>
                    </div>
                    <ContraChargeAmountChart currencySymbol={getCurrencySymbol()} dateRange={state.dateRange}
                                             shortPeriodChartData={state.shortPeriodIndicator}
                                             chartData={state.shortPeriodIndicator}
                                             infoTooltip={t(`${T_PREFIX}contracChargeAmountInfoTooltip`)}></ContraChargeAmountChart>
                </div>
            </div>
        </Page>
    )
}
export default ConciliationDashboard;
