import React from 'react';

const ModelContext = React.createContext([]);

export function ModelProvider(props: any) {
  return <ModelContext.Provider value={props?.model || ['NO_MODEL_PROVIDED']} {...props} />;
}

export function useModel() {
  const context = React.useContext(ModelContext);

  if (!context) {
    throw new Error('useModel should be inside the provider ModelContext');
  }

  if (context.every && context.every((item) => { return item === 'NO_MODEL_PROVIDED'; })) {
    return undefined;
  }

  return context;
}
