import { ChangeEvent } from "react";
import { IPropsFieldValue, IPropsInputChange, IPropsSelectChange, ValueType } from "./fieldPropertiesInterface";

export default function modelHelper({ model, attr, value, onChange }: IPropsFieldValue & (IPropsInputChange | IPropsSelectChange)) {
  
  if (!model && !value) {
    throw new Error('You should provide the a model property to the Form component or to each field component.');
  }

  const [ state, setState ] = model || [ undefined, undefined ];

  const onValueChange = (event: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLSelectElement>& ChangeEvent<HTMLTextAreaElement>): void => {
    if (onChange) {
      onChange(event);
    } 
    
    if (state && attr) {
      setValue(event.target.value);
    }
  }

  const getValue = (): ValueType => {
    if (value) {
      return value;  
    }

    if (state && attr && state[attr]) {
      return state[attr];
    }
    
    return '';
  }

  const setValue = (newValue: ValueType): void => {
    if (value) {
      value = newValue;
      return;
    }

    if (state && attr) {
      const modelWithChange = {...state};
      modelWithChange[attr] = newValue;
      setState(modelWithChange);
    }
  }

  return { onValueChange, getValue, setValue };
}
