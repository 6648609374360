export interface IAssociateBranch {
    name: string;
    externalId: string;
    location: Loc
    nicknameMp: string | null;
}

export class Loc {
    streetNumber: string = "";
    cityName: string = "";
    stateName: string = "";
    latitude?: number = 0;
    longitude?: number = 0;
    reference: string = "";
    streetName:string = "";
}
