import './PercentageChart.scss';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TFunction, withTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Card from '../containers/card';
import ChartReference from './ChartReference';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import { IPeriodIndicator } from '../../models/IBarChartData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
    labels: [string, string],
    chartData: IPeriodIndicator,
    t: TFunction,
    currencySymbol:string
}

interface IDataProperties {
    label: string;
    data: Array<number>;
    backgroundColor: string;
    backgroundColorReference: string;
    currencySymbol:string
    GrossValue:number
}

interface IData {
    labels: Array<string>;
    datasets: Array<IDataProperties>
}


const PercentageChart: React.FC<IProps> = ({ t, labels, chartData,currencySymbol }) => {
    const data: IData = {
        labels: [''],
        datasets: [
            {
                label: t(labels[0]),
                data: [0],
                backgroundColor: '#2E8BC9',
                backgroundColorReference: 'light-blue',
                currencySymbol,
                GrossValue:0

            },
            {
                label: t(labels[1]),
                data: [100],
                backgroundColor: '#2B5681',
                backgroundColorReference: 'blue',
                currencySymbol,
                GrossValue:0
            },
        ]
    };

    const [state, setState] = useState({ dataType: 'amount', showDetail: true, data });

    const options = {
        indexAxis: 'y' as const,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false
            },
            datalabels: {
                display: false
            },
        },
        scales: {
            x: {
                ticks:{
                    callback: (value:any, index:any, values:any)=> {
                        try{
                            return value+"%"
                        }catch(e){}
                        return value;
                    }
                },
                stacked: true
            },
            y: {
                stacked: true
            }
        }
    };

    const recalculateChart = (dataType: string) => {

        if (!chartData) {
            return;
        }
        if (dataType === 'amount') {

            data.datasets[0].currencySymbol=currencySymbol;
            data.datasets[1].currencySymbol=currencySymbol;
            data.datasets[0].GrossValue=chartData.campainAmountTotals[0]*-1;
            data.datasets[1].GrossValue=chartData.vouchAmountTotals[0]+chartData.campainAmountTotals[0];
            data.datasets[1].currencySymbol=currencySymbol;
            data.datasets[0].data = [(chartData.campainAmountTotals[0]*-1/chartData.vouchAmountTotals[0])*100];
            data.datasets[1].data = [(chartData.vouchAmountTotals[0]+chartData.campainAmountTotals[0])*100/chartData.vouchAmountTotals[0]];
        }
        else if (dataType === 'quantity') {
            data.datasets[0].currencySymbol=""
            data.datasets[1].currencySymbol=""
            data.datasets[0].GrossValue=chartData.campainCountTotals[0];
            data.datasets[1].GrossValue=chartData.vouchCountTotals[0]-chartData.campainCountTotals[0];
            data.datasets[0].data = [chartData.campainCountTotals[0]*100/chartData.vouchCountTotals[0]];
            data.datasets[1].data = [(chartData.vouchCountTotals[0]-chartData.campainCountTotals[0])*100/chartData.vouchCountTotals[0]];

        }
        setState({ ...state, data });
    }

    useEffect(() => {
        recalculateChart(state.dataType);
    }, [chartData, state.dataType]);

    return (
        <Card title="salesByPromotions" actions={
          <RadioButtonGroup
              model={[ state, setState ]} 
              attr="dataType" 
              fieldDefinitions={[
                  { key: 'quantity', value: 'quantity', label: 'quantity' },
                  { key: 'amount', value: 'amount', label: 'amount' }
              ]}
          ></RadioButtonGroup>
        }>
            <>
                <div className="percentage-chart-container" >
                    <Bar options={options} data={state.data} />
                </div>
                {
                    !state.showDetail ||
                        <div className='row'>
                            <div className='col-md-5'>
                                <ChartReference datasets={state.data.datasets} ></ChartReference>
                            </div>
                        </div>
                }
                <div className="chart-detail-button" onClick={() => setState({ ...state, showDetail: !state.showDetail })}>
                    <a href={window.location.href}>
                        <i className={`fa-solid fa-chevron-${(!state.showDetail) ? 'down' : 'up'}`}></i>
                    </a>
                </div>
            </>
        </Card>
    );
}

export default withTranslation()(PercentageChart);