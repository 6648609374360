import React, { RefObject, useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import { TFunction, withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Swal from "sweetalert2";
import { ReactComponent as CloseSVG } from "../../../node_modules/itd-common/assets/icons/close.svg";
import { ReactComponent as LeftArrowSVG } from "../../../node_modules/itd-common/assets/icons/left-arrow.svg";
import { ReactComponent as RightArrowSVG } from "../../../node_modules/itd-common/assets/icons/right-arrow.svg";
import { ReactComponent as SearchIconSVG } from "../../../node_modules/itd-common/assets/icons/search-icon.svg";
import { HelpService, ISupportTicket } from "../../services/HelpService";
import { UtilService } from "../../services/UtilService";
import Card from '../containers/card';
import NavigationTabLinks from '../containers/navigationTabLinks';
import Page from '../containers/page';
import InputField from '../controls/fields/inputField';
import './HelpComponent.scss';
import { WebCache } from '../../services/WebCache';
import { ITab } from '../../models/ITab';


interface IState {
    searchState: string,
    filteredItems: DynamicObject[],
    isSearching: boolean,
    isAllQuestionsOpen: boolean,
    openedTickets:ISupportTicket[],
    lastTickets:ISupportTicket[]
}

interface IAccordionItem {
    title: string,
    body: string,
    category: string,
}

interface DynamicObject {
    [key: string]: any;
}

interface Section {
    id: string,
    title: string,
    route: string,
    body: string[]
}

type DynamicObjectOrString = DynamicObject | string;
const CONTAINER_CLASS: string = "help-sidebar-body-container";

interface IProps {
    t: TFunction
}
const T_PREFIX: string = 'pages.helpTicket.';

const HelpComponent: React.FC<IProps> = ({ t }) => {
    const location = useLocation();
    const currentUser = WebCache.getCurrentUser()?.countryId;

    const [state, setState] = useState<IState>({
        searchState: "",
        filteredItems: [],
        isSearching: false,
        isAllQuestionsOpen: false,
        openedTickets:[],
        lastTickets:[]
    });
    const [section, setSection] = useState<string>("session");
    const routeToKey = (route: string) => {
        return route.replace("/", "");
    };
    const getCurrentPathname = (): string => {
        return routeToKey(location.pathname);
    };
    const [activeKey, setActiveKey] = React.useState(getCurrentPathname());


    useEffect(() => {
        HelpService.getTicketStatus().then(function (res){
            setState({...state,openedTickets:res.data.openTickets,lastTickets:res.data.lastTickets} )
            if(res.data.openTickets.filter(x=>x.needToClose).length>0){
            Swal.fire({
                    title: `<i class="fas fa-exclamation-triangle text-warning"></i> ${t(`${T_PREFIX}needToCloseModal.htmlTitle`)}`,
                    html: t(`${T_PREFIX}needToCloseModal.html`),
                    confirmButtonColor: '#f60'
                }).then((result:any) => {
                window.location.href = '/#/help/incident?inc='+res.data.openTickets.filter(x=>x.needToClose)[0].id;
                });
            }
        })
    }, []);

    useEffect(() => {
        if (window.location.href.split("#")[window.location.href.split.length] === undefined) {
            window.location.replace(`${window.location.href}#${section}`);
        } else if (!window.location.href.endsWith(section)) {
            setSection(window.location.href.split("#")[window.location.href.split.length]);
        }
    }, [window.location.href]);

    const renderParagraph = (content: string, className: string, key: string): JSX.Element => {
        const parts = content.split('*');

        // Map each part to JSX elements
        const elements = parts.map((part, index) => {
            const isBold = index % 2 === 0;
            const element = isBold ? <strong key={`${key}_${index}`}>{part}</strong> : part;
    
            return element;
        });
    
        return <p key={`p_${key}`} className={`${className}`} >{elements}</p>;
    };

    const renderHeader = (number: number, content: string, className: string, key: string): JSX.Element => {
        return React.createElement(`h${number}`, { key, className: `${CONTAINER_CLASS}__header ${className}` }, content)
    }
    
    const renderOrderedList = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        return <ol key={`ol_${key}`} className={`${className}`}  >{renderHelpItem(content)}</ol>
    }

    const renderLink = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        return <a key={`a_${key}`} className={`${className}`} target="_blank"  href="https://liquidacionelectronica.fiservargentina.com/guiadelectura/" rel="noreferrer">{renderHelpItem(content)}</a>
    }
        
    const renderUnorderedList = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        return <ul key={`ul_${key}`} className={`${CONTAINER_CLASS}__unordered-list ${className}`} >{renderHelpItem(content)}</ul>
    }

    const renderListItem = (content: DynamicObjectOrString, className: string, key: string): JSX.Element => {
        if (typeof content === 'string') {
            // Split the content by asterisks to identify bold sections
            const parts = content.split('*');
    
            // Map each part to JSX elements
            const elements = parts.map((part, index) => {
                const isBold = index % 2 === 0;
    
                const element = isBold ? <strong key={`${key}_${index}`}>{part}</strong> : part;
    
                return element;
            });
    
            // Return the list item with parsed content
            return (
                <li key={`li_${key}`} className={`${className}`}>
                    {elements}
                </li>
            );
        } else {
            return (
                <li key={`li_${key}`} className={`${className}`}>
                    {renderHelpItem(content)}
                </li>
            );
        }
    };
    

    const renderHelpItem = (dynamicObject: DynamicObjectOrString): JSX.Element | string | undefined => {
        if (typeof dynamicObject === 'object') {
            return renderHelpItemObject(dynamicObject);
        }
        else if (typeof dynamicObject === 'string') {
            return dynamicObject;
        }
    }
    const searchItems = () => {
        const pages: DynamicObject = t("pages", { returnObjects: true });
        let values: DynamicObject[]= [];
    
        Object.values(pages).forEach((elements: Section[]) => {// obtengo todas las preguntas frecuentes
            if(Array.isArray(elements)){
                let results= elements.find((element: Section) => {// cada seccion de pregunta lo recorro y verifico si contiene la palabra
                    if(element.title.toLowerCase().includes(state.searchState.toLowerCase()) || isWordInBody(element.body))
                        return element
                });
                if(results)// los resultados se alamacenan, para mostrarlos
                    values= [...values, results];
            }
        });
        if(values.length > 0){
            setState({...state, filteredItems: values, isSearching: true});
        }else{
            Swal.fire(t("Error"), t("notResults"), "error");
        }
    }

    const isWordInBody = (values: string[]) => {
        let states: string[] = values; 
        let wordsArray: string[]= [];
        while (!Array.isArray(states)) { //verifico que sea un objecto y asi parsearlo
            let elements: string[]= Object.values(states);
            wordsArray= [...wordsArray, ...elements];
            states= elements;
        }
        let results= wordsArray.find(item => {// obtengo el objecto parseado a array
            if(typeof item === "string" && item.toLowerCase().includes(state.searchState.toLowerCase())){ //verifico si contiene la palabra, pueden venir objectos pero son las listas de ejemplos (no entra al if)
                return item;
            }
        })
        return results != undefined;
    }

    const renderHelpItems = (): JSX.Element => {
        const pages: DynamicObject = t("pages", { returnObjects: true });
        let values: DynamicObject[]= [];
        if(state.isSearching){
            values= state.filteredItems;
        }else{
            values= pages[section];
        }

        return (
            <div className="help-sidebar-body-container" >
                {
                    (values) ?
                    values.map((child: DynamicObject, index: any) => 
                        !state.isAllQuestionsOpen && index > 3 && !state.isSearching ?
                            <>
                            </>
                        :
                            <Accordion.Item className='mt-4' key={routeToKey(child.route)} eventKey={routeToKey(child.route)} onClick={() => ((routeToKey(child.route) === activeKey) ? setActiveKey('') : setActiveKey(routeToKey(child.route)))}>
                                <Accordion.Header>
                                    { Array.isArray(child.title) ?
                                        child.title[currentUser ? currentUser-1: 2]
                                        : child.title
                                    }
                                </Accordion.Header>
                                <Accordion.Body>
                                    {renderHelpItem(child.body)}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    :
                        <></>
                }
            </div>
        )
    }
    const renderHelpItemObject = (dynamicObject: DynamicObject): JSX.Element => {
        const dynamicObjectKeys: Array<string> = Object.keys(dynamicObject);
        return (
            <>
                {
                    dynamicObjectKeys.map((dynamicObjectKey: string) => {
                        let helpTag: JSX.Element = <></>;
                        const spplitedDynamicObjectKey: Array<string> = dynamicObjectKey.split('_');
                        const htmlTag = spplitedDynamicObjectKey[0];
                        const key = `${spplitedDynamicObjectKey[1]}_${Math.floor(Math.random() * 10000).toString()}`;
                        const className: string = spplitedDynamicObjectKey[2] || '';
                        let dynamicObjectValue = dynamicObject[dynamicObjectKey];
                        if(Array.isArray(dynamicObjectValue)){
                            dynamicObjectValue= dynamicObjectValue[currentUser ? (currentUser-1) : 1];
                        }
                        switch(htmlTag) {
                            case 'p':
                                helpTag = renderParagraph(dynamicObjectValue, className, key);
                                break;
                            case 'h1':
                            case 'h2':
                            case 'h3':
                            case 'h4':
                            case 'h5':
                            case 'h6':
                                const headerNumber: number = Number.parseInt(htmlTag.replace('h', ''));
                                helpTag = renderHeader(headerNumber, dynamicObjectValue, className, key);
                                break;
                            case 'ol':
                                helpTag = renderOrderedList(dynamicObjectValue, className, key);
                                break;
                            case 'a':
                                helpTag = renderLink(dynamicObjectValue, className, key);
                                break;
                            case 'ul':
                                helpTag = renderUnorderedList(dynamicObjectValue, className, key);
                                break;
                            case 'li':
                                helpTag = renderListItem(dynamicObjectValue, className, key);
                                break;
                        }
                        return (helpTag);
                    })
                }
            </>
        )
    }
                
    const filteredItems: ITab[] = [
    { to: '/help#session', label: 'Inicio de sesión'},
    { to: '/help#mobileAppVersion', label: 'Versión App móvil Merchant Center'},
    { to: '/help#dashbord', label: 'Dashboard'},
    { to: '/help#movements', label: 'Movimientos'},
    currentUser == 1 ? { to: '/help#charge', label: 'Cobrar'} : null,
    currentUser == 2 ? { to: '/help#chargeUy', label: 'Cobrar'} : null,
    { to: '/help#conciliation',label: 'Conciliación'},
    { to: '/help#liquidations',label: 'Liquidaciones'},
    { to: '/help#documents', label: 'Documentos'},
    { to: '/help#reports', label: 'Reportes de archivos'},
    { to: '/help#myCommerce', label: 'Mi Comercio'},
    { to: '/help#registerGuide', label: 'Guía de lectura del diseño de registro'},
    { to: '/help#help', label: 'Ayuda'}
    ].filter(tab => tab !== null) as ITab[]; 
    const componentRef:RefObject<any> = useRef();
    return (
            <Page>
                <>
                    <div className={`${state.isAllQuestionsOpen && "hidden"}`}>
                        <div className="col-md-4">
                            <h3 className='ms-4 margin-bottom'>{t(`title`)}</h3>
                        </div>
                    </div>
                        <div ref={componentRef} >
                            <div className='ms-4'>
                                <div className={`row ${!state.isAllQuestionsOpen || "hidden"}`}>
                                    <div className="col-md-11 margin-bottom">
                                        <h3 className='md-2'>{t(`subtitle`)}</h3>
                                        <InputField
                                            type="text"
                                            attr='searchState'
                                            label={t(`searchInput`)}
                                            model={[state, setState]}
                                            sufix={
                                                <a href={window.location.href} className="searchIcon h-100 aling-items-end d-flex"
                                                    onClick={()=> searchItems()}                                                  
                                                >
                                                    <SearchIconSVG></SearchIconSVG>
                                                </a>
                                            }
                                        ></InputField>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 pe-0">
                                        <div className='aling-items-center d-inline'>
                                            <h3 className='d-block'>{t(`frequentQuestions`)}</h3>
                                            { state.isAllQuestionsOpen || state.isSearching ?
                                                <a type='button' className="pe-3 mt-2 leftArrow" onClick={() => setState({...state, isAllQuestionsOpen: false, isSearching: false, searchState: ""})}>
                                                    <div className='d-flex d-inline'>
                                                        <LeftArrowSVG className="me-2 fs-5 pe-1"></LeftArrowSVG><h4>{t(`${T_PREFIX}return`)}</h4>
                                                    </div>
                                                </a>
                                                : ""
                                            }   
                                        </div>
                                    </div>
                                    { !state.isAllQuestionsOpen && !state.isSearching ?
                                        <div className="col-md-3 fw-bolder ps-0">
                                            <a type='button' className="d-flex justify-content-end pt-1">
                                                <p onClick={() => setState({...state, isAllQuestionsOpen: true})}>
                                                    {t(`seeAll`)}
                                                    <span className='ms-3'>
                                                        <RightArrowSVG></RightArrowSVG>
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                        : ""
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-11">
                                    <NavigationTabLinks items={filteredItems}></NavigationTabLinks>
                                        <div className="panel-body tabs-menu-body latest-tasks p-0 border-0 mb-5">
                                            <Accordion activeKey={activeKey}>
                                                {renderHelpItems()}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                                <section className={`${!state.isAllQuestionsOpen || "hidden"}`}>
                                    {/* <div className="row">
                                        <div className="col-md-11 mt-5">
                                            <h3 className='mb-1'>Otros contenidos</h3>
                                            <p>Descubrí otros materiales que pueden ayudarte a utilizar nuestra plataforma.</p>
                                            <div className="row"> 
                                                <div className="col-md-4 mt-3">
                                                    <Card title="Vídeos y lives">
                                                        <p>Descubrí vídeos tutoriales y mantenete atento a nuestros lives.</p>
                                                    </ Card>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <Card title="Blog">
                                                        <p>Descubrí contenidos interesantes en nuestra selección de blogs.</p>
                                                    </ Card>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <Card title="Circulares y descargas">
                                                        <p>Descargue materiales importantes para la gestión de su comercio.</p>
                                                    </ Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-11 mt-5 mb-5">
                                            <h3 className='d-inline'>{t(`${T_PREFIX}titleHelp`)}</h3>
                                            <div className="row needHelp"> 
                                                <div className="col-md-6 mt-3">
                                                        <Card title="Abrir ticket">
                                                            <Link to={'/help/incident'} className='incidentLink'>
                                                                <p>{t(`${T_PREFIX}openTicket`)}</p>
                                                            </Link>
                                                        </ Card>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <Card title="Historial de tickets">
                                                        <div className="row">
                                                            {
                                                                state.lastTickets.map(ticket=>{
                                                                    return (<Link to={'/help/incident?inc='+ticket.id}>
                                                                        <p>{UtilService.getDDMMyyFormatFromString(ticket.openDate)} -{ticket.title} - {ticket.state=="0"?"Abierto":"Cerrado"}</p>
                                                                    </Link>)
                                                                })
                                                            }
                                                                <p>{t(`${T_PREFIX}checkTicket`)}</p>
                                                        </div>
                                                    </ Card>
                                                </div>
                                                {/* <div className="col-md-12 mt-2 contact-section">
                                                    <Card>
                                                        <div className='row'>
                                                            <div className='col-md-1 vertical-aling'>
                                                                <WhatsappIconSVG className='justify-content-end'></WhatsappIconSVG><p></p>
                                                            </div>
                                                            <div className="col-md-4 pt-5"> 
                                                                <h4>Atención por  WhatsApp</h4>
                                                                <p>Obtén respuesta a tus dudas con nuestro servicio de Whatsapp en cualquier momento</p>
                                                            </div>
                                                            <div className='col-md-1 vertical-divider mt-4'></div>
                                                            <div className='col-md-2 vertical-aling justify-content-end'>
                                                                <PhoneIconSVG></PhoneIconSVG>
                                                            </div>
                                                            <div className="col-md-4 pt-5 float-right">
                                                                <h4>Canales de atención</h4>
                                                                <p>Atención de lunes a sábado de 8 a 20hs</p>
                                                            </div>
                                                        </div>
                                                    </ Card>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-11 mt-5">
                                            <h3 className='d-inline'>Developer experience</h3>
                                            <p className='mt-1 mb-1'>Te ofrece materiales y funciones para desarrolladores..</p>
                                            <div className="row"> 
                                                <div className="col-md-6 mt-3">
                                                    <Card title="Abrir incidente">
                                                        <p>Contactános informando algún incidente a traves de formulario.</p>
                                                    </ Card>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <Card title="Historial de incidentes">
                                                        <p>Consulta también tu historial de incidentes abierto.</p>
                                                    </ Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </section>
                            </div>
                        </div>
                </>
            </Page>
    )
}
export default withTranslation("translation-help")(HelpComponent);