import { DateObject } from 'react-multi-date-picker';

export class DateHelper {

    static buildDateRange(): [ DateObject, DateObject ] {
        const fromDate = new DateObject();
        fromDate.hour = 0;
        fromDate.minute = 0;
        fromDate.second = 0;
        
        const toDate = new DateObject();
        toDate.hour = 23;
        toDate.minute = 59;
        toDate.second = 59;

        return [ fromDate, toDate ];
    }

    static dateObjectToString = (dateObject: DateObject): string => {
        return this.dateObjectToDate(dateObject).toJSON();
    }

    static dateObjectToDate = (dateObject: DateObject): Date => {
        if (!dateObject) {
            return new Date();
        }

        let year: number = dateObject.year;
        let month: number = dateObject.month.number;
        let day: number = dateObject.day;

        return new Date(year, month - 1, day);
    }

}