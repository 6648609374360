import { AxiosResponse } from "axios";

export class DownloadFileHelper {
    
    static downloadFromPromise(promise: Promise<AxiosResponse<any>>, fileName?: string, blobOptions?: BlobPropertyBag): Promise<any> {
        return promise.then((response: AxiosResponse<any>) => {
            let outputFilename: string | undefined = fileName;
            if (!fileName) {
                // @ts-ignore
                outputFilename = response.headers['content-disposition']
                    .split(';')
                    .find((n: any) => n.includes('filename='))
                    .replace('filename=', '')
                    .trim();
            }

            if (!outputFilename) {
                return;
            }

            const url = URL.createObjectURL(new Blob([response.data], blobOptions));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            link.click();
        });
    }
}
