import React, { ReactElement } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { ToastContainer } from "react-toastify";
import "./page.scss";

interface IProps {
    children: ReactElement;
    t: TFunction
}

interface IState {
}

class Page extends React.Component<IProps, IState> {

    render() {
        const { t } = this.props;
        return (
            <div className="page-container" >
                <div className="main-container container-fluid">
                    <ToastContainer autoClose={3000} ></ToastContainer>
                    <div className="page-header"></div>
                    {this.props.children}
                </div>
            </div>
        )
    };

}

export default withTranslation()(Page);
