import {axiosApiInstance} from "./HttpService";
import {AxiosResponse} from "axios";

interface IGenerateTicketRequest{
    EMail: string,
    Description: string,
    UserName: string,
    Title: string,
    Section: string,
    SubSection: string,
    FiscalDocument: string,
    MerchantDocument: string,
    Terminal: string,
    FiscalNumberWholesaler: string
}

interface IUpdateTicketRequest{

    Id: string,
    Description: string,
    Reopen: boolean
}

export interface ISupportTicket{

    id: string,
    title: string,
    description: string,
    userName: string,
    trackId: string,
    section:string
    subSection:string,
    eMail: string,
    needToClose: boolean,
    reOpenDate: string,
    openDate: string,
    closeDate: string,
    state: string,
    resolutionText:string,
    fiscalDocument: string;
    merchantDocument: string;
    terminal: string;
    fiscalNumberWholesaler: string;
}

export class HelpService{

    public static postGenerateTicket(request: IGenerateTicketRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/SupportTicket/createSupportTicket`;
        return axiosApiInstance.post(dataURL, request);
    }

    public static getTicket(ticketId:string):Promise<AxiosResponse<ISupportTicket>>{
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/SupportTicket/getTicket`;
        return axiosApiInstance.post(dataURL,{id:ticketId});
    }

    public static getTicketStatus():Promise<AxiosResponse<{lastTickets:ISupportTicket[],openTickets:ISupportTicket[]}>>{
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/SupportTicket/getTicketStatus`;
        return axiosApiInstance.post(dataURL);
    }


    public static updateTicket(request: IUpdateTicketRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/SupportTicket/updateTicket`;
        return axiosApiInstance.post(dataURL, request);
    }
}