import React from 'react';
import { GoogleMap,   MarkerF } from '@react-google-maps/api';
import { withTranslation} from "react-i18next";
import {IBranche, ICloseBatchStatusRow, ITerminal, ITerminalStatusResponse} from "../../models/ITerminal";
import {WebCache} from "../../services/WebCache";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import {TransactionService} from "../../services/TransactionService";
import {AxiosResponse} from "axios";
import {Modal} from "react-bootstrap";
import "./TerminalReport.scss"
import { ReactComponent as ReportsIconSVG } from "../../../node_modules/itd-common/assets/icons/reports.svg";
import { ReactComponent as BranchIconSVG } from "../../../node_modules/itd-common/assets/icons/settings-dark.svg";
import { ReactComponent as SettingsDarkIconSVG } from "../../../node_modules/itd-common/assets/icons/branch-icon.svg";

import {UtilService} from "../../services/UtilService";
import { Link } from 'react-router-dom';
import Page from '../containers/page';
interface IProps {

}
interface IState {
    center: {
        lat: number,
        lng: number
    },
    branches: IBranchMapPointer[],
    terminalStatusResponseToShow:ITerminalStatusResponse|null,
    batchStatusToShow: ICloseBatchStatusRow[]|null,
    showWarning:boolean
}

interface IBranchMapPointer{
    terminals:ITerminal[],
    branch:IBranche
    terminalStatusResponseToShow:ITerminalStatusResponse|null,
}

class TerminalMap extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    this.state = {
            center: WebCache.getCurrentUser()?.countryId==2?{lat: -34.9055016, lng: -56.1851147}:{lat:  -34.6284564, lng: -58.5776372},
            branches: [],
            terminalStatusResponseToShow:null,
            batchStatusToShow:null,
            showWarning:false
        }
    }

    showBranchData=(branch:IBranchMapPointer)=>{
        if(WebCache.getCurrentUser()?.countryId==2)
            this.setState({terminalStatusResponseToShow:branch.terminalStatusResponseToShow})
    }

    showBatchStatus=(row: ICloseBatchStatusRow[]) =>{
        this.setState({terminalStatusResponseToShow:null,batchStatusToShow:row})
    }
    formatDate=(date: string) =>{
        if(date==null)
            return "";
        if(date.indexOf(".")>0)
            date=date.split(".")[0];
        if(date.indexOf("Z")>0)
            date=date.split("Z")[0];
        if(date.indexOf("T")>0)
            date=date.split("T").join(" ");
        return date;
    }




    componentDidMount() {
        let terminals=WebCache.getAllTerminals()

        this.setState({branches:[],showWarning:WebCache.showBranchWarnings()});
        for (const branch of WebCache.getAllBranches()) {
            if (branch.position != null && branch.position.lng != 0)
                TransactionService.getTransactionStatusByTerminal(terminals.filter(terminal => terminal.branchName != null && terminal.branchName == branch.name).map(terminal => terminal.terminalNumber)).then((response: AxiosResponse<ITerminalStatusResponse>) => {
                    let branchPointers: IBranchMapPointer[] = this.state.branches;
                    branchPointers.push({
                        terminalStatusResponseToShow: response.data,
                        branch: branch,
                        terminals: terminals.filter(terminal => terminal.branchName != null && terminal.branchName == branch.name)
                    });
                    this.setState({branches: branchPointers});
                })
        }
    }


    render() {
        // @ts-ignore
        const { t } = this.props;
        return (
            <Page>
                <React.Fragment>
                    <div>
                        <h3 className="title">{t("location")}</h3>
                        <div className="row">
                            <div className="text-wrap mb-4 row" hidden={!this.state.showWarning}>
                                <div >
                                    <div className="alert alert-danger">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-hidden="true">×
                                        </button>
                                        <span className=""><svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"
                                                                viewBox="0 0 24 24"><path fill="#fad383"
                                                                                        d="M15.728,22H8.272a1.00014,1.00014,0,0,1-.707-.293l-5.272-5.272A1.00014,1.00014,0,0,1,2,15.728V8.272a1.00014,1.00014,0,0,1,.293-.707l5.272-5.272A1.00014,1.00014,0,0,1,8.272,2H15.728a1.00014,1.00014,0,0,1,.707.293l5.272,5.272A1.00014,1.00014,0,0,1,22,8.272V15.728a1.00014,1.00014,0,0,1-.293.707l-5.272,5.272A1.00014,1.00014,0,0,1,15.728,22Z"/><circle
                                            cx="12" cy="16" r="1" fill="#f7b731"/><path fill="#f7b731"
                                                                                        d="M12,13a1,1,0,0,1-1-1V8a1,1,0,0,1,2,0v4A1,1,0,0,1,12,13Z"/></svg></span>
                                        <strong>{t("needToFillBranchMapAddress")}</strong>
                                        {/*
                                        <hr className="message-inner-separator">
                                            <p>Best check yo self, you're not looking too good</p>
                                        </hr>
                                    */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <p></p>
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '600px',

                                    }}
                                    center={this.state.center}
                                    zoom={8}>
                                    {

                                        this.state.branches.length > 0 ?
                                            this.state.branches.map(branch => {
                                                return (
                                                    (branch.branch.position!=null &&branch.branch.position.lng!=0) ?
                                                        <MarkerF
                                                            options={
                                                                {
                                                                    label: {
                                                                        text: branch.terminals.length+"",
                                                                        fontFamily: "Material Icons",
                                                                        color: "#ffffff",
                                                                        fontSize: "18px",
                                                                    },
                                                                    icon:{
                                                                        labelOrigin: new google.maps.Point(faLocationPin.icon[0] / 2,  faLocationPin.icon[1]/2),
                                                                        path: faLocationPin.icon[4] as string,
                                                                        fillColor: branch.terminalStatusResponseToShow!=null&&branch.terminalStatusResponseToShow?.rows!=null&&branch.terminalStatusResponseToShow?.rows.filter(row=>!row.batchsOk).length>0?"#FFA500":"#009000",
                                                                        fillOpacity: 1,
                                                                        anchor: new google.maps.Point(
                                                                            faLocationPin.icon[0] / 2, // width
                                                                            faLocationPin.icon[1] // height
                                                                        ),
                                                                        strokeWeight: 1,
                                                                        strokeColor: "#ffffff",
                                                                        scale: 0.075,

                                                                    },
                                                                    clickable:true


                                                                }
                                                            }
                                                            onClick={(event)=>{this.showBranchData(branch)}}
                                                            position={branch.branch.position}
                                                        />:""
                                                )
                                            }) :""
                                    }
                                </GoogleMap>
                            </div>
                        </div>
                        <div className='row mt-5 mb-5 col-md-12'>
                            <div className="col-md-5 div-infoTerminals " typeof='button'>
                                <Link to={"/terminals/reports"}>
                                    <div className='p-0 h-100 w-100'>
                                        <ReportsIconSVG className='h-50 ps-2'></ReportsIconSVG>
                                        <h6 className='mt-3 ps-2 text-dark'>{t("reports")}</h6>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-2'></div>
                            <div className="col-md-5 div-infoTerminals" typeof='button'>
                                <Link to={"/terminals/branches"}>
                                    <div className='p-0 h-100 w-100'>
                                        <BranchIconSVG className='h-50 ps-2'></BranchIconSVG>
                                        <h6 className='mt-3 ps-2 pt-1 text-dark'>{t("branches")}</h6>
                                    </div>
                                </Link>
                            </div>
                            {/* <div className="col-md-3 div-info div-info-3 ms-md-4" typeof='button'>
                                <Link to={"/terminals/reports"}>
                                    <div className='p-0 h-100 w-100'>
                                        <SettingsDarkIconSVG className='h-50 ps-2'></SettingsDarkIconSVG>
                                        <h6 className='mt-3 ps-2 text-dark'>{t("settings")}</h6>
                                    </div>
                                </Link>
                            </div> */}
                        </div>
                        </div>
                    <Modal show={this.state.terminalStatusResponseToShow!=null} size="xl">
                        <div className="card border p-0 pb-3">
                            <div className="card-header border-0 pt-3">
                                <div className="card-options">
                                    <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({terminalStatusResponseToShow:null})} ><i className="fe fe-x"></i></a>
                                </div>
                            </div>
                            <div className="card-body text-center">
                                <div className="table-responsive">
                                    <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                                        <thead className="table-primary">
                                        <tr key={"tr-head"}>
                                            <th key={`li-terminal`}>{t("terminal")}</th>
                                            <th key={`li-terminal`}>{t("lastAuthorizedTransactionDate")}</th>
                                            <th key={`li-terminal`}>{t("lastTransactionDate")}</th>
                                            <th key={`li-terminal`}>{t("lastTransactionResponseCode")}</th>
                                            <th key={`li-terminal`}>{t("authorizedTransactionCount")}</th>
                                            <th key={`li-terminal`}>{t("transactionCount")}</th>
                                            <th key={`li-terminal`}>{t("batchs")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.terminalStatusResponseToShow?.rows.map((row, Tindex) => {
                                                return (
                                                    <tr key={Tindex} className={row.batchsOk?"":"terminal-without-close"}>
                                                    <td> {row.terminalId}</td>
                                                        <td> {this.formatDate(row.lastAuthorizedTransactionDate)}</td>
                                                        <td> {this.formatDate(row.lastTransactionDate)}</td>
                                                        <td> {t("responseCode-"+row.lastTransactionResponseCode)}</td>
                                                        <td> {row.authorizedTransactionCount}</td>
                                                        <td> {row.transactionCount}</td>
                                                        <td> <a href={window.location.href} onClick={(event: any) => this.showBatchStatus( row.rows)}><i className={""+(row.batchsOk?"fa fa-check-circle success-icon":"fe fe-alert-triangle text-primary")}></i></a></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer text-center border-0 pt-0">
                                <div className="row">
                                    <div className="text-center">
                                        <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({terminalStatusResponseToShow:null})} >{t('close')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal show={this.state.batchStatusToShow!=null} size="xl">
                        <div className="card border p-0 pb-3">
                            <div className="card-header border-0 pt-3">
                                <div className="card-options">
                                    <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({batchStatusToShow:null})} ><i className="fe fe-x"></i></a>
                                </div>
                            </div>
                            <div className="card-body text-center">
                                <div className="table-responsive">
                                    <table id="datetime-table" className="table table-bordered text-nowrap mb-0" >
                                        <thead className="table-primary">
                                        <tr key={"tr-head"}>
                                            <th key={`li-terminal`}>{t("terminal")}</th>
                                            <th key={`li-terminal`}>{t("acquirer")}</th>
                                            <th key={`li-terminal`}>{t("lastAuthorizedCloseTransactionDate")}</th>
                                            <th key={`li-terminal`}>{t("lastCloseTransactionDate")}</th>
                                            <th key={`li-terminal`}>{t("lastCloseBatchResponseCode")}</th>
                                            <th key={`li-terminal`}>{t("lastAuthorizedTransactionDate")}</th>
                                            <th key={`li-terminal`}>{t("lastTransactionDate")}</th>
                                            <th key={`li-terminal`}>{t("lastTransactionResponseCode")}</th>
                                            <th key={`li-terminal`}>{t("authorizedTransactionCount")}</th>
                                            <th key={`li-terminal`}>{t("transactionCount")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.batchStatusToShow?.map((row, Tindex) => {
                                                return (
                                                    <tr key={Tindex} className={row.lastCloseBatchResponseCode!=null?"":"terminal-without-close"}>
                                                        <td> {row.terminal}</td>
                                                        <td> <img src={UtilService.getImageTag((t("acquirer-"+row.acquirer)+"").toUpperCase())}></img></td>
                                                        <td> {this.formatDate(row.lastAuthorizedCloseTransactionDate)}</td>
                                                        <td> {this.formatDate(row.lastCloseTransactionDate)}</td>
                                                        <td> {row.lastCloseBatchResponseCode}</td>
                                                        <td> {this.formatDate(row.lastAuthorizedTransactionDate)}</td>
                                                        <td> {this.formatDate(row.lastTransactionDate)}</td>
                                                        <td> {row.lastTransactionResponseCode.length==0?"":t("responseCode-"+row.lastTransactionResponseCode)}</td>
                                                        <td> {row.authorizedTransactionCount}</td>
                                                        <td> {row.transactionCount}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer text-center border-0 pt-0">
                                <div className="row">
                                    <div className="text-center">
                                        <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({batchStatusToShow:null})} >{t('close')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </React.Fragment>
            </Page>
        )
    };



}
export default withTranslation()(TerminalMap);
