import { useTranslation } from "react-i18next";
import "./button.scss";
import { IButtonEitherLabelOrChildrenProps, IButtonProps } from "./buttonPropertiesInterface";

interface IProps extends IButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
}

export default function Button({ type, disabled, onClick, onKeyDown, className, color, noBlock, noMargin, reference, ...props }: IProps & IButtonEitherLabelOrChildrenProps) {
  const { t } = useTranslation();

  const buildContent = () => {
    if ('label' in props && props.label.length > 0) {
      return t(props.label);
    }

    return (('children' in props) ? props.children : <></>);
  };

  const getClasses = (): string => {
    let classes: string = `button btn `;
    
    classes += `${(color) ? `btn-${color}` : 'btn-primary'} `;
    classes += `${(disabled) ? 'disabled' : ''} `;
    classes += `${(noBlock) ? 'ps-4 pe-4' : 'btn-block' } `;
    classes += `${(noMargin) ? 'mt-0' : '' } `;
    classes += `${className || ''}`;
    
    return classes;
  };

  return (
    <button
      className={getClasses()}
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      ref={reference}
    >
      {buildContent()}
    </button>
  );
}
