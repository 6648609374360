import React, {useState, useEffect} from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import {ShortcutsService} from "../services/shortcuts/ShortcutsService";
import "./Dictaphone.css"
import {Modal} from "react-bootstrap";
import {IShortcutResult} from "../models/IShortcut";
import {useTranslation} from "react-i18next";
import { ReactComponent as IconMicrophoneSVG } from "../../node_modules/itd-common/assets/icons/microphonepodcast_grey.svg";

interface IProps { }

interface IState {

    showShortCutsModal:boolean,
    shortcuts:IShortcutResult[],
    text:string
}



let Dictaphone: React.FC<IProps> = () => {


    const { t } = useTranslation();

    let [state, setState] = useState<IState>({
        shortcuts:[],
        showShortCutsModal:false,
        text:""
    });
    let fooRef:any;
    useEffect(() => {
        fooRef.addEventListener('touchstart', startListening, { passive: false });
    }, []);


    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        finalTranscript,

    } = useSpeechRecognition();

    useEffect(() => {
        if (finalTranscript !== '') {

            var text=finalTranscript.toLowerCase()
                .replaceAll("pfizer","Fiserv")
                .replaceAll("realizadas","reversadas")
                .replaceAll("revisadas","reversadas")
                .replaceAll("á","a")
                .replaceAll("é","e")
                .replaceAll("í","i")
                .replaceAll("ó","o")
                .replaceAll("ú","u")
            ;
            setState({...state,text:text,showShortCutsModal:true,shortcuts:ShortcutsService.processShortcutText(text)});
        }
    }, [ finalTranscript]);

    let disableEvent=(e:any): void => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        e.cancelBubble = true;
        e.returnValue = false;

    }
    let processText = (e:any): void => {
        disableEvent(e);
        //SpeechRecognition.stopListening();
    };



    let startListening= (e:any): void => {
        disableEvent(e);
        resetTranscript();
        SpeechRecognition.startListening({ continuous:false,language: 'es-UY' })
    };

    let hideModal=(): void =>{
        resetTranscript()
        setState({...state,showShortCutsModal:!state.showShortCutsModal,text:""})
    }

    let goToShortcut=(shortcut:IShortcutResult): void =>{
        hideModal()
        window.location.href = shortcut.url;
    }

    if (!browserSupportsSpeechRecognition) {
        return (<span>Browser doesn't support speech recognition.</span>);
    }
    return (
        <React.Fragment>
            <Modal show={state.showShortCutsModal}>
                <div className="card border p-0 pb-3">
                    <div className="card-header border-0 pt-3">
                        <div className="card-options">
                            <a href={window.location.href} className="card-options-remove" onClick={() =>
                                hideModal()}><i className="fe fe-x"></i></a>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        <div className="card-body">
                            <div className="">
                                <ul className="list-group">
                                    {
                                            state.shortcuts.map(shortcut => {
                                                let style="fa-list";
                                                return (
                                                    <li className="list-group-item shotcut-item">
                                                        <a  onClick={() =>goToShortcut(shortcut)}>
                                                            <i className={style +" fa-solid me-2 shotcut-item-left-icon"} aria-hidden="true"></i>{shortcut.description}
                                                            <i className="fa-solid fa-circle-play shotcut-item-right-icon"></i>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-center border-0 pt-0">
                        <div className="row">
                            <div className="text-center">
                                <a href={window.location.href} className="btn btn-white me-2"  onClick={() =>
                                    hideModal()}>{t('cancel')}</a>
                                <a href={window.location.href} className="btn me-2 btn-danger"  onClick={() =>
                                    hideModal()}>{t('shortcutnotfound')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <a 
                href={window.location.href}
                ref={ref => fooRef = ref}
                className="theme-layout nav-link-bg layout-setting"
                onMouseUp={processText}
                onMouseDown={startListening}
                onTouchEnd={processText}
            >
                <span className="dark-layout icon-svg-container">
                    <IconMicrophoneSVG className={`icon-svg icon-svg--size ${!listening || 'microphone-active'}`} ></IconMicrophoneSVG>
                    <span className="fs-16 d-none d-xl-block text-gray header-text">{t("microphone")}</span>
                    {/* <i className={"fa-solid fa-microphone header-icon "+(listening ? 'microphone-active' : 'microphone-inactive') } ></i> */}
                </span>
            </a>
            <div className="tooltip bs-tooltip-bottom dictaphone-tooltip" hidden={transcript === null || transcript.length === 0}>
                <div className="tooltip-arrow"></div>
                <div className="tooltip-inner">
                    {state.text.length>0?state.text:transcript}
                </div>
            </div>
        </React.Fragment>
        /*
        <div>
            <p>Microphone: {listening ? 'on' : 'off'}</p>
        <button onClick={() =>SpeechRecognition.startListening()}>Start</button>
        <button onClick={() =>SpeechRecognition.stopListening()}>Stop</button>
        <button onClick={() =>resetTranscript()}>Reset</button>
        <p>{transcript}</p>
        </div>
        */
);
}
export default Dictaphone;


