import React, { useState } from 'react';
import { Card, Modal } from "react-bootstrap";
import { TFunction, withTranslation } from 'react-i18next';
import Form from '../containers/form';
import Button from '../controls/buttons/button'; // Importamos el componente Button
import SubmitButton from '../controls/buttons/submitButton';
import { ReactComponent as IconTrashSVG } from "../../../node_modules/itd-common/assets/icons/trash.svg";
import { ReactComponent as IconFiltersSVG } from "../../../node_modules/itd-common/assets/icons/icon-filters.svg";
import { ReactComponent as IconCloseSVG } from "../../../node_modules/itd-common/assets/icons/close.svg";

interface IProps {
    header?: React.ReactElement,
    children: React.ReactElement,
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void,
    clear?: () => void,
    model?: any,
    hidden?: Boolean,
    t: TFunction
}

const FormFilter = ({ header, hidden, children, onSubmit, clear, model, t }: IProps) => {
    const [hiddenModal, setHiddenModal] = useState(true);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
        hidden && setHiddenModal(true);
        onSubmit(event);
    }
    }

return (
    <div className="filter-accordion form-filter mb-4">
        <>
            <Modal show={!hiddenModal} size='lg' scrollable={true}> 
                <div className='row'>
                    <div className='col-md-12 d-flex justify-content-end'>
                        <a type="button" className='me-5 mt-5' onClick={()=> setHiddenModal(true)}>
                            <IconCloseSVG ></IconCloseSVG>
                        </a>
                    </div>
                </div>
                <div className='row scrollable-container'>
                    <Form onSubmit={handleSubmit} model={model} >
                        <>
                            <div className='row ms-5 mt-5 mb-5'>
                                <h3 className='mb-1'>{t('filters')}</h3>
                                <p className='text-gray'>{t('elementsSelectedSearch')}</p>
                            </div>
                            <section className='d-flex aling-items-center justify-content-center'>
                                    {children}
                            </section>
                            <div className="row mt-5 mb-5 justify-content-end"> 
                                <div className="col-md-3 d-flex justify-content-end align-items-center">
                                    { !clear ||
                                    <a type='button' className='fw-bold' onClick={clear}>
                                        {t('clear')} <IconTrashSVG></IconTrashSVG>
                                    </a>
                                    }
                                </div>
                                <div className="col-md-3">
                                    <SubmitButton label="query" className='mt-0'></SubmitButton>
                                </div>
                                <div className="col-md-1">
                                </div>
                            </div>
                        </>
                    </Form>
                </div>
            </Modal>
            { hidden ? // si el parametro hidden viene true, se oculta los filtros y cuando se hace click en el icono. se muestran en un modal
                <>  <a type='button' onClick={() => setHiddenModal(false)}>
                        <IconFiltersSVG></IconFiltersSVG>
                    </a>
                </>
                : 
                <div className="filter-accordion mb-4">
                    <Form onSubmit={handleSubmit} model={model}>
                    <>
                        {header}
                        {children}
                        <div className="row mt-5 justify-content-end"> 
                            <div className="col-md-2 d-flex justify-content-end align-items-center">
                                { !clear ||
                                <a type='button' className='fw-bold' onClick={clear}>
                                    {t('clear')} <IconTrashSVG></IconTrashSVG>
                                </a>
                                }
                            </div>
                            <div className="col-md-3">
                                <SubmitButton label="query" className='mt-0'></SubmitButton>
                            </div>
                        </div>
                    </>
                    </Form>
                </div>
            }
        </>
        </div>
    );
};

export default withTranslation()(FormFilter);
