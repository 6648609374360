import React, {useEffect, useState} from 'react';
import {Autocomplete, DrawingManagerF, GoogleMap, MarkerF, PolygonF} from '@react-google-maps/api';
import {useTranslation} from "react-i18next";
import {IMapMarker} from "../../models/IMapMarker";

interface IProps {
    position: {
        lat: number,
        lng: number
    },
    fullAddress: string,
    onSuccess: (res: { polygonPath:{lat: number,lng: number}[]}) => void,
    showCancelButton: boolean,
    polygonPath:{lat: number,lng: number}[],
    additionalMarkers:IMapMarker[],
}
//const ScriptLoaded = require("../../docs/ScriptLoaded").default;
interface IState {
    center: {
        lat: number,
        lng: number
    },
    position: {
        lat: number,
        lng: number
    },
    autocomplete: any,
    fullAddressForm: string,
    editEnabled: boolean,
    polygonComplete:boolean,
    polygonPath:{lat: number,lng: number}[],
    map:google.maps.Map|null,
    additionalMarkers:IMapMarker[]
}




let CoverageLocation: React.FC<IProps> = (props) => {

    const { t } = useTranslation();

    let [state, setState] = useState<IState>({
        center: {
            lat: -34.9070181,
            lng: -56.2019864
        },
        position: {
            lat: 0,
            lng: 0
        },
        autocomplete: null,
        fullAddressForm: "",
        editEnabled: false,
        polygonComplete:false,
        polygonPath:[],
        map:null,
        additionalMarkers:[]
    });

    const containerStyle = {
        width: '100%',
        height: '400px',

    };

    let onSuccess = function () {
        setState({ ...state, editEnabled: false });
        props.onSuccess({ polygonPath: state.polygonPath });
    }

    let onCancel = function () {
        window.location.reload();
    }

    useEffect(() => {

        if (props.position != null) {

            setState({
                ...state,
                polygonPath:props.polygonPath,
                position: props.position,
                center: props.position,
                fullAddressForm:
                    props.fullAddress,
                additionalMarkers:props.additionalMarkers
            })
        }

    }, [props])

    let onLoad = function (autocomplete: any) {
        state.autocomplete = autocomplete
    }

    let onPlaceChanged = function () {
        if (state.autocomplete !== null) {
            setState({
                ...state, center: {
                    lat: state.autocomplete.getPlace().geometry.location.lat(),
                    lng: state.autocomplete.getPlace().geometry.location.lng()
                }, position: {
                    lat: state.autocomplete.getPlace().geometry.location.lat(),
                    lng: state.autocomplete.getPlace().geometry.location.lng()
                },
                fullAddressForm: state.autocomplete.getPlace().formatted_address
            })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }



    let onMarkLoad = function (marker: any) {
        console.log('marker: ', marker)

    }

    let onChangeInput = function (event: React.ChangeEvent<HTMLInputElement>) {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    let onPolygonComplete = function (polygon: google.maps.Polygon) {
        //if(state.editEnabled){
        let  polygonPath:{lat: number,lng: number}[]=[];
        polygon.getPath().forEach(function(a,b){
            polygonPath.push({lat:a.lat(),lng:a.lng()})
            //console.log(a+","+b);
        })
         setState({...state,polygonComplete:true,polygonPath:polygonPath})
        //}

    }

    let onDrawLoad = function (drawingManager: google.maps.drawing.DrawingManager) {
        setState({...state,map:drawingManager.getMap()})
    }



    return (
        <React.Fragment>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{t("address")}</h3>
                    <div style={{ position: "absolute", right: "10px" }}>
                        <button disabled={state.editEnabled} type="button" className="btn btn-icon  btn-primary" onClick={() => setState({ ...state, editEnabled: true,polygonPath:[] })}><i
                            className="fe fe-edit-2"></i></button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">

                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            options={{
                                componentRestrictions: { country: "uy" },
                                types: ["address"]
                            }
                            }
                        >
                            <input
                                disabled={true}
                                onChange={onChangeInput}
                                type="text"
                                placeholder={t("address")}
                                name="fullAddressForm"
                                value={state.fullAddressForm}
                                className="form-control"></input>
                        </Autocomplete>
                        <p></p>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={state.center}
                            zoom={15}
                            >
                            <PolygonF
                                path={state.polygonPath}
                                key="polygon"
                                options={{
                                    fillOpacity: 0.4,
                                    strokeColor: "black",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 3
                                }}
                            />
                            <DrawingManagerF
                                onPolygonComplete={(event)=>{onPolygonComplete(event)}}
                                onLoad={onDrawLoad}
                                options={ {
                                    drawingControl:false,
                                    drawingMode:(state.editEnabled&&!state.polygonComplete)?google.maps.drawing.OverlayType.POLYGON:undefined
                                }}
                            />
                            {
                                state.additionalMarkers.map((marker, Tindex) => {
                                    return (
                                        <MarkerF
                                            onLoad={onMarkLoad}
                                            key={marker.name}
                                            position={{lat:marker.lat,lng:marker.lng}}

                                            options={{ icon:{url:marker.imagePath,scaledSize:new google.maps.Size(50,50)},opacity:marker.enabled?0.5:1}}
                                        />
                                    )
                                })
                            }
                            <MarkerF
                                onLoad={onMarkLoad}
                                position={state.position}
                            />
                        </GoogleMap>
                    </div>
                </div>
                <div className="card-footer">
                    <button style={{ marginLeft: "10px" }} type="button" disabled={!state.editEnabled} className="btn btn-success"
                        onClick={() => onSuccess()} ><i className="fe fe-save me-2"></i>{t("save")}
                    </button>

                    {props.showCancelButton &&
                        <button type="button" disabled={!state.editEnabled} className="btn btn-danger"
                            onClick={() => onCancel()} ><i className="fe fe-save me-2"></i>{t("cancel")}
                        </button>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default CoverageLocation;
