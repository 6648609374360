import "./checkBoxField.scss";
import { withTranslation } from "react-i18next";
import { useModel } from "../../contexts/modelContext";
import { ITranslationPros } from "../controlsPropertiesInterface";
import { IPropsField, IPropsInputChange } from "./fieldPropertiesInterface";
import modelHelper from "./modelHelper";

type IPropsChecked = { checked: boolean };
type IPropsCheckBoxField = IPropsField & IPropsInputChange & ITranslationPros & IPropsChecked;

function CheckBoxField({
  id,
  model,
  attr,
  label,
  name,
  onChange,
  checked,
  t
}: IPropsCheckBoxField) {
    const modelContext = useModel();
    const modelState = model || modelContext;
    const { getValue, onValueChange } = modelHelper({ model: modelState, attr, value: String(checked).toString(), onChange });

    const getKey = (): string => {
        return `input-${id || attr || label}-${name}`;
    }
  
    return (
        <>
            <input
                className='checkbox-field icon-select-field'
                type="checkbox"
                key={getKey()}
                id={id}
                onChange={onValueChange}
                value={getValue()}
                checked={checked}
                placeholder=" "/>
            <label>
                {t(`${label}`)}
            </label>
        </>
    );
}

export default withTranslation()(CheckBoxField);