import React from 'react';
import { withTranslation } from 'react-i18next';
import { IContainerProps } from './containersInterfaces';

const Card: React.FC<IContainerProps> = ({ title, children, actions, t }) => {

    const renderHeader = () => {
        return (
            <>
                <div className={`${ (title || actions) ? 'card-header' : '' } d-flex justify-content-between`}>
                    { !title || <h2 className="card-title">{t(title)}</h2> }
                    {actions}
                </div>
            </>            
        );
    }
    
    const renderBody = () => {
        return (
            <div className="card-body">
                {children}
            </div>
        );
    }

    return (
        <div className="card">
            { renderHeader() }
            { renderBody() }
        </div>
    )
}

export default withTranslation()(Card);
